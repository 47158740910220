import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const ITINERARY_UPDATE = 'ITINERARY_UPDATE';
export const ITINERARY_TEXT_UPDATE = 'ITINERARY_TEXT_UPDATE';
export const ITINERARY_DEEP_UPDATE = 'ITINERARY_DEEP_UPDATE';
export const ITINERARY_RESET = 'ITINERARY_RESET';
export const ITINERARY_SAVE = 'ITINERARY_SAVE';
export const ITINERARY_SAVE_SUCCESS = 'ITINERARY_SAVE_SUCCESS';
export const ITINERARY_LIBORDER_UPDATE = 'ITINERARY_LIBORDER_UPDATE';

let dummyItinerary: AppModel.Itinerary = null;

const logService = new LogService();
//const helpersService = new HelpersService();

export function itineraryReducer(state: AppModel.Itinerary = dummyItinerary, action: ActionWithPayload) {
  switch (action.type) {
    case ITINERARY_UPDATE:
    state = action.payload;
    state.saved = false;
    logService.consoleLog('ITINERARY_UPDATE:',state);
    return state;

    case ITINERARY_TEXT_UPDATE:
    state.saved = false;
    logService.consoleLog('ITINERARY_TEXT_UPDATE:',state);
    return state;

    case ITINERARY_LIBORDER_UPDATE:
    state = action.payload;
    //state = dayCalculationService.calc(state);
    logService.consoleLog('ITINERARY_LIBORDER_UPDATE',state);
    return state;

    case ITINERARY_SAVE:
    //state = action.payload; // enable this line to use the api response as new state, i disabled it for now as any dateString needs to be converted to dateObject, and the view will need to be rerendered. At this time it also doesnt provide anything extra
    state.saved = true;
    logService.consoleLog('ITINERARY_SAVE:',state);
    return state;

    case ITINERARY_SAVE_SUCCESS:
    state = action.payload;
    state.saved = true;
    return state;

    case ITINERARY_RESET:
    return dummyItinerary;

    default:
    return state;
  }
}
