import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-expired-dialog',
  templateUrl: './expired-dialog.component.html',
  styleUrls: ['./expired-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpiredDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
