import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-advanced-margin-edit-dialog',
  templateUrl: './advanced-margin-edit-dialog.component.html',
  styleUrls: ['./advanced-margin-edit-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedMarginEditDialogComponent implements OnInit {
  title: string;
  inputHeader: string;
  margin: number;
  data: any;
  selectedSegmentsData: any;
  roundPrices = false;

  displayedColumns = ['Title', 'Subtitle', 'Cost EUR', 'Margin %', 'Sales EUR', 'Actions'];
  selectedRows = [];

  constructor(private matDialogRef: MatDialogRef<AdvancedMarginEditDialogComponent>) { }

  ngOnInit() {
    if (this.matDialogRef.componentInstance !== null) {
      this.title = this.matDialogRef.componentInstance.title;
      this.inputHeader = this.matDialogRef.componentInstance.inputHeader;
      this.margin = this.matDialogRef.componentInstance.margin;
      if (this.margin > 100) {
        this.margin = 100;
      } else if (this.margin < -1000) {
        this.margin = 0;
      }
      this.data = this.matDialogRef.componentInstance.data;
      if (this.matDialogRef.componentInstance.selectedSegmentsData != null && this.matDialogRef.componentInstance.selectedSegmentsData.length > 0) {
        this.selectedSegmentsData = this.matDialogRef.componentInstance.selectedSegmentsData;
        for (const option of this.selectedSegmentsData) {
          if (option['Margin %'] > 0 && this.checkCost(option['Cost EUR']) === false && option.MarginEnabled === false) {
            this.selectedRows.push(option)
          }
        }
      }
    }
  }

  selectOption(option) {
    if (this.selectedRows && this.selectedRows.length > 0) {
      for (let i = 0; i < this.selectedRows.length; i++) {
        if (this.selectedRows[i].Title === option.Title && this.selectedRows[i].Subtitle === option.Subtitle && this.selectedRows[i]['Margin %'] === option['Margin %'] && this.selectedRows[i]['Sales EUR'] === option['Sales EUR']) {
          this.selectedRows.splice(i, 1);
          return;
        }
      }
    }
    this.selectedRows.push(option);
  }

  checkCost(cost) {
    if (Number(cost) === 0) {
      return true;
    }
    return false;
  }

  selectOptions() {
    let checkAll = false;
    for (const option of this.data) {
      if (this.checkSelectedOptions(option) === false) {
        if (option['Margin %'] > 0 && this.checkCost(option['Cost EUR']) === false && option.MarginEnabled === false) {
          checkAll = true;
          break;
        }
      }
    }

    if (checkAll === true) {
      for (const option of this.data) {
        if (option['Margin %'] > 0 && this.checkCost(option['Cost EUR']) === false && option.MarginEnabled === false) {
          this.selectedRows.push(option)
        }
      }
    } else {
      this.selectedRows = [];
    }
  }

  checkSelectedOptions(option) {
    for (const row of this.selectedRows) {
      if (row.Title === option.Title && row.Subtitle === option.Subtitle && row['Margin %'] === option['Margin %'] && row['Cost EUR'] === option['Cost EUR'] && row.extra.id === option.extra.id && option.MarginEnabled === false) {
        return true;
      }
    }
    return false;
  }

  updateRoundPrices() {
    this.roundPrices = !this.roundPrices;
  }

  closeDialog() {
    for (let i = 0; i < this.selectedRows.length; i++) {
      if (this.selectedRows[i]['Margin %'] < 1 || this.checkCost(this.selectedRows[i]['Cost EUR']) === true || this.selectedRows[i].MarginEnabled === true) {
        this.selectedRows.splice(i, 1);
        i--;
      }
    }
    this.matDialogRef.close({ margin: this.margin, selectedRows: this.selectedRows, roundPrices: this.roundPrices })
  }
}
