//import { LogService } from '../services/log.service';
import { ActionWithPayload } from '../models/action-with-payload';
//const logService = new LogService();

export const BAG_ADD = 'BAG_ADD';
export const BAG_REMOVE = 'BAG_REMOVE';

let initialState = {
  parties: ['available-participants'],
  elements: [],
  flightElements: [],
  sideSegments: [],
  media: [],
  texts: [],
  partiesLength: 0,
  elementsLength: 0,
  mediaLength: 0,
  textsLength: 0
}

export function bagsReducer(state: any = initialState, action: ActionWithPayload) {
  switch (action.type) {
    case BAG_ADD:
      if (state[action.payload.type].indexOf(action.payload.bag) === -1) {
        state[action.payload.type].push(action.payload.bag);
      }
      state[`${action.payload.type}Length`] = state[action.payload.type].length;
      // console.log('BAG_ADD:',state);
      return { ...state };
    case BAG_REMOVE:
      const bagIndex = state[action.payload.type].indexOf(action.payload.bag);
      if (bagIndex > -1) {
        state[action.payload.type].splice(bagIndex, 1);
      }
      state[`${action.payload.type}Length`] = state[action.payload.type].length;
      //logService.consoleLog('BAG_REMOVE:',state);
      return { ...state };
    default:
      return { ...state };
  }
}
