import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-desty-party-dialog',
  templateUrl: './desty-party-dialog.component.html',
  styleUrls: ['./desty-party-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestyPartyDialogComponent implements OnInit {
  data: any;
  type: any;
  fromDate: any;

  parties = [];

  constructor(public ref: MatDialogRef<any>) {
    ref.disableClose = false;
  }

  ngOnInit() {
    if (typeof this.ref.componentInstance === 'object' && this.ref.componentInstance !== null) {
      this.data = this.ref.componentInstance.data;
      this.type = this.ref.componentInstance.type;
      this.fromDate = this.ref.componentInstance.fromDate;
      this.formatParticipantData();
    }
    if (this.type === 'area-search') {
      this.ref.updatePosition({ top: '310px', right: '240px' });
    } else {
      this.ref.updatePosition({ top: '290px', right: '240px' });
    }
    this.ref.beforeClose().subscribe(() => this.ref.close(this.parties));
  }

  formatParticipantData() {
    // for (const party of Object.keys(this.data)) { // This works if we want child support
    //   let adults = 0;
    //   let children = 0;
    //   for (const participant of this.data[party]) {
    //     if (participant.age_calc_type === 'adult') {
    //       adults++;
    //     } else if (participant.age_calc_type === 'child') {
    //       children++;
    //     } else {
    //       if (participant.birthdate === '') {
    //         adults++;
    //       } else {
    //         if (this.helpersService.calculateAge(participant.birthdate, this.fromDate) > 17) {
    //           adults++;
    //         } else {
    //           children++;
    //         }
    //       }
    //     }
    //   }
    //   this.parties.push({
    //     name: party,
    //     adults: adults,
    //     children: children
    //   });
    // }
    for (const party of Object.keys(this.data)) {
      if (this.data[party] > 5) {
        this.data[party] = 5;
      }
      this.parties.push({
        name: party,
        adults: this.data[party],
        children: 0
      });
    }
  }

  setValue(party, type, event) {
    party[type] = event.value;
  }
}
