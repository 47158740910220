import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, ViewChild, Input, OnChanges, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatSelect } from '@angular/material';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Observable, Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, map, takeUntil, take } from 'rxjs/operators';
import { Config } from '../../classes/config.class';
import { AuthService, isGlobal } from '../../services/auth.service';
import { VisualtourbuilderService } from '../../services/visualtourbuilder.service';
import { TelespiritService } from '../../services/telespirit.service';
import { ElementInfoDialogComponent } from '../element-info-dialog/element-info-dialog.component';
import { CreateElementDialogComponent } from '../create-element-dialog/create-element-dialog.component';
import { HelpersService } from 'app/services/helpers.service';

@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('categorySelect') selectElem: MatSelect;
  @ViewChild('form') elementCategoryForm: NgForm;
  @ViewChild('searchContainer', { read: ElementRef }) searchContainer: ElementRef;
  @Input() bags: any[] = [];
  @Input() unitsValue: any;

  unsubscribe = new Subject<void>();

  env: any;

  elements$: Observable<AppModel.Element[]>;
  elementsSubscription: Subscription;
  elementsValue: AppModel.Element[];

  elementCategories$: Observable<any[]>;
  elementCategoriesSubscription: Subscription;
  elementCategoriesValue: any[];

  departments$: Observable<any[]>;
  departmentsSubscription: Subscription;
  departmentsValue: any[];
  departments: number[];

  searchText: string;
  searchTextChanged: Subject<string> = new Subject<string>();
  searchTextChangedSubscription: Subscription;

  filteredOptions: Observable<string[]>;
  elementCategory: any[];
  oldElementCategory: any[];

  offset: number;
  oldQuery: any;

  favoriteUnits = [];
  unitsArray = [];
  activeUnit: any;

  searchAmount = 0;
  pageNr = 0;

  sameSearch = false;
  tagOnly = false;
  busy = false;

  searched = false;
  createElementDialogOpened = false;

  multipleClickIndex: number;
  selectedElements = [];

  isGlobalValue = isGlobal;

  constructor(
    private auth: AuthService,
    public config: Config,
    private store: Store<any>,
    private telespiritService: TelespiritService,
    private visualTourBuilderService: VisualtourbuilderService,
    private helpersService: HelpersService,
    private changeDetectorRef: ChangeDetectorRef,
    private overlayContainer: OverlayContainer,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.env = this.config;
    this.elements$ = this.store.select('elements');
    this.elementCategories$ = this.store.select('elementCategories');
    this.departments$ = this.store.select('departments');
  }

  ngOnInit() {
    if (this.selectElem != null) {
      this.selectElem.openedChange.pipe(takeUntil(this.unsubscribe)).subscribe((opened) => {
        if (!opened) {
          this.overlayContainer.getContainerElement().classList.remove('select-overlay');
        }
      });
    }
    if (this.env.enablePrefillDepartmentSearch === true) {
      if (this.isGlobalValue === true && this.env.tsToken != null) {
        if (this.env.tpData != null && this.env.tpData.department != null) {
          this.departments = [this.env.tpData.department.id];
        }
      } else {
        if (this.auth.tokenId != null && this.auth.decodedToken[this.auth.tokenId].departmentId) {
          this.departments = [this.auth.decodedToken[this.auth.tokenId].departmentId];
        }
      }
    }
    this.elementsSubscription = this.elements$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.busy = false;
      if (x && x.length > 0) {
        if (this.elementsValue == null || this.elementsValue.length === 0) {
          this.elementsValue = x;
        } else {
          for (const element of x) {
            let found = false;
            for (const elementValue of this.elementsValue) {
              if (elementValue.TSProduct.id === element.TSProduct.id) {
                found = true;
              }
            }
            if (found === false) {
              this.elementsValue.push(element);
            }
          }
        }
      } else {
        this.elementsValue = [];
      }
      if (this.router.url.indexOf('map-view') > -1) {
        this.router.navigate(['/map-view'], { queryParams: this.tokenObj });
      }
      this.changeDetectorRef.detectChanges();
    });
    this.departmentsSubscription = this.departments$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.departmentsValue = x;
      this.changeDetectorRef.detectChanges();
    });
    this.searchTextChangedSubscription = this.searchTextChanged.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscribe)).subscribe(x => {
      if (this.searchText != null && this.searchText.length > 1) {
        this.search(true);
      }
    });
    this.elementCategoriesSubscription = this.elementCategories$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      if (x != null) {
        this.elementCategoriesValue = x.reduce((all, parent, index) => {
          parent.depth = 'depth-0';
          parent.visible = true;
          parent.expand = false;
          all.push(parent);
          if (parent.children != null) {
            for (let child of parent.children) {
              child.depth = 'depth-1';
              child.visible = false;
              child.expand = false;
              all.push(child);
              if (child.children != null) {
                for (let child_2 of child.children) {
                  child_2.depth = 'depth-2';
                  child_2.visible = false;
                  child_2.expand = false;
                  all.push(child_2);
                  if (child_2.children != null) {
                    for (let child_3 of child_2.children) {
                      child_3.depth = 'depth-3';
                      child_3.visible = false;
                      child_3.expand = false;
                      all.push(child_3);
                    }
                  }
                }
              }
            }
          }
          return all;
        }, []);
        this.filteredOptions = this.elementCategoryForm.valueChanges.pipe(startWith(''), map((value: any) => this._filter(value)));
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnChanges() {
    this.unitsArray = [];
    if (this.favoriteUnits.length < 4 || (this.env && this.env.favoriteUnits && this.env.favoriteUnits.length > 0 && this.favoriteUnits.length < this.env.favoriteUnits.length)) {
      if (this.env && this.env.favoriteUnits && this.env.favoriteUnits.length > 0) {
        if (this.unitsValue && this.unitsValue.items) {
          for (const unitId of this.env.favoriteUnits) {
            if (this.favoriteUnits.length < 4) {
              this.favoriteUnits.push(this.unitsValue.items[unitId]);
            }
          }
        }
      } else {
        if (this.unitsValue && this.unitsValue.items) {
          for (const unitId of Object.keys(this.unitsValue.items)) {
            if (this.favoriteUnits.length < 4) {
              this.favoriteUnits.push(this.unitsValue.items[unitId]);
            }
            this.unitsArray.push(this.unitsValue.items[unitId]);
          }
        }
      }
    } else {
      if (this.unitsValue && this.unitsValue.items) {
        for (const unitId of Object.keys(this.unitsValue.items)) {
          this.unitsArray.push(this.unitsValue.items[unitId]);
        }
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get offsetHeight() {
    if (this.searchContainer && this.searchContainer.nativeElement) {
      return this.searchContainer.nativeElement.offsetHeight + 48;
    }
    return 0;
  }

  get mergedBags() {
    return this.bags.concat(['side-itinerary-segments']);
  }

  private _filter(value: any): any[] {
    const filterValue = value.elementCategoryCtrl != null && typeof value.elementCategoryCtrl.toLowerCase === 'function' ? value.elementCategoryCtrl.toLowerCase() : '';
    return this.elementCategoriesValue ? this.elementCategoriesValue.filter(option => option.name.toLowerCase().includes(filterValue)) : [];
  }

  beforeOpen() {
    this.updateDropdownWidth();
    this.overlayContainer.getContainerElement().classList.add('select-overlay');
  }

  displayFn(category?: any): string | undefined {
    return category ? category.name : undefined;
  }

  search(reset = null, pageNr = null) {
    this.disableOptions();
    this.oldElementCategory = this.elementCategory;

    this.busy = true;
    if (reset === true) {
      this.elementsValue = [];
      this.pageNr = 1;
    }
    this.changeDetectorRef.detectChanges();

    if (isGlobal === false || this.config.baseUrl != null) {
      let queryParams: string = '{"advanceQuery":"';

      if (this.elementCategory !== null && Object.prototype.toString.call(this.elementCategory) === '[object Array]' && this.elementCategory.length > 0) {
        for (const category of this.elementCategory) {
          if (category.name === 'Uncheck all') {
            this.uncheckAllCategories();
          }
        }
      }
      if (this.elementCategory !== null && Object.prototype.toString.call(this.elementCategory) === '[object Array]' && this.elementCategory.length > 0) {
        if (queryParams[queryParams.length - 1] === ')') {
          queryParams += ' AND ';
        }
        queryParams += '(PRO_CAT_ID IN (';

        let parentId = 99999;

        for (const category of this.elementCategory) {
          if (category.parent_id == null) {
            parentId = category.id;

            if (queryParams[queryParams.length - 1] === '(') {
              queryParams += category.id;
            } else {
              queryParams += ')) AND (PRO_CAT_ID IN (' + category.id;
            }
          } else {
            if (parentId !== category.parent_id) {
              parentId = category.parent_id;
              if (queryParams[queryParams.length - 1] === '(') {
                queryParams += category.id;
              } else {
                queryParams += ')) AND (PRO_CAT_ID IN (' + category.id;
              }
            } else {
              if (queryParams[queryParams.length - 1] === '(') {
                queryParams += category.id;
              } else {
                queryParams += ',' + category.id;
              }
            }
          }
        }
        queryParams += '))';
      }
      if (this.departments != null && this.departments.length > 0 && this.departmentsValue != null && this.departmentsValue.length > 0) {
        if (queryParams[queryParams.length - 1] === ')') {
          queryParams += ' AND ';
        }

        queryParams += '(PRO_DEPT_ID IN (';

        for (let j = 0; j < this.departments.length; j++) {
          if (j !== 0) {
            queryParams += ',' + this.departments[j];
          } else {
            queryParams += this.departments[j];
          }
        }

        queryParams += '))';
      }
      if (this.searchText != null && this.searchText !== '') {
        if (queryParams[queryParams.length - 1] === ')') {
          queryParams += ' AND ';
        }
        if (this.tagOnly === true) {
          queryParams += '(PRO_TAG ~ ' + this.searchText + ')';
        } else {
          queryParams += '(PRO_TAG ~ ' + this.searchText + ' OR PRO_NAME ~ ' + this.searchText + ')';
        }
        if (this.isNumber(this.searchText) === true) {
          this.telespiritService.getElement(this.searchText).then((element: any) => {
            if (element.id != null) {
              const elements = [];
              const markers = [];
              if (element.unitId && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId].isTimeBased === true) {
                if (element.unitAmount === 0) {
                  element.unitAmount = 1;
                }
                element.nights = Math.max(eval((this.unitsValue.items[element.unitId].multiplier || 'X*1').replace(/x/ig, element.unitAmount)), 0);
              } else {
                element.nights = 0;
              }
              if (this.config.manualCalcByDefault === true) {
                element.manual_calc = true;
              }
              if (element.media != null && element.media.length > 0) {
                element.media = element.media.filter(x => x.mediaspirit_id != null || x.sourceType === 'mediaspirit');
              }
              if (element.maps != null && element.maps.latitude != null && element.maps.longitude != null) {
                element.maps.enabled = true;
                markers.push({ title: element.title, description: element.description, latitude: element.maps.latitude, longitude: element.maps.longitude });
              }
              if (element.hasOwnProperty('TSOrderline') === false || typeof element.TSOrderline !== 'object') {
                element.TSOrderline = {
                  extraFieldValues: element.orderlineExtrafields
                };
                delete element.orderlineExtrafields;
              }
              if (element.roomTypes != null && element.roomTypes.length > 0) {
                element.roomTypes = this.helpersService.sortSubtitles(element.roomTypes, this.env.defaultSubtitleSort, this.env.orderLanguage, element.dataSource, this.env.enableLanguageSubtitles);
              }
              elements.push(element);
              this.store.dispatch({ type: 'MARKERS_UPDATE', payload: markers });
              this.store.dispatch({ type: 'ELEMENTS_UPDATE', payload: elements, config: this.env });
            }
          });
        } else {
          if (this.activeUnit != null && this.activeUnit.name != null && this.activeUnit.name !== '') {
            queryParams += ` AND PRO_UNIT_NAME ~ ${this.activeUnit.name}`
          }
          queryParams += '"}';
          if (JSON.stringify(queryParams) === JSON.stringify(this.oldQuery)) {
            this.sameSearch = true;
          }
          this.telespiritService.searchElements(this.searchText, queryParams, pageNr).then(data => {
            const res: any = data;
            this.searchAmount = res.amount;
            this.pageNr = Number(res.pageNr) + 1;
            this.oldQuery = queryParams;
            this.busy = false;
            this.changeDetectorRef.detectChanges();
          });
        }
      } else {
        if (queryParams.length > 17) {
          if (this.activeUnit != null && this.activeUnit.name != null && this.activeUnit.name !== '') {
            queryParams += ` AND PRO_UNIT_NAME ~ ${this.activeUnit.name}`
          }
          queryParams += '"}';
          if (JSON.stringify(queryParams) === JSON.stringify(this.oldQuery)) {
            this.sameSearch = true;
          }
          this.telespiritService.searchElements(this.searchText, queryParams, pageNr).then(data => {
            const res: any = data;
            this.searchAmount = res.amount;
            this.pageNr = Number(res.pageNr) + 1;
            this.oldQuery = queryParams;
            this.busy = false;
            this.changeDetectorRef.detectChanges();
          });
        } else {
          if (this.activeUnit != null && this.activeUnit.name != null && this.activeUnit.name !== '') {
            queryParams += `PRO_UNIT_NAME ~ ${this.activeUnit.name}"}`;
            if (JSON.stringify(queryParams) === JSON.stringify(this.oldQuery)) {
              this.sameSearch = true;
            }
            this.telespiritService.searchElements(this.searchText, queryParams, pageNr).then(data => {
              const res: any = data;
              this.searchAmount = res.amount;
              this.pageNr = Number(res.pageNr) + 1;
              this.oldQuery = queryParams;
              this.busy = false;
              this.changeDetectorRef.detectChanges();
            });
          } else {
            this.elementsValue = null;
            this.busy = false;
          }
        }
      }
    } else {
      this.visualTourBuilderService.getVtbElements(this.searchText, this.activeUnit || null, this.auth, this.pageNr, 20).then(data => {
        if (data != null) {
          this.searchAmount = data.totalRows;
          if (reset) {
            this.pageNr = 2;
          } else {
            this.pageNr = this.pageNr + 1;
          }
        }
        this.busy = false;
        this.changeDetectorRef.detectChanges();
      });
    }

    this.searched = true;
  }

  onSearchTextChange(query: string) {
    this.searchTextChanged.next(query);
  }

  isNumber(val) {
    if (Number(val) > 0) {
      return true;
    }
    return false;
  }

  expand(element, $event) {
    if (element.expand === false) {
      element.expand = true;
      for (const child of element.children) {
        child.visible = true;
        if (child.children != null && child.expand === true) {
          for (const child2 of child.children) {
            child2.visible = true;
            if (child2.children != null && child2.expand === true) {
              for (const child3 of child2.children) {
                child3.visible = true;
                if (child3.children != null && child3.expand === true) {
                  for (const child4 of child3.children) {
                    child4.visible = true;
                    if (child4.children != null && child4.expand === true) {
                      for (const child5 of child4.children) {
                        child5.visible = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      $event.stopPropagation();
      return;
    }
    element.expand = false;
    for (const child of element.children) {
      child.visible = false;
      if (child.children != null) {
        for (const child2 of child.children) {
          child2.visible = false;
          if (child2.children != null) {
            for (const child3 of child2.children) {
              child3.visible = false;
              if (child3.children != null) {
                for (const child4 of child3.children) {
                  child4.visible = false;
                  if (child4.children != null) {
                    for (const child5 of child4.children) {
                      child5.visible = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    $event.stopPropagation();
  }

  disableOptions() {
    if (this.oldElementCategory != null) {
      for (const element of this.oldElementCategory) {
        if (element.children && element.children.length > 0) {
          for (const child of element.children) {
            child.disabled = false;
            if (child.children != null) {
              for (const child2 of child.children) {
                child2.disabled = false;
                if (child2.children != null) {
                  for (const child3 of child2.children) {
                    child3.disabled = false;
                    if (child3.children != null) {
                      for (const child4 of child3.children) {
                        child4.disabled = false;
                        if (child4.children != null) {
                          for (const child5 of child4.children) {
                            child5.disabled = false;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (this.elementCategory) {
      for (const element of this.elementCategory) {
        if (element.children && element.children.length > 0) {
          for (const child of element.children) {
            child.disabled = true;
            if (child.children != null) {
              for (const child2 of child.children) {
                child2.disabled = true;
                if (child2.children != null) {
                  for (const child3 of child2.children) {
                    child3.disabled = true;
                    if (child3.children != null) {
                      for (const child4 of child3.children) {
                        child4.disabled = true;
                        if (child4.children != null) {
                          for (const child5 of child4.children) {
                            child5.disabled = true;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  getBorderColor(element) {
    let strikingKeys = [];
    if (this.env.striking != null) {
      strikingKeys = Object.keys(this.env.striking);
    }
    if (element.categories && element.categories.length > 0) {
      for (const category of element.categories) {
        for (const key of strikingKeys) {
          if (category.name.toLowerCase() === key.toLowerCase()) {
            return this.env.striking[key];
          }
        }
      }
    }
    return "1px dashed #777777";
  }

  registerPanelScrollEvent() {
    if (this.selectElem && this.selectElem.panel && this.selectElem.panel.nativeElement) {
      this.offset = 0;
      const panel = this.selectElem.panel.nativeElement;
      panel.addEventListener('scroll', event => this.resetScroll(event, panel));
    }
  }

  resetScroll(event, panel) {
    if (this.offset - event.target.scrollTop < 120 && this.offset - event.target.scrollTop > -120) {
      this.offset = event.target.scrollTop;
    } else {
      panel.scrollTop = this.offset;
    }
  }

  checkLoadMore() {
    if (this.elementsValue.length < this.searchAmount) {
      return true;
    }
    return false;
  }

  checkLength(array) {
    if (array && array.length > 0) {
      return true;
    }
    return false;
  }

  getElementTooltip(element) {
    let categoryText = '';
    let tag = '';
    let internalInfoClean = '';
    if (element.categories != null && element.categories.length > 0) {
      for (const category of element.categories) {
        categoryText += category.name + '\n';
      }
    }
    if (element.TSProduct != null && element.TSProduct.tag != null) {
      tag = element.TSProduct.tag;
    }
    if (element.internalInfoClean != null) {
      internalInfoClean = element.internalInfoClean;
    }
    if (tag.length > 0 && internalInfoClean.length > 0) {
      return element.title + '\n' + tag + '\n' + internalInfoClean + '\n' + categoryText;
    } else if (tag.length > 0 && internalInfoClean.length === 0) {
      return element.title + '\n' + tag + '\n' + categoryText;
    } else if (tag.length === 0 && internalInfoClean.length > 0) {
      return element.title + '\n' + internalInfoClean + '\n' + categoryText;
    } else {
      return element.title + '\n' + categoryText;
    }
  }

  uncheckAllCategories() {
    this.elementCategory = [];
  }

  updateDropdownWidth() {
    const element: HTMLElement = document.querySelector('.cdk-overlay-pane');
    if (element) {
      element.style.left = Number(element.offsetLeft - 252) + 'px';
      element.style.top = '65px';
    }
  }

  getElementExtraInfo(element) {
    let dialogRef: any = this.dialog.open(ElementInfoDialogComponent, {
      height: '600px',
      width: '800px',
    });
    dialogRef.componentInstance = {
      element: element
    }
  }

  get tokenObj() {
    let obj: any = {};
    if (this.auth.authenticated != null && this.auth.authenticated === true) {
      obj.token = this.auth.token;
    }
    return obj;
  }

  goToMap(element) {
    const tempObj = this.tokenObj;
    tempObj.lat = element.maps.latitude;
    tempObj.lng = element.maps.longitude;

    this.router.navigate(['/map-view'], { queryParams: tempObj })
  }

  checkMapData(element) {
    if (element.maps != null) {
      if (element.maps.latitude != null && !isNaN(element.maps.latitude) && element.maps.longitude != null && !isNaN(element.maps.longitude)) {
        return true
      }
    }
    return false;
  }

  showSideContext(type) {
    this.store.dispatch({ type: 'USER_INTERFACE_SHOW_CONTEXT', payload: type });
  }

  setActiveUnit(unit) {
    if (this.activeUnit == null || this.activeUnit.id != unit.id) {
      this.activeUnit = unit;
      this.search(true);
    } else {
      this.activeUnit = null;
      this.search(true);
    }
  }

  addCustomElement(unit) {
    this.createElementDialogOpened = true;
    let dialogRef: any = this.dialog.open(CreateElementDialogComponent, {
      hasBackdrop: false,
      height: '500px',
      width: '700px',
      panelClass: 'no-padding-dialog'
    });
    dialogRef.componentInstance = {
      units: this.unitsValue,
      selectedUnit: unit,
      categories: this.filteredOptions || null
    }
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.createElementDialogOpened = false;
      if (result != null && result !== '') {
        if (isGlobal === true) {
          this.visualTourBuilderService.createVtbElement(result).then(x => {
            if (x === true) {
              this.showSideContext('elements');
            }
          });
        } else {
          console.log('BO create', result);
        }
      }
    });
  }

  multiSelect(element, event, index) {
    if (event.ctrlKey || event.shiftKey || event.metaKey) {
      if (this.multipleClickIndex == null) {
        this.multipleClickIndex = index;
      } else {
        if (event.shiftKey === false) {
          this.multipleClickIndex = index;
        }
      }
      if (element.multiSelected === true) {
        delete element.multiSelected;
        if (event.shiftKey === true && this.multipleClickIndex !== index) {
          if (this.multipleClickIndex > index) {
            for (let i = index; i < (this.multipleClickIndex + 1); i++) {
              if (this.elementsValue[i].multiSelected != null) {
                delete this.elementsValue[i].multiSelected;
              }
            }
          } else {
            for (let i = index; i > (this.multipleClickIndex - 1); i--) {
              if (this.elementsValue[i].multiSelected != null) {
                delete this.elementsValue[i].multiSelected;
              }
            }
          }
        }
      } else {
        element.multiSelected = true;
        if (event.shiftKey === true && this.multipleClickIndex !== index) {
          if (this.multipleClickIndex > index) {
            for (let i = index; i < (this.multipleClickIndex + 1); i++) {
              this.elementsValue[i].multiSelected = true;
            }
          } else {
            for (let i = index; i > (this.multipleClickIndex - 1); i--) {
              this.elementsValue[i].multiSelected = true;
            }
          }
        }
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  checkMultiSelected() {
    if (this.elementsValue != null && this.elementsValue.length > 0) {
      for (const element of this.elementsValue) {
        if (element.multiSelected === true) {
          return true;
        }
      }
    }
    return false;
  }

  clearMultiSelection() {
    if (this.elementsValue != null && this.elementsValue.length > 0) {
      for (const element of this.elementsValue) {
        if (element.multiSelected === true) {
          element.multiSelected = false;
        }
      }
    }
  }

  checkFilters() {
    if (this.activeUnit != null) {
      return true;
    }
    if (this.tagOnly === true) {
      return true;
    }
    if (this.searchText != null && this.searchText.length > 0) {
      return true;
    }
    if (this.departments != null && this.departments.length > 0) {
      return true;
    }
    if (this.elementCategory != null && this.elementCategory.length > 0) {
      return true;
    }

    return false;
  }

  resetFilters() {
    this.activeUnit = null;
    this.tagOnly = false;
    this.searchText = '';
    this.departments = [];
    this.elementCategory = [];
    this.elementsValue = [];
    this.searched = false;
  }
}
