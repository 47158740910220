import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-exchange-rate-dialog',
  templateUrl: './exchange-rate-dialog.component.html',
  styleUrls: ['./exchange-rate-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExchangeRateDialogComponent implements OnInit {
  title: string;
  changedElements: any;
  constructor(public dialogRef: MatDialogRef<any>) {
  }

  ngOnInit() {
    if(typeof this.dialogRef.componentInstance === 'object' && this.dialogRef.componentInstance !== null){
      this.title = this.dialogRef.componentInstance.title;
      this.changedElements = this.dialogRef.componentInstance.changedRates;
    }
  }

  setChecked(val){
    for(let changedElement of this.changedElements){
      changedElement.checked = val;
    }
  }
}
