import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const EXTRA_FIELDS_UPDATE = 'EXTRA_FIELDS_UPDATE';
const logService = new LogService();

export function extraFieldsReducer(state: any[] = undefined, action: ActionWithPayload) {
  switch (action.type) {
    case EXTRA_FIELDS_UPDATE:
      state = action.payload;
      logService.consoleLog('EXTRA_FIELDS_UPDATE:',state);
      return state;
    default:
      return state;
  }
}
