import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, ViewEncapsulation, HostListener, QueryList, ViewChildren, ElementRef, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/app-state';
import { PriceCalculationService } from '../../services/price-calculation.service';
import { AuthService, isGlobal } from '../../services/auth.service';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { Config } from '../../classes/config.class';
import { take, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { formatDate, DatePipe } from '@angular/common';
import { ExpiredDialogComponent } from '../expired-dialog/expired-dialog.component';
import { HelpersService } from 'app/services/helpers.service';
import { TelespiritService } from 'app/services/telespirit.service';
import { MatDialog } from '@angular/material';
import * as fileSaver from 'file-saver';
import { NotificationsService } from 'angular2-notifications';
import { PubnubService } from 'app/services/pubnub.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { CopyDialogComponent } from '../copy-dialog/copy-dialog.component';
import { MagicDialogComponent } from '../magic-dialog/magic-dialog.component';
import { VisualtourbuilderService } from 'app/services/visualtourbuilder.service';
import { IataService } from 'app/services/iata.service';
import { ConsolidateTransferDialogComponent } from '../consolidate-transfer-dialog/consolidate-transfer-dialog.component';

@Component({
    selector: 'app-top-navigation',
    templateUrl: './top-navigation.component.html',
    styleUrls: ['./top-navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class TopNavigationComponent implements OnInit, OnDestroy {
    @ViewChildren("activeEditMenu") public activeEditMenus: QueryList<any>;
    @ViewChild('centerMenu', { read: ElementRef }) centerMenu: ElementRef;
    @HostListener('window:beforeunload')
    beforeUnloadHandler() {
        if (this.newWindow != null) {
            this.newWindow.close();
        }
    }
    @Input() saved: boolean;

    unsubscribe = new Subject<void>();

    offsetWidth = 0;
    centerOffsetWidth = 200;

    env: any;
    isGlobalValue = isGlobal;

    salesTotal: any;
    pricePerParticipant: any;
    participants: any[] = [];
    // priceTooltip: string = '';
    notCalculated: boolean;

    itineraries$: any;
    itineraries: any;
    itinerariesSubscription: Subscription;

    itinerary$: Observable<AppModel.Itinerary>;
    itinerary: AppModel.Itinerary;
    itinerarySubscription: Subscription;

    itineraryBackups$: Observable<any>;
    itineraryBackups: any;
    itineraryBackupsSubscription: Subscription;

    counter: Observable<any>;
    counterSubscription: Subscription = new Subscription();
    routerSubscription: Subscription;
    currenciesSubscription: Subscription;
    currencies$: Observable<any>;

    unitsSubscription: Subscription;
    units$: Observable<any>;
    units: any;

    customerTemplates$: Observable<any>;
    customerTemplates: any;
    customerTemplatesSubscription: Subscription;

    currenciesLoaded = false;
    updateExchangeRatesDialogShown = false;
    updatePricesDialogShown = false;
    participantErrorShown = false;

    currencyCodes = [];
    activeView: string;
    autoSaveInterval: any;
    customerTemplate: any;
    publishedTemplates: any;
    newWindow: any;
    autoSaveOn = false;

    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private config: Config,
        public datepipe: DatePipe,
        private store: Store<AppState>,
        public dialog: MatDialog,
        public router: Router,
        private route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public auth: AuthService,
        private telespiritService: TelespiritService,
        private visualtourbuilderService: VisualtourbuilderService,
        private priceCalculationService: PriceCalculationService,
        public helpersService: HelpersService,
        private notificationsService: NotificationsService,
        public pubnubService: PubnubService,
        private iataService: IataService
    ) {
        this.env = this.config;
        this.itineraries$ = this.store.select('itineraries');
        this.itinerary$ = this.store.select('itinerary');
        this.itineraryBackups$ = this.store.select('itineraryBackups');
        this.counter = this.store.select('counter');
        this.currencies$ = this.store.select('currencies');
        this.units$ = this.store.select('units');
        this.customerTemplates$ = this.store.select('customerTemplates');
    }

    ngOnInit() {
        if (this.env.enableAutoSave === true) {
            this.autoSave();
        }
        this.routerSubscription = this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(event => {
            if (event instanceof NavigationStart) {
                this.getSalesPriceTotal();
                if (event.url.indexOf('/participants-view') > -1) {
                    this.activeView = 'pax';
                } else if (event.url.indexOf('/list-view') > -1) {
                    this.activeView = 'list';
                } else if (event.url.indexOf('/map-view') > -1) {
                    this.activeView = 'map';
                } else if (event.url.indexOf('/grid-view') > -1) {
                    this.activeView = 'price';
                } else if (event.url.indexOf('/json-view') > -1) {
                    this.activeView = 'json';
                } else if (event.url.indexOf('/day-texts-view') > -1) {
                    this.activeView = 'day-texts';
                } else if (event.url.indexOf('/texts-view') > -1) {
                    this.activeView = 'texts';
                } else {
                    this.activeView = 'edit';
                }
                this.cdr.detectChanges();
            }
        });
        this.salesTotal = 0;
        this.itinerariesSubscription = this.itineraries$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
            this.itineraries = x;
            if (this.itineraries && this.itineraries.ids && this.itineraries.ids.length > 0) {
                this.itineraries.ids.sort((a, b) => {
                    const dateA: any = new Date(this.itineraries.items[a].ts);
                    const dateB: any = new Date(this.itineraries.items[b].ts);
                    return dateB - dateA;
                });
            }
        });
        this.itinerarySubscription = this.itinerary$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
            this.itinerary = x;
            this.addVtbId();
            this.getSalesPriceTotal();
            this.updateExchangeRates();
            this.formatPublishedTemplates();
        });
        this.itineraryBackupsSubscription = this.itineraryBackups$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
            this.itineraryBackups = x;
        });
        this.counterSubscription.add(this.counter.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
            if (this.env.autoRecalc === true) {
                this.autoRecalc();
            }
            this.calcOffset();
            this.formatPublishedTemplates();
            this.getSalesPriceTotal();
            this.cdr.detectChanges();
        }));
        this.currenciesSubscription = this.currencies$.pipe(takeUntil(this.unsubscribe)).subscribe((x) => {
            if (this.currencyCodes.length === 0) {
                if (x.codes != null) {
                    this.currencyCodes = x.codes;
                    this.currenciesLoaded = true;
                }
            } else {
                let discrepancyFound = false;
                if (this.currencyCodes.length != x.codes) {
                    this.currenciesLoaded = true;
                } else {
                    for (const code of x.codes) {
                        if (this.currencyCodes.indexOf(code) < 0) {
                            discrepancyFound = true;
                            break;
                        }
                    }
                    if (discrepancyFound === false) {
                        for (const code of this.currencyCodes) {
                            if (x.codes.indexOf(code) < 0) {
                                discrepancyFound = true;
                                break;
                            }
                        }
                    }
                }
                if (discrepancyFound === false) {
                    this.currenciesLoaded = true;
                }
            }
            this.updateExchangeRates();
        });
        this.unitsSubscription = this.units$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
            this.units = x;
        });
        this.customerTemplatesSubscription = this.customerTemplates$.subscribe(x => {
            this.customerTemplates = x;
        });
        this.activeEditMenus.changes.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
            if (x.first != null) {
                this.offsetWidth = x.first.nativeElement.offsetWidth - 11;
                this.calcOffset();
                this.cdr.detectChanges();
            }
        });
    }

    calcOffset() {
        if (this.itinerary && this.itinerary.data && this.itinerary.data.templateView === true) {
            this.centerOffsetWidth = (this.centerMenu.nativeElement.offsetWidth / 2) - 75;
        } else {
            this.centerOffsetWidth = this.centerMenu.nativeElement.offsetWidth / 2;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    get hasRoomTypesMismatch(): boolean {
        if (this.itinerary != null) {
            return this.itinerary.data.segments.some(segment => (segment.elements || []).some(element => {
                if (element.subTitleLock === true) {
                    return false;
                } else if (element.roomTypeMatchesParticipants === false) {
                    return true;
                }
            }));
        }
        return false;
    }

    get tokenObj() {
        let obj: any = {};
        if (this.auth != null && this.auth.authenticated != null && this.auth.authenticated === true) {
            const url = new URL(window.location.href);
            obj.token = url.searchParams.get("token");
        }
        if (this.route.snapshot.queryParams.id) {
            obj.id = this.route.snapshot.queryParams.id;
        }
        return obj;
    }

    autoRecalc() {
        if (this.itinerary != null) {
            this.priceCalculationService.itineraryData = this.itinerary;
            this.priceCalculationService.generateColumnDefsObjAndRowData(null, null, null, null, null, null, null, null);
            let foundNotCalculated = false;
            for (let segment of this.itinerary.data.segments) {
                for (let element of segment.elements) {
                    if (element.optional === false && element.not_calculated === true && element.unitId !== this.units.roundId && !element.manual_calc && element.TSProduct) {
                        foundNotCalculated = true;
                        break;
                    }
                }
            }
            if (foundNotCalculated === true) {
                this.priceCalculationService.recalculatePrices({
                    api: {
                        forEachNode: (callback) => {
                            this.priceCalculationService.rowData.forEach((rowData: any, index: number) => {
                                callback({
                                    data: rowData,
                                    setDataValue: (field: string, value: any) => {
                                        let params = {
                                            oldValue: rowData[field],
                                            newValue: value,
                                            colDef: {
                                                field: field
                                            },
                                            data: rowData
                                        }
                                        this.priceCalculationService.valueSetter(params);
                                        rowData[field] = params.newValue;
                                    },
                                    rowIndex: index
                                });  // simulates agGrid rowNode
                            });
                        }
                    }
                }).then(x => {
                    this.store.dispatch({ type: 'PRICE_UPDATED' });
                    this.getSalesPriceTotal();
                });
            }
        }
    }

    updateExchangeRates() {
        if (this.env.disableUpdateCurrencyExchangeRate === false) {
            this.updateExchangeRatesDialogShown = this.priceCalculationService.checkExchangeRates(this.itinerary, this.currenciesLoaded, this.updateExchangeRatesDialogShown);
        }
    }

    getSalesPriceTotal() {
        let notCalculatedElements = 0;
        let uncalculatedDestyElement = false;
        this.pricePerParticipant = {};
        this.participants = [];
        // this.priceTooltip = '';
        if (this.itinerary) {
            this.salesTotal = 0;
            for (let party in this.itinerary.data.participants) {
                for (let participant of this.itinerary.data.participants[party]) {
                    this.pricePerParticipant[participant.id] = 0;
                    participant.partyName = party;
                    this.participants.push(participant);
                }
            }
            for (let segment of this.itinerary.data.segments) {
                if (segment != null) {
                    for (let element of segment.elements || []) {
                        let foundText = false;
                        if (element.subTitle == null && (this.units && this.units.items && this.units.items[element.unitId] && this.helpersService.isText(this.units.items[element.unitId]) === true)) {
                            foundText = true;
                        }
                        if (this.units && this.units.items && this.units.items[element.unitId] && this.units.items[element.unitId].name !== 'round') {
                            if (element.not_calculated == true && (element.manual_calc === false || element.manual_calc == null)) {
                                if (foundText === false) {
                                    if (element.olPrices && element.olPrices.hasError) {
                                        if (element.olPrices.hasError.length < 1) {
                                            this.notCalculated = true;
                                            notCalculatedElements++;
                                            if (element.dataSource === 'desty') {
                                                uncalculatedDestyElement = true;
                                            }
                                        }
                                    } else {
                                        this.notCalculated = true;
                                        notCalculatedElements++;
                                        if (element.dataSource === 'desty') {
                                            uncalculatedDestyElement = true;
                                        }
                                    }
                                }
                            }
                        }
                        if (element.olPrices && element.optional === false && element.unitId > 0 && foundText === false) {
                            if (element.olPrices.salesTotal) {
                                this.salesTotal = this.salesTotal + Number(element.olPrices.salesTotal);
                            }
                            for (let participant in element.olPrices.participants) {
                                if (element.olPrices.participants[participant] != null && element.olPrices.participants[participant].salesPrice) {
                                    this.pricePerParticipant[participant] += (Number(element.olPrices.participants[participant].salesPrice) || 0);
                                }
                            }
                        }
                    }
                }
            }
            if (notCalculatedElements == 0 || (this.isGlobalValue === true && this.env.tsToken == null && uncalculatedDestyElement === false)) {
                this.notCalculated = false;
            }
            // let longestString = 0;
            // for (let participant of this.participants) {
            //   this.priceTooltip += `${participant.partyName} - ${participant.name} ${participant.surname} - €${this.decimalPipe.transform(this.pricePerParticipant[participant.id], '1.2-2', 'nl-NL')} \n`;
            //   if (longestString < `${participant.partyName} - ${participant.name} ${participant.surname} - €${this.decimalPipe.transform(this.pricePerParticipant[participant.id], '1.2-2', 'nl-NL')} \n`.length) {
            //     longestString = `${participant.partyName} - ${participant.name} ${participant.surname} - €${this.decimalPipe.transform(this.pricePerParticipant[participant.id], '1.2-2', 'nl-NL')} \n`.length;
            //   }
            // }
            // for (let i = 0; i < longestString; i++) {
            //   this.priceTooltip += `-`;
            // }
            // this.priceTooltip += '\n';
            // this.priceTooltip += `Total: €${this.salesTotal.toFixed(2)}`;
        }
        this.cdr.detectChanges();
    }

    addVtbId() {
        if (this.itinerary) {
            this.itinerary.data.pdfVtbId = this.itinerary.id;
            this.cdr.detectChanges();
        }
    }

    async save() {
        if (this.helpersService.isExpired() === true) {
            this.dialog.open(ExpiredDialogComponent, {
                width: '600px'
            });
        } else {
            const hiddenOption = { ...this.itinerary.data.hiddenLayout };
            if (this.itinerary.data.hiddenLayout != null) {
                delete this.itinerary.data.hiddenLayout;
            }
            this.itinerary = this.cleanParticipants(this.itinerary);
            this.itinerary.data.pdfVtbId = this.config.selectedItinerary;
            if (isGlobal === false || this.config.tsToken != null) {
                this.telespiritService.putItinerary(this.itinerary);
            } else if (isGlobal === true) {
                this.visualtourbuilderService.updateVtbData(this.itinerary);
            }
            this.itinerary.data.hiddenLayout = hiddenOption;
        }
    }

    autoSave() {
        this.autoSaveOn = true;
        this.autoSaveInterval = interval(60000).subscribe(x => {
            if (this.itinerary.saved === false) {
                this.save();
            }
        });
    }

    disableAutoSave() {
        this.autoSaveInterval.unsubscribe();
        this.autoSaveOn = false;
    }

    checkPublishedTemplates(templateId) {
        let bool = false
        for (const template of this.itinerary.data.publishedTemplates) {
            if (template.templateId === templateId) {
                bool = true;
            }
        }
        return bool;
    }

    getTemplates(templates) {
        if (templates != null && templates.length > 0) {
            templates.sort(function (a, b) { return new Date(b.date).getTime() - new Date(a.date).getTime() });
            return templates;
        }
        return [];
    }

    getCheckDate(date) {
        if (date) {
            if ((new Date().getTime() - new Date(date).getTime()) > (1000 * 60 * 2)) {
                return true;
            }
        }
        return false;
    }

    formatPublishedTemplates() {
        if (this.itinerary && this.itinerary.data && this.itinerary.data.publishedTemplates) {
            this.publishedTemplates = this.itinerary.data.publishedTemplates;
            let succesTemplates = [];
            for (const template of this.publishedTemplates) {
                if (template.status) {
                    if (template.status === 'success') {
                        succesTemplates.push(template);
                    }
                } else {
                    succesTemplates.push(template);
                }
            }
            this.publishedTemplates = succesTemplates.sort((a, b) => {
                const dateA: any = new Date(a.date);
                const dateB: any = new Date(b.date);
                return dateB - dateA;
            });
        } else {
            this.publishedTemplates = [];
        }
    }

    openTemplateViewer(message, previewUrl = null) {
        let url = "https://ts-vtb-templateviewer.firebaseapp.com";
        if (previewUrl != null) {
            url = previewUrl;
        }
        if (this.pubnubService.occupancy === 1) {
            this.newWindow = window.open(`${url}/?key=${this.pubnubService.channel}&load=${message}`, this.pubnubService.channel, 'width=800,height=600');
            if (this.newWindow != null) {
                this.newWindow.focus();
            } else {
                this.notificationsService.warn('Warning', "New window could not be created. Please check if your browser is blocking the opening of this window", {
                    timeOut: 10000
                });
            }
        }
    }

    openPdfViewer(message, previewUrl = null) {
        let url = "https://ts-vtb-pdf-viewer-dev.firebaseapp.com";
        if (previewUrl != null) {
            url = previewUrl;
        }
        if (this.pubnubService.occupancy === 1) {
            this.newWindow = window.open(`${url}/?key=${this.pubnubService.channel}&load=${message}`, this.pubnubService.channel, 'width=1024,height=768');
            if (this.newWindow != null) {
                this.newWindow.focus();
            } else {
                this.notificationsService.warn('Warning', "New window could not be created. Please check if your browser is blocking the opening of this window", {
                    timeOut: 10000
                });
            }
        }
    }

    async openCustomerTemplate(id, type, event, template) {
        let message = 'new';
        if (this.newWindow != null) {
            message = 'open';
        }
        if (event === 'new') {
            message = 'new';
        }
        if (isGlobal === false || this.config.tsToken != null) {
            await this.telespiritService.putItinerary(this.itinerary).then(async () => {
                this.customerTemplate = await this.telespiritService.getCustomerTemplate(id);
                this.pubnubService.customerTemplateId = id;
                this.pubnubService.customerTemplate = this.customerTemplate;
                await this.pubnubService.init(this.itinerary).then(x => x.channel);
                switch (type) {
                    case 'pdf':
                        this.openPdfViewer(message);
                        break;
                    case 'pdfjson':
                        this.openPdfViewer(message);
                        break;
                    default:
                        this.openTemplateViewer(message);
                }
            });
        } else if (isGlobal === true) {
            await this.visualtourbuilderService.updateVtbData(this.itinerary).then(async () => {
                this.customerTemplate = template;
                this.pubnubService.customerTemplateId = id;
                await this.pubnubService.init(this.itinerary).then(x => x.channel);
                switch (type) {
                    case 'pdf':
                        this.openPdfViewer(message, template.previewUrl);
                        break;
                    case 'pdfjson':
                        this.openPdfViewer(message);
                        break;
                    default:
                        this.openTemplateViewer(message, template.previewUrl);
                }
            });
        }
    }

    saveToBackOffice(publishedTemplate) {
        if (publishedTemplate.status != null && publishedTemplate.status !== 'pending') {
            const pdfName = publishedTemplate.downloadName != null ? publishedTemplate.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '') : publishedTemplate.pdfName.replace(/\s/g, '_');
            this.telespiritService.savePdf(publishedTemplate.url, pdfName, publishedTemplate.completeUrl).then(res => {
                this.notificationsService.success('Template has been stored in the Backoffice', '', {
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
            });
        }
    }

    async republishTemplate(template, transfer = null) {
        let coverPhoto = null;
        if (this.itinerary.data && this.itinerary.data.cover && this.itinerary.data.cover.length > 0) {
            coverPhoto = this.itinerary.data.cover[0];
        }
        if (template.status != null && template.status !== 'pending') {
            if (template.type === 'pdf') {
                this.notificationsService.alert('PDF republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                    timeOut: 120000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
            } else if (template.type === 'pdfjson') {
                this.notificationsService.alert('Agent JSON republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                    timeOut: 120000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
            } else if (template.type === 'website') {
                this.notificationsService.alert('Website republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                    timeOut: 120000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
            } else if (template.type === 'app') {
                this.notificationsService.alert('App republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                    timeOut: 120000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
            }
            template.cover = coverPhoto;
            template.loading = 0;
            template.date = new Date();
            template.status = 'pending';
            if (isGlobal === true && template.environment === 'vtbGlobal') {
                this.visualtourbuilderService.republishDesignTemplate(template.templateId, this.itinerary.vtb_global_id || this.itinerary.id, template.publishedId).then(() => {
                    template.status = 'success';
                    template.loading = 100;
                    this.notificationsService.success('App was republished!', '', {
                        timeOut: 10000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                    this.save();
                });
            } else {
                await this.pubnubService.init(this.itinerary).then(x => x.channel);
                this.telespiritService.republishCustomerTemplate(template, this.pubnubService.channel).then(async () => {
                    if (transfer === true) {
                        if (this.env.enableAdditionalTransfer === true) {
                            this.transferVtb(this.pubnubService.pubnubConfig.publishKey, 'additional');
                        } else {
                            this.transferVtb(this.pubnubService.pubnubConfig.publishKey);
                        }
                    } else {
                        if (isGlobal === false || this.config.tsToken != null) {
                            this.telespiritService.putItinerary(this.itinerary);
                        }
                        if (isGlobal === true) {
                            this.visualtourbuilderService.updateVtbData(this.itinerary);
                        }
                    }
                    this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: this.itinerary });
                    this.store.dispatch({ type: 'COUNTER_ADD' });
                });
            }
        } else {
            this.notificationsService.error(`Can't republish template`, 'Status is still pending', {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
            });
        }
    }

    async deleteTemplate(template) {
        if (this.itinerary && this.itinerary.data && this.itinerary.data.publishedTemplates && this.itinerary.data.publishedTemplates.length > 0) {
            for (let i = 0; i < this.itinerary.data.publishedTemplates.length; i++) {
                if (this.itinerary.data.publishedTemplates[i].publishedId === template.publishedId && this.itinerary.data.publishedTemplates[i].templateId === template.templateId) {
                    this.itinerary.data.publishedTemplates.splice(i, 1);
                    break;
                }
            }
        }
        if (isGlobal === true && template.environment === 'vtbGlobal') {
            // this.visualtourbuilderService.deleteDesignTemplate(template.templateId, this.itinerary.id).then(() => {
            if (this.config.tsToken != null) {
                this.telespiritService.putItinerary(this.itinerary);
            } else if (isGlobal === true) {
                this.visualtourbuilderService.updateVtbData(this.itinerary);
            }
            // });
        } else {
            await this.pubnubService.init(this.itinerary).then(x => x.channel);
            this.telespiritService.deleteTemplate(template, this.itinerary.id, this.pubnubService.channel).then(async () => {
                this.telespiritService.putItinerary(this.itinerary);
                this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: this.itinerary });
                this.store.dispatch({ type: 'COUNTER_ADD' });
            });
        }
        this.cdr.detectChanges();
    }

    downloadTemplate(template) {
        if (template.status != null && template.status !== 'pending') {
            let url = 'https://'
            if (template.completeUrl != null) {
                url += template.url;
            } else {
                url = template.downloadName != null ? `https://${template.url}/${template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf` : `https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`;
            }
            this.telespiritService.downloadFile(url).subscribe(response => {
                const res: any = response;
                let blob: any = new Blob([res._body], { type: 'application/pdf' });
                const downloadName = template.downloadName || `${("000000" + (this.itinerary.link_id || this.config.linkId)).slice(-6)} ${template.pdfName} ${formatDate(Date.now(), 'dd-MM-yyyy HHmmss', this.locale)}`;
                fileSaver.saveAs(blob, `${downloadName}.pdf`);
            }), error => console.log('Error downloading the file', error);
        }
    }

    recheckStatus(template) {
        let url = '';
        if (template.completeUrl != null) {
            url = `https://${template.url}`;
        }
        if (template != null && template.downloadName != null && template.pdfName != null) {
            if (url.length === 0) {
                url = template.downloadName != null ? `https://${template.url}/${template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf` : `https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`;
            }
            this.telespiritService.downloadFile(url).subscribe(async response => {
                if (response) {
                    template.status = 'success';
                    this.notificationsService.success('PDF status has been refreshed!', '', {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                    this.store.dispatch({ type: 'COUNTER_ADD' });
                    if (isGlobal === false || this.config.tsToken != null) {
                        this.telespiritService.putItinerary(this.itinerary);
                    } else if (isGlobal === true) {
                        this.visualtourbuilderService.updateVtbData(this.itinerary);
                    }
                }
            }), error => console.log('Error checking status', error);
        } else if (template != null && template.url != null && template.pdfName != null) {
            if (url.length === 0) {
                url = `https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`;
            }
            this.telespiritService.downloadFile(url).subscribe(async response => {
                if (response) {
                    template.status = 'success';
                    this.notificationsService.success('PDF status has been refreshed!', '', {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                    this.store.dispatch({ type: 'COUNTER_ADD' });
                    if (isGlobal === false || this.config.tsToken != null) {
                        this.telespiritService.putItinerary(this.itinerary);
                    } else if (isGlobal === true) {
                        this.visualtourbuilderService.updateVtbData(this.itinerary);
                    }
                }
            }), error => console.log('Error checking status', error);
        } else if (template != null && template.url != null) {
            if (template.environment != null) {
                this.visualtourbuilderService.getProposalBuilds([template.publishedId]).then(builds => {
                    if (builds != null && builds.length > 0) {
                        for (const build of builds) {
                            if (build.status === 'done') {
                                this.visualtourbuilderService.getProposals(template.templateId, this.itinerary.id, template.publishedId).then(proposal => {
                                    template.status = 'success';
                                    template.url = proposal.url;
                                    const toast = this.notificationsService.success(`${template.type[0].toUpperCase() + template.type.slice(1)} status was succesfully refreshed!`, 'Click here to open', {
                                        timeOut: 20000,
                                        showProgressBar: true,
                                        pauseOnHover: true
                                    });
                                    toast.click.subscribe(() => {
                                        window.open(template.url, "_blank");
                                    });
                                    if (isGlobal === false || this.config.tsToken != null) {
                                        this.telespiritService.putItinerary(this.itinerary);
                                    }
                                    if (isGlobal === true) {
                                        this.visualtourbuilderService.updateVtbData(this.itinerary);
                                    }
                                });
                                break;
                            }
                        };
                    }
                });
            } else {
                if (url.length === 0) {
                    url = `https://${template.url}/index.html`;
                }
                this.telespiritService.downloadFile(url).subscribe(async response => {
                    if (response) {
                        template.status = 'success';
                        if (template.type && template.type === 'website') {
                            this.notificationsService.success('Website status has been refreshed!', '', {
                                timeOut: 5000,
                                showProgressBar: true,
                                pauseOnHover: true,
                            });
                        } else {
                            this.notificationsService.success('Website/App status has been refreshed!', '', {
                                timeOut: 5000,
                                showProgressBar: true,
                                pauseOnHover: true,
                            });
                        }
                        this.store.dispatch({ type: 'COUNTER_ADD' });
                        if (isGlobal === false || this.config.tsToken != null) {
                            this.telespiritService.putItinerary(this.itinerary);
                        }
                        if (isGlobal === true) {
                            this.visualtourbuilderService.updateVtbData(this.itinerary);
                        }
                    }
                }), error => console.log('Error checking status', error);
            }
        }
    }

    async preparePublish(template, type = null, transfer = false) {
        if (this.itinerary.data.niceUrl == null || this.itinerary.data.niceUrl === '') {
            this.notificationsService.error('No NiceURL set, plz ask TravelSpirit', '', {
                timeOut: 10000,
                showProgressBar: true,
                pauseOnHover: true,
            });
            return;
        }
        if (isGlobal === true && template.environment === 'vtbGlobal') {
            await this.visualtourbuilderService.updateVtbData(this.itinerary).then(async () => {
                let coverPhoto = null;
                if (this.itinerary.data && this.itinerary.data.cover && this.itinerary.data.cover.length > 0) {
                    coverPhoto = this.itinerary.data.cover[0];
                }

                if (template.type === 'app') {
                    this.notificationsService.alert('App publish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                        timeOut: 120000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                }
                this.visualtourbuilderService.publishDesignTemplate(template.id, this.itinerary.vtb_global_id || this.itinerary.id).then(x => {
                    if (this.itinerary.data.publishedTemplates == null) {
                        this.itinerary.data.publishedTemplates = [];
                    }
                    let url = '';
                    let status = 'pending';
                    if (template.type === 'website') {
                        this.notificationsService.alert('Website publish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                            timeOut: 120000,
                            showProgressBar: true,
                            pauseOnHover: true,
                        });
                    } else if (template.type === 'pdf') {
                        this.notificationsService.alert('PDF publish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                            timeOut: 120000,
                            showProgressBar: true,
                            pauseOnHover: true,
                        });
                    } else {
                        status = 'succes';
                        this.notificationsService.success('App was created!', '', {
                            timeOut: 60000,
                            showProgressBar: true,
                            pauseOnHover: true,
                        });
                    }

                    this.itinerary.data.publishedTemplates.push(
                        {
                            templateId: template.id,
                            publishedId: x.id,
                            url,
                            name: template.name,
                            date: x.createdAt,
                            status,
                            type: template.type,
                            loading: 100,
                            cover: coverPhoto,
                            environment: template.environment
                        }
                    );
                    this.save();
                });
            });
        } else if (isGlobal === false || this.config.tsToken != null) {
            await this.telespiritService.putItinerary(this.itinerary).then(async () => {
                this.publishCustomerTemplate(template, type, transfer);
            });
        }
    }

    async publishCustomerTemplate(template, type, transfer) {
        let coverPhoto = null;
        if (this.itinerary.data && this.itinerary.data.cover && this.itinerary.data.cover.length > 0) {
            coverPhoto = this.itinerary.data.cover[0];
        }
        await this.pubnubService.init(this.itinerary).then(x => x.channel);
        let templateName = template.name;
        if (template.type === 'pdf' || template.type === 'pdfjson') {
            templateName = `#${("000000" + (this.itinerary.link_id || this.config.linkId)).slice(-6)} ${template.name} ${formatDate(Date.now(), 'dd-MM-yyyy HH:mm:ss', this.locale)}`;
        }
        this.telespiritService.publishCustomerTemplate(template, templateName, this.pubnubService.channel, type).then(async (x: any) => {
            if (this.itinerary.data.publishedTemplates == null) {
                this.itinerary.data.publishedTemplates = [];
            }
            if (template.type === 'pdf' || template.type === 'pdfjson') {
                x.folderName = x.folderName.replace(/^(.*?)\//, this.itinerary.data.niceUrl + '/');
                if (type === 'pdfcreate') {
                    if (template.type === 'pdfjson') {
                        this.notificationsService.alert('Agent JSON publish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                            timeOut: 120000,
                            showProgressBar: true,
                            pauseOnHover: true,
                        });
                        this.itinerary.data.publishedTemplates.push(
                            {
                                templateId: template.id,
                                publishedId: x.id,
                                url: `${x.folderName}/zra.json`,
                                completeUrl: `${x.folderName}/zra.json`,
                                date: new Date(),
                                pdfName: template.name,
                                downloadName: templateName,
                                status: 'pending',
                                type: template.type
                            }
                        );
                    } else {
                        this.notificationsService.alert('PDF publish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                            timeOut: 120000,
                            showProgressBar: true,
                            pauseOnHover: true,
                        });
                        this.itinerary.data.publishedTemplates.push(
                            {
                                templateId: template.id,
                                publishedId: x.id,
                                url: `${x.folderName}/${templateName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf`,
                                completeUrl: `${x.folderName}/${templateName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf`,
                                date: new Date(),
                                pdfName: template.name,
                                downloadName: templateName,
                                status: 'pending',
                                type: template.type
                            }
                        );
                    }
                } else if (type === 'pdfstore') {
                    this.notificationsService.alert('PDF publish and store to Backoffice was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                        timeOut: 120000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                    this.itinerary.data.publishedTemplates.push(
                        {
                            templateId: template.id,
                            publishedId: x.id,
                            url: `${x.folderName}/${templateName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf`,
                            completeUrl: `${x.folderName}/${templateName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf`,
                            date: new Date(),
                            pdfName: template.name,
                            downloadName: templateName,
                            status: 'pending',
                            type: template.type,
                            stored: true
                        }
                    );
                }
            } else {
                x.folderName = x.folderName.replace(/^(.*?)\//, this.itinerary.data.niceUrl + '/');
                let templateType = '';
                if (template.runtime === 'angular') {
                    templateType = 'website';
                    this.notificationsService.alert('Website publish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                        timeOut: 120000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                } else {
                    templateType = 'app';
                    this.notificationsService.alert('App publish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
                        timeOut: 120000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                }
                this.itinerary.data.publishedTemplates.push(
                    {
                        templateId: template.id,
                        publishedId: x.id,
                        url: `${x.folderName}/`,
                        completeUrl: `${x.folderName}/`,
                        name: template.name,
                        date: new Date(),
                        status: 'pending',
                        type: templateType,
                        loading: 0,
                        cover: coverPhoto
                    }
                );
            }
            if (transfer === true) {
                if (this.env.enableAdditionalTransfer === true) {
                    this.transferVtb(this.pubnubService.pubnubConfig.publishKey, 'additional');
                } else {
                    this.transferVtb(this.pubnubService.pubnubConfig.publishKey);
                }
            } else {
                if (isGlobal === false || this.config.tsToken != null) {
                    this.telespiritService.putItinerary(this.itinerary);
                }
                if (isGlobal === true) {
                    this.visualtourbuilderService.updateVtbData(this.itinerary);
                }
            }
        }).catch(e => {
            console.log(e);
        });
    }

    setActiveItinerary(id) {
        let queryParams = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));
        queryParams.id = id;
        this.router.navigate(['/'], { queryParams: queryParams }).then(async () => {
            if (this.itinerary.saved === false) {
                this.confirmSetActiveItinerary(id);
            } else {
                await this.telespiritService.getExtraFieldsAndRounding(id).then(async x => {
                    await this.telespiritService.getItinerary(id, this.iataService);
                });
            }
        });
    }

    confirmSetActiveItinerary(id) {
        let dialogRef: any = this.dialog.open(ConfirmDialogComponent, {
            height: '200px',
            width: '400px',
        });
        dialogRef.componentInstance = `You haven't saved your current itinerary yet. Do you really want to load this itinerary? All progress on the current itinerary will be lost.`;
        dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
            if (result == 'true') {
                await this.telespiritService.getExtraFieldsAndRounding(id).then(async x => {
                    await this.telespiritService.getItinerary(id, this.iataService);
                });
            }
        });
    }

    async transferVtb(publishKey, type = null) {
        const channel = await this.pubnubService.init(this.itinerary).then(x => x.channel);
        if (type === 'consolidated') {
            await this.telespiritService.checkConsolidate(channel).then(async (x) => {
                if (x != null) {
                    await this.telespiritService.putItinerary(this.itinerary).then(async () => {
                        const cpids = await this.getCPIDs();
                        let dialogRef: any = this.dialog.open(ConsolidateTransferDialogComponent, {
                            width: '1200px'
                        });
                        dialogRef.componentInstance = {
                            segments: this.itinerary.data.segments,
                            cpids: cpids
                        };
                        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
                            if (result != null && result != false) {
                                return this.telespiritService.transferVtb(publishKey, channel, type, result);
                            }
                        });
                    });
                } else {
                    this.notificationsService.error('Could not start consolidated transfer', 'Locked orderlines / Travelplan / statusitems in the Travelplan' + this.datepipe.transform(new Date(), 'HH:mm'), {
                        timeOut: 120000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                }
            });
        } else {
            await this.telespiritService.putItinerary(this.itinerary).then(async () => {
                return this.telespiritService.transferVtb(publishKey, channel, type);
            });
        }
    }

    copy() {
        if (this.helpersService.isExpired() === false) {
            let dialogRef: any = this.dialog.open(CopyDialogComponent, {
                height: '200px',
                width: '400px',
            });
            dialogRef.componentInstance = {
                title: this.isGlobalValue === false || this.env.tsToken != null ? "Copy Itinerary" : "Copy VTB",
                data: this.itinerary
            }
            dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
                if (result != null && result.data != null) {
                    if (this.isGlobalValue === false) {
                        this.telespiritService.copyItinerary(result);
                    } else if (this.isGlobalValue === true && this.env.tsToken != null) {
                        this.telespiritService.copyItinerary(result, 'global');
                    } else if (this.isGlobalValue === true) {
                        this.visualtourbuilderService.copyVtb(result);
                    }
                }
            });
        } else {
            this.dialog.open(ExpiredDialogComponent, {
                width: '600px'
            });
        }
    }

    updateItinerary(itinerary) {
        if (this.helpersService.isExpired() === false) {
            this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: JSON.parse(JSON.stringify(itinerary)) });
            this.store.dispatch({ type: 'COUNTER_ADD' });
        } else {
            this.dialog.open(ExpiredDialogComponent, {
                width: '600px'
            });
        }
    }

    handleTemplateViewClick() {
        if (this.itinerary) {
            if (this.itinerary.data && this.itinerary.data.templateView != null) {
                this.itinerary.data.templateView = !this.itinerary.data.templateView;
                this.updateItinerary(this.itinerary);
            }
        }
    }

    openMagicDialog() {
        let dialog: any = this.dialog.open(MagicDialogComponent, {
            height: '600px',
            width: '750px',
            hasBackdrop: true,
            panelClass: 'no-padding-dialog'
        });
        dialog.componentInstance = {
            publishedTemplates: this.getTemplates(this.itinerary.data.publishedTemplates),
            itinerary: this.itinerary
        };
    }

    checkHiddenLayout(type) {
        if (this.itinerary != null && this.itinerary.data != null && this.itinerary.data.templateView === true && this.itinerary.data.hiddenLayout != null) {
            if (this.itinerary.data.hiddenLayout.itineraryFeatures != null && this.itinerary.data.hiddenLayout.itineraryFeatures.length > 0) {
                if (this.itinerary.data.hiddenLayout.itineraryFeatures.indexOf(type) > -1) {
                    return true;
                }
            }
        }
        return false;
    }

    cleanParticipants(itinerary) {
        if (itinerary != null && itinerary.data != null && itinerary.data.participants != null) {
            const parties = Object.keys(itinerary.data.participants);
            for (const party of parties) {
                for (const participant of itinerary.data.participants[party]) {
                    if (participant.multiSelected != null) {
                        delete participant.multiSelected;
                    }
                }
            }
        }
        return itinerary;
    }

    async getCPIDs(pageNr = null) {
        let cpids = [];
        await this.telespiritService.searchConsolidateElements({ "advanceQuery": "PRO_COLLECTIVE = 1" }, pageNr).then(async data => {
            const res: any = data;
            if (res.elements != null && res.elements.length > 0) {
                cpids = cpids.concat(res.elements);
                if (cpids.length <= res.amount) {
                    cpids = cpids.concat(await this.getCPIDs(Number(res.pageNr) + 1));
                }
            }
            return cpids;
        });
        return cpids;
    }

    getTsUrl() {
        if (this.config.tsToken != null) {
            return this.config.baseUrl.replace('/modules/restapi/index.php/jwt/vtb', '/') + 'admin.php?mod=tsorder&act=edit&id=' + this.itinerary.data.liborderId;
        } else {
            return this.auth.decodedToken.iss + 'admin.php?mod=tsorder&act=edit&id=' + this.itinerary.data.liborderId;
        }
    }

    checkPreviewUrl(template) {
        if (template.environment != null && template.environment === 'vtbGlobal') {
            if (template.previewUrl != null) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }
}
