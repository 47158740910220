import { ActionWithPayload } from '../models/action-with-payload';
export const ITINERARIES_UPDATE = 'ITINERARIES_UPDATE';
export const ITINERARIES_INIT = 'ITINERARIES_INIT';

let dummyItineraries = {
  ids: [],
  items: {}
};

export function itinerariesReducer(state: any = dummyItineraries, action: ActionWithPayload) {
  switch (action.type) {
    case ITINERARIES_UPDATE:
      state = action.payload.reduce((all, item) => {
        all.ids.push(item.id);
        all.items[item.id] = item;
        return all;
      }, { ids: [], items: {} });
      return state;

    case ITINERARIES_INIT:
      const res: any = action.payload;
      state = {
        ids: [res.id]
      };
      state.items = {};
      state.items[res.id] = res.data;
      return state;

    default:
      return state;
  }
}
