import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const DEPARTMENTS_UPDATE = 'DEPARTMENTS_UPDATE';
const logService = new LogService();

export function departmentsReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {
    case DEPARTMENTS_UPDATE:
      state = action.payload;
      logService.consoleLog('DEPARTMENTS_UPDATE:', state);
      return state;

    default:
      return state;
  }
}
