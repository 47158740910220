import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../models/app-state';
import { AuthService } from './auth.service';
import { TelespiritService } from './telespirit.service';
import { HelpersService } from './helpers.service';
import { NotificationsService } from 'angular2-notifications';
import { DayCalculationService } from './day-calculation.service';

import { Observable } from 'rxjs';
import { Config } from '../classes/config.class';
import { PriceCalculationClass } from '../classes/price-calculation.class';
import { ExchangeRateDialogComponent } from '../components/exchange-rate-dialog/exchange-rate-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class PriceCalculationService extends PriceCalculationClass {
    units$: Observable<any>;
    currencies$: Observable<any>;
    findExchangeRates = true;

    constructor(store: Store<AppState>, auth: AuthService, teleService: TelespiritService, public helpersService: HelpersService, notificationsService: NotificationsService, dayCalculationService: DayCalculationService, config: Config, matDialog: MatDialog, pageScrollService: PageScrollService, router: Router, @Inject(DOCUMENT) document: any) {
        super(matDialog, auth, teleService, helpersService, notificationsService, dayCalculationService, config, store, pageScrollService, router, document);
        this.units$ = store.select('units');
        this.units$.subscribe(x => {
            this.unitsData = x;
            this.roundId = x.roundId;
        });

        this.currencies$ = store.select('currencies');
        this.currencies$.subscribe(x => {
            this.currenciesValue = x;
        });
    }

    findChangedExchangeRates(itinerary) {
        let changedRates = [];
        let exchangeDate: any = new Date();
        if (this.currenciesValue.orderlinePurchaseExchangeDate === 'order_startdate') {
            exchangeDate = new Date(itinerary.data.startDate);
        }
        exchangeDate = Number(exchangeDate.getFullYear() + this.helpersService.formatTrailingZeros(exchangeDate.getMonth() + 1, 2) + this.helpersService.formatTrailingZeros(exchangeDate.getDate(), 2));
        for (let segment of itinerary.data.segments) {
            for (let element of segment.elements) {
                if (element.olPrices != null && element.olPrices.exchangeRate && this.helpersService.isText(this.unitsData.items[element.unitId]) === false) {
                    const sourceCurrency = this.currenciesValue.itemsByCode[element.olPrices.purchaseCurrency] || this.currenciesValue.itemsByCode['EUR'];
                    const targetCurrency = this.currenciesValue.itemsByCode[element.olPrices.salesCurrency] || this.currenciesValue.itemsByCode['EUR'];
                    const exchangeObj = this.currenciesValue.exchangeRates.find(exchangeRate => sourceCurrency.id === exchangeRate.currency_id_one && targetCurrency.id === exchangeRate.currency_id_two && exchangeDate >= exchangeRate.rate_start_date && exchangeDate <= exchangeRate.rate_end_date);
                    if (exchangeObj != null && Number(exchangeObj.rate) !== Number(element.olPrices.exchangeRate)) {
                        changedRates.push({ element: element, oldRate: element.olPrices.exchangeRate, newRate: exchangeObj.rate, sourceCurrency: sourceCurrency.iso_code || sourceCurrency.isoCode, targetCurrency: targetCurrency.iso_code || targetCurrency.isoCode });
                    }
                }
            }
        }
        return changedRates;
    }

    checkExchangeRates(itinerary, currenciesLoaded, updateExchangeRatesDialogShown = false, findExchangeRates = false) {
        if (itinerary != null && currenciesLoaded === true && updateExchangeRatesDialogShown === false && (this.findExchangeRates === true || findExchangeRates === true)) {
            const changedRates = this.findChangedExchangeRates(itinerary);
            if (changedRates.length > 0) {
                let dialog: any = this.matDialog.open(ExchangeRateDialogComponent);
                dialog.componentInstance = {
                    title: 'Currency exchange rate(s) have changed',
                    changedRates: changedRates
                };
                dialog.afterClosed().pipe(take(1)).subscribe(confirmedAction => {
                    if (confirmedAction == 'true') {
                        this.itineraryData = itinerary;
                        this.generateColumnDefsObjAndRowData(null, null, null, null, null, null, null, null);
                        for (let changedRate of changedRates) {
                            if (changedRate.checked === true) {
                                this.rowData.forEach((rowData: any, index: number) => {
                                    if (rowData.extra.id === changedRate.element.vtbObjectId) {
                                        rowData['Exch Rate'] = changedRate.newRate;
                                        const rowNode = { // this object simulates agGrid rowNode
                                            data: rowData,
                                            setDataValue: (field: string, value: any) => {
                                                let params = {
                                                    oldValue: rowData[field],
                                                    newValue: value,
                                                    colDef: {
                                                        field: field
                                                    },
                                                    data: rowData
                                                }
                                                this.valueSetter(params);
                                                rowData[field] = params.newValue;
                                            },
                                            rowIndex: index
                                        }
                                        this.setRowNodeFieldValue(rowNode, 'Exch Rate', Number(changedRate.newRate).toFixed(9));
                                        this.setRowNodeFieldValue(rowNode, 'Cost EUR', Number(Number(rowNode.data.Cost) * Number(changedRate.newRate)).toFixed(9));
                                        this.setRowNodeFieldValue(rowNode, 'Salesprice ' + rowNode.data.element.olPrices.salesCurrency, Number(Number(Number(rowNode.data['Cost EUR']) * 100) / (100 - Number(rowNode.data['Margin %']))).toFixed(3));

                                        const participantAmount = Object.keys(rowNode.data.element.olPrices.participants).length;
                                        for (let participantId in rowNode.data.element.olPrices.participants) {
                                            if (rowNode.data.element.olPrices.participants[participantId] != null) {
                                                if (rowNode.data[this.getParticipantsName(participantId) + ' Cost ' + '(' + participantId + ')']) {
                                                    rowNode.data[this.getParticipantsName(participantId) + ' Cost ' + '(' + participantId + ')'] = rowNode.data['Cost EUR'] / participantAmount;
                                                }
                                                if (rowNode.data[this.getParticipantsName(participantId) + ' Salesprice ' + '(' + participantId + ')']) {
                                                    rowNode.data[this.getParticipantsName(participantId) + ' Salesprice ' + '(' + participantId + ')'] = rowNode.data['Salesprice ' + rowNode.data.element.olPrices.salesCurrency] / participantAmount;
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }
                        this.saveChangesToObject({ saveTrigger: true });
                    }
                });
            }
            this.findExchangeRates = false;
            return true;
        } else {
            return false;
        }
    }
}
