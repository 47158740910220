import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { KeyValuePipe } from './pipes/keyvalue.pipe';
import { SafeDatePipe } from './pipes/safe-date.pipe';

import { InlineCkeditorDirective } from './directives/inline-ckeditor.directive';
import { OnlyNumber } from './directives/only-number.directive';
import { MediaComponent } from './components/media/media.component';

import {
  MatButtonModule,
  MatSelectModule,
  MatCheckboxModule,
  MatTabsModule,
  MatExpansionModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatGridListModule,
  MatListModule,
  MatBottomSheetModule,
  MatDialogModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatInputModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatCardModule,
  MatSidenavModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatSnackBarModule,
  MatRadioModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatProgressBarModule,
  MatSlideToggleModule
} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';

import { SliderComponent } from './components/slider/slider.component';

@NgModule({
  declarations: [SliderComponent, KeyValuePipe, SafeDatePipe, InlineCkeditorDirective, OnlyNumber, MediaComponent],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatListModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatCardModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    CKEditorModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    PerfectScrollbarModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatListModule,
    MatBottomSheetModule,
    MatDialogModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatCardModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    CKEditorModule,
    SliderComponent,
    MediaComponent,
    ReactiveFormsModule,
    KeyValuePipe,
    SafeDatePipe,
    InlineCkeditorDirective,
    OnlyNumber,
    InfiniteScrollModule,
    PerfectScrollbarModule
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD-MM-YYYY',
        },
        display: {
          dateInput: 'DD-MM-YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      }
    },
    SafeDatePipe
  ]
})
export class SharedModule { }
