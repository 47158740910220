import { DatePipe } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Store } from '@ngrx/store';
import { NotificationsService } from 'angular2-notifications';
import { isGlobal } from '../../services/auth.service';
import { AppState } from 'app/models/app-state';
import { PubnubService } from 'app/services/pubnub.service';
import { TelespiritService } from 'app/services/telespirit.service';
import { ClipboardService } from 'ngx-clipboard';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CopyUrlDialogComponent } from '../copy-url-dialog/copy-url-dialog.component';
import { Config } from 'app/classes/config.class';
import { VisualtourbuilderService } from 'app/services/visualtourbuilder.service';
import { HelpersService } from 'app/services/helpers.service';

@Component({
  selector: 'app-magic-dialog',
  templateUrl: './magic-dialog.component.html',
  styleUrls: ['./magic-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagicDialogComponent implements OnInit, OnDestroy {
  itinerary: any;
  publishedTemplates: any;

  counter: Observable<any>;
  counterData: any;

  unsubscribe = new Subject<void>();

  constructor(
    public config: Config,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    public datepipe: DatePipe,
    private clipboardApi: ClipboardService,
    public dialogRef: MatDialogRef<MagicDialogComponent>,
    private store: Store<AppState>,
    private telespiritService: TelespiritService,
    private visualtourbuilderService: VisualtourbuilderService,
    private pubnubService: PubnubService,
    private notificationsService: NotificationsService,
    public helpersService: HelpersService
  ) {
    this.counter = store.select('counter');
  }

  ngOnInit() {
    if (this.dialogRef.componentInstance !== null) {
      this.itinerary = this.dialogRef.componentInstance.itinerary;
      this.publishedTemplates = this.dialogRef.componentInstance.publishedTemplates;
      for (const template of this.publishedTemplates) {
        this.handleTemplateStatus(template);
      }
      this.cdr.detectChanges();
    }

    this.counter.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.itinerary && this.itinerary.data && this.itinerary.data.publishedTemplates) {
        for (const template of this.itinerary.data.publishedTemplates) {
          this.handleTemplateStatus(template);
        }
        this.publishedTemplates = this.itinerary.data.publishedTemplates;
      }
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.itinerary && this.itinerary.data && this.itinerary.data.publishedTemplates) {
      for (const template of this.itinerary.data.publishedTemplates) {
        delete template.subscribe;
      }
    }
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  handleTemplateStatus(template) {
    if (template.status === 'success') {
      delete template.loading;
      if (template.subscribe) {
        delete template.subscribe;
      }
      delete template.loading;
    } else if (template.loading != null && template.loading !== 100 && template.subscribe == null) {
      template.subscribe = true;
      const timerSubscription = timer(0, 600).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        if (template.loading === 100 || template.loading == null) {
          timerSubscription.unsubscribe();
          delete template.subscribe;
          if (template.loading != null) {
            delete template.loading;
          }
          return;
        }
        template.loading = template.loading + 1;
        this.cdr.detectChanges();
      });
    }
    this.cdr.detectChanges();
  }

  copyUrl(template) {
    const foundHttps = template.completeUrl != null;
    if (foundHttps === true) {
      this.clipboardApi.copyFromContent(`https://${template.url}`);
    }
    if (template.status != null && template.status !== 'pending') {
      if (template.type === 'pdfjson') {
        if (foundHttps === false) {
          this.clipboardApi.copyFromContent(`https://${template.url}/zra.json`);
        }
      } else if (template.type === 'pdf') {
        if (foundHttps === false) {
          if (template.downloadName != null) {
            this.clipboardApi.copyFromContent(`https://${template.url}/${template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf`);
          } else {
            this.clipboardApi.copyFromContent(`https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`);
          }
        }
      } else {
        if (foundHttps === false) {
          if (isGlobal === true && template.environment === 'vtbGlobal') {
            this.clipboardApi.copyFromContent(`${template.url}/`);
          } else {
            this.clipboardApi.copyFromContent(`https://${template.url}/`);
          }
        }
      }
      const copUrlDialog = this.dialog.open(CopyUrlDialogComponent, {
        height: '120px',
        width: '250px',
        hasBackdrop: false,
        panelClass: 'no-padding-dialog'
      });

      copUrlDialog.afterOpened().subscribe(() => {
        setTimeout(() => {
          copUrlDialog.close();
        }, 2000);
      });
    }
  }

  async republishTemplate(template) {
    let coverPhoto = null;
    if (this.itinerary.data && this.itinerary.data.cover && this.itinerary.data.cover.length > 0) {
      coverPhoto = this.itinerary.data.cover[0];
    }
    if (template.status != null && template.status !== 'pending') {
      if (template.type === 'pdf') {
        this.notificationsService.alert('PDF republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
          timeOut: 120000,
          showProgressBar: true,
          pauseOnHover: true,
        });
      } else if (template.type === 'pdfjson') {
        this.notificationsService.alert('Agent JSON republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
          timeOut: 120000,
          showProgressBar: true,
          pauseOnHover: true,
        });
      } else if (template.type === 'website') {
        this.notificationsService.alert('Website republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
          timeOut: 120000,
          showProgressBar: true,
          pauseOnHover: true,
        });
      } else if (template.type === 'app') {
        this.notificationsService.alert('App republish was started!', 'Started at ' + this.datepipe.transform(new Date(), 'HH:mm'), {
          timeOut: 120000,
          showProgressBar: true,
          pauseOnHover: true,
        });
      }
      template.cover = coverPhoto;
      template.loading = 0;
      template.date = new Date();
      template.status = 'pending';
      if (isGlobal === true && template.environment === 'vtbGlobal') {
        this.visualtourbuilderService.republishDesignTemplate(template.templateId, this.itinerary.vtb_global_id || this.itinerary.id, template.publishedId).then(() => {
          template.status = 'success';
          template.loading = 100;
          this.notificationsService.success('App was republished!', '', {
            timeOut: 10000,
            showProgressBar: true,
            pauseOnHover: true,
          });
          if (this.config.tsToken != null) {
            this.telespiritService.putItinerary(this.itinerary);
          }
          this.visualtourbuilderService.updateVtbData(this.itinerary);
        });
      } else {
        await this.pubnubService.init(this.itinerary).then(x => x.channel);
        this.telespiritService.republishCustomerTemplate(template, this.pubnubService.channel).then(async () => {
          if (isGlobal === false || this.config.tsToken != null) {
            this.telespiritService.putItinerary(this.itinerary);
          }
          if (isGlobal === true) {
            this.visualtourbuilderService.updateVtbData(this.itinerary);
          }
          this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: this.itinerary });
          this.store.dispatch({ type: 'COUNTER_ADD' });
        });
      }
    } else {
      this.notificationsService.error(`Can't republish template`, 'Status is still pending', {
        timeOut: 5000,
        showProgressBar: true,
        pauseOnHover: true,
      });
    }
  }

  async deleteTemplate(template) {
    if (this.itinerary && this.itinerary.data && this.itinerary.data.publishedTemplates && this.itinerary.data.publishedTemplates.length > 0) {
      for (let i = 0; i < this.itinerary.data.publishedTemplates.length; i++) {
        if (this.itinerary.data.publishedTemplates[i].publishedId === template.publishedId && this.itinerary.data.publishedTemplates[i].templateId === template.templateId) {
          this.itinerary.data.publishedTemplates.splice(i, 1);
          break;
        }
      }
    }
    if (isGlobal === true && template.environment === 'vtbGlobal') {
      // this.visualtourbuilderService.deleteDesignTemplate(template.templateId, this.itinerary.id).then(() => {
      if (this.config.tsToken != null) {
        this.telespiritService.putItinerary(this.itinerary);
      }
      if (isGlobal === true) {
        this.visualtourbuilderService.updateVtbData(this.itinerary);
      }
      // });
    } else {
      await this.pubnubService.init(this.itinerary).then(x => x.channel);
      this.telespiritService.deleteTemplate(template, this.itinerary.id, this.pubnubService.channel).then(async () => {
        this.telespiritService.putItinerary(this.itinerary);
        if (isGlobal === true) {
          this.visualtourbuilderService.updateVtbData(this.itinerary);
        }
        this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: this.itinerary });
        this.store.dispatch({ type: 'COUNTER_ADD' });
      });
    }
    this.cdr.detectChanges();
  }

  recheckStatus(template) {
    let url = '';
    if (template.completeUrl != null) {
      url = `https://${template.url}`;
    }
    if (template && template.downloadName && template.pdfName) {
      if (url.length === 0) {
        url = template.downloadName != null ? `https://${template.url}/${template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf` : `https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`;
      }
      this.telespiritService.downloadFile(url).subscribe(async response => {
        if (response) {
          template.status = 'success';
          this.notificationsService.success('PDF status has been refreshed!', '', {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
          });
          this.store.dispatch({ type: 'COUNTER_ADD' });
          if (isGlobal === false || this.config.tsToken != null) {
            this.telespiritService.putItinerary(this.itinerary);
          }
          if (isGlobal === true) {
            this.visualtourbuilderService.updateVtbData(this.itinerary);
          }
        }
      }), error => console.log('Error checking status', error);
    } else if (template && template.url && template.pdfName) {
      if (url.length === 0) {
        url = `https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`;
      }
      this.telespiritService.downloadFile(url).subscribe(async response => {
        if (response) {
          template.status = 'success';
          this.notificationsService.success('PDF status has been refreshed!', '', {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
          });
          this.store.dispatch({ type: 'COUNTER_ADD' });
          if (isGlobal === false || this.config.tsToken != null) {
            this.telespiritService.putItinerary(this.itinerary);
          }
          if (isGlobal === true) {
            this.visualtourbuilderService.updateVtbData(this.itinerary);
          }
        }
      }), error => console.log('Error checking status', error);
    } else if (template && template.url) {
      if (template.environment != null) {
        this.visualtourbuilderService.getProposalBuilds([template.publishedId]).then(builds => {
          if (builds != null && builds.length > 0) {
            let changed = 'false';
            builds.forEach(build => {
              if (build.status === 'done') {
                template.status = 'success';
                const toast = this.notificationsService.success(`${template.type[0].toUpperCase() + template.type.slice(1)} status was succesfully refreshed!`, 'Click here to open', {
                  timeOut: 20000,
                  showProgressBar: true,
                  pauseOnHover: true
                });
                toast.click.subscribe(() => {
                  window.open(template.url, "_blank");
                });
                changed = 'true';
              }
            });
            if (changed === 'true') {
              if (isGlobal === false || this.config.tsToken != null) {
                this.telespiritService.putItinerary(this.itinerary);
              }
              if (isGlobal === true) {
                this.visualtourbuilderService.updateVtbData(this.itinerary);
              }
            }
          }
        });
      } else {
        if (url.length === 0) {
          url = `https://${template.url}/index.html`;
        }
        this.telespiritService.downloadFile(url).subscribe(async response => {
          if (response) {
            template.status = 'success';
            if (template.type && template.type === 'website') {
              this.notificationsService.success('Website status has been refreshed!', '', {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
              });
            } else {
              this.notificationsService.success('Website/App status has been refreshed!', '', {
                timeOut: 5000,
                showProgressBar: true,
                pauseOnHover: true,
              });
            }
            this.store.dispatch({ type: 'COUNTER_ADD' });
            if (isGlobal === false || this.config.tsToken != null) {
              this.telespiritService.putItinerary(this.itinerary);
            }
            if (isGlobal === true) {
              this.visualtourbuilderService.updateVtbData(this.itinerary);
            }
          }
        }), error => console.log('Error checking status', error);
      }
    }
  }
}
