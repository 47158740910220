import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatSelect } from '@angular/material';
import { take, takeUntil } from 'rxjs/operators';
import { CreateRoomtypesDialogComponent } from '../create-roomtypes-dialog/create-roomtypes-dialog.component';
import * as moment from 'moment';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-element-dialog',
  templateUrl: './create-element-dialog.component.html',
  styleUrls: ['./create-element-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateElementDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('categorySelect') selectElem: MatSelect;
  viewInitialized = false;
  units: any;
  unitIds: any;
  selectedUnit: any;
  categories: any;
  media = [];

  offset: number;
  createdElement: any;

  unsubscribe = new Subject<void>();

  constructor(public dialogRef: MatDialogRef<any>, private dialog: MatDialog, private cdr: ChangeDetectorRef, private overlayContainer: OverlayContainer) { }

  ngOnInit() {
    if (this.selectElem != null) {
      this.selectElem.openedChange.pipe(takeUntil(this.unsubscribe)).subscribe((opened) => {
        if (!opened) {
          this.overlayContainer.getContainerElement().classList.remove('select-overlay');
        }
      });
    }
    if (this.dialogRef.componentInstance !== null) {
      this.units = this.dialogRef.componentInstance.units;
      if (this.units != null) {
        this.unitIds = Object.keys(this.units.items);
      }
      this.selectedUnit = this.dialogRef.componentInstance.selectedUnit;
      this.categories = this.dialogRef.componentInstance.categories;
      this.createdElement = {
        TSProduct: {
          id: 420, // TODO: update to id of the element
          disabledForPurchase: false,
          bookableDays: []
        },
        bookableDays: [],
        categories: [],
        externalInfo: [],
        maps: {
          latitude: 0,
          longitude: 0,
          zoom: 2
        },
        itemType: 'element',
        media: [],
        title: null,
        subTitle: null,
        additionalText: null,
        unitAmount: 0,
        unitId: this.selectedUnit.id,
        unitName: this.selectedUnit.name,
        optional: false,
        olPrices: {
          participants: {}
        },
        orderlineExtrafields: [],
        roomTypes: []
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInitialized = true;
      this.cdr.detectChanges();
    }, 500);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  beforeOpen() {
    this.updateDropdownWidth();
    this.overlayContainer.getContainerElement().classList.add('select-overlay');
  }

  updateDropdownWidth() {
    const elements: any = document.querySelectorAll('.cdk-overlay-pane');
    if (elements[1]) {
      elements[1].style.left = Number(elements[1].offsetLeft - 252) + 'px';
      elements[1].style.top = '65px';
    }
  }

  registerPanelScrollEvent() {
    if (this.selectElem && this.selectElem.panel && this.selectElem.panel.nativeElement) {
      this.offset = 0;
      const panel = this.selectElem.panel.nativeElement;
      panel.addEventListener('scroll', event => this.resetScroll(event, panel));
    }
  }

  resetScroll(event, panel) {
    if (this.offset - event.target.scrollTop < 120 && this.offset - event.target.scrollTop > -120) {
      this.offset = event.target.scrollTop;
    } else {
      panel.scrollTop = this.offset;
    }
  }

  dragEnded(obj) {
    this.createdElement.maps.latitude = obj.coords.lat;
    this.createdElement.maps.longitude = obj.coords.lng;
  }

  zoomChanged(zoom) {
    this.createdElement.maps.zoom = zoom;
  }

  createRoomType(event) {
    event.stopPropagation();
    let dialogRef: any = this.dialog.open(CreateRoomtypesDialogComponent);
    dialogRef.componentInstance = {
      roomTypes: this.createdElement.roomTypes
    };
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      if (result != null && result.length > 0) {
        for (const roomType of result) {
          roomType.from = moment(roomType.from).format('YYYY-MM-DD');
          roomType.to = moment(roomType.to).format('YYYY-MM-DD');
        }
        this.createdElement.roomTypes = result;
        if (this.createdElement.subTitle == null || this.createdElement.subTitle === "") {
          this.createdElement.subTitle = this.createdElement.roomTypes[0].description;
        }
        this.cdr.detectChanges();
      }
    });
  }

  expand(element, $event) {
    if (element.expand === false) {
      element.expand = true;
      for (const child of element.children) {
        child.visible = true;
        if (child.children != null && child.expand === true) {
          for (const child2 of child.children) {
            child2.visible = true;
            if (child2.children != null && child2.expand === true) {
              for (const child3 of child2.children) {
                child3.visible = true;
                if (child3.children != null && child3.expand === true) {
                  for (const child4 of child3.children) {
                    child4.visible = true;
                    if (child4.children != null && child4.expand === true) {
                      for (const child5 of child4.children) {
                        child5.visible = true;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      $event.stopPropagation();
      return;
    }
    element.expand = false;
    for (const child of element.children) {
      child.visible = false;
      if (child.children != null) {
        for (const child2 of child.children) {
          child2.visible = false;
          if (child2.children != null) {
            for (const child3 of child2.children) {
              child3.visible = false;
              if (child3.children != null) {
                for (const child4 of child3.children) {
                  child4.visible = false;
                  if (child4.children != null) {
                    for (const child5 of child4.children) {
                      child5.visible = false;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    $event.stopPropagation();
  }

  selectedCategory(event) {
    this.createdElement.categories = event.value;
  }
}
