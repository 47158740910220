import { Component, OnChanges, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AuthService, isGlobal } from '../../services/auth.service';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderComponent implements OnChanges {
    @Input() slides: any[];
    activeIndex: number;
    automaticSlide: any;
    constructor(private ref: ChangeDetectorRef, private auth: AuthService) {
        this.activeIndex = 0;
    }

    get sliderInnerWidth() {
        return (this.slides.length * 100) + '%';
    }

    get slideWidth() {
        return (100 / this.slides.length) + '%';
    }

    get sliderInnerLeft() {
        return '-' + (this.activeIndex * 100) + '%';
    }

    get formattedSlides() {
        return this.slides.reduce((all, item) => {
            let mediaspiritDefaultCropres = '';
            if (isGlobal === false) {
                mediaspiritDefaultCropres = this.auth.decodedToken['https://visualtourbuilder.com/app_metadata'].mediaspiritDefaultCropresUri != null ? this.auth.decodedToken['https://visualtourbuilder.com/app_metadata'].mediaspiritDefaultCropresUri : '/square/md';
            } else {
                mediaspiritDefaultCropres = this.auth.decodedToken.vtbConfig.mediaspiritDefaultCropresUri != null ? this.auth.decodedToken.vtbConfig.mediaspiritDefaultCropresUri : '/square/md';
            }
            let url = item.url.split(mediaspiritDefaultCropres + '/');
            let encodedURI = `${url[0]}/pano2/header/${encodeURIComponent(url[1])}`.replace('640/480', '1280/480').replace(mediaspiritDefaultCropres, 'pano2/header');
            all.push({ url: encodedURI });
            return all;
        }, []);
    }

    ngOnChanges(changes) {
        let that = this;
        clearInterval(this.automaticSlide);
        this.automaticSlide = window.setInterval(() => {
            if (that.activeIndex < (that.slides.length - 1)) {
                that.activeIndex++;
            } else {
                that.activeIndex = 0;
            }
            that.ref.markForCheck();
        }, 4000);
    }

}
