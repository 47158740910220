import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-element-update-confirm',
  templateUrl: './element-update-confirm.component.html',
  styleUrls: ['./element-update-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementUpdateConfirmComponent implements OnInit {
  elementUpdates: any = [];
  elementCheckUncheckAll: any = [];
  errorTypes: any = [];

  constructor(private matDialogRef: MatDialogRef<ElementUpdateConfirmComponent>) { }

  ngOnInit() {
    if (this.matDialogRef.componentInstance !== null) {
      this.elementUpdates = this.matDialogRef.componentInstance.elementUpdates;
      this.elementCheckUncheckAll = this.matDialogRef.componentInstance.elementCheckUncheckAll;
      if (this.matDialogRef.componentInstance.errorTypes != null) {
        this.errorTypes = this.matDialogRef.componentInstance.errorTypes;
      }
    }
  }

  setChecked(event) {
    const checked = event.checked;
    for (let element of this.elementCheckUncheckAll) {
      if (typeof element.extrafields === 'undefined') {
        this.elementUpdates[element] = checked;
      } else {
        for (let extrafield of element.extrafields) {
          this.elementUpdates.extrafields[extrafield] = checked;
        }
      }
    }
  }

  checkErrorTypes(type) {
    if (this.errorTypes.length > 0) {
      for (const error of this.errorTypes) {
        if (error === type) {
          return true;
        } else if (error.indexOf('-extrafield')) {
          if (error.replace('-extrafield', '') === type) {
            return true;
          }
        }
      }
    } else {
      return false;
    }
  }
}