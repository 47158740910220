import { Injectable } from '@angular/core';
import { CarRentalDialogComponent } from '../components/car-rental-dialog/car-rental-dialog.component';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ElementsService {
  constructor(private matDialog: MatDialog) { }

  showCarRentalDialog(segments, segment, callback, context, units, element = null, index = null) {
    const tempCarElements = [];
    const elementSegmentIndexes = [];
    let segmentsObj = {};
    if (element == null) {
      for (const carElement of segment.carRentalElements) {
        tempCarElements.push(carElement.element);
        elementSegmentIndexes.push(carElement.segmentIndex);
        segmentsObj[carElement.segmentIndex] = segments[carElement.segmentIndex];
      }
    } else {
      tempCarElements.push(element);
      elementSegmentIndexes.push(index);
      segmentsObj[index] = segments[index];
    }
    let timebasedUnitFound = false;
    if (segment.elements != null && segment.elements.length > 0) {
      for (const element of segment.elements) {
        if (units != null && units.items != null && units.items[element.unitId] != null && units.items[element.unitId].isTimeBased === true && (units.items[element.unitId].flexRelatedProduct === false || units.items[element.unitId].flexRelated === false)) {
          if (element.nights > 0) {
            timebasedUnitFound = true;
            break;
          }
        }
      }
    }
    let dialog: any = this.matDialog.open(CarRentalDialogComponent, { disableClose: true, width: '480px' });
    dialog.componentInstance.data = {
      elements: tempCarElements,
      segments: segmentsObj,
      elementSegmentIndexes: elementSegmentIndexes,
      activeElement: element,
      units: units,
      timebasedElementsFound: timebasedUnitFound
    };
    dialog.afterClosed().pipe(take(1)).subscribe(result => {
      callback.call(context);
      callback.call(context); // double trigger needed for flex car icon to be updated on side itinerary
    });
  }
}
