import { Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererParams } from 'ag-grid';

@Component({
    selector: 'segment-link',
    templateUrl: './segment-link.component.html',
    styleUrls: ['./segment-link.component.scss']
})
export class SegmentLinkComponent {

    @ViewChild('.link') link: ElementRef;

    public params: ICellRendererParams;

    constructor() { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    toSegment(event) {
        for (const method of this.params.colDef['execute']) {
            method.call(this.params.colDef['context'], this.params);
        }
    }
}
