import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DestyService } from 'app/services/desty.service';

@Component({
  selector: 'app-external-hotel-booking-dialog',
  templateUrl: './external-hotel-booking-dialog.component.html',
  styleUrls: ['./external-hotel-booking-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalHotelBookingDialogComponent implements OnInit {
  type: string;
  searchType: any;
  element: any;
  busy = false;

  token: any;

  available = false;
  hasBreakfast = false;
  refundable = true;
  prePaid = false;
  roomType = '';
  mainInfo = '';
  sellingCurrency = '';
  sellingPrice = 0;

  rooms = [];
  selectedRoom: any;

  errorMsg = '';
  terms = false;

  displayedColumns = ['checkbox', 'roomtype description', 'price'];

  constructor(private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<any>, private destyService: DestyService) { }

  ngOnInit() {
    if (typeof this.dialogRef.componentInstance === 'object' && this.dialogRef.componentInstance !== null) {
      this.type = this.dialogRef.componentInstance.type;
      this.element = this.dialogRef.componentInstance.data;

      if (this.type === 'desty') {
        this.busy = true;
        const partyArray = []
        if (this.element.olPrices && this.element.olPrices.participants && Object.keys(this.element.olPrices.participants).length > 0) {
          partyArray.push({ adults: Object.keys(this.element.olPrices.participants).length });
        }
        let postObj = {
          ids: this.element.externalInfo.externalId,
          availabilitySearch: {
            checkinDate: this.element.date.getFullYear() + '-' + ((this.element.date.getMonth() + 1) < 10 ? '0' : '') + Number(this.element.date.getMonth() + 1) + '-' + (this.element.date.getDate() < 10 ? '0' : '') + this.element.date.getDate(),
            nights: this.element.nights,
            party: partyArray
          }
        }
        this.destyService.checkHotelAvailability(postObj, 'available').then(x => {
          if (x[0] && x[0].hotelInfo && x[0].hotelInfo.rooms && x[0].hotelInfo.rooms.length > 0) {
            this.searchType = 'available';
            this.rooms = x[0].hotelInfo.rooms.sort((a, b) => {
              if (a.sellingPriceTotal && a.sellingPriceTotal.value && b.sellingPriceTotal && b.sellingPriceTotal.value) {
                return a.sellingPriceTotal.value - b.sellingPriceTotal.value;
              }
              return 0;
            });
            this.busy = false;
          } else {
            this.busy = false;
            this.errorMsg = "No rooms found for this hotel at this date. Please try to book this hotel again with a different start date";
          }
          this.cdr.markForCheck();
        }).catch(err => {
          console.log(err)
          this.busy = false;
          this.errorMsg = "No rooms found for this hotel at this date. Please try to book this hotel again with a different start date and/or different amount of participants per party";
          this.cdr.markForCheck();
        });
      }
    }
  }

  selectRoomType() {
    if (this.element.subTitle != null && this.element.subTitle !== '') {
      if (this.element.olPrices && this.element.olPrices.participants) {
        this.busy = true;
        this.searchType = 'roomDetails';
        this.destyService.startPreBook(this.element, this.element.externalInfo.externalId, this.selectedRoom).then(x => {
          const res: any = x;
          if (res != null) {
            if (res.error != null) {
              this.errorMsg = "This hotel is unavailable at this date. Please try to book this hotel again with a different start date.";
              this.busy = false;
              this.cdr.markForCheck();
              return;
            }
            if (res.token != null) {
              this.token = res.token;
            }
            if (res.roomDetails != null) {
              if (res.roomDetails.isAvailable === true) {
                this.available = true;
              }
              if (res.roomDetails.hasBreakfast === true) {
                this.hasBreakfast = true;
              }
              if (res.roomDetails.isNonRefundable === true) {
                this.refundable = false;
              }
              if (res.roomDetails.isPrePayment === true) {
                this.prePaid = true;
              }
              if (res.roomDetails.moreInformation != null && res.roomDetails.moreInformation !== '') {
                this.mainInfo = res.roomDetails.moreInformation;
              }
              if (res.roomDetails.name != null && res.roomDetails.name !== '') {
                this.roomType = res.roomDetails.name;
                if (res.roomDetails.boardDescription != null && res.roomDetails.boardDescription !== '') {
                  this.roomType += ' (' + res.roomDetails.boardDescription + ')';
                }
              }
              if (res.roomDetails.sellingPriceTotal != null) {
                if (res.roomDetails.sellingPriceTotal.currency != null && res.roomDetails.sellingPriceTotal.value != null) {
                  this.sellingCurrency = res.roomDetails.sellingPriceTotal.currency;
                  this.sellingPrice = res.roomDetails.sellingPriceTotal.value;
                }
              }
            } else {
              this.errorMsg = "This hotel is unavailable at this date. Please try to book this hotel again with a different start date.";
            }
          }
          this.busy = false;
          this.cdr.markForCheck();
        });
      } else {
        this.errorMsg = 'No participants linked to this element, please add some participants before booking a Desty hotel.';
      }
    } else {
      this.errorMsg = 'Subtitle/Roomtype is not set for this element, please regather info or set a valid subtitle.';
    }
  }

  bookExternalHotel() {
    if (this.searchType === 'available') {
      this.selectRoomType();
      return;
    }
    this.dialogRef.close({ type: 'true', token: this.token, roomType: this.selectedRoom });
  }

  checkRoom(room) {
    if (this.selectedRoom && this.selectedRoom.id === room.id) {
      return true;
    }
    return false;
  }

  roomClicked(room) {
    if (room === this.selectedRoom) {
      this.selectedRoom = null;
    } else {
      this.selectedRoom = room;
    }
  }
}
