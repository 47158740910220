// ./effects/auth.effects.ts
import { Injectable } from '@angular/core';
//import { HttpClient } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ActionWithPayload } from '../models/action-with-payload';
import { TelespiritService } from '../services/telespirit.service';
import { GlobalErrorHandler } from '../services/error-handler';
import { DayCalculationService } from '../services/day-calculation.service';
import { PubnubService } from '../services/pubnub.service';
import { HelpersService } from '../services/helpers.service';
import { NotificationsService } from 'angular2-notifications';
import { DestyService } from '../services/desty.service';
import { isGlobal } from '../services/auth.service';
import { VisualtourbuilderService } from 'app/services/visualtourbuilder.service';
import { Config } from 'app/classes/config.class';

export class EffectError implements Action {
  readonly type = '[Error] Effect Error';
}

@Injectable()
export class ItineraryEffects {
  // Listen for the 'LOGIN' action
  /*@Effect() ITINERARY_SAVE$: Observable<Action> = this.actions$.pipe(
    ofType('ITINERARY_SAVE'),
    mergeMap((action:ActionWithPayload) =>
      this.http.post('/assets/js/dummy-data2.json',action.payload).pipe(
        map(data => ({ type: 'ITINERARY_SAVE_SUCCESS', payload: data }))
        ,catchError(() => of({ type: 'ITINERARY_SAVE_FAILED' }))
      )
    )
  );*/

  backupTimeFrom = 0;


  @Effect() ITINERARY_UPDATE$: Observable<any> = this.actions$.pipe(
    ofType('ITINERARY_UPDATE'),
    mergeMap((action: ActionWithPayload) => {
      // console.log('Running ITINERARY_UPDATE effect');

      let itinerary = action.payload;
      itinerary.ts = new Date();
      let liborderId;
      let activeSegment;
      let activeElement;

      for (let segment of itinerary.data.segments) {
        if (segment != null) {
          if (segment.hasOwnProperty('liborderId') === true) {
            liborderId = segment.liborderId;
            //console.log('Found libOrder:',segment.name,liborderId);
            break;
          }
          if (segment.elements != null && segment.elements.length > 0) {
            for (let element of segment.elements) {
              if (element.dataType != null && element.dataType === 'hotel') {
                delete element.dataType;
                activeElement = element;
                break;
              } else if (element.dataType != null && element.dataType === 'desty') {
                delete element.dataType;
                delete element.tags;
                activeElement = element;
                activeSegment = segment;
                break;
              } else if (element.dataType != null && element.dataType === 'elementSegment' && (element.externalInfo != null && element.externalInfo.externalId != null)) {
                delete element.tags;
                activeElement = element;
                activeSegment = segment;
                break;
              }
            }
          }
        }
      }

      if (liborderId != null) {
        // console.log('Requesting full lib order:',liborderId);
        if (isGlobal === true && this.config.tsToken == null) {
          return this.visualtourbuilderService.getTemplate(liborderId).then(x => {
            x.liborderId = liborderId;
            if (x.segments != null && x.segments.length > 0) {
              for (const segment of x.segments) {
                segment.liborderId = liborderId;
              }
            }
            if (x == null) {
              this.pubnubService.init(itinerary, true);
              if (this.checkForTimeDiff(itinerary.ts) === true) {
                return { type: 'ITINERARY_BACKUPS_ADD', payload: itinerary };
              }
              return { type: 'NO_ACTION' };
            } else {
              x.segments[0].droppedIndex = true;
              this.dayCalculationService.calcAndUpdateItinerary(x, 'ITINERARY_LIBORDER_UPDATE');
              this.pubnubService.init(itinerary, true);
              if (this.checkForTimeDiff(itinerary.ts) === true) {
                return { type: 'ITINERARY_BACKUPS_ADD', payload: itinerary };
              }
              return { type: 'NO_ACTION' };
            }
          }).catch(err => {
            //console.log('Error getting full lib order:',liborderId,error);
            for (let i = 0; i < itinerary.data.segments.length; i++) {
              if (itinerary.data.segments[i].hasOwnProperty('liborderId') === true && itinerary.data.segments[i].liborderId === liborderId) {
                //console.log('Deleting placeholder lib order segment:',itinerary.data.segments[i]);
                itinerary.data.segments.splice(i, 1);
                break;
              }
            }
            this.globalErrorHandler.handleError(err);
            return of(new EffectError());
          });
        } else {
          return this.telespiritService.getFullLiborder(liborderId).pipe(take(1),
            map((res) => {
              const data: any = res;
              //console.log('Found full lib order:',liborderId,data);
              if (data == null) {
                this.pubnubService.init(itinerary, true);
                if (this.checkForTimeDiff(itinerary.ts) === true) {
                  return { type: 'ITINERARY_BACKUPS_ADD', payload: itinerary };
                }
                return { type: 'NO_ACTION' };
              } else {
                data.segments[0].droppedNights = this.helpersService.getTotalNights(data.segments);
                data.segments[0].droppedIndex = true;
                this.dayCalculationService.calcAndUpdateItinerary(data, 'ITINERARY_LIBORDER_UPDATE');
                this.pubnubService.init(itinerary, true);
                if (this.checkForTimeDiff(itinerary.ts) === true) {
                  return { type: 'ITINERARY_BACKUPS_ADD', payload: itinerary };
                }
                return { type: 'NO_ACTION' };
              }
            }), catchError((error) => {
              //console.log('Error getting full lib order:',liborderId,error);
              for (let i = 0; i < itinerary.data.segments.length; i++) {
                if (itinerary.data.segments[i].hasOwnProperty('liborderId') === true && itinerary.data.segments[i].liborderId === liborderId) {
                  //console.log('Deleting placeholder lib order segment:',itinerary.data.segments[i]);
                  itinerary.data.segments.splice(i, 1);
                  break;
                }
              }
              this.globalErrorHandler.handleError(error);
              return of(new EffectError());
            })
          );
        }
      } else if (activeElement && activeElement.externalInfo != null && activeElement.externalInfo.externalType === 'desty') {
        return this.destyService.getHotelInfo(activeElement.externalInfo.externalId).then(x => {
          return this.helpersService.setDestyElementData(activeSegment, x, itinerary.data).then(() => {
            this.dayCalculationService.calcAndUpdateItinerary(itinerary, 'ITINERARY_UPDATE', true);
            this.pubnubService.init(itinerary, true);
            if (this.checkForTimeDiff(itinerary.ts) === true) {
              return { type: 'ITINERARY_BACKUPS_ADD', payload: itinerary };
            }
            return { type: 'NO_ACTION' };
          }).catch(err => {
            console.log(err)
            this.dayCalculationService.calcAndUpdateItinerary(itinerary, 'ITINERARY_UPDATE', true);
            this.notificationsService.error('Desty data was not gathered succesfully!', 'Regather the data or drag and drop the element again', {
              timeOut: 5000,
              showProgressBar: true,
              pauseOnHover: true,
            });
            return { type: 'NO_ACTION' };
          });
        }).catch(err => {
          activeElement.externalInfo.gatherError = true;
          activeElement.externalInfo.gatheringData = false;
          this.dayCalculationService.calcAndUpdateItinerary(itinerary, 'ITINERARY_UPDATE', true);
          this.notificationsService.error('Desty data was not gathered succesfully!', 'Regather the data or drag and drop the element again', {
            timeOut: 5000,
            showProgressBar: true,
            pauseOnHover: true,
          });
          return { type: 'NO_ACTION' };
        });
      } else {
        if (itinerary.pubnubChange != null && itinerary.pubnubChange === true) {
          delete itinerary.pubnubChange;
          this.dayCalculationService.calcAndUpdateItinerary(itinerary, 'ITINERARY_UPDATE', true);
        }
        this.pubnubService.init(itinerary, true);
        if (this.checkForTimeDiff(itinerary.ts) === true) {
          return of({ type: 'ITINERARY_BACKUPS_ADD', payload: itinerary });
        }
        return of({ type: 'NO_ACTION' });
      }
    })
  );

  @Effect() ITINERARY_TEXT_UPDATE$: Observable<Action> = this.actions$.pipe(
    ofType('ITINERARY_TEXT_UPDATE'),
    mergeMap((action: ActionWithPayload) => {
      action.payload.source = 'vtb';
      this.pubnubService.publishMessage(action.payload);
      return of({ type: 'NO_ACTION' });
    })
  )

  constructor(
    private actions$: Actions,
    private telespiritService: TelespiritService,
    private destyService: DestyService,
    private visualtourbuilderService: VisualtourbuilderService,
    private helpersService: HelpersService,
    private globalErrorHandler: GlobalErrorHandler,
    private dayCalculationService: DayCalculationService,
    private pubnubService: PubnubService,
    private notificationsService: NotificationsService,
    private config: Config
  ) { }

  checkForTimeDiff(timestamp) {
    const timestampMs = new Date(timestamp).getTime();
    const currentMs = new Date().getTime();
    const delay = Number(localStorage.getItem('backupDelay')) * 1000;
    if ((currentMs - this.backupTimeFrom) > delay) {
      this.backupTimeFrom = timestampMs;
      return true;
    }
    return false;
  }
}
