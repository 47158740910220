
//import { throwError as observableThrowError } from 'rxjs/internal/observable/throwError';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class IataService {

  endpoint: string;
  constructor(private auth: AuthService, private http: HttpClient) {
    if (this.auth.decodedToken[this.auth.tokenId] != null && this.auth.decodedToken[this.auth.tokenId].baseUrl != null) {
      this.endpoint = this.auth.decodedToken[this.auth.tokenId].baseUrl;
    }
  }

  searchAirports(query: string, config, isHarbour = false): Observable<any> { // works properly with other api endpoint...
    const params = { city: query };
    const url = new URL(window.location.href);
    let token = url.searchParams.get("token");
    let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    if (isHarbour === true) {
      queryString += '&entity_type=harbour';
    }
    let queryParams = '&limit=50';
    if (config.flightDataLanguage != null) {
      queryParams += `&lang=${config.flightDataLanguage || 'en'}`;
    }
    let baseUrlEndpoint;
    if (config.baseUrl == null) {
      baseUrlEndpoint = this.endpoint + '/airport';
    } else {
      baseUrlEndpoint = config.baseUrl + '/airport';
      token = config.tsToken;
    }
    return this.http.get(`${baseUrlEndpoint}?${queryString}${queryParams}`, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    }).pipe(map((res: any) => {
      if (res && res.response && res.airports) {
        return res.response.airports;
      } else if (res && res.data) {
        return res.data;
      }
    }), catchError((error: any) => observableThrowError(error || 'Server error')));
  }

  searchSingleAirport(query: string, config, isHarbour = false) {
    return new Promise(resolve => {
      this.searchAirports(query, config, isHarbour).subscribe(airports => {
        if (airports != null && airports.length > 0) {
          resolve(airports[0]);
        }
        resolve(airports);
      })
    });
  }

  searchAirportOnIataCode(query, baseUrl = null, tsToken = null) {
    const params = { iata_code: query };
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    const queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let baseUrlEndpoint;
    if (baseUrl == null) {
      baseUrlEndpoint = this.endpoint + '/airport';
    } else {
      baseUrlEndpoint = baseUrl + '/airport';
    }
    return this.http.get(`${baseUrlEndpoint}?${queryString}&limit=50`, {
      headers: new HttpHeaders({
        'Authorization': tsToken || token
      })
    }).pipe(map((res: any) => {
      if (res && res.response && res.airports) {
        return res.response.airports;
      } else if (res && res.data) {
        return res.data;
      }
    }), catchError((error: any) => observableThrowError(error || 'Server error')));
  }

  searchAirlines(query: string, config) {
    const url = new URL(window.location.href);
    let token = url.searchParams.get("token");
    let baseUrlEndpoint;
    if (config.baseUrl == null) {
      baseUrlEndpoint = this.endpoint + '/airline';
    } else {
      baseUrlEndpoint = config.baseUrl + '/airline';
      token = config.tsToken;
    }
    return this.http.get(`${baseUrlEndpoint}?searchText=${query}&limit=150`, {
      headers: new HttpHeaders({
        'Authorization': token
      })
    }).pipe(map((res: any) => {
      if (res && res.response && res.airlines) {
        return res.response.airlines;
      } else if (res && res.data) {
        return res.data;
      }
    }), catchError((error) => {
      return this.http.jsonp('https://airlabs.co/api/v7/airlines.jsonp?api_key=816202e0-7a41-4537-8057-ef154290b4f8&callback=JSONP_CALLBACK&iata_code=' + query, 'JSONP_CALLBACK').pipe(map((res: any) => { return res.response; }), catchError((error: any) => observableThrowError(error || 'Server error')));
    }))
  }
}
