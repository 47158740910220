import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<any>) {
  }

  ngOnInit() {
    if (typeof this.dialogRef.componentInstance === 'object' && this.dialogRef.componentInstance !== null) {
      this.title = this.dialogRef.componentInstance.title;
      this.message = this.dialogRef.componentInstance.message;
    } else {
      this.message = this.dialogRef.componentInstance;
    }
  }
}
