import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-prices-participants-sales-total-dialog',
  templateUrl: './prices-participants-sales-total-dialog.component.html',
  styleUrls: ['./prices-participants-sales-total-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PricesParticipantsSalesTotalDialogComponent implements OnInit {
  title: string;
  inputHeader: string;
  salesTotal: number;
  participantName: string;

  divideEqually = false;

  constructor(private matDialogRef: MatDialogRef<PricesParticipantsSalesTotalDialogComponent>) { }

  ngOnInit() {
    if (this.matDialogRef.componentInstance !== null) {
      this.title = this.matDialogRef.componentInstance.title;
      this.inputHeader = this.matDialogRef.componentInstance.inputHeader;
      this.salesTotal = Number(this.matDialogRef.componentInstance.salesTotal);
      this.participantName = this.matDialogRef.componentInstance.participantName || null;
    }
  }

  closeDialog() {
    if (this.participantName != null) {
      this.matDialogRef.close({ salesTotal: this.salesTotal, participantName: this.participantName });
    } else {
      this.matDialogRef.close({ salesTotal: this.salesTotal, divideEqually: this.divideEqually });
    }
  }
}
