import { Component, OnInit, OnDestroy, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../models/app-state';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { DayCalculationService } from '../../services/day-calculation.service';
import { Config } from '../../classes/config.class';
import { TelespiritService } from '../../services/telespirit.service';
import { HelpersService } from '../../services/helpers.service';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { take, takeUntil } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { PageScrollService } from 'ngx-page-scroll-core';
import { AuthService, isGlobal } from '../../services/auth.service';
import { ExpiredDialogComponent } from 'app/components/expired-dialog/expired-dialog.component';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit, OnDestroy {
  @ViewChild('bag') bag: any;

  unsubscribe = new Subject<void>();

  itinerary: Observable<AppModel.Itinerary>;
  itinerarySubscription: Subscription;
  itineraryValue: any;

  segmentTypes: Observable<any>;
  segmentTypesSubscription: Subscription;
  segmentTypesValue: any;

  unitTypes: Observable<any>;
  unitTypesSubscription: Subscription;
  unitTypesValue: any;

  inputUpdateStrategy: any = { updateOn: 'blur' };
  toggle: boolean = false;
  searchedRoomTypes: any;

  env: any;
  panelWidth = '';
  isGlobalValue = isGlobal;

  constructor(private matDialog: MatDialog, private store: Store<AppState>, private dayCalculationService: DayCalculationService, private telespiritService: TelespiritService, public helpersService: HelpersService, private config: Config, private authService: AuthService, private pageScrollService: PageScrollService, private router: Router, @Inject(DOCUMENT) private document: any, private cdr: ChangeDetectorRef) {
    this.itinerary = this.store.select('itinerary');
    this.segmentTypes = this.store.select('segmentTypes');
    this.unitTypes = this.store.select('units');
    this.searchedRoomTypes = [];
    this.env = this.config;
  }

  ngOnInit() {
    this.itinerarySubscription = this.itinerary.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.itineraryValue = x;
    });
    this.segmentTypesSubscription = this.segmentTypes.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.segmentTypesValue = x;
    });
    this.unitTypesSubscription = this.unitTypes.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.unitTypesValue = x;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get tokenObj() {
    let obj: any = {};
    if (this.authService.authenticated != null && this.authService.authenticated === true) {
      obj.token = this.authService.token;
    }
    return obj;
  }

  getElement(element) {
    if (this.isGlobalValue === false || this.env.tsToken != null) {
      const language = this.itineraryValue.data.TSOrder.language || null;
      if (element.TSProduct && element.TSProduct.id) {
        this.telespiritService.getElement(element.TSProduct.id).then((x: any) => {
          if (x.roomTypes != null && x.roomTypes.length > 0) {
            if (this.env.defaultSubtitleSort != null && this.env.defaultSubtitleSort !== '') {
              element.roomTypes = this.helpersService.sortSubtitles(x.roomTypes, this.env.defaultSubtitleSort, language, null, this.env.enableLanguageSubtitles);
            } else {
              element.roomTypes = x.roomTypes;
            }
            element.insuranceData = null;
          }
        });
      } else {
        if (element.roomTypes && element.roomTypes.length > 0) {
          if (this.env.defaultSubtitleSort != null && this.env.defaultSubtitleSort !== '') {
            element.roomTypes = this.helpersService.sortSubtitles(element.roomTypes, this.env.defaultSubtitleSort, language, element.dataSource, this.env.enableLanguageSubtitles);
          } else {
            element.roomTypes = this.helpersService.sortSubtitles(element.roomTypes, 'priceasc', language, element.dataSource, this.env.enableLanguageSubtitles);
          }
        }
      }
    }
  }

  scrollToSegment(segmentIndex, retry = true) {
    const plainView = document.querySelector('#plain-view-scroll');
    if (plainView != null) {
      this.pageScrollService.scroll({ document: this.document, scrollTarget: `#item_${segmentIndex}`, scrollViews: [plainView] });
    } else if (retry === true) {
      this.router.navigate(['/'], { queryParams: this.tokenObj }).then(x => {
        setTimeout(() => {
          this.scrollToSegment(segmentIndex, false);
        });
      });
    }
  }

  storeUpdateItinerary() {
    if (this.helpersService.isExpired() === false) {
      this.dayCalculationService.calcAndUpdateItinerary(this.itineraryValue, 'ITINERARY_UPDATE');
      this.store.dispatch({ type: 'COUNTER_ADD' });
      this.cdr.detectChanges();
    } else {
      this.matDialog.open(ExpiredDialogComponent, {
        width: '600px'
      });
    }
  }

  toggledOptional() {
    this.dayCalculationService.calcAndUpdateItinerary(this.itineraryValue, 'ITINERARY_UPDATE');
  }

  deleteSegment(segmentIndex) {
    let destyElementFound = false;
    if (this.itineraryValue.data.segments[segmentIndex] && this.itineraryValue.data.segments[segmentIndex].elements != null && this.itineraryValue.data.segments[segmentIndex].elements.length > 0) {
      for (const element of this.itineraryValue.data.segments[segmentIndex].elements) {
        if (element.externalInfo != null && element.externalInfo.externalType != null && element.externalInfo.externalType === 'desty' && element.externalInfo.destyBookingData != null) {
          destyElementFound = true;
          break;
        }
      }
    }

    if (destyElementFound === true) {
      let dialog: any = this.matDialog.open(ConfirmDialogComponent, {
        width: '500px'
      });
      dialog.componentInstance = {
        title: 'Booked hotels found in the Segment',
        message: `Deleting this segment will NOT cancel the hotels that are booked at desty, are you sure you want to delete this segment from the VTB?`
      };
      dialog.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === 'true') {
          this.itineraryValue.data.segments.splice(segmentIndex, 1);
          this.itineraryValue.data.deletedIndex = segmentIndex;
          this.storeUpdateItinerary();
        }
      });
    } else {
      this.itineraryValue.data.segments.splice(segmentIndex, 1);
      this.itineraryValue.data.deletedIndex = segmentIndex;
      this.storeUpdateItinerary();
    }
  }

  removeAllSegments() {
    let dialog: any = this.matDialog.open(ConfirmDialogComponent);
    dialog.componentInstance = 'You are about to remove all segments, are you sure?';
    dialog.afterClosed().pipe(take(1)).subscribe(x => {
      if (x != null && x !== '') {
        this.itineraryValue.data.segments = [];
        this.itineraryValue.data.totalDays = 1;
        this.itineraryValue.data.totalDaysAllocated = 1;
        this.itineraryValue.data.startDate = new Date();
        this.itineraryValue.data.endDate = new Date();
        this.storeUpdateItinerary();
      }
    });
  }

  deleteElement(segmentIndex, elementIndex) {
    if (this.itineraryValue.data.segments[segmentIndex].elements[elementIndex].dataSource != null && this.itineraryValue.data.segments[segmentIndex].elements[elementIndex].dataSource === 'desty' && this.itineraryValue.data.segments[segmentIndex].elements[elementIndex].externalInfo != null && this.itineraryValue.data.segments[segmentIndex].elements[elementIndex].externalInfo.destyBookingData != null) {
      let dialog: any = this.matDialog.open(ConfirmDialogComponent, {
        width: '500px'
      });
      dialog.componentInstance = {
        title: 'Booked hotel delete warning',
        message: `Deleting this hotel will NOT cancel the booking at desty, are you sure you want to delete this hotel from the VTB?`
      };
      dialog.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === 'true') {
          this.itineraryValue.data.segments[segmentIndex].elements.splice(elementIndex, 1);
          this.storeUpdateItinerary();
        }
      });
    } else {
      this.itineraryValue.data.segments[segmentIndex].elements.splice(elementIndex, 1);
      this.storeUpdateItinerary();
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.storeUpdateItinerary();
  }

  getIconUrl(unitId) {
    if (this.unitTypesValue != null && this.unitTypesValue.items && this.unitTypesValue.items[unitId] != null) {
      const iconUrl = this.unitTypesValue.items[unitId].iconUrl;
      if (iconUrl != null) {
        return iconUrl;
      }
    }
    return null;
  }

  getPanelWidth(element) {
    let roomTypesLength = 0;
    if (element.roomTypes != null) {
      for (const roomType of element.roomTypes) {
        if (roomType.description != null) {
          if (roomType.description.length > roomTypesLength) {
            roomTypesLength = roomType.description.length;
          }
        }
      }
    }
    this.panelWidth = (roomTypesLength * 9) + 'px';
    return this.panelWidth;
  }

  checkBookedHotels() {
    if (this.itineraryValue != null && this.itineraryValue.data != null && this.itineraryValue.data.segments != null && this.itineraryValue.data.segments.length > 0) {
      for (const segment of this.itineraryValue.data.segments) {
        for (const element of segment.elements) {
          if (element.externalInfo != null && element.externalInfo.destyBookingData != null) {
            return true;
          }
        }
      }
    }
    return false;
  }

  checkHiddenLayout(segment, type) {
    if (this.itinerary != null && this.itineraryValue.data != null && this.itineraryValue.data.templateView === true && this.itineraryValue.data.hiddenLayout != null) {
      if (this.itineraryValue.data.hiddenLayout.segments != null && Object.keys(this.itineraryValue.data.hiddenLayout.segments).length > 0) {
        if (this.itineraryValue.data.hiddenLayout.segments[segment.typeId] != null) {
          if (this.itineraryValue.data.hiddenLayout.segments[segment.typeId].indexOf(type) > -1) {
            return true;
          }
        }
        if (this.itineraryValue.data.hiddenLayout.segments['default'] != null && this.itineraryValue.data.hiddenLayout.segments['default'].indexOf(type) > -1) {
          return true;
        }
      }
    }
    return false;
  }

  updateSubtitle(element, selection) {
    if (element.externalInfo != null) {
      for (const roomType of element.roomTypes) {
        if (selection === roomType.description) {
          if (roomType.defaultPrice != null && roomType.defaultPrice.value != null) {
            if (roomType.defaultPrice.currency) {
              element.olPrices.purchaseCurrency = roomType.defaultPrice.currency;
            }
            element.olPrices.costPrice = roomType.defaultPrice.value;
            if (element.olPrices != null && element.olPrices.participants) {
              for (const participant of Object.keys(element.olPrices.participants)) {
                element.olPrices.participants[participant].costPrice = roomType.defaultPrice.value / Object.keys(element.olPrices.participants).length;
              }
            }
          }
          break;
        }
      }
    }
  }
}
