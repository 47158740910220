import { LogService } from '../services/log.service';
import { ActionWithPayload } from '../models/action-with-payload';

export const ROUNDING_UPDATE = 'ROUNDING_UPDATE';
const logService = new LogService();

export function roundingReducer(state: any[] = undefined, action: ActionWithPayload) {
    switch (action.type) {
      case ROUNDING_UPDATE:
        state = action.payload;
        logService.consoleLog('ROUNDING_UPDATE:',state);
        return state;
      default:
        return state;
    }
  }