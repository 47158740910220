import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-error-message-dialog',
  templateUrl: './error-message-dialog.component.html',
  styleUrls: ['./error-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorMessageDialogComponent implements OnInit {

  title: string;
  errors: any;

  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    if (this.dialogRef.componentInstance != null) {
      this.title = this.dialogRef.componentInstance.title;
      this.errors = this.dialogRef.componentInstance.errors;
    }
  }

}
