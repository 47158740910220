import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { TelespiritService } from '../../services/telespirit.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Config } from '../../classes/config.class';
import { isGlobal } from '../../services/auth.service';
@Component({
  selector: 'app-libtexts',
  templateUrl: './libtexts.component.html',
  styleUrls: ['./libtexts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibtextsComponent implements OnInit, OnDestroy {
  @Input() bags: any[] = [];

  unsubscribe = new Subject<void>();
  libtexts: Observable<any>;
  libtextsSubscription: Subscription;
  libtextsValue: any;
  env: any;
  isGlobalValue = isGlobal

  constructor(
    private store: Store<any>,
    private telespiritService: TelespiritService,
    private changeDetectorRef: ChangeDetectorRef,
    private config: Config
  ) {
    this.env = this.config;
    this.libtexts = this.store.select('libtexts');
  }

  ngOnInit() {
    this.libtextsSubscription = this.libtexts.pipe(takeUntil(this.unsubscribe)).subscribe((x) => {
      this.libtextsValue = x;
      if (isGlobal === true && this.config.tsToken == null) {
        this.libtextsValue = this.processGlobalData();
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  fetchDataByType(typeId) {
    this.telespiritService.getLibtextItemsByType(typeId);
  }

  processGlobalData() {
    const types = [];
    for (const tempText of this.libtextsValue) {
      const tempType = tempText.type[0];
      const tempGroup = tempText.group[0];
      if (types.length > 0) {
        for (const type of types) {
          let found = false;
          if (tempType.id === type.id) {
            found = true;
            if (type.groups != null && type.groups.length > 0) {
              for (const group of type.groups) {
                let found = false;
                if (tempGroup.id === group.id) {
                  if (group.texts != null && group.texts.length > 0) {
                    for (const text of group.texts) {
                      found = false;
                      if (tempText.id === text.id) {
                        found = true;
                        break;
                      }
                      if (found === false) {
                        group.texts.push(
                          {
                            id: tempText.id,
                            description: tempText.name,
                            value: tempText.text
                          }
                        );
                      }
                    }
                  } else {
                    group.texts.push(
                      {
                        id: tempText.id,
                        description: tempText.name,
                        value: tempText.text
                      }
                    );
                  }
                  break;
                }
                if (found === false) {
                  type.groups.push(
                    {
                      id: tempGroup.id,
                      name: tempGroup.name,
                      sortOrder: tempGroup.sortOrder,
                      texts: [
                        {
                          id: tempText.id,
                          description: tempText.name,
                          value: tempText.text
                        }
                      ]
                    }
                  )
                }
              }
            } else {
              type.groups.push(
                {
                  id: tempGroup.id,
                  name: tempGroup.name,
                  sortOrder: tempGroup.sortOrder,
                  texts: [
                    {
                      id: tempText.id,
                      description: tempText.name,
                      value: tempText.text
                    }
                  ]
                }
              )
            }
            break;
          }
          if (found === false) {
            types.push(
              {
                id: tempType.id,
                name: tempType.name,
                sortOrder: tempType.sortOrder,
                groups: [
                  {
                    id: tempGroup.id,
                    name: tempGroup.name,
                    sortOrder: tempGroup.sortOrder,
                    texts: [
                      {
                        id: tempText.id,
                        description: tempText.name,
                        value: tempText.text
                      }
                    ]
                  }
                ]
              }
            );
          }
        }
      } else {
        types.push(
          {
            id: tempType.id,
            name: tempType.name,
            sortOrder: tempType.sortOrder,
            groups: [
              {
                id: tempGroup.id,
                name: tempGroup.name,
                sortOrder: tempGroup.sortOrder,
                texts: [
                  {
                    id: tempText.id,
                    description: tempText.name,
                    value: tempText.text
                  }
                ]
              }
            ]
          }
        );
      }
    }
    return types;
  }
}
