import { LogService } from '../services/log.service';
import { ActionWithPayload } from '../models/action-with-payload';

export const DAYTEXTS_UPDATED = 'DAYTEXTS_UPDATED';
let logService = new LogService();

export function dayTextsReducer(state: any, action: ActionWithPayload) {
    switch (action.type) {
        case DAYTEXTS_UPDATED:
            state = action.payload;
            logService.consoleLog('DAYTEXTS_UPDATED:', state);
            return { ...state };
        default:
            return state;
    }
}
