export class Config {
  production: boolean;
  logging?: any;
  tsToken?: string;
  baseUrl?: string;
  linkType?: any;
  linkId?: any;
  selectedItinerary?: any;
  currenciesFound = false;
  orderLanguage?: any;
  internalPopupIcon?: string;
  tpData?: Object;

  theme: any;
  clientId: any;
  niceUrl?: any;
  arrANVR?: any;
  autocorrectLanguage: any;
  flightDataLanguage?: any;
  backupDelay: any;
  customGlueIdentifiers?: any;
  providerNames?: any;
  favoriteUnits?: any;
  defaultSubtitleSort?: any;
  defaultSegment?: any;
  defaultMargin?: any;
  defaultDestyRadius?: any;
  upAndUntilBabyAge?: any;
  upAndUntilChildAge?: any;
  upAndUntilTeenagerAge?: any;
  defaultOptionalUnits?: any;
  defaultSalesCurrency: null;

  lockDepartment?: boolean;
  manualCalcByDefault?: boolean;
  setGlueTitle?: boolean;
  setTitle?: any;
  retainDraggedElement?: boolean;
  nightsOnly?: boolean;
  autoRecalc?: boolean;
  smartMode?: boolean;
  templateView?: boolean;
  alwaysEnableTemplateView?: boolean;
  overwriteGeneralInfoTemplate?: boolean;
  compactGlueSegment?: boolean;
  defaultFoldSegments?: boolean;
  destyBooking?: boolean;

  quickExtraFieldEdit?: any;
  onlyShowExtraFields?: any;
  hiddenLayout?: any;
  gridViewSettings?: any;
  notificationOptions?: any;
  pnr?: any;
  striking?: any;

  enableDayTexts?: boolean;
  enableOverwriteGeneralInfoTemplate?: boolean;
  enableDesty?: boolean;
  enableAirTrade?: boolean;
  enableAirtradeImportPNR?: boolean;
  enableAutoSave?: boolean;
  enableParticipantDialogs?: boolean;
  enableMoveMultipleParticipantsDialog?: boolean;
  enablePrefillDepartmentSearch?: boolean;
  enableCrosscheckProduct?: boolean;
  enableTransferVtb?: boolean;
  enableConsolidatedTransferVtb?: boolean;
  enableLanguageSubtitles?: boolean;
  disableUnitEdit?: boolean;
  disableItineraryNightsEdit?: boolean;
  disableSegmentNightsEdit?: boolean;
  disableUnallocatedNightsWarning?: boolean;
  disableJsonView?: boolean;
  disableMapView?: boolean;
  disablePriceView?: boolean;
  disableParticipantModification?: boolean;
  disableAddElement?: boolean;
  disableExtraFieldsOverride?: boolean;
  disablePdfPreviewButton?: boolean;
  disableUpdateCurrencyExchangeRate?: boolean;

  setProperties(obj) {
    for (let property in obj) {
      this[property] = obj[property];
    }
  }
}
