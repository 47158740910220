import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-error-prices-dialog',
  templateUrl: './error-prices-dialog.component.html',
  styleUrls: ['./error-prices-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorPricesDialogComponent implements OnInit {
  title: string;
  errorElements: any;

  constructor(public dialogRef: MatDialogRef<any>) {
  }

  ngOnInit() {
    if(typeof this.dialogRef.componentInstance === 'object' && this.dialogRef.componentInstance !== null){
      this.title = this.dialogRef.componentInstance.title;
      this.errorElements = this.dialogRef.componentInstance.errorElements;
    }
  }
}
