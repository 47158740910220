import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DestyService } from 'app/services/desty.service';

@Component({
  selector: 'app-desty-info-dialog',
  templateUrl: './desty-info-dialog.component.html',
  styleUrls: ['./desty-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestyInfoDialogComponent implements OnInit {
  hotel: any;
  participants: any;
  busy = false;
  roomTypesBusy = false;
  hotelData: any;
  activeTabIndex = 0;
  index = 0;
  activeIndex = 0;
  galleryIndex = 0;
  media = [];
  roomTypes = [];
  services = '';

  constructor(private changeDetectorRef: ChangeDetectorRef, private destyService: DestyService, private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this.hotel = this.dialogRef.componentInstance.hotel;
    this.participants = this.dialogRef.componentInstance.participants;
    this.busy = true;
    this.destyService.getHotelInfo(this.hotel.externalInfo.externalId).then(x => {
      this.hotelData = x;
      if (this.hotelData.hotelInfo && this.hotelData.hotelInfo.images && this.hotelData.hotelInfo.images.length > 0) {
        this.media = this.hotelData.hotelInfo.images;
      }
      if (this.hotelData && this.hotelData.hotelInfo && this.hotelData.hotelInfo.services && this.hotelData.hotelInfo.services.length > 0) {
        for (const service of this.hotelData.hotelInfo.services) {
          if (this.services === '') {
            this.services += service;
          } else {
            this.services += ', ' + service;
          }
        }
      }
      this.busy = false;
      this.changeDetectorRef.detectChanges();
    }).catch(e => {
      console.log(e)
      this.busy = false;
      this.changeDetectorRef.detectChanges();
    });
  }

  setActiveTabIndex(e) {
    this.activeTabIndex = e.index;
    if (e.index === 3) {
      if (this.roomTypes.length < 1) {
        this.getRoomTypes();
      }
    }
  }

  nextImage() {
    if (this.activeIndex === this.media.length - 1) {
      this.activeIndex = 0;
    } else {
      this.activeIndex = this.activeIndex + 1;
    }
  }

  previousImage() {
    if (this.activeIndex === 0) {
      this.activeIndex = this.media.length - 1;
    } else {
      this.activeIndex = this.activeIndex - 1;
    }
  }

  nextGallery() {
    this.galleryIndex = this.galleryIndex + 1;
  }

  previousGallery() {
    this.galleryIndex = this.galleryIndex - 1;
  }

  getIndex() {
    if (this.index >= this.media.length) {
      this.index = 0;
    }
    if (this.index < this.galleryIndex || this.index > this.galleryIndex + 3) {
      this.index = this.index + 1;
      return true;
    }
    this.index = this.index + 1;
    return false;
  }

  setActiveIndex(i) {
    this.activeIndex = i;
  }

  replaceHtml(text) {
    return text.replace(/<br>/g, '\n').replace(/<br\/>/g, '\n').replace(/<br \/>/g, '\n')
      .replace(/<p>/g, '').replace(/<\/p>/g, ' ')
      .replace(/<strong>/g, '').replace(/<\/strong>/g, '')
      .replace(/<b>/g, '').replace(/<\/b>/g, '')
      .replace(/<i>/g, '').replace(/<\/i>/g, '')
      .replace(/<u>/g, '').replace(/<\/u>/g, '')
      .replace(/<li>/g, '').replace(/<\/li>/g, '')
      .replace(/<ul>/g, '').replace(/<\/ul>/g, '')
  }

  getRoomTypes() {
    const partyArray = []
    if (this.participants != null) {
      partyArray.push({ adults: this.participants });
    } else {
      partyArray.push({ adults: 2 });
    }

    let postObj = {
      ids: this.hotel.externalInfo.externalId,
      availabilitySearch: {
        checkinDate: this.hotel.externalInfo.destyFromDate.getFullYear() + '-' + ((this.hotel.externalInfo.destyFromDate.getMonth() + 1) < 10 ? '0' : '') + Number(this.hotel.externalInfo.destyFromDate.getMonth() + 1) + '-' + (this.hotel.externalInfo.destyFromDate.getDate() < 10 ? '0' : '') + this.hotel.externalInfo.destyFromDate.getDate(),
        nights: this.hotel.nights,
        party: partyArray
      }
    }
    this.roomTypesBusy = true;
    this.destyService.checkHotelAvailability(postObj).then(x => {
      let res: any = x;
      if (res != null && res[0] != null && res[0].hotelInfo != null && res[0].hotelInfo.rooms != null && res[0].hotelInfo.rooms.length > 0) {
        this.roomTypes = res[0].hotelInfo.rooms;
        this.roomTypesBusy = false;
      } else {
        this.roomTypes = [];
        this.roomTypesBusy = false;
      }
      this.changeDetectorRef.detectChanges();
    }).catch(() => {
      this.roomTypes = [];
      this.roomTypesBusy = false;
      this.changeDetectorRef.detectChanges();
    });
  }
}
