import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-element-info-dialog',
  templateUrl: './element-info-dialog.component.html',
  styleUrls: ['./element-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementInfoDialogComponent implements OnInit {

  elementData: any;
  activeTabIndex = 0;
  activeIndex = 0;
  index = 0;
  galleryIndex = 0;

  constructor(private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this.elementData = this.dialogRef.componentInstance.element;
  }

  setActiveTabIndex(e) {
    this.activeTabIndex = e.index;
  }

  nextImage() {
    if (this.activeIndex === this.elementData.media.length - 1) {
      this.activeIndex = 0;
    } else {
      this.activeIndex = this.activeIndex + 1;
    }
  }

  previousImage() {
    if (this.activeIndex === 0) {
      this.activeIndex = this.elementData.media.length - 1;
    } else {
      this.activeIndex = this.activeIndex - 1;
    }
  }

  nextGallery() {
    this.galleryIndex = this.galleryIndex + 1;
  }

  previousGallery() {
    this.galleryIndex = this.galleryIndex - 1;
  }

  getIndex() {
    if (this.index >= this.elementData.media.length) {
      this.index = 0;
    }
    if (this.index < this.galleryIndex || this.index > this.galleryIndex + 3) {
      this.index = this.index + 1;
      return true;
    }
    this.index = this.index + 1;
    return false;
  }

  setActiveIndex(i) {
    this.activeIndex = i;
  }

  stripHTML(text) {
    if (text == null) return 'Empty';
    const regex = /(<([^>]+)>)/ig;
    let newText = text.replace(/<br>/g, '\n').replace(/<br\/>/g, '\n').replace(/<br \/>/g, '\n');
    newText = newText.replace(regex, "").replace(/&nbsp;/g, ' ');
    return (this.decodeHtmlCharCodes(newText.trim()) != null) ? this.decodeHtmlCharCodes(newText.trim()) : 'Empty';
  }

  decodeHtmlCharCodes(str) { 
    return str.replace(/(&#(\d+);)/g, function(match, capture, charCode) {
      return String.fromCharCode(charCode);
    });
  }

}
