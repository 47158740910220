import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { NotificationsService } from 'angular2-notifications';

@Component({
  selector: 'app-consolidate-transfer-dialog',
  templateUrl: './consolidate-transfer-dialog.component.html',
  styleUrls: ['./consolidate-transfer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsolidateTransferDialogComponent implements OnInit {
  displayedColumns = ['supplier', 'currency', 'salesEUR', 'costprice', 'exchangerate', 'costpriceEUR', 'donotconsolidate', 'CPID'];
  cpids = [];
  segments = [];
  consolidatedOrderlines = [];

  constructor(
    public dialogRef: MatDialogRef<any>,
    private notificationsService: NotificationsService,
  ) { }

  ngOnInit() {
    this.segments = this.dialogRef.componentInstance.segments;
    this.cpids = this.dialogRef.componentInstance.cpids;
    this.getConsolidatedOrderlines();
  }

  getConsolidatedOrderlines() {
    if (this.segments != null && this.segments.length > 0) {
      for (const segment of this.segments) {
        if (segment.elements != null && segment.elements.length > 0) {
          for (const element of segment.elements) {
            if (element.optional === false && (element.TSProduct == null || element.TSProduct.disabledForPurchase == null || element.TSProduct.disabledForPurchase === false)) {
              let added = false;
              if (this.consolidatedOrderlines.length === 0) {
                this.consolidatedOrderlines.push(
                  {
                    supplierId: element.supplierId,
                    supplier: element.supplierName != ' [#000000]' ? element.supplierName : 'none',
                    currency: element.olPrices.purchaseCurrency || '',
                    salesEur: Number(element.olPrices.salesTotal) || 0,
                    costPrice: Number(Number(element.olPrices.costPrice) / Number(element.olPrices.exchangeRate)) || 0,
                    exchangeRate: Number(element.olPrices.exchangeRate) || 0,
                    costPriceEur: Number(element.olPrices.costPrice) || 0,
                    elementIds: [element.vtbElementId],
                    amount: 1,
                    cpids: []
                  }
                );
                added = true;
              } else {
                for (const consolidate of this.consolidatedOrderlines) {
                  if (element.supplierName != null && element.olPrices.purchaseCurrency != null && element.supplierName === consolidate.supplier && element.olPrices.purchaseCurrency === consolidate.currency) {
                    let found = false;
                    for (const id of consolidate.elementIds) {
                      if (id === element.vtbElementId) {
                        found = true;
                        break;
                      }
                    }
                    if (found === false) {
                      consolidate.salesEur += Number(element.olPrices.salesTotal);
                      consolidate.costPrice += Number(Number(element.olPrices.costPrice) / Number(element.olPrices.exchangeRate));
                      consolidate.exchangeRate += Number(element.olPrices.exchangeRate);
                      consolidate.costPriceEur += Number(element.olPrices.costPrice);
                      consolidate.elementIds.push(element.vtbElementId);
                      consolidate.amount += 1;
                      added = true;
                    }
                  } else if (element.supplierName === ' [#000000]' && consolidate.supplier === 'none' && element.olPrices.purchaseCurrency != null && element.olPrices.purchaseCurrency === consolidate.currency) {
                    let found = false;
                    for (const id of consolidate.elementIds) {
                      if (id === element.vtbElementId) {
                        found = true;
                        break;
                      }
                    }
                    if (found === false) {
                      consolidate.salesEur += Number(element.olPrices.salesTotal);
                      consolidate.costPrice += Number(Number(element.olPrices.costPrice) / Number(element.olPrices.exchangeRate));
                      consolidate.exchangeRate += Number(element.olPrices.exchangeRate);
                      consolidate.costPriceEur += Number(element.olPrices.costPrice);
                      consolidate.elementIds.push(element.vtbElementId);
                      consolidate.amount += 1;
                      added = true;
                    }
                  }
                }
              }
              if (added === false) {
                this.consolidatedOrderlines.push(
                  {
                    supplierId: element.supplierId,
                    supplier: element.supplierName != ' [#000000]' ? element.supplierName : 'none',
                    currency: element.olPrices.purchaseCurrency || '',
                    salesEur: Number(element.olPrices.salesTotal) || 0,
                    costPrice: Number(Number(element.olPrices.costPrice) / Number(element.olPrices.exchangeRate)) || 0,
                    exchangeRate: Number(element.olPrices.exchangeRate) || 0,
                    costPriceEur: Number(element.olPrices.costPrice) || 0,
                    elementIds: [element.vtbElementId],
                    amount: 1,
                    cpids: []
                  }
                );
              }
            }
          }
        }
      }
    }
    for (const consolidate of this.consolidatedOrderlines) {
      if (consolidate.amount > 1) {
        consolidate.exchangeRate = Number(consolidate.exchangeRate / consolidate.amount);
      }
      consolidate.salesEur = Number(consolidate.salesEur) != 0 ? Number(consolidate.salesEur).toFixed(3) : 0;
      consolidate.costPrice = Number(consolidate.costPrice) != 0 ? Number(consolidate.costPrice).toFixed(3) : 0;
      consolidate.costPriceEur = Number(consolidate.costPriceEur) != 0 ? Number(consolidate.costPriceEur).toFixed(3) : 0;
      consolidate.doNot = false;
      consolidate.cpid = null;
      consolidate.cpids = this.getProductCPIDs(consolidate.supplierId, consolidate.supplier, consolidate.currency, consolidate.amount);
      if (consolidate.cpids.length > 0) {
        if (consolidate.cpid == null) {
          consolidate.cpid = consolidate.cpids[0];
        }
      }
    }
    this.consolidatedOrderlines = this.consolidatedOrderlines.sort((a, b) => a.supplier.localeCompare(b.supplier));
  }

  getProductCPIDs(id, supplierName, currency, amount) {
    const foundCpids = [];
    if (id != null && this.cpids != null && this.cpids.length > 0) {
      for (const cpid of this.cpids) {
        if (Number(cpid.supplierId) === Number(id) && cpid.buyingCurrencyCode === currency) {
          foundCpids.push(cpid);
        } else {
          if (cpid.arrSuppliers != null && cpid.arrSuppliers.length > 0) {
            for (const supplier of cpid.arrSuppliers) {
              if (Number(supplier.id) === Number(id) && cpid.buyingCurrencyCode === currency) {
                foundCpids.push(cpid);
              }
            }
          }
        }
      }
    }
    if (amount > 1 && foundCpids.length === 0 && supplierName !== 'none') {
      this.notificationsService.error(`Consolidated error`, `No consolidated products found for: ${supplierName} with cur: ${currency}`);
    }
    return foundCpids;
  }

  checkConsolidateOrderlines() {
    if (this.consolidatedOrderlines != null && this.consolidatedOrderlines.length > 0) {
      for (const consolidated of this.consolidatedOrderlines) {
        if (consolidated.doNot === false && consolidated.amount > 1 && (consolidated.supplier !== 'none' && consolidated.cpid == null)) {
          return true;
        }
      }
    }
    return false;
  }

  consolidateOrderlines() {
    const tempObj = {
      consolidateGroups: []
    };
    if (this.consolidatedOrderlines != null && this.consolidatedOrderlines.length > 0) {
      for (const consolidate of this.consolidatedOrderlines) {
        if (consolidate.doNot === false && consolidate.cpid != null) {
          tempObj.consolidateGroups.push(
            {
              cpid: consolidate.cpid.TSProduct.id,
              consolidatedElements: consolidate.elementIds
            }
          )
        }
      }
    }
    this.dialogRef.close(tempObj);
  }
}
