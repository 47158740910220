import { Directive, Input, HostBinding, OnInit, AfterViewInit, Output, EventEmitter, OnChanges, OnDestroy, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import * as CKEDITOR from '@sitespirit/ckeditor5-build-classic-and-inline';

@Directive({
  selector: '[inlineCkeditor]'
})
export class InlineCkeditorDirective implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @HostBinding('contentEditable') contentEditable: boolean;
  @HostBinding('id') id: string;
  @HostBinding('innerHTML') innerHTML: string;
  disableOnChanges: boolean = false;

  /* Two way databinding magic start */
  private _value: string = '';
  @Input() get inlineCkeditor() {
    return this._value;
  }
  set inlineCkeditor(val) {
    if (val != null) {
      if (this._value !== val && this._editor && this._editor.instance != null) {
        this._editor.instance.setData(val);
      }
      this._value = val;
      this.inlineCkeditorChange.emit(this._value);
    }
  }
  @Output() inlineCkeditorChange: EventEmitter<string> = new EventEmitter();
  /* Two way databinding magic end */

  @Output() onEvent: EventEmitter<any> = new EventEmitter();

  initEditor() {
    if (this._editor.instance === null) {
      this._editor.selector = this.id;
      const config = this.config || {};
      CKEDITOR.InlineEditor.create(document.getElementById(this._editor.selector), config).then(editor => {
        this._editor.instance = editor;
        editor.model.document.on('change', (e) => {
          this.handleEvent(e);
        });
        editor.ui.focusTracker.on('change:isFocused', (e, name, isFocused) => {
          if (!isFocused) {
            this.handleEvent(e);
          }
        });
        editor.ui.focusTracker.on('change:data', (e, name, data) => {
          if (!data) {
            this.handleEvent(e);
          }
        });
      }).catch(error => {
        console.error(error);
      });
    }
  }

  handleEvent(e) {
    const currentValue = this._editor.instance.getData();
    const previousValue = this._value;
    this._value = currentValue;
    /*if(this.config.updateStrategy.indexOf(e) > -1){
      this.inlineCkeditor = currentValue;
    }*/
    this.inlineCkeditor = currentValue;
    this.disableOnChanges = true;
    this.onEvent.emit({ previousValue: previousValue, currentValue: currentValue, type: e, event: e });
    setTimeout(() => this.disableOnChanges = false, 0);
  }

  private _editor: any = {
    selector: null,
    instance: null
  }

  @Input() config: any = {};

  constructor(private _sanitizer: DomSanitizer) { }

  ngOnChanges(changes) {
    if (this.disableOnChanges === false) {
      this.innerHTML = this._sanitizer.sanitize(SecurityContext.HTML, changes.inlineCkeditor.currentValue);
    } else {
      this.disableOnChanges = false;
    }
  }

  ngOnInit() {
    this.contentEditable = this.config.contentEditable || true;
    this.id = this.config.id || Math.random().toString(36).substr(2, 9);
  }

  ngAfterViewInit() {
    this.initEditor();
  }

  ngOnDestroy() {
    if (this._editor.instance != null) {
      this._editor.instance.destroy();
    }
  }
}
