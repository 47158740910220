import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TelespiritService } from '../../services/telespirit.service';

@Component({
  selector: 'app-confirm-libtext-drop',
  templateUrl: './confirm-libtext-drop.component.html',
  styleUrls: ['./confirm-libtext-drop.component.scss']
})
export class ConfirmLibtextDropComponent implements OnInit {
  text: string;
  constructor(
    public dialogRef: MatDialogRef<any>,
    private telespiritService: TelespiritService
  ) { }

  ngOnInit() {
    if (this.dialogRef.componentInstance.value != null) {
      this.text = this.dialogRef.componentInstance.value;
    } else {
      this.telespiritService.getLibtextItem(this.dialogRef.componentInstance.id).then(x => {
        let response: any = x;
        this.text = response.content;
      });
    }
  }

  get insertBefore() {
    return {
      insertType: 'before',
      text: this.text
    }
  }

  get insertAfter() {
    return {
      insertType: 'after',
      text: this.text
    }
  }

  get replace() {
    return {
      insertType: 'replace',
      text: this.text
    }
  }

  get caretPosition() {
    return {
      insertType: 'caretPosition',
      text: this.text
    }
  }
}
