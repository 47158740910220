import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { AuthService, isGlobal } from './auth.service';

@Injectable()
export class DestyService {
    token: any;

    constructor(private apollo: Apollo, private authHttp: HttpClient, private auth: AuthService) {
        const url = new URL(window.location.href);
        this.token = url.searchParams.get("token");
    }

    getDestinations(name) {
        const apolloQuery = gql`
            {
                destyDestinations(name: "${name}") {
                    rows {
                        id
                        name
                    }
                }
            }
        `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then(x => {
            const res: any = x;
            if (res.data && res.data.destyDestinations && res.data.destyDestinations.rows && res.data.destyDestinations.rows.length > 0) {
                const results = [];
                for (const row of res.data.destyDestinations.rows) {
                    results.push(row);
                }
                return results;
            } else {
                return [];
            }
        });
    }

    searchHotels(postObj) {
        let query = '{';
        if (postObj.polygon && postObj.polygon.length > 0) {
            query += 'geoSearch:{ polygon:[';

            let coordinatesQuery = '';
            for (const coordinates of postObj.polygon) {
                if (coordinatesQuery.length > 0) {
                    coordinatesQuery += ', '
                }
                coordinatesQuery += '{latitude: ' + coordinates.latitude + ', longitude: ' + coordinates.longitude + '}';
            }
            query += coordinatesQuery + ']}'
        }
        if (postObj.name != null) {
            if (query.length > 1) {
                query += ', name: "' + postObj.name + '"';
            } else {
                query += 'name: "' + postObj.name + '"';
            }
        }
        if (postObj.destination != null) {
            if (query.length > 1) {
                query += ', destinationId: ' + postObj.destination;
            } else {
                query += 'destinationId: ' + postObj.destination;
            }
        }
        if (postObj.geo != null) {
            if (query.length > 1) {
                query += ', geoSort: { latitude: ' + postObj.geo.latitude + ', longitude: ' + postObj.geo.longitude + ', radius: ' + postObj.geo.radius + '}';
            } else {
                query += 'geoSort: { latitude: ' + postObj.geo.latitude + ', longitude: ' + postObj.geo.longitude + ', radius: ' + postObj.geo.radius + '}';
            }
        }
        if (postObj.fromStars != null) {
            if (query.length > 1) {
                query += ', starsFrom: ' + postObj.fromStars;
            } else {
                query += 'starsFrom: ' + postObj.fromStars;
            }
        }
        if (postObj.untilStars != null) {
            if (query.length > 1) {
                query += ', starsUntil: ' + postObj.untilStars;
            } else {
                query += 'starsUntil: ' + postObj.untilStars;
            }
        }
        if (postObj.availabilitySearch != null) {
            if (query.length > 1) {
                query += ', ';
            }
            query += `availabilitySearch:{ checkinDate: "${postObj.availabilitySearch.checkinDate}", nights: ${postObj.availabilitySearch.nights} `;
            if (postObj.availabilitySearch.party && postObj.availabilitySearch.party.length > 0) {
                query += ', party: [';
                for (const party of postObj.availabilitySearch.party) {
                    query += `{ adults: ${party.adults} },`;
                }
                query += ']';
            }
            query += `}`
        }
        query += '}';

        return new Promise((resolve) => {
            let apolloQuery;
            if (postObj.bookmark != null) {
                if (postObj.limit != null) {
                    query += `, paging: { limit: ${postObj.limit}, bookmark: "${postObj.bookmark}"}`;
                } else {
                    query += `, paging: { bookmark: "${postObj.bookmark}"}`;
                }
            } else if (postObj.limit! + null) {
                query += `, paging: { limit: ${postObj.limit} }`;
            }
            if (postObj.availabilitySearch != null) {
                apolloQuery = gql`
                    {
                        destyHotels(filter: ${query}) {
                            bookmark
                            rows{
                                id
                                destination {
                                    id
                                    name
                                }
                                stars
                                name
                                thumbUrl
                                geo {
                                  latitude
                                  longitude
                                }    
                                hasAvailabilityInfo
                                hotelInfo {
                                    bestPrice {
                                        currency
                                        value
                                    }
                                    images {
                                        url
                                        title
                                    }
                                    rooms { 
                                        id 
                                        name
                                        description
                                        boardDescription
                                        adults
                                        sellingPriceTotal { 
                                            value
                                            currency 
                                        } 
                                        isAvailable
                                    }
                                }
                            }
                        }
                    }
                `;
            } else {
                apolloQuery = gql`
                    {
                        destyHotels(filter: ${query}) {
                            bookmark
                            rows{
                                id
                                destination {
                                    id
                                    name
                                }
                                stars
                                name
                                thumbUrl
                                geo{
                                    latitude
                                    longitude
                                }
                            }
                        }
                    }
                `;
            }

            resolve(this.apollo.query({
                query: apolloQuery,
            }).toPromise().then(x => {
                const res: any = x;
                if (res.data && res.data.destyHotels && res.data.destyHotels.rows && res.data.destyHotels.rows.length > 0) {
                    const results = [];
                    for (const row of res.data.destyHotels.rows) {
                        const tempObj = {
                            dataSource: 'desty',
                            dataType: 'desty',
                            destination: row.destination || null,
                            externalInfo: {
                                externalType: 'desty',
                                externalId: row.id,
                                hasAvailabilityInfo: row.hasAvailabilityInfo || false,
                                extraInfo: row.hotelInfo || null
                            },
                            geo: row.geo,
                            name: row.name,
                            olPrices: {
                                participants: {},
                                purchaseCurrency: null,
                                costPrice: null
                            },
                            stars: row.stars,
                            thumbUrl: row.thumbUrl,
                            unitId: 2
                        }
                        results.push(tempObj);
                    }
                    return { bookmark: res.data.destyHotels.bookmark, results: results };
                } else {
                    return { bookmark: null, results: [] };
                }
            }));
        });
    }

    checkHotelAvailability(postObj, type = null) {
        let query = `ids: [${postObj.ids}], availabilitySearch:{ checkinDate: "${postObj.availabilitySearch.checkinDate}", nights: ${postObj.availabilitySearch.nights} `;
        if (postObj.availabilitySearch.party && postObj.availabilitySearch.party.length > 0) {
            query += ', party: [';
            for (const party of postObj.availabilitySearch.party) {
                query += `{ adults: ${party.adults} },`;
            }
            query += ']';
        }
        query += `}`
        return new Promise((resolve) => {
            let apolloQuery = gql`
                {
                    destyHotelAvailability(${query}){
                        id
                        hasAvailabilityInfo
                        hotelInfo {
                            bestPrice {
                                value
                                currency
                            }
                            rooms {
                                id
                                name
                                description
                                boardDescription
                                isAvailable
                                isNonRefundable
                                isPrePayment
                                hasBreakfast
                                adults
                                sellingPriceTotal { 
                                    value
                                    currency 
                                }
                            }
                        }
                    }
                }
            `;
            resolve(this.apollo.query({
                query: apolloQuery,
            }).toPromise().then(x => {
                const res: any = x;
                if (res.data && res.data.destyHotelAvailability && res.data.destyHotelAvailability[0] && res.data.destyHotelAvailability[0].hasAvailabilityInfo === true) {
                    return res.data.destyHotelAvailability;
                } else {
                    return 'error found';
                }
            }).catch(err => {
                console.log(err)
                throw err;
            }));
        });
    }

    getHotelInfo(id) {
        return new Promise((resolve) => {
            let apolloQuery = gql`
                {
                    destyHotelDetails(id: ${id}) {
                        id
                        name
                        description {
                            en
                            de
                            nl
                            es
                            pt
                            fr
                        }
                        hotelInfo{
                            images{
                                url
                            }
                            services
                            bestPrice {
                                currency
                                value
                            }
                            rooms {
                                id
                                name
                                description
                                boardDescription
                                moreInformation
                                adults
                                sellingPricePerRoom {
                                    currency
                                    value
                                }
                                sellingPriceTotal {
                                    currency
                                    value
                                }
                            }
                        }
                    }
                }
            `;
            resolve(this.apollo.query({
                query: apolloQuery,
            }).toPromise().then(x => {
                const res: any = x;
                if (res.data && res.data.destyHotelDetails) {
                    return res.data.destyHotelDetails;
                } else {
                    return 'error found';
                }
            }).catch(err => {
                console.log(err)
                return err;
            }));
        });
    }

    getDestyAuthToken() {
        if (isGlobal === false) {
            this.authHttp.get(`${this.auth.decodedToken[this.auth.tokenId].baseUrl}/vtbglobal/token`, {
                headers: new HttpHeaders({
                    'Authorization': this.token
                })
            }).toPromise().then(x => {
                const res: any = x;
                localStorage.setItem('desty_token', res.id_token);
            }).catch(e => {
                console.log(e);
            });
        }
    }

    startPreBook(element, hotelId, roomtype) {
        const checkinDate = element.date.getFullYear() + '-' + ((element.date.getMonth() + 1) < 10 ? '0' : '') + Number(element.date.getMonth() + 1) + '-' + (element.date.getDate() < 10 ? '0' : '') + element.date.getDate();
        let adults = Object.keys(element.olPrices.participants).length;
        if (roomtype != null) {
            const apolloQuery = gql`
                mutation {
                    destyPreBook(hotelId: ${hotelId}, roomId: ${roomtype.id}, availabilitySearch: {checkinDate: "${checkinDate}", nights: ${element.nights}, party: [{adults: ${adults}}]}){
                        token
                        roomDetails{
                            adults
                            name
                            boardDescription
                            moreInformation
                            isAvailable
                            isNonRefundable
                            isPrePayment
                            hasBreakfast
                            sellingPriceTotal {
                                currency
                                value
                            }
                            images {
                                url
                            }
                            cancellationPolicies {
                                value {
                                    currency
                                    value
                                }
                            }
                        }
                        roomBookingDetails{
                            supplier{
                                id
                                name
                            },
                            taxes{
                                description
                                price{
                                    value
                                    currency
                                }
                            }
                        }
                    }
                }
            `;
            return this.apollo.mutate({
                mutation: apolloQuery,
            }).toPromise().then(x => {
                const res: any = x;
                if (res && res.data && res.data.destyPreBook) {
                    return res.data.destyPreBook;
                } else {
                    return res;
                }
            }).catch(err => {
                console.log(err)
                return { error: err };
            });
        } else {
            return new Promise(resolve => { resolve([]) });
        }
    }

    bookDesty(token, hotelId, roomtype) {
        const apolloQuery = gql`
            mutation {
                destyBook(token: "${token}", hotelId: ${hotelId}, roomId: ${roomtype.id}, pax: [{ name:"Travel", surname:"Spirit", isMain:true, isChild: false, age:32 }]){
                    observations
                    taxes{
                        description
                    }
                    reservationId
                    reservationDate
                    checkInDate
                    checkInHour
                    checkOutDate
                    checkOutHour
                    cancellationPolicies {
                        endDate
                        startDate
                        value {
                            currency
                            value
                        }
                    }
                    voucherInfo
                }
            }
        `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        }).toPromise().then(x => {
            const res: any = x;
            if (res && res.data && res.data.destyBook != null) {
                res.data.destyBook.reservationDateTime = new Date();
                return res.data.destyBook;
            } else if (res.errors && res.errors.length > 0) {
                return res.errors;
            } else {
                return res;
            }
        }).catch(err => {
            console.log(err)
            return { error: err };
        });
    }
}
