import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-internal-info-dialog',
  templateUrl: './internal-info-dialog.component.html',
  styleUrls: ['./internal-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalInfoDialogComponent implements OnInit {
  internalInfoText: any;

  constructor(private matDialogRef: MatDialogRef<InternalInfoDialogComponent>) { }

  ngOnInit() {
    this.internalInfoText = this.matDialogRef.componentInstance;
  }

}
