import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'app/classes/config.class';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {
  token = 'pk.eyJ1IjoidHJhdmVsc3Bpcml0IiwiYSI6ImNrNXM1M2l5NzBpeHIzZW1sanY4YW1jazQifQ.MS1R012Gh6rVHUwNeF9WlA';

  constructor(private http: HttpClient, public config: Config) { }

  getAutocorrectLocations(searchText) {
    return new Promise((resolve, reject) => {
      this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${searchText}.json?access_token=${this.token}&language=${this.config.autocorrectLanguage}`).toPromise().then(x => {
        const res: any = x;
        if (res && res.features) {
          resolve(res.features);
        } else {
          reject();
        }
      }).catch(e => {
        reject(e);
      })
    });
  }

  formatHotels(res) {
    const formattedResults = [];
    for (const row of res.rows) {
      if (formattedResults.map(x => x.name).indexOf(row.doc.name.toLowerCase().trim()) < 0) {
        formattedResults.push({
          "name": row.doc.name.toLowerCase().trim(),
          "docs": row.doc
        });
      }
    }
    return formattedResults;
  }

  formatLocations(res) {
    const formattedResults = [];
    for (const row of res.rows) {
      // if (row.doc.city.split(',').length < 2) {
      if (formattedResults.map(x => x.name).indexOf(row.doc.country_code + ' ' + row.doc.city.toLowerCase().trim()) < 0) {
        formattedResults.push({
          "name": row.doc.country_code + ' ' + row.doc.city.toLowerCase().trim(),
          "value": row.doc.city.toLowerCase().trim(),
          "docs": row.doc
        });
      }
      // }
    }
    return formattedResults;
  }
}
