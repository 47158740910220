import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const ELEMENT_CATEGORIES_UPDATE = 'ELEMENT_CATEGORIES_UPDATE';
const logService = new LogService();

export function elementCategoriesReducer(state: any[] = undefined, action: ActionWithPayload) {
  switch (action.type) {
    case ELEMENT_CATEGORIES_UPDATE:
      state = action.payload;
      logService.consoleLog('ELEMENT_CATEGORIES_UPDATE:',state);
      return state;
    default:
      return state;
  }
}
