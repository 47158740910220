// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  logging: true,

  clientId: 'TravelSpirit',
  theme: 'default',
  niceUrl: '',
  autocorrectLanguage: 'nl',
  backupDelay: 60,
  favoriteUnits: [],
  customGlueIdentifiers: [],
  defaultOptionalUnits: [],
  providerNames: [],
  defaultSubtitleSort: 'asc',
  defaultSegment: 'Default',
  defaultDestyRadius: '50',
  defaultMargin: 20,
  internalPopupIcon: '‼️1F525‼️',
  defaultSalesCurrency: 'EUR',
  upAndUntilBabyAge: 1,
  upAndUntilChildAge: 12,
  upAndUntilTeenagerAge: 17,

  lockDepartment: false,
  manualCalcByDefault: false,
  manualBackofficeUpdate: false,
  setGlueTitle: true,
  setTitle: [],
  retainDraggedElement: true,
  nightsOnly: false,
  autoRecalc: false,
  smartMode: true,
  templateView: false,
  alwaysEnableTemplateView: false,
  overwriteGeneralInfoTemplate: false,
  compactGlueSegment: true,
  defaultFoldSegments: false,
  destyBooking: false,

  gridViewSettings: {
    columnVisibility: {
      "Cost Per Unit": "always",
      "Cost EUR Per Unit": "advanced",
      "Sales EUR Per Unit": "advanced",
      "Cost Participants": "always",
      "Cost EUR Participants": "always",
      "Sales EUR Participants": "always"
    }
  },
  notificationOptions: {
    timeOut: 3000,
    maxStack: 1,
    preventDuplicates: true
  },
  pnr: {
    amadeus: {
      genderMap: {
        'MR': 'Mr.',
        'MRS': 'Mrs.'
      },
      addPassengers: true
    }
  },

  enableDesty: false,
  enableAirTrade: true,
  enableAirtradeImportPNR: false,
  enableAutoSave: false,
  enableParticipantDialogs: true,
  enableMoveMultipleParticipantsDialog: true,
  enablePrefillDepartmentSearch: false,
  enableOverwriteGeneralInfoTemplate: true,
  enableDayTexts: false,
  enableHiddenDates: false,
  enableCrosscheckProduct: false,
  enableTransferVtb: true,
  enableConsolidatedTransferVtb: false,
  enableAdditionalTransfer: false,
  enableLanguageSubtitles: false,
  disableUnitEdit: false,
  disableItineraryNightsEdit: true,
  disableSegmentNightsEdit: true,
  disableUnallocatedNightsWarning: true,
  disableJsonView: false,
  disableMapView: false,
  disableParticipantModification: true,
  disableAddElement: false,
  disableExtraFieldsOverride: false,
  disablePdfPreviewButton: false,
  disableUpdateCurrencyExchangeRate: false,
  disablePriceView: false
};
