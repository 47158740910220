import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSafe'
})
export class GetSafePipe implements PipeTransform {
  transform(value: any, args?: any): any { // https://silvantroxler.ch/2017/avoid-cannot-read-property-of-undefined/
    try {
        return value();
    } catch (e) {
        return undefined;
    }
  }
}
