import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { takeUntil } from 'rxjs/operators';
import { USER_INTERFACE_SHOW_CONTEXT } from '../../reducers/user-interface';
import { AppState } from '../../models/app-state';
import { AuthService, isGlobal } from '../../services/auth.service';
import { NotificationsService } from 'angular2-notifications';
import { Config } from '../../classes/config.class';

@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['./media.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaComponent implements OnInit, AfterViewInit, OnDestroy {
    @Output() update = new EventEmitter();
    @Input() type: any;
    @Input() media: AppModel.Medium[] = [];
    @Input() segmentIndex: number;
    @Input() inViewport: boolean = false;
    @Input() itinerary = null;
    @ViewChild('bag') bag: any;

    unsubscribe = new Subject<void>();

    itineraryBackups$: Observable<any>;
    itineraryBackups: any;
    itineraryBackupsSubscription: Subscription;

    preventSingleClick = false;
    timer: any;

    index = 0;
    activeIndex = 0;
    galleryIndex = 0;
    bagSubscription: Subscription;

    env: any;

    constructor(
        private config: Config,
        private store: Store<AppState>,
        private auth: AuthService,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService
    ) {
        this.env = this.config;
        this.itineraryBackups$ = this.store.select('itineraryBackups');
    }

    ngOnInit() {
        this.itineraryBackupsSubscription = this.itineraryBackups$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
            this.index = 0;
            this.activeIndex = 0;
            this.galleryIndex = 0;
        });
        for (const image of this.media) { // TODO: Remove this in a few months/years when van verre has been using the new url for a long time
            if (image.url.indexOf('media-vanverre.pagespirit') > -1) {
                image.url = image.url.replace('http://media-vanverre.pagespirit.com', 'https://media.vanverre.nl');
            }
        }
    }

    ngAfterViewInit() {
        this.store.dispatch({ type: 'BAG_ADD', payload: { type: 'media', bag: this.bag } });
    }

    ngOnDestroy() {
        this.store.dispatch({ type: 'BAG_REMOVE', payload: { type: 'media', bag: this.bag } });
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    cleanUp() {
        let fixedProperties = ['sourceType', 'sourceId', 'url', 'description', 'id', 'tags', 'cache_data'];
        let mediaSpiritBasePath = '';
        let mediaspiritDefaultCropresUri = '';

        if (isGlobal === false) {
            mediaSpiritBasePath = this.auth.decodedToken['https://visualtourbuilder.com/app_metadata'].mediaspiritBaseUrl;
            mediaspiritDefaultCropresUri = this.auth.decodedToken['https://visualtourbuilder.com/app_metadata'].mediaspiritDefaultCropresUri != null ? this.auth.decodedToken['https://visualtourbuilder.com/app_metadata'].mediaspiritDefaultCropresUri : '/square/md';
        } else {
            mediaSpiritBasePath = this.auth.decodedToken.vtbConfig.mediaspiritBaseUrl;
            mediaspiritDefaultCropresUri = this.auth.decodedToken.vtbConfig.mediaspiritDefaultCropresUri != null ? this.auth.decodedToken.vtbConfig.mediaspiritDefaultCropresUri : '/square/md';
        }

        for (let item of this.media) {
            if (item.cache_data == null) {
                item.cache_data = { ...item };
            }
            if (item.hasOwnProperty('sourceType') === false) {
                item.sourceType = 'mediaspirit';
            }

            if (item.hasOwnProperty('_id') === true) {
                item.sourceId = item._id;
            }

            if (item.hasOwnProperty('sourceId') === false) {
                item.sourceId = null;
            }

            if (item.hasOwnProperty('url') === false) {
                item.url = `${mediaSpiritBasePath}${mediaspiritDefaultCropresUri}${item.nlCleanUrl}`;
            }

            if (item.hasOwnProperty('nlDescription') === true) {
                item.description = item.nlDescription;
            } else if (item.hasOwnProperty('enDescription') === true) {
                item.description = item.enDescription;
            } else if (item.hasOwnProperty('deDescription') === true) {
                item.description = item.deDescription;
            }

            if (item.hasOwnProperty('tags') === true) {
                const previousTags = item.tags;
                item.tags = [];
                if (previousTags && previousTags.length > 0 && previousTags[0]['_id']) {
                    for (const tag of previousTags) {
                        if (tag && tag['_id']) {
                            item.tags.push(tag['_id']);
                        }
                    }
                } else {
                    item.tags = previousTags;
                }
            }

            for (let property in item) {
                if (fixedProperties.indexOf(property) === -1) {
                    delete item[property];
                }
            }
        }
        this.update.emit();
    }

    addMedia() {
        this.inViewport = true;
        this.cdr.detectChanges();
    }

    showContext() {
        this.inViewport = true;
        this.store.dispatch({ type: USER_INTERFACE_SHOW_CONTEXT, payload: 'mediaspirit' });
    }

    removeImage(index) {
        this.media.splice(index, 1);
        if (this.media.length < 3) {
            this.activeIndex = 0;
        } else if (this.activeIndex >= this.media.length - 1) {
            this.activeIndex = 0;
        }
        if (this.index > 0) this.index--;
        if (this.galleryIndex > 0) this.galleryIndex--;
        this.cdr.detectChanges();
    }

    nextImage() {
        if (this.activeIndex === this.media.length - 1) {
            this.activeIndex = 0;
        } else {
            this.activeIndex = this.activeIndex + 1;
        }
    }

    previousImage() {
        if (this.activeIndex === 0) {
            this.activeIndex = this.media.length - 1;
        } else {
            this.activeIndex = this.activeIndex - 1;
        }
    }

    nextGallery() {
        this.galleryIndex = this.galleryIndex + 1;
    }

    previousGallery() {
        this.galleryIndex = this.galleryIndex - 1;
    }

    getIndex() {
        if (this.index >= this.media.length) {
            this.index = 0;
        }
        if (this.index < this.galleryIndex || this.index > this.galleryIndex + 3) {
            this.index = this.index + 1;
            return true;
        }
        this.index = this.index + 1;
        return false;
    }

    setActiveIndex(i) {
        this.preventSingleClick = false;
        const delay = 200;
        this.timer = setTimeout(() => {
            if (!this.preventSingleClick) {
                this.activeIndex = i;
                this.cdr.markForCheck();
            }
        }, delay);
    }

    drop(event: CdkDragDrop<any[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            if (event.previousIndex === event.currentIndex) {
                this.setMainImage(event.container.data[event.currentIndex], event.currentIndex);
            }
        } else {
            if (this.type === 'cover' && this.media.length > 3) {
                this.notificationsService.error('Error adding cover image', 'You can only add a maximum of 4 images');
                return;
            }
            if (this.env.retainDraggedElement === true) {
                const clone = { ...event.previousContainer.data[event.previousIndex] };
                event.previousContainer.data.splice(event.previousIndex, 0, clone);
            }
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            this.index++;
        }
        this.cleanUp();
        this.cdr.detectChanges();
    }

    openMediaSpirit(sourceId, id) {
        const imageId = sourceId || id;
        this.preventSingleClick = true;
        clearTimeout(this.timer);
        const mediaSpiritProfile = JSON.parse(localStorage.getItem('profile_mediaspirit'));
        if (mediaSpiritProfile['https://mediaspirit.io/app_metadata'] != null && mediaSpiritProfile['https://mediaspirit.io/app_metadata'].newApi == true) {
            window.open("https://new-admin.mediaspirit.io/image-redirect?id=" + imageId, "_blank");
        } else {
            window.open("https://admin.mediaspirit.io/image-redirect?id=" + imageId, "_blank");
        }
    }

    checkIndex(i, galleryIndex) {
        if (i >= galleryIndex && i < galleryIndex + 4) {
            return true;
        }
        return false;
    }

    getUrl() {
        if (this.media && this.media[this.activeIndex] && this.media[this.activeIndex].url) {
            return this.media[this.activeIndex].url;
        }
        return '';
    }

    getMediaTooltip(imageData) {
        if (imageData.cache_data != null) {
            return imageData.cache_data.name;
        }
        return '';
    }

    getSourceType() {
        if (this.media && this.media[this.activeIndex] && this.media[this.activeIndex].sourceType) {
            return this.media[this.activeIndex].sourceType;
        }
        return '';
    }

    setMainImage(image, index) {
        this.media.splice(index, 1);
        this.media.splice(0, 0, image);
    }
}
