import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-copy-url-dialog',
  templateUrl: './copy-url-dialog.component.html',
  styleUrls: ['./copy-url-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyUrlDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CopyUrlDialogComponent>) { }

  ngOnInit() {
  }
}
