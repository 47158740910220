import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/app-state';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-car-rental-dialog',
  templateUrl: './car-rental-dialog.component.html',
  styleUrls: ['./car-rental-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CarRentalDialogComponent implements OnInit, OnDestroy {
  unsubscribe = new Subject<void>();

  data: any = {
    activeElement: null,
    segments: null,
    elements: [],
    elementSegmentIndexes: [],
    timebasedElementsFound: null,
    units: {}
  }

  activeIndex: number = 0;

  itinerary$: Observable<AppModel.Itinerary>;
  itinerarySubscription: Subscription;

  unitsData: any;

  itineraryEndDate: any;
  allStartOffset: any;
  allEndOffset: any;
  totalDays: any;

  elementOffsetArr = [];

  constructor(public matDialogRef: MatDialogRef<CarRentalDialogComponent>, private store: Store<AppState>) {
    this.itinerary$ = this.store.select('itinerary');
  }

  ngOnInit() {
    this.data.elements.forEach((element, i) => {
      let dayDiff = 0;
      if (element.relativeOffset === true) {
        const timeDiff = this.data.segments[this.data.elementSegmentIndexes[i]].endDate.getTime() - element.endDate.getTime();
        dayDiff = timeDiff / (1000 * 3600 * 24);
        if (dayDiff < 0) {
          dayDiff = dayDiff * -1;
        }
      }
      this.elementOffsetArr.push({
        originalOffset: element.offset,
        originalEndOffset: element.endOffset,
        originalManualFlex: element.manualFlex,
        originalRelativeOffset: element.relativeOffset,
        minEndOffset: element.endOffset - dayDiff,
        manualOffsetCalc: element.manualOffsetCalc
      });
      if (this.data.activeElement != null) {
        if (this.data.activeElement === element) {
          this.activeIndex = i + 1;
        }
      }
    });
    this.itinerarySubscription = this.itinerary$.pipe(takeUntil(this.unsubscribe)).subscribe(itinerary => {
      if (itinerary != null && itinerary.data != null && itinerary.data.endDate != null) {
        this.itineraryEndDate = itinerary.data.endDate;
        this.totalDays = itinerary.data.totalDays;
      }
    });
    this.unitsData = this.data.units;
    this.setAllOffsets();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get datesByIndex() {
    if (this.itineraryEndDate != null) {
      let datesByIndex = {};
      this.data.elements.forEach((element, index) => {
        let start = new Date(this.data.segments[this.data.elementSegmentIndexes[index]].date);
        start.setDate(start.getDate() + (element.offset || 0));
        let end = new Date(this.itineraryEndDate);
        if (element.endOffset == null) {
          element.endOffset = 0;
        }
        end.setDate(end.getDate() - element.endOffset);
        datesByIndex[index] = {
          start: start,
          end: end
        }
      });
      return datesByIndex;
    } else {
      return null;
    }
  }

  get allStartOffsetDate() {
    if (this.data.segments[this.data.elementSegmentIndexes[0]].date != null) {
      let date = new Date(this.data.segments[this.data.elementSegmentIndexes[0]].date);
      date.setDate(date.getDate() + (this.allStartOffset || 0));
      return date;
    } else {
      return null;
    }
  }

  get allEndOffsetDate() {
    if (this.itineraryEndDate != null) {
      let date = new Date(this.itineraryEndDate);
      date.setDate(date.getDate() - (this.allEndOffset || 0));
      return date;
    } else {
      return null;
    }
  }

  setAllStartOffset(offset) {
    for (let element of this.data.elements) {
      element.offset = offset;
    }
  }

  setAllEndOffset(offset) {
    for (let element of this.data.elements) {
      element.endOffset = offset;
    }
  }

  setAllOffsets() {
    const equalStartOffset = this.data.elements.every(x => x.offset === this.data.elements[0].offset);
    const equalEndOffset = this.data.elements.every(x => Math.max(eval((this.unitsData.items[x.unitId].reverseMultiplier || 'X*1').replace(/x/ig, x.endOffset)), 0) === Math.max(eval((this.unitsData.items[this.data.elements[0].unitId].reverseMultiplier || 'X*1').replace(/x/ig, this.data.elements[0].endOffset)), 0));

    if (equalStartOffset === true) {
      this.allStartOffset = this.data.elements[0].offset;
    } else {
      this.allStartOffset = null;
    }

    if (equalEndOffset === true) {
      this.allEndOffset = this.data.elements[0].endOffset;
    } else {
      this.allEndOffset = null;
    }
  }

  selectedTabChanged(selected) {
    if (selected.tab.textLabel === 'All') {
      this.setAllOffsets();
    }
  }

  calcEndOffset(element, index) {
    if (element.relativeOffset === false) {
      element.endOffset = this.elementOffsetArr[index].previousOffset;
      delete element.manualOffsetCalc;
    } else {
      let offsetDiff = 0;
      if (element.endOffset != this.elementOffsetArr[index].originalEndOffset && this.elementOffsetArr[index].originalEndOffset != null) {
        offsetDiff = element.endOffset - this.elementOffsetArr[index].originalEndOffset;
      }
      // 86400000 milliseconds = 1 day
      if ((element.endDate.getTime() - (offsetDiff * 86400000)) !== this.data.segments[this.data.elementSegmentIndexes[index]].endDate.getTime()) {
        if ((element.endDate.getTime() - (offsetDiff * 86400000)) < this.data.segments[this.data.elementSegmentIndexes[index]].endDate.getTime()) {
          const timeDiff = this.data.segments[this.data.elementSegmentIndexes[index]].endDate.getTime() - (element.endDate.getTime() - (offsetDiff * 86400000));
          const dayDiff = timeDiff / (1000 * 3600 * 24);
          this.elementOffsetArr[index].previousOffset = element.endOffset;
          element.endOffset -= Math.round(dayDiff);
          this.elementOffsetArr[index].minEndOffset = element.endOffset;
        } else {
          const timeDiff = (element.endDate.getTime() - (offsetDiff * 86400000)) - this.data.segments[this.data.elementSegmentIndexes[index]].endDate.getTime();
          const dayDiff = timeDiff / (1000 * 3600 * 24);
          this.elementOffsetArr[index].previousOffset = element.endOffset;
          element.endOffset += Math.round(dayDiff);
          this.elementOffsetArr[index].minEndOffset = element.endOffset;
        }
      } else {
        this.elementOffsetArr[index].previousOffset = element.endOffset;
      }
    }
  }

  resetValues() {
    this.data.elements.forEach((element, index) => {
      element.offset = this.elementOffsetArr[index].originalOffset;
      element.endOffset = this.elementOffsetArr[index].originalEndOffset;
      element.manualFlex = this.elementOffsetArr[index].originalManualFlex;
      element.relativeOffset = this.elementOffsetArr[index].originalRelativeOffset;
      if (this.elementOffsetArr[index].manualOffsetCalc == null) {
        delete element.manualOffsetCalc;
      } else {
        element.manualOffsetCalc = this.elementOffsetArr[index].manualOffsetCalc;
      }
    });
  }

  preventDefault(event) {
    event.preventDefault();
  }

  updateManualCalc(event, element, i) {
    if (element.relativeOffset === true) {
      element.manualOffsetCalc = event - this.elementOffsetArr[i].minEndOffset;
    }
  }

  calcMax(start, end, offset) {
    const timeDiff = start.getTime() - (end.getTime() + (offset * 86400000));
    let dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));
    if (dayDiff < 0) {
      dayDiff = dayDiff * -1;
    }
    return dayDiff;
  }
}
