import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-create-library-order-dialog',
    templateUrl: './create-library-order-dialog.component.html',
    styleUrls: ['./create-library-order-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateLibraryOrderDialogComponent implements OnInit {
    hiddenLayout: any;
    segments: any;
    segmentTypes: any;
    selectedSegments = [];
    displayedColumns = ['title', 'segmentType', 'day', 'actions'];

    orderTexts = true;
    orderExtraFields = true;
    templateName = '';

    constructor(public dialogRef: MatDialogRef<any>) { }

    ngOnInit() {
        this.hiddenLayout = this.dialogRef.componentInstance.hiddenLayout;
        this.segments = this.dialogRef.componentInstance.segments;
        this.segmentTypes = this.dialogRef.componentInstance.segmentTypes;
        this.selectAll();
    }

    selectAll() {
        if (this.isAllSelected() === true) {
            this.selectedSegments = [];
        } else {
            this.selectedSegments = [];
            for (let i = 0; i < this.segments.length; i++) {
                this.selectedSegments.push({ segment: this.segments[i], checked: true });
            }
        }
    }

    isAllSelected() {
        return this.selectedSegments.length === this.segments.length;
    }

    getSelectedOptions() {
        const segments = []
        for (const segmentObj of this.selectedSegments) {
            segments.push(segmentObj.segment);
        }
        this.dialogRef.close({ segments: segments, orderTexts: this.orderTexts, orderExtraFields: this.orderExtraFields, templateName: this.templateName });
    }

    checkSegment(segment) {
        if (this.selectedSegments && this.selectedSegments.length > 0) {
            for (const selectedSegment of this.selectedSegments) {
                if (selectedSegment.segment.vtbObjectId === segment.vtbObjectId) {
                    if (selectedSegment.checked === true) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    selectSegment(segment) {
        if (this.selectedSegments && this.selectedSegments.length > 0) {
            for (let i = 0; i < this.selectedSegments.length; i++) {
                if (this.selectedSegments[i].segment.vtbObjectId === segment.vtbObjectId) {
                    this.selectedSegments.splice(i, 1);
                    return;
                }
            }
            this.selectedSegments.push({ segment: segment, checked: true });
        } else {
            this.selectedSegments.push({ segment: segment, checked: true });
        }
    }

    getCopyText() {
        let text = '';

        if (this.hiddenLayout != null && this.hiddenLayout.texts != null && this.hiddenLayout.texts.length > 0) {
            if (this.hiddenLayout.texts.indexOf('internal') < 0) {
                text += 'internal';
            }
            if (this.hiddenLayout.texts.indexOf('proposal') < 0) {
                if (text.length > 0) {
                    text += ', proposal';
                } else {
                    text += 'proposal';
                }
            }
            if (this.hiddenLayout.texts.indexOf('invoice') < 0) {
                if (text.length > 0) {
                    text += ', invoice';
                } else {
                    text += 'invoice';
                }
            }
            if (this.hiddenLayout.texts.indexOf('general') < 0) {
                if (text.length > 0) {
                    text += ', general';
                } else {
                    text += 'general';
                }
            }
        } else {
            text = 'internal, proposal, invoice, general';
        }

        return text;
    }
}
