import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const SEGMENT_TYPES_UPDATE = 'SEGMENT_TYPES_UPDATE';

export let segmentTypesState: any = {};
export let glueId: number[] = [];
export let defaultId: number = null;
let glueIdentifiers: string[] = ['lijmsteen', 'glue']; // this must always be lowercase
const logService = new LogService();

export function segmentTypesReducer(state: any = segmentTypesState, action: ActionWithPayload) {
    switch (action.type) {
        case SEGMENT_TYPES_UPDATE:
            let defaultSegment = '';
            if (action.config.defaultSegment != null) {
                defaultSegment = action.config.defaultSegment;
            }
            state = action.payload.reduce((all, item) => {
                all.ids.push(Number(item.id));
                item.id = Number(item.id);
                all.items[Number(item.id)] = item;
                if (defaultSegment != '') {
                    if (item.name.toLowerCase() === defaultSegment.toLowerCase()) {
                        defaultId = Number(item.id);
                    }
                }
                if (action.config.defaultFoldSegments === false) {
                    if (glueIdentifiers.indexOf(item.name.toLowerCase()) > -1) {
                        all.glueId.push(Number(item.id));
                        glueId.push(Number(item.id));
                    } else if (action.config.customGlueIdentifiers.indexOf(Number(item.id)) > -1 || action.config.customGlueIdentifiers.indexOf(String(item.id)) > -1) {
                        all.glueId.push(Number(item.id));
                        glueId.push(Number(item.id));
                    }
                } else {
                    all.glueId.push(Number(item.id));
                    glueId.push(Number(item.id));
                }
                return all;
            }, { ids: [], items: {}, glueId: [], isFallbackGlueId: false });
            if (state.glueId == null && state.ids.length > 0) {
                state.glueId.push(state.ids[0]);
                glueId.push(state.glueId);
                state.isFallbackGlueId = true;
            }
            segmentTypesState = state;
            logService.consoleLog('SEGMENT_TYPES_UPDATE:', state);
            return state;
        default:
            return state;
    }
}
