import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
    selector: 'app-segments-copy-dialog',
    templateUrl: './segments-copy-dialog.component.html',
    styleUrls: ['./segments-copy-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentsCopyDialogComponent implements OnInit {
    segments: any;
    segmentTypes: any;
    selectedSegments = [];
    displayedColumns = ['title', 'segmentType', 'day', 'actions'];

    constructor(public dialogRef: MatDialogRef<any>) { }

    ngOnInit() {
        this.segments = this.dialogRef.componentInstance.segments;
        this.segmentTypes = this.dialogRef.componentInstance.segmentTypes;
        this.selectAll();
    }

    selectAll() {
        if (this.isAllSelected() === true) {
            this.selectedSegments = [];
        } else {
            this.selectedSegments = [];
            for (let i = 0; i < this.segments.length; i++) {
                this.selectedSegments.push({ segment: this.segments[i], checked: true });
            }
        }
    }

    isAllSelected() {
        return this.selectedSegments.length === this.segments.length;
    }

    getSelectedOptions() {
        this.dialogRef.close({ segments: this.selectedSegments });
    }

    checkSegment(segment) {
        if (this.selectedSegments && this.selectedSegments.length > 0) {
            for (const selectedSegment of this.selectedSegments) {
                if (selectedSegment.segment.vtbObjectId === segment.vtbObjectId) {
                    if (selectedSegment.checked === true) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    selectSegment(segment) {
        if (this.selectedSegments && this.selectedSegments.length > 0) {
            for (let i = 0; i < this.selectedSegments.length; i++) {
                if (this.selectedSegments[i].segment.vtbObjectId === segment.vtbObjectId) {
                    this.selectedSegments.splice(i, 1);
                    return;
                }
            }
            this.selectedSegments.push({ segment: segment, checked: true });
        } else {
            this.selectedSegments.push({ segment: segment, checked: true });
        }
    }
}
