import { NgModule } from '@angular/core';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { HttpHeaders } from '@angular/common/http';
import { DefaultOptions } from 'apollo-client';

import { isGlobal } from '../app/services/auth.service';

// https://stackoverflow.com/questions/47608258/getting-header-append-is-not-a-function-in-setting-auth-using-apollo-angular-exa

export function createApollo(httpLink: HttpLink): any {

  const auth = setContext((_, { headers = new HttpHeaders() }) => {
    // get the authentication token from local storage if it exists
    let token = '';
    if (isGlobal === true) {
      const url = new URL(window.location.href);
      token = url.searchParams.get("token");
    } else {
      token = localStorage.getItem('desty_token');
    }
    if (!token) {
      return {};
    } else {
      return {
        headers: headers.append('Authorization', `Bearer ${token}`)
      };
    }
  });

  // const hash = 'dlho6zqi3';
  const http = httpLink.create({ uri: `https://vtb-global-vtb-gateway-z7stqwbrxa-ew.a.run.app/graphql` });
  // https://vtb-global-graphapi-gateway-z7stqwbrxa-ew.a.run.app/graphql

  const defaultOptions: DefaultOptions = {
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  return {
    link: auth.concat(http),
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
  exports: [ApolloModule, HttpLinkModule]
})

export class VtbGraphqlModule { }
