import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../models/app-state';
import { AuthActions } from '../actions/auth.actions';
import { JwtHelperService } from '@auth0/angular-jwt';

export let isGlobal: boolean = true;

@Injectable()
export class AuthService {

    tokenId: string = 'https://visualtourbuilder.com/app_metadata';

    constructor(private store: Store<AppState>, private authActions: AuthActions, private JwtHelper: JwtHelperService) {
        if (this.authenticated) {
            let profile = JSON.parse(localStorage.getItem('profile'));
            this.store.dispatch(this.authActions.IsloggedIn(profile));
        } else {
            this.store.dispatch(this.authActions.IsloggedOut());
        }
    }

    get authenticated() {
        const url = new URL(window.location.href);
        const rawToken = url.searchParams.get("token");
        return !this.JwtHelper.isTokenExpired(rawToken);
    }

    get token() {
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        return token;
    }

    public logout(): Promise<any> {
        return new Promise((resolve, reject) => {
            localStorage.removeItem('profile');
            this.store.dispatch(this.authActions.logoutSuccess());
            resolve();
        });
    }

    get isTokenValid() {
        const url = new URL(window.location.href);
        const rawToken = url.searchParams.get("token");
        if (!this.JwtHelper.isTokenExpired(rawToken)) {
            return true;
        } else {
            return false;
        }
    }

    get decodedToken() {
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        const decodedToken = this.JwtHelper.decodeToken(token);
        if (decodedToken != null && decodedToken[this.tokenId] != null && decodedToken[this.tokenId].baseUrl != null) {
            isGlobal = false;
        }
        return decodedToken;
    }
}
