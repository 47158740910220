import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-external-hotel-booked-dialog',
  templateUrl: './external-hotel-booked-dialog.component.html',
  styleUrls: ['./external-hotel-booked-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalHotelBookedDialogComponent implements OnInit {
  data: any;
  type = 'desty';
  bookedRoom: any;
  checkInDate: any;
  checkOutDate: any;
  reservationDate: any;

  constructor(private cdr: ChangeDetectorRef, public dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    if (typeof this.dialogRef.componentInstance === 'object' && this.dialogRef.componentInstance !== null) {
      this.data = this.dialogRef.componentInstance.data;
      this.type = this.dialogRef.componentInstance.type;
      this.bookedRoom = this.dialogRef.componentInstance.bookedRoom;
      if (this.data.checkInDate != null) {
        this.checkInDate = new Date(this.data.checkInDate);
      }
      if (this.data.checkOutDate != null) {
        this.checkOutDate = new Date(this.data.checkOutDate);
      }
      if (this.data.reservationDate) {
        this.reservationDate = new Date(this.data.reservationDate);
      }
      this.cdr.markForCheck();
    }
  }
}
