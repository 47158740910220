import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard';
import { isGlobal } from '../services/auth.service';
import { CopyUrlDialogComponent } from '../components/copy-url-dialog/copy-url-dialog.component';

import * as moment from 'moment';

@Injectable()
export class HelpersService {
    expireTimestamp;
    textRegex = RegExp('text|txt|tekst', 'gi');

    constructor(
        private dialog: MatDialog,
        private clipboardApi: ClipboardService
    ) { }

    generateRandomId() {
        return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    }

    setElementParticipants(indexObj, itinerary, participants = null) {
        if (itinerary != null && itinerary.data != null && itinerary.data.participants != null) {
            const element = indexObj.segmentIndex != null && indexObj.elementIndex != null ? itinerary.data.segments[indexObj.segmentIndex].elements[indexObj.elementIndex] : indexObj.element;
            if (participants != null) {
                this.setParticipants(element, participants);
            } else {
                for (let room in itinerary.data.participants) {
                    this.setParticipants(element, itinerary.data.participants[room]);
                }
            }
            if (element && element.olPrices && element.olPrices.participants) {
                this.setElementRoomType(indexObj, itinerary, Object.keys(element.olPrices.participants).length);
            } else {
                this.setElementRoomType(indexObj, itinerary, 0);
            }
        }
    }

    setParticipants(element, participants) {
        if (participants) {
            for (let participant of participants) {
                participant = String(participant.id);
                if (element && element.olPrices && element.olPrices.participants && element.olPrices.participants.hasOwnProperty(participant) === false) {
                    if (Array.isArray(element.olPrices.participants) === true) {
                        element.olPrices.participants = {};
                    }
                    element.olPrices.participants[participant] = {
                        id: participant,
                        costPrice: 0,
                        salesPrice: 0
                    };
                }
            }
        }
    }

    removeEmptyParticipants(participants) {
        if (participants.length > 0) {
            const tempParticipants = participants.filter(function (el) {
                return el != null;
            });
            let obj = {};
            tempParticipants.forEach(participant => {
                obj[participant.id] = participant;
            });
            return obj;
        }
        return participants;
    }

    setElementRoomType(indexObj, itinerary, elementParticipantsCount) {
        if (itinerary != null && itinerary.data != null && itinerary.data.participants != null) {
            const element = indexObj.segmentIndex != null && indexObj.elementIndex != null ? itinerary.data.segments[indexObj.segmentIndex].elements[indexObj.elementIndex] : indexObj.element;
            if (element.roomTypes != null && (element.subTitle == null || element.subTitle === '')) {
                let matchedRoomTypes = element.roomTypes
                    .filter(roomType => {
                        return elementParticipantsCount <= (roomType.maxPerson || 999) && elementParticipantsCount >= (roomType.minPerson || 0);
                    })
                    .sort((a, b) => {
                        return Number(a.defaultPrice) - Number(b.defaultPrice);
                    });

                if (matchedRoomTypes.length > 0) {
                    element.subTitle = matchedRoomTypes[0].description;
                    if (matchedRoomTypes[0].note != null) {
                        element.subAdditionalText = matchedRoomTypes[0].note;
                    }
                    if (matchedRoomTypes[0].supplierinfo != null) {
                        element.supplierInfo = matchedRoomTypes[0].supplierinfo;
                    }
                    if (element.internalText == null || element.internalText === '') {
                        if (matchedRoomTypes[0].internal_info != null) {
                            element.internalText = matchedRoomTypes[0].internal_info;
                        }
                    } else {
                        if (matchedRoomTypes[0].internal_info != null && matchedRoomTypes[0].internal_info !== '') {
                            element.internalText = matchedRoomTypes[0].internal_info;
                        }
                    }
                }
            }
        }
    }

    setElementInteralInfo(element) {
        element = JSON.parse(JSON.stringify(element));
        if (element != null) {
            element.vtbElementId = this.generateRandomId();
            element.vtbObjectId = this.generateRandomId();
            if (element.TSOrderline != null && element.TSOrderline.extraFieldValues != null && element.TSOrderline.extraFieldValues.length > 0) {
                const newExtraFields = [];
                for (const extraField of element.TSOrderline.extraFieldValues) {
                    let tempExtraField = JSON.parse(JSON.stringify(extraField));
                    tempExtraField.vtbElementId = this.generateRandomId();
                    tempExtraField.vtbObjectId = this.generateRandomId();
                    newExtraFields.push(tempExtraField);
                }
                element.TSOrderline.extraFieldValues = [...newExtraFields];
            }
            if (element.internalInfo != null) element.internalText = element.internalInfo;
            if (element.roomTypes != null) {
                const roomTypes = element.roomTypes;
                if (roomTypes != null && roomTypes.length > 0) {
                    for (let roomType of roomTypes) {
                        if (element.subTitle != null && roomType.description == element.subTitle) {
                            element.pricematrixInternalText = roomType.internal_info;
                        }
                    }
                }
            }
        }
        return element;
    }

    formatTrailingZeros(value, size) {
        return ('000000000' + value).substr(-size);
    }

    chunk(arr, d) {
        const temp = arr.slice()
        const out = []
        const rem = temp.length % d

        while (temp.length !== rem) out.unshift(temp.splice(temp.length - d, d))
        rem && out.unshift(temp.splice(0, rem))

        return out
    }

    findDestyRate(results, element) {
        return new Promise((resolve, reject) => {
            let roomType;
            if (element.roomTypes) {
                if (element.roomTypes.length > 0) {
                    for (const roomtype of element.roomTypes) {
                        if (element.subTitle === roomtype.description && Number(element.subTitle.length) === Number(roomtype.description.length)) {
                            roomType = roomtype;
                            break;
                        }
                    }
                }
            }
            if (roomType) {
                if (results.hotelInfo && results.hotelInfo.rooms && results.hotelInfo.rooms.length > 0) {
                    for (const room of results.hotelInfo.rooms) {
                        if (roomType.description === `${room.name}${room.boardDescription != null && room.boardDescription !== '' ? ` (${room.boardDescription})` : ``}` && Number(roomType.description.length) === Number(`${room.name}${room.boardDescription != null && room.boardDescription !== '' ? ` (${room.boardDescription})` : ``}`.length)) {
                            resolve({ value: room.sellingPriceTotal.value, currency: room.sellingPriceTotal.currency });
                            return;
                        }
                    }
                }
            } else {
                if (results.hotelInfo && results.hotelInfo.rooms && results.hotelInfo.rooms.length > 0) {
                    let cheapestRoom = null;
                    for (const room of results.hotelInfo.rooms) {
                        if (room.sellingPriceTotal != null) {
                            if (cheapestRoom == null) {
                                cheapestRoom = room;
                            } else {
                                if (room.sellingPriceTotal.value < cheapestRoom.sellingPriceTotal.value) {
                                    cheapestRoom = room;
                                }
                            }
                        }
                    }
                    if (cheapestRoom != null) {
                        resolve({ value: cheapestRoom.sellingPriceTotal.value, currency: cheapestRoom.sellingPriceTotal.currency, subtitle: `${cheapestRoom.name}${cheapestRoom.boardDescription != null && cheapestRoom.boardDescription !== '' ? ` (${cheapestRoom.boardDescription})` : ``}` });
                        return;
                    }
                }
            }
            reject();
        });
    }

    dateDiffInDays(a, b) {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;

        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    calculateAge(date, fromDate) {
        const newDate = new Date(date);
        const futureDate = new Date(fromDate)
        const diff_ms = futureDate.getTime() - newDate.getTime();
        const age_dt = new Date(diff_ms);

        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    formatRoomTypes(rates) {
        return new Promise((resolve) => {
            let roomTypes = [];
            if (rates.length > 0) {
                roomTypes = rates.reduce((roomTypes, roomType) => {
                    if (roomType) {
                        if (roomType.price) {
                            if (roomType.price['@attributes'] && roomType.price['@attributes'].value && roomType.price['@attributes'].currency) {
                                roomType.defaultPrice = roomType.price['@attributes'].value;
                                roomType.currency = roomType.price['@attributes'].currency;
                            }
                            delete roomType.price;
                        }
                        if (roomType.rate) {
                            if (roomType.rate.length > 0) {
                                for (const rate of roomType.rate) {
                                    const roomtypeCopy = JSON.parse(JSON.stringify(roomType));
                                    roomtypeCopy.providerData = {};
                                    roomtypeCopy.description = rate.name;
                                    if (rate.meal && rate.meal['@content']) {
                                        roomtypeCopy.providerData.meal = rate.meal['@content'];
                                    }
                                    if (rate.room) {
                                        if (rate.room['@attributes'] && rate.room['@attributes'].type && rate.room['@attributes'].code) {
                                            roomtypeCopy.type = rate.room['@attributes'].type;
                                            roomtypeCopy.code = rate.room['@attributes'].code;
                                        }
                                        if (rate.room.name) {
                                            roomtypeCopy.shortName = rate.room.name;
                                        }
                                    }
                                    if (rate.occupancy) {
                                        if (rate.occupancy.pax && rate.occupancy.pax['@attributes']) {
                                            if (rate.occupancy.pax['@attributes'].count) {
                                                roomtypeCopy.minPerson = rate.occupancy.pax['@attributes'].count;
                                                roomtypeCopy.maxPerson = rate.occupancy.pax['@attributes'].count;
                                            }
                                            roomtypeCopy.providerData.pax = rate.occupancy.pax['@attributes'];
                                        }
                                    }
                                    if (rate.remark && rate.remark.length > 0) {
                                        roomtypeCopy.providerData.remarks = [];
                                        for (const remark of rate.remark) {
                                            roomtypeCopy.providerData.remarks.push({ header: remark['@attributes'].provider_category, content: remark['@content'] });
                                        }
                                    }
                                    if (rate.fact && rate.fact.length > 0) {
                                        roomtypeCopy.providerData.facts = [];
                                        for (const fact of rate.fact) {
                                            roomtypeCopy.providerData.facts.push({ header: fact['@attributes'].name, content: fact['@content'] });
                                        }
                                    }
                                    if (rate.image && rate.image.length > 0) {
                                        roomtypeCopy.providerData.images = rate.image;
                                    }
                                    delete roomtypeCopy.rate;
                                    if (roomtypeCopy.cnx) {
                                        delete roomtypeCopy.cnx;
                                    }
                                    if (roomtypeCopy['@attributes']) {
                                        roomtypeCopy.providerData.providerName = rate['@attributes'].source;
                                        roomtypeCopy.providerData.roomTypeId = rate['@attributes'].mhr;
                                        roomtypeCopy.providerData.rateMesh = rate['@attributes'].mesh;
                                        delete roomtypeCopy['@attributes'];
                                    }
                                    roomtypeCopy.minUnit = null;
                                    roomtypeCopy.maxUnit = null;
                                    roomTypes.push(roomtypeCopy);
                                }
                            } else {
                                roomType.providerData = {};
                                roomType.description = roomType.rate.name;
                                if (roomType.rate.meal && roomType.rate.meal['@content']) {
                                    roomType.providerData.meal = roomType.rate.meal['@content'];
                                }
                                if (roomType.rate.room) {
                                    if (roomType.rate.room['@attributes'] && roomType.rate.room['@attributes'].type && roomType.rate.room['@attributes'].code) {
                                        roomType.type = roomType.rate.room['@attributes'].type;
                                        roomType.code = roomType.rate.room['@attributes'].code;
                                    }
                                    if (roomType.rate.room.name) {
                                        roomType.shortName = roomType.rate.room.name;
                                    }
                                }
                                if (roomType.rate.occupancy) {
                                    if (roomType.rate.occupancy.pax && roomType.rate.occupancy.pax['@attributes']) {
                                        if (roomType.rate.occupancy.pax['@attributes'].count) {
                                            roomType.minPerson = roomType.rate.occupancy.pax['@attributes'].count;
                                            roomType.maxPerson = roomType.rate.occupancy.pax['@attributes'].count;
                                        }
                                        roomType.providerData.pax = roomType.rate.occupancy.pax['@attributes'];
                                    }
                                }
                                if (roomType.rate.remark && roomType.rate.remark.length > 0) {
                                    roomType.providerData.remarks = [];
                                    for (const remark of roomType.rate.remark) {
                                        roomType.providerData.remarks.push({ header: remark['@attributes'].provider_category, content: remark['@content'] });
                                    }
                                }
                                if (roomType.rate.fact && roomType.rate.fact.length > 0) {
                                    roomType.providerData.facts = [];
                                    for (const fact of roomType.rate.fact) {
                                        roomType.providerData.facts.push({ header: fact['@attributes'].name, content: fact['@content'] });
                                    }
                                }
                                if (roomType.rate.image && roomType.rate.image.length > 0) {
                                    roomType.providerData.images = roomType.rate.image;
                                }
                                delete roomType.rate;
                                if (roomType.cnx) {
                                    delete roomType.cnx;
                                }
                                if (roomType['@attributes']) {
                                    roomType.providerData.providerName = roomType['@attributes'].source;
                                    roomType.providerData.roomTypeId = roomType['@attributes'].mhr;
                                    roomType.providerData.rateMesh = roomType['@attributes'].mesh;
                                    delete roomType['@attributes'];
                                }
                                roomType.minUnit = null;
                                roomType.maxUnit = null;
                                roomTypes.push(roomType);
                            }
                        }
                    }
                    return roomTypes;
                }, []);
            }
            resolve(this.removeDuplicateRoomTypes(roomTypes));
        });
    }

    removeDuplicateRoomTypes(roomTypes) {
        return roomTypes.filter((v, i, a) => a.findIndex(t => (t.description === v.description)) === i);
    }

    removeDuplicateExtraFields(extraFields) {
        return extraFields.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
    }

    sortSubtitles(roomTypes, subtitleSort, language, type = null, languageSetting = false) {
        let languageRoomTypes = [];
        if (roomTypes.length > 0) {
            if (language != null) {
                for (let i = roomTypes.length - 1; i >= 0; i--) {
                    if (roomTypes[i].language != null && roomTypes[i].language === language) {
                        languageRoomTypes.push(roomTypes[i]);
                        roomTypes.splice(i, 1);
                    }
                }
            } else {
                for (let i = roomTypes.length - 1; i >= 0; i--) {
                    if (roomTypes[i].language == null || roomTypes[i].language == null) {
                        languageRoomTypes.push(roomTypes[i]);
                        roomTypes.splice(i, 1);
                    }
                }
            }

            let sortSubtitles = [];
            if (subtitleSort.trim() !== '') {
                sortSubtitles = subtitleSort.split(';');
                sortSubtitles = sortSubtitles.filter((item, index) => sortSubtitles.indexOf(item) === index);
            }
            if (sortSubtitles.length > 0) {
                if (sortSubtitles.length > 1) {
                    if (languageRoomTypes.length > 0) {
                        languageRoomTypes = this.recursiveSort(languageRoomTypes, sortSubtitles, '', type);
                    }
                    roomTypes = this.recursiveSort(roomTypes, sortSubtitles, '', type);
                } else {
                    if (sortSubtitles[0] === 'asc') {
                        if (languageRoomTypes.length > 0) {
                            languageRoomTypes = this.sortAlphabeticalAsc(languageRoomTypes);
                        }
                        roomTypes = this.sortAlphabeticalAsc(roomTypes);
                    } else if (sortSubtitles[0] === 'desc') {
                        if (languageRoomTypes.length > 0) {
                            languageRoomTypes = this.sortAlphabeticalDesc(languageRoomTypes);
                        }
                        roomTypes = this.sortAlphabeticalDesc(roomTypes);
                    } else if (sortSubtitles[0] === 'priceasc') {
                        if (languageRoomTypes.length > 0) {
                            languageRoomTypes = this.sortNumericalAsc(languageRoomTypes, '', type);
                        }
                        roomTypes = this.sortNumericalAsc(roomTypes, '', type);
                    } else if (sortSubtitles[0] === 'pricedesc') {
                        if (languageRoomTypes.length > 0) {
                            languageRoomTypes = this.sortNumericalDesc(languageRoomTypes, '', type);
                        }
                        roomTypes = this.sortNumericalDesc(roomTypes, '', type);
                    }
                }
            }
        }
        if (languageSetting === true) {
            const emptyRoomTypes = [];
            if (language.toLowerCase() === 'nl') {
                for (const roomType of roomTypes) {
                    if (roomType.language == null) {
                        emptyRoomTypes.push(roomType);
                    }
                }
            }
            return languageRoomTypes.concat(emptyRoomTypes);
        } else {
            return languageRoomTypes.concat(roomTypes);
        }
    }

    recursiveSort(roomTypes, sortSubtitles, lastSort, type = null) {
        if (sortSubtitles.length > 0) {
            if (lastSort === '') {
                if (sortSubtitles[0] === 'asc') {
                    roomTypes = this.sortAlphabeticalAsc(roomTypes);
                    lastSort = 'asc';
                } else if (sortSubtitles[0] === 'desc') {
                    roomTypes = this.sortAlphabeticalDesc(roomTypes);
                    lastSort = 'desc';
                } else if (sortSubtitles[0] === 'priceasc') {
                    roomTypes = this.sortNumericalAsc(roomTypes, '', type);
                    lastSort = 'priceasc';
                } else if (sortSubtitles[0] === 'pricedesc') {
                    roomTypes = this.sortNumericalDesc(roomTypes, '', type);
                    lastSort = 'pricedesc';
                }
            } else {
                if (lastSort === 'priceasc' || lastSort === 'pricedesc') {
                    if (sortSubtitles[0] === 'asc') {
                        roomTypes = this.sortAlphabeticalAsc(roomTypes, lastSort);
                    } else if (sortSubtitles[0] === 'desc') {
                        roomTypes = this.sortAlphabeticalDesc(roomTypes, lastSort);
                    }
                } else if (lastSort === 'asc' || lastSort === 'desc') {
                    if (sortSubtitles[0] === 'priceasc') {
                        roomTypes = this.sortNumericalAsc(roomTypes, lastSort, type);
                    } else if (sortSubtitles[0] === 'pricedesc') {
                        roomTypes = this.sortNumericalDesc(roomTypes, lastSort, type);
                    }
                }
            }

            sortSubtitles.shift();
            if (sortSubtitles.length > 0) {
                return this.recursiveSort(roomTypes, sortSubtitles, lastSort, type);
            } else {
                return roomTypes
            }
        } else {
            return roomTypes;
        }
    }

    sortAlphabeticalAsc(roomTypes, lastSort = '') {
        const compare = (a, b) => {
            const textA = a.description.toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u');
            const textB = b.description.toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u');
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        };
        if (lastSort === '') {
            return roomTypes.sort(compare);
        } else {
            let sortedArray = [];
            let sortArray = [];
            for (const roomType of roomTypes) {
                if (sortArray.length > 0) {
                    if (Number(roomType.defaultPrice) === Number(sortArray[0].defaultPrice)) {
                        sortArray.push(roomType);
                    } else {
                        if (sortArray.length > 1) {
                            sortedArray = sortedArray.concat(this.sortAlphabeticalAsc(sortArray));
                        } else {
                            sortedArray = sortedArray.concat(sortArray);
                        }
                        sortArray = [];
                        sortArray.push(roomType);
                    }
                } else {
                    sortArray.push(roomType);
                }
            }
            if (sortArray.length > 0) {
                if (sortArray.length > 1) {
                    sortedArray = sortedArray.concat(this.sortAlphabeticalAsc(sortArray));
                } else {
                    sortedArray = sortedArray.concat(sortArray);
                }
            }
            return sortedArray;
        }
    }

    sortAlphabeticalDesc(roomTypes, lastSort = '') {
        const compare = (a, b) => {
            const textA = a.description.toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u');
            const textB = b.description.toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u');
            return (textA < textB) ? 1 : (textA > textB) ? -1 : 0;
        };
        if (lastSort === '') {
            return roomTypes.sort(compare);
        } else {
            let sortedArray = [];
            let sortArray = [];
            for (const roomType of roomTypes) {
                if (sortArray.length > 0) {
                    if (Number(roomType.defaultPrice) === Number(sortArray[0].defaultPrice)) {
                        sortArray.push(roomType);
                    } else {
                        if (sortArray.length > 1) {
                            sortedArray = sortedArray.concat(this.sortAlphabeticalDesc(sortArray));
                        } else {
                            sortedArray = sortedArray.concat(sortArray);
                        }
                        sortArray = [];
                        sortArray.push(roomType);
                    }
                } else {
                    sortArray.push(roomType);
                }
            }
            if (sortArray.length > 0) {
                if (sortArray.length > 1) {
                    sortedArray = sortedArray.concat(this.sortAlphabeticalDesc(sortArray));
                } else {
                    sortedArray = sortedArray.concat(sortArray);
                }
            }
            return sortedArray;
        }
    }

    sortNumericalAsc(roomTypes, lastSort, type = null) {
        if (roomTypes[0].defaultPrice == null) {
            return roomTypes
        }
        let compare;
        if (type === 'desty') {
            compare = (a, b) => {
                if (a.defaultPrice && a.defaultPrice.value && b.defaultPrice && b.defaultPrice.value) {
                    const aPrice = a.defaultPrice.value;
                    const bPrice = b.defaultPrice.value;
                    return aPrice - bPrice;
                }
                return 0;
            };
        } else {
            compare = (a, b) => {
                const aPrice = Number(a.defaultPrice.replace(/[^0-9.-]+/g, ""));
                const bPrice = Number(b.defaultPrice.replace(/[^0-9.-]+/g, ""));

                return bPrice - aPrice;
            };
        }
        if (lastSort === '') {
            return roomTypes.sort(compare);
        } else {
            let sortedArray = [];
            let sortArray = [];
            for (const roomType of roomTypes) {
                if (sortArray.length > 0) {
                    if (roomType.description[0].toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u') === sortArray[0].description[0].toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u')) {
                        sortArray.push(roomType);
                    } else {
                        if (sortArray.length > 1) {
                            sortedArray = sortedArray.concat(this.sortNumericalAsc(sortArray, '', type));
                        } else {
                            sortedArray = sortedArray.concat(sortArray);
                        }
                        sortArray = [];
                        sortArray.push(roomType);
                    }
                } else {
                    sortArray.push(roomType);
                }
            }
            if (sortArray.length > 0) {
                if (sortArray.length > 1) {
                    sortedArray = sortedArray.concat(this.sortNumericalAsc(sortArray, '', type));
                } else {
                    sortedArray = sortedArray.concat(sortArray);
                }
            }
            return sortedArray;
        }
    }

    sortNumericalDesc(roomTypes, lastSort, type = null) {
        let compare;
        if (type === 'desty') {
            compare = (a, b) => {
                if (a.defaultPrice && a.defaultPrice.value && b.defaultPrice && b.defaultPrice.value) {
                    const aPrice = a.defaultPrice.value;
                    const bPrice = b.defaultPrice.value;
                    return bPrice - aPrice;
                }
                return 0;
            };
        } else {
            compare = (a, b) => {
                const aPrice = Number(a.defaultPrice.replace(/[^0-9.-]+/g, ""));
                const bPrice = Number(b.defaultPrice.replace(/[^0-9.-]+/g, ""));

                return bPrice - aPrice;
            };
        }
        if (lastSort === '') {
            return roomTypes.sort(compare);
        } else {
            let sortedArray = [];
            let sortArray = [];
            for (const roomType of roomTypes) {
                if (sortArray.length > 0) {
                    if (roomType.description[0].toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u') === sortArray[0].description[0].toLowerCase().replace(/[èéêë]/g, 'e').replace(/[àáâãäå]/g, 'a').replace(/[ìíîï]/g, 'i').replace(/[òóôõö]/g, 'o').replace(/[ùúûü]/g, 'u')) {
                        sortArray.push(roomType);
                    } else {
                        if (sortArray.length > 1) {
                            sortedArray = sortedArray.concat(this.sortNumericalDesc(sortArray, '', type));
                        } else {
                            sortedArray = sortedArray.concat(sortArray);
                        }
                        sortArray = [];
                        sortArray.push(roomType);
                    }
                } else {
                    sortArray.push(roomType);
                }
            }
            if (sortArray.length > 0) {
                if (sortArray.length > 1) {
                    sortedArray = sortedArray.concat(this.sortNumericalDesc(sortArray, '', type));
                } else {
                    sortedArray = sortedArray.concat(sortArray);
                }
            }
            return sortedArray;
        }
    }

    getToolTip(roomType, type = null) {
        if (roomType.description != null) {
            if (roomType.defaultPrice != null) {
                if (type === 'desty') {
                    if (roomType.defaultPrice.value) {
                        return roomType.description + ' - €' + Number(roomType.defaultPrice.value).toFixed(2);
                    } else {
                        return roomType.description;
                    }
                } else {
                    return roomType.description + ' - €' + Number(roomType.defaultPrice).toFixed(2);
                }
            }
            return roomType.description;
        }
        return roomType;
    }

    showInternalInformationText(internalInfo, matDialog, InternalInfoDialogComponent) {
        if (internalInfo != null) {
            const dialogRef: any = matDialog.open(InternalInfoDialogComponent);
            const regex = /‼️(.*?)‼️/g;
            let internalInfoIcons = internalInfo.match(regex);
            if (internalInfoIcons != null) {
                internalInfoIcons.forEach((infoIcon) => {
                    if (infoIcon.indexOf("&") === -1) internalInfo = internalInfo.replace('‼️', '‼️&#x');
                });
            }
            dialogRef.componentInstance = internalInfo;
            dialogRef.afterClosed().pipe(take(1)).subscribe(data => {
            });
        }
    }

    formatDestySegment(segment) {
        segment.typeId = 1;
        segment.media = [];
        segment.flightInfo = [];
        let foundGeo = null;
        if (segment.geo && segment.geo.latitude && segment.geo.longitude) {
            foundGeo = JSON.parse(JSON.stringify(segment.geo));
            // delete segment.geo;
        }
        if (foundGeo != null) {
            segment.maps = {};
            segment.maps.latitude = foundGeo.latitude;
            segment.maps.longitude = foundGeo.longitude;
            segment.maps.zoom = 16;
        }
        if (segment.id) {
            delete segment.id;
        }
        if (segment.stars) {
            delete segment.stars;
        }
        if (segment.name) {
            delete segment.name;
        }
        if (segment.thumbUrl) {
            delete segment.thumbUrl;
        }
        if (segment.nights) {
            delete segment.nights;
        }
        if (segment.unitId) {
            delete segment.unitId;
        }
        if (segment.externalInfo.destyDestination) {
            segment.title = segment.externalInfo.destyDestination;
        }
        delete segment.externalInfo;
        delete segment.__typename;
        // delete segment.thumbUrl;
        if (segment.elements && segment.elements.length > 0) {
            for (const element of segment.elements) {
                if (element.externalInfo != null && element.externalInfo.hasAvailabilityInfo === true) {
                    if (element.externalInfo) {
                        if (element.externalInfo.images && element.externalInfo.images.length > 0) {
                            element.media = [];
                            for (const image of element.externalInfo.images) {
                                element.media.push({ url: image.url, sourceType: 'desty', sourceId: image.url.substring(image.url.lastIndexOf('/') + 1) })
                            }
                            delete element.externalInfo.images;
                            delete element.thumbUrl
                        } else {
                            element.media = [];
                            if (element.thumbUrl) {
                                element.media.push({ url: element.thumbUrl, sourceType: 'desty', sourceId: element.thumbUrl.substring(element.thumbUrl.lastIndexOf('/') + 1) });
                                delete element.thumbUrl
                            }
                        }
                        if (element.externalInfo && element.externalInfo.extraInfo && element.externalInfo.extraInfo.bestPrice) {
                            if (element.externalInfo.extraInfo.bestPrice.currency) {
                                element.olPrices.purchaseCurrency = element.externalInfo.extraInfo.bestPrice.currency;
                            }
                            if (element.externalInfo.extraInfo.bestPrice.value) {
                                element.olPrices.costPrice = element.externalInfo.extraInfo.bestPrice.value;
                            }
                        }
                        if (element.externalInfo.availabilitySearchClicked != null) {
                            delete element.externalInfo.availabilitySearchClicked;
                        }
                    }
                }
                // if (element.geo && element.geo.latitude && element.geo.longitude) {
                //   delete element.geo;
                // }
                if (foundGeo != null) {
                    element.maps = {};
                    element.maps.enabled = true;
                    element.maps.latitude = foundGeo.latitude;
                    element.maps.longitude = foundGeo.longitude;
                    element.maps.zoom = 16;
                }
                if (element.name) {
                    element.title = element.name;
                    delete element.name;
                }
                delete element.__typename;
                element.vtbElementId = this.generateRandomId();
                element.vtbObjectId = this.generateRandomId();
                element.unitId = 2;
                element.optional = false;
                delete element.externalInfo.destyDestination;
                delete element.externalInfo.availabilityError;
                delete element.externalInfo.hasAvailabilityInfo;
                delete element.externalInfo.usedAvailabilitySearch;
            }
        }
        return segment;
    }

    displayInternalInfoIcon(element) {
        let internalInfo = null;
        if (element.internalText && element.internalText != null) internalInfo = element.internalText;
        if (element.pricematrixInternalText && element.pricematrixInternalText != null) internalInfo += ' [' + element.pricematrixInternalText + ']';
        if (element.pricespecialInternalText && element.pricespecialInternalText != null) internalInfo += ' {' + element.pricespecialInternalText + '}';

        if (element.isOldPricesUsed != null && element.isOldPricesUsed === true) return '⚠️';
        if (internalInfo == null) return '';
        else {
            if (internalInfo.indexOf("‼️") !== -1) {
                const firstPos = internalInfo.indexOf("‼️");
                const endPos = internalInfo.lastIndexOf("‼️");
                if (firstPos !== -1 && endPos !== -1) {
                    const regex = /‼️(.*?)‼️/g;
                    let internalInfoIcons = internalInfo.match(regex);
                    if (internalInfoIcons != null) {
                        let specialInfoIcon = null;
                        internalInfoIcons.forEach((infoIcon) => {
                            infoIcon = infoIcon.replace(/‼️/g, '');
                            if (infoIcon.indexOf("&") !== -1) specialInfoIcon = infoIcon;
                        });
                        if (specialInfoIcon != null) return specialInfoIcon;
                        else {
                            let nonSpecialInfoIcon = null;
                            internalInfoIcons.forEach((infoIcon) => {
                                infoIcon = infoIcon.replace(/‼️/g, '');
                                if (infoIcon.indexOf("&") === -1) nonSpecialInfoIcon = '&#x' + infoIcon;
                            });
                            return nonSpecialInfoIcon;
                        }
                    }
                } else return '🔥';
            } else return 'ℹ️';
        }
    }

    formatSearchedElements(elements, units, config, auth = null, baseUrl = '') {
        if ((auth.decodedToken && auth.decodedToken[auth.tokenId] && auth.decodedToken[auth.tokenId].baseUrl) || baseUrl !== '') {
            for (let element of elements) {
                if (element.unitId && units.items[element.unitId] && (units.items[element.unitId].isTimeBased === true && units.items[element.unitId].allowOverlap === false)) {
                    if (element.unitAmount === 0) {
                        element.unitAmount = 1;
                    }
                    element.nights = Math.max(eval((units.items[element.unitId].multiplier || 'X*1').replace(/x/ig, element.unitAmount)), 0);
                } else {
                    element.nights = 0;
                }
                if (config.manualCalcByDefault === true) {
                    element.manual_calc = true;
                }
                if (element.media != null && element.media.length > 0) {
                    element.media = element.media.filter(x => x.mediaspirit_id != null || x.sourceType === 'mediaspirit');
                }
                if (element.maps != null && element.maps.latitude != null && element.maps.longitude != null) {
                    element.maps.enabled = true;
                }
                if (element.hasOwnProperty('TSOrderline') === false || typeof element.TSOrderline !== 'object') {
                    element.TSOrderline = {
                        extraFieldValues: element.orderlineExtrafields
                    };
                    delete element.orderlineExtrafields;
                }
            }
            return elements;
        } else {
            const formattedElements = [];
            for (let element of elements) {
                if (element.data != null) {
                    if (element.data.unitId && units.items[element.data.unitId] && (units.items[element.data.unitId].isTimeBased === true && units.items[element.data.unitId].allowOverlap === false)) {
                        if (element.data.unitAmount === 0) {
                            element.data.unitAmount = 1;
                        }
                        element.data.nights = Math.max(eval((units.items[element.data.unitId].multiplier || 'X*1').replace(/x/ig, element.data.unitAmount)), 0);
                    } else {
                        element.data.nights = 0;
                    }
                    if (config.manualCalcByDefault === true) {
                        element.data.manual_calc = true;
                    }
                    if (element.data.media != null && element.data.media.length > 0) {
                        element.data.media = element.data.media.filter(x => x.mediaspirit_id != null || x.sourceType === 'mediaspirit');
                    }
                    if (element.data.maps != null && element.data.maps.latitude != null && element.data.maps.longitude != null) {
                        element.data.maps.enabled = true;
                    }
                    if (element.data.hasOwnProperty('TSOrderline') === false || typeof element.data.TSOrderline !== 'object') {
                        element.data.TSOrderline = {
                            extraFieldValues: element.data.orderlineExtrafields
                        };
                        delete element.data.orderlineExtrafields;
                    }
                    formattedElements.push(element.data)
                }
            }
            return formattedElements;
        }
    }

    getNewDate(date) {
        return new Date(date);
    }

    checkDates(sdate, fdate) {
        if (sdate.getDate() === fdate.getDate()) {
            if (sdate.getMonth() === fdate.getMonth()) {
                if (sdate.getYear() === fdate.getYear()) {
                    return true;
                }
            }
        }
        return false;
    }

    setElementData(activeElement, segment, id) {
        return new Promise<void>(resolve => {
            let startDate = activeElement.date;
            for (const element of segment.elements) {
                if (element.externalInfo != null && element.externalInfo.externalId && element.externalInfo.externalId === id && new Date(element.date).getTime() === new Date(startDate).getTime()) {
                    if (activeElement.additionalText) {
                        element.additionalText = activeElement.additionalText;
                    }
                    if (activeElement.media && activeElement.media.length > 0) {
                        element.media = activeElement.media;
                    }
                    if (activeElement.cityName) {
                        element.cityName = activeElement.cityName;
                    }
                    if (activeElement.maps && activeElement.maps.latitude && activeElement.maps.longitude) {
                        element.maps = activeElement.maps;
                    }
                    if (activeElement.roomTypes) {
                        element.roomTypes = activeElement.roomTypes;
                        element.insuranceData = null;
                    }
                    if (activeElement.externalInfo) {
                        element.externalInfo = activeElement.externalInfo;
                    }
                    if (activeElement.nights && activeElement.unitAmount) {
                        element.nights = activeElement.nights;
                        element.unitAmount = activeElement.unitAmount;
                    }
                    if (activeElement.olPrices && activeElement.olPrices.costPrice) {
                        element.olPrices.costPrice = activeElement.olPrices.costPrice;
                    }
                }
            }
            resolve();
        });
    }

    setDestyElementData(segment, data, itineraryData) {
        return new Promise<void>(resolve => {
            for (const element of segment.elements) {
                if (element.externalInfo != null && element.externalInfo.externalId === data.id) {
                    if (data.hotelInfo != null) {
                        if (data.hotelInfo.images != null && data.hotelInfo.images.length > 0) {
                            element.media = [];
                            for (const image of data.hotelInfo.images) {
                                element.media.push({ url: image.url, sourceType: 'desty', sourceId: image.url.substring(image.url.lastIndexOf('/') + 1) })
                            }
                            if (element.thumbUrl) {
                                // delete element.thumbUrl
                            }
                        }
                    } else {
                        element.media = [];
                        if (element.thumbUrl) {
                            element.media.push({ url: element.thumbUrl, sourceType: 'desty', sourceId: element.thumbUrl.substring(element.thumbUrl.lastIndexOf('/') + 1) });
                            // delete element.thumbUrl
                        }
                    }
                    if (element.externalInfo && element.externalInfo.extraInfo && element.externalInfo.extraInfo.bestPrice) {
                        if (element.externalInfo.extraInfo.bestPrice.currency) {
                            element.olPrices.purchaseCurrency = element.externalInfo.extraInfo.bestPrice.currency;
                        }
                        if (element.externalInfo.extraInfo.bestPrice.value) {
                            element.olPrices.costPrice = element.externalInfo.extraInfo.bestPrice.value;
                            if (element.olPrices.participants) {
                                for (const participant of Object.keys(element.olPrices.participants)) {
                                    element.olPrices.participants[participant].costPrice = element.olPrices.costPrice / Object.keys(element.olPrices.participants).length;
                                }
                            }
                        }
                    }
                    if (element.geo && element.geo.latitude && element.geo.longitude) {
                        element.maps = {};
                        element.maps.enabled = true;
                        element.maps.latitude = element.geo.latitude;
                        element.maps.longitude = element.geo.longitude;
                        element.maps.zoom = 16;
                        delete element.geo;
                    }
                    if (element.name) {
                        element.title = element.name;
                        delete element.name;
                    }
                    delete element.__typename;
                    element.vtbElementId = this.generateRandomId();
                    element.vtbObjectId = this.generateRandomId();
                    element.unitId = 2;
                    // element.nights = 1;
                    // element.unitAmount = 1;
                    element.optional = false;
                    element.roomTypes = [];
                    element.insuranceData = null;
                    if (itineraryData.defaultManualCalc === true) {
                        element.manual_calc = true;
                    }
                    if (data.description) {
                        element.additionalTexts = data.description;
                        if (data.description.en) {
                            element.additionalText = data.description.en;
                            element.additionalTextLanguage = 'en';
                        }
                    }
                    if (data.hotelInfo) {
                        if (element.externalInfo && element.externalInfo.extraInfo && element.externalInfo.extraInfo.rooms && element.externalInfo.extraInfo.rooms.length > 0) {
                            for (const room of element.externalInfo.extraInfo.rooms) {
                                if (element.externalInfo.extraInfo.bestPrice != null && element.externalInfo.extraInfo.bestPrice.value != null && element.externalInfo.extraInfo.bestPrice.value > 0) {
                                    if (room.sellingPriceTotal != null && room.sellingPriceTotal.value != null && element.externalInfo.extraInfo.bestPrice != null && element.externalInfo.extraInfo.bestPrice.value != null && room.sellingPriceTotal.value === element.externalInfo.extraInfo.bestPrice.value) {
                                        element.subTitle = `${room.name} (${room.boardDescription})`;
                                    }
                                }
                                element.roomTypes.push({
                                    id: room.id || null,
                                    defaultPrice: room.sellingPriceTotal,
                                    defaultPricePerRoom: room.sellingPricePerRoom,
                                    description: `${room.name}${room.boardDescription != null && room.boardDescription !== '' ? ` (${room.boardDescription})` : ``}`,
                                    internal_info: room.description,
                                    maxPerson: room.adults,
                                    minPerson: 0,
                                    supplierinfo: room.moreInformation
                                });
                            }
                            if (element.subTitle == null || element.subTitle === '') {
                                if (element.roomTypes[0].boardDescription != null && element.roomTypes[0].boardDescription !== '') {
                                    element.subTitle = `${element.roomTypes[0].name} (${element.roomTypes[0].boardDescription})`;
                                } else {
                                    element.subTitle = `${element.roomTypes[0].name}`;
                                }
                            }
                        } else {
                            if (data.hotelInfo && data.hotelInfo.rooms && data.hotelInfo.rooms.length > 0) {
                                let cheapestRoom = null
                                for (const room of data.hotelInfo.rooms) {
                                    if (cheapestRoom === null) {
                                        cheapestRoom = room;
                                    } else {
                                        if (room.sellingPriceTotal) {
                                            if (cheapestRoom.sellingPriceTotal == null || cheapestRoom.sellingPriceTotal > room.sellingPriceTotal) {
                                                cheapestRoom = room;
                                            }
                                        }
                                    }
                                    element.roomTypes.push({
                                        id: room.id || null,
                                        defaultPrice: room.sellingPriceTotal,
                                        defaultPricePerRoom: room.sellingPricePerRoom,
                                        description: `${room.name}${room.boardDescription != null && room.boardDescription !== '' ? ` (${room.boardDescription})` : ``}`,
                                        internal_info: room.description,
                                        maxPerson: room.adults,
                                        minPerson: 0,
                                        supplierinfo: room.moreInformation
                                    });
                                }
                                if (cheapestRoom && cheapestRoom.name) {
                                    if (cheapestRoom.boardDescription != null && cheapestRoom.boardDescription !== '') {
                                        element.subTitle = `${cheapestRoom.name} (${cheapestRoom.boardDescription})`;
                                    } else {
                                        element.subTitle = `${cheapestRoom.name}`;
                                    }
                                }
                            }
                        }
                        if (data.hotelInfo.images && data.hotelInfo.images.length > 0) {
                            element.media = [];
                            for (const image of data.hotelInfo.images) {
                                element.media.push({ url: image.url, sourceType: 'desty', sourceId: image.url.substring(image.url.lastIndexOf('/') + 1) })
                            }
                        }
                        if (data.hotelInfo.services && data.hotelInfo.services.length > 0) {
                            element.externalInfo.destyServices = [];
                            element.externalInfo.destyServices = data.hotelInfo.services;
                        }
                    }
                    element.externalInfo.gatheringData = false;
                }
            }
            resolve();
        });
    }

    cleanSegmentCopy(copiedSegment, itinerary, units) {
        const participantsIds = [];
        copiedSegment.vtbObjectId = this.generateRandomId();
        copiedSegment.vtbSegmentId = this.generateRandomId();
        if (copiedSegment.vtbElementId != null) {
            delete copiedSegment.vtbElementId;
        }
        if (copiedSegment.flightInfo != null && copiedSegment.flightInfo.length > 0) {
            for (const flight of copiedSegment.flightInfo) {
                flight.vtbFlightId = this.generateRandomId();
            }
        }
        const partyKeys = Object.keys(itinerary.data.participants);
        for (const party of partyKeys) {
            for (const participant of itinerary.data.participants[party]) {
                participantsIds.push(participant.id);
            }
        }

        if (copiedSegment.TSBlock != null && Object.keys(copiedSegment.TSBlock) != null) {
            delete copiedSegment.TSBlock // Remove blockId to be copied!
        }

        for (const element of copiedSegment.elements) {
            if (element.TSOrderline != null && element.TSOrderline.id != null) {
                delete element.TSOrderline.id;
            }

            if (element.olPrices && element.olPrices.participants) {
                const participantKeys = Object.keys(element.olPrices.participants);
                for (const key of participantKeys) {
                    if (participantsIds.indexOf(key) < 0) {
                        delete element.olPrices.participants[key];
                    }
                }
            }

            if (units.flexId[element.unitId] != null) {
                element.recalculateEndOffset = true;
            }

            if (element.vtbObjectId) {
                element.vtbObjectId = this.generateRandomId();
            }
            if (element.vtbElementId) {
                element.vtbElementId = this.generateRandomId();
            }
        }
        return copiedSegment;
    }

    getNewDateWithoutTimezone(date) {
        const minutes = date.getTimezoneOffset();
        return new Date(date.getTime() + minutes * 60000);
    }

    checkExpired(expireTimestamp) {
        let date_future = new Date();
        date_future.setTime(expireTimestamp);
        let date_now = new Date();

        let seconds = Math.floor((date_future.getTime() - (date_now.getTime())) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

        if (days < 0) {
            return 'expired';
        } else {
            if (days === 0 && hours === 0 && minutes < 30) {
                return true;
            }
        }
        return false;
    }

    isExpired() {
        if (this.checkExpired(this.expireTimestamp) === 'expired') {
            return true;
        }
        return false;
    }

    getAirportObject(code, iataService, isGlobal, vtbService, config) {
        if (code != null && code != '') {
            return new Promise((resolve) => {
                let airport;
                if (isGlobal === false || config.tsToken != null) {
                    airport = iataService.searchAirportOnIataCode(code, config.baseUrl || null, config.tsToken || null);
                } else {
                    airport = vtbService.searchAirports(code);
                }
                airport.subscribe(x => {
                    for (const airport of x) {
                        if (airport.code === code || airport.iata_code === code) {
                            resolve(airport);
                        }
                    }
                    resolve(x[0]);
                });
            });
        }
        return null;
    }

    getAirlineObject(code, iataService, isGlobal, vtbService, config) {
        if (code != null && code != '') {
            return new Promise((resolve) => {
                let airline;
                if (isGlobal === false || config.tsToken != null) {
                    airline = iataService.searchAirlines(code, config);
                } else {
                    airline = vtbService.searchAirlines(code);
                }
                airline.subscribe(x => {
                    for (const airline of x) {
                        if (airline.code === code || airline.iata_code === code) {
                            resolve(airline);
                        }
                    }
                    resolve(x[0]);
                });
            });
        }
        return null;
    }

    setElementOffsetDays(index, item, unitsValue) {
        let newOffset = 0;
        if (unitsValue.items[item.elements[index].unitId].isTimeBased === true) {
            if (item.elements[index - 1].offset != null) {
                newOffset += item.elements[index - 1].offset;
            }
            if (item.elements[index - 1].nights != null && item.elements[index - 1].nights === 0) {
                for (let i = index; i > -1; i--) {
                    if (item.elements[i].nights > 0) {
                        newOffset = item.elements[i].nights;
                        newOffset += item.elements[i].offset;
                        break;
                    }
                }
            } else {
                newOffset += item.elements[index - 1].nights;
            }
        } else {
            newOffset = item.elements[index - 1].offset;
        }
        item.elements[index].offset = newOffset;
        return item.elements[index];
    }

    needsFlexCalc(segments, units) {
        for (let segment of segments) {
            for (let element of segment.elements) {
                if (units.flexId[element.unitId] && element.hasOwnProperty('endSegmentIndex') === false) {
                    return true;
                }
            }
        }
        return false;
    }

    validateExtraFieldParents(extraFields) {
        const tempExtraFields = JSON.parse(JSON.stringify(extraFields));
        const wrongParentIdExtraFields = [];
        for (const category of tempExtraFields) {
            if (category.fields != null) {
                for (let i = category.fields.length - 1; i >= 0; i--) {
                    if (Number(category.fields[i].parent_id) !== Number(category.id)) {
                        wrongParentIdExtraFields.push(category.fields[i]);
                        category.fields.splice(i, 1);
                    }
                }
            }
        }
        if (wrongParentIdExtraFields.length > 0) {
            for (const extraField of wrongParentIdExtraFields) {
                for (const category of tempExtraFields) {
                    if (Number(category.id) === Number(extraField.parent_id)) {
                        category.fields.push(extraField);
                        break;
                    }
                }
            }
        }
        return tempExtraFields;
    }

    setExtraFieldVtbObjectIds(extraFields) {
        if (extraFields != null && extraFields.length > 0) {
            for (let extraField of extraFields) {
                extraField.vtbObjectId = this.generateRandomId();
                this.setExtraFieldVtbObjectIds(extraField.fields);
            }
        }
    }

    setElementExtraFieldVtbObjectIds(extraFields) {
        if (extraFields != null && extraFields.length > 0) {
            for (let extraField of extraFields) {
                extraField.vtbObjectId = this.generateRandomId();
            }
        }
    }

    isValidDate(date: any) {
        const tempDate = new Date(date);
        return tempDate instanceof Date && !isNaN(tempDate.getTime());
    }

    checkHarbour(segmentTypes, itemType) {
        if (segmentTypes != null && segmentTypes.items != null && segmentTypes.items[itemType] != null) {
            if (segmentTypes.items[itemType].name.toLowerCase().indexOf('harbor') > -1 || segmentTypes.items[itemType].name.toLowerCase().indexOf('harbour') > -1 || segmentTypes.items[itemType].name.toLowerCase().indexOf('haven') > -1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getSelectedRoomType(roomTypes, field, element) {
        let pmNote = null;
        for (const roomType of roomTypes) {
            if (roomType.description === element.subTitle) {
                pmNote = roomType[field];
                break;
            }
        }
        return pmNote;
    }

    async preSaveChecks(itinerary) {
        const itineraryCopy = JSON.parse(JSON.stringify(itinerary));
        if (itineraryCopy.data != null) {
            if (itineraryCopy.data.cover != null && itineraryCopy.data.cover.length > 0) {
                for (const image of itineraryCopy.data.cover) {
                    if (image.sourceType === 'mediaspirit' && image.sourceId == null) {
                        if (image.id != null) {
                            image.sourceId = image.id;
                        }
                    }
                }
            }
            if (itineraryCopy.data.extraFieldValues != null && itineraryCopy.data.extraFieldValues.length > 0) {
                for (const extraFieldCategory of itineraryCopy.data.extraFieldValues) {
                    if (typeof extraFieldCategory.id === 'string') {
                        extraFieldCategory.id = Number(extraFieldCategory.id);
                    }
                    if (extraFieldCategory.fields != null && extraFieldCategory.fields.length > 0) {
                        for (const extraField of extraFieldCategory.fields) {
                            if (typeof extraField.id === 'string') {
                                extraField.id = Number(extraField.id);
                            }
                            if (extraField.type === 'Date') {
                                if (extraField.value != null && extraField.value !== '') {
                                    extraField.value = moment(extraField.value).format('YYYY-MM-DD');
                                }
                            }
                        }
                    }
                }
            }
            if (itineraryCopy.data.dayTexts != null && Object.keys(itineraryCopy.data.dayTexts).length > 0) {
                for (const key of Object.keys(itineraryCopy.data.dayTexts)) {
                    if (itineraryCopy.data.dayTexts[key] != null && typeof itineraryCopy.data.dayTexts[key].value === 'object') {
                        itineraryCopy.data.dayTexts[key].value = '';
                    }
                }
            }
            if (itineraryCopy.data.startDate != null) {
                itineraryCopy.data.startDate = moment(itineraryCopy.data.startDate).format('YYYY-MM-DD');
            }
            if (itineraryCopy.data.endDate != null) {
                itineraryCopy.data.endDate = moment(itineraryCopy.data.endDate).format('YYYY-MM-DD');
            }
            if (itineraryCopy.data.segments != null && itineraryCopy.data.segments.length > 0) {
                for (let segment of itineraryCopy.data.segments) {
                    if (segment.media != null && segment.media.length > 0) {
                        for (const segmentImage of segment.media) {
                            if (segmentImage.sourceType === 'mediaspirit' && segmentImage.sourceId == null) {
                                if (segmentImage.id != null) {
                                    segmentImage.sourceId = segmentImage.id;
                                }
                            }
                        }
                    }
                    if (typeof segment.typeId === 'string') {
                        segment.typeId = Number(segment.typeId);
                    }
                    if (segment.date != null) {
                        segment.date = moment(segment.date).format('YYYY-MM-DD');
                    }
                    if (segment.endDate != null) {
                        segment.endDate = moment(segment.endDate).format('YYYY-MM-DD');
                    }
                    for (let element of segment.elements) {
                        if (element.media != null && element.media.length > 0) {
                            for (const elementImage of element.media) {
                                if (elementImage.sourceType === 'mediaspirit' && elementImage.sourceId == null) {
                                    if (elementImage.id != null) {
                                        elementImage.sourceId = elementImage.id;
                                    }
                                }
                            }
                        }
                        if (element.date != null) {
                            element.date = moment(element.date).format('YYYY-MM-DD');
                        }
                        if (element.endDate != null) {
                            element.endDate = moment(element.endDate).format('YYYY-MM-DD');
                        }
                        if (element.participantSalesUpdate != null) {
                            delete element.participantSalesUpdate;
                        }
                        if (element.initialParticipantCheck != null) {
                            delete element.initialParticipantCheck;
                        }
                    }
                    if (segment != null && segment.flightInfo != null) {
                        for (let flightInfo of segment.flightInfo || []) {
                            if (flightInfo.arrivalDate != null) {
                                flightInfo.arrivalDate = moment(flightInfo.arrivalDate).format('YYYY-MM-DD');
                            }
                            if (flightInfo.departureDate != null) {
                                flightInfo.departureDate = moment(flightInfo.departureDate).format('YYYY-MM-DD');
                            }
                        }
                    }
                }
            }
            itineraryCopy.data.savedDate = new Date();
        }
        return itineraryCopy;
    }

    isText(unit) {
        if (unit != null) {
            if (unit.textUnit != null) {
                return unit.textUnit === 1 ? true : false;
            } else {
                if (unit.name.match(this.textRegex) != null && unit.name.match(this.textRegex).length > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    getTotalNights(segments) {
        let nights = 0;
        for (const segment of segments) {
            nights += segment.nights + 1;
        }
        return nights;
    }

    updateExternalProduct(currentElement, elementUpdates, externalElement, type = null) {
        let update = false;
        if (elementUpdates != null) {
            currentElement.isElementUpdated = true;
            if (elementUpdates.title) {
                currentElement.title = externalElement.title;
            }
            if (elementUpdates.additionalText) {
                currentElement.additionalText = externalElement.additionalText;
            }
            if (elementUpdates.subAdditionalText) {
                currentElement.subAdditionalText = this.getSelectedRoomType(externalElement.roomTypes, 'note', currentElement);
            }
            if (elementUpdates.supplierInfo) {
                currentElement.supplierInfo = this.getSelectedRoomType(externalElement.roomTypes, 'supplierinfo', currentElement);
            }
            if (elementUpdates.pricematrixInternalText) {
                currentElement.pricematrixInternalText = this.getSelectedRoomType(externalElement.roomTypes, 'internal_info', currentElement);
            }
            if (elementUpdates.internalText) {
                currentElement.internalText = externalElement.internalInfo
            }
            if (elementUpdates.maps && externalElement.maps != null) {
                const mapsArr = {
                    heading: Number(externalElement.maps.heading),
                    pitch: Number(externalElement.maps.pitch),
                    longitude: Number(externalElement.maps.longitude),
                    latitude: Number(externalElement.maps.latitude),
                    fieldofview: Number(externalElement.maps.fieldofview),
                    zoom: Number(externalElement.maps.zoom),
                    searchplace: externalElement.maps.searchplace,
                    static_image_zoom: externalElement.maps.static_image_zoom,
                    address_info: externalElement.maps.address_info,
                    enabled: (externalElement.maps.latitude != null && externalElement.maps.longitude != null) ? true : false
                }
                currentElement.maps = mapsArr;
            }
            if (elementUpdates.media) {
                currentElement.media = externalElement.media;
            }
            if (type != null) {
                externalElement.TSOrderline.extraFieldValues.forEach((ef, index) => {
                    if (elementUpdates.extrafields[ef.translated_name] === true) {
                        let found = false;
                        for (const extrafield of currentElement.TSOrderline.extraFieldValues) {
                            if (extrafield.id === ef.id) {
                                found = true;
                                if (ef.field_type == 'Orderline_CheckBox' && ef.value == null) {
                                    extrafield.value = false;
                                } else {
                                    extrafield.value = ef.value;
                                }
                                if (ef.options != null) {
                                    extrafield.options = ef.options;
                                }
                                if (ef.value_lang != null) {
                                    extrafield.value_lang = ef.value_lang;
                                } else {
                                    extrafield.value_lang = null;
                                }
                            }
                            if (extrafield.vtbElementId == null) {
                                extrafield.vtbElementId = this.generateRandomId();
                            }
                            if (extrafield.vtbObjectId == null) {
                                extrafield.vtbObjectId = this.generateRandomId();
                            }
                        }
                        if (found === false) {
                            currentElement.TSOrderline.extraFieldValues.splice(index, 0, {
                                display_at: true,
                                display_at_template_travelplan: true,
                                display_at_travelplan: true,
                                display_at_vtb: true,
                                display_at_vtb_template: true,
                                field_type: ef.field_type,
                                id: ef.id,
                                name: ef.translated_name,
                                translated_name: ef.translated_name,
                                value: ef.value || null,
                                value_lang: ef.value_lang || null,
                                vtbElementId: this.generateRandomId(),
                                vtbObjectId: this.generateRandomId()
                            });
                        }
                    }
                });
            } else {
                externalElement.orderlineExtrafields.forEach((ef, index) => {
                    if (elementUpdates.extrafields[ef.name] === true) {
                        let found = false;
                        for (const extrafield of currentElement.TSOrderline.extraFieldValues) {
                            if (extrafield.id === ef.id) {
                                found = true;
                                if (ef.field_type == 'Orderline_CheckBox' && ef.value == null) {
                                    extrafield.value = false;
                                } else {
                                    extrafield.value = ef.value;
                                }
                                if (ef.options != null) {
                                    extrafield.options = ef.options;
                                }
                                if (ef.value_lang != null) {
                                    extrafield.value_lang = ef.value_lang;
                                } else {
                                    extrafield.value_lang = null;
                                }
                            }
                        }
                        if (found === false) {
                            currentElement.TSOrderline.extraFieldValues.splice(index, 0, {
                                category_id: ef.category_id,
                                defaults: ef.defaults,
                                display_at: ef.display_at,
                                display_at_template_travelplan: ef.display_at_template_travelplan,
                                display_at_travelplan: ef.display_at_travelplan,
                                display_at_vtb: ef.display_at_vtb,
                                display_at_vtb_template: ef.display_at_vtb_template,
                                enable_multilanguage: ef.enable_multilanguage,
                                field_type: ef.field_type,
                                field_width: ef.field_width,
                                id: ef.id,
                                layout_data: ef.layout_data,
                                maxlength: ef.maxlength,
                                maxlength_enforce: ef.maxlength_enforce,
                                name: ef.name,
                                show_in_product: ef.show_in_product,
                                sort_order: ef.sort_order,
                                translated_name: ef.translated_name,
                                type_id: ef.type_id,
                                valid_regex: ef.valid_regex,
                                value: ef.value || null,
                                value_lang: ef.value_lang || null,
                                vtbElementId: this.generateRandomId(),
                                vtbObjectId: this.generateRandomId()
                            });
                        }
                    }
                });
            }
            update = true;
        }

        if (externalElement.ProductTravelInfo != null) {
            currentElement.ProductTravelInfo = externalElement.ProductTravelInfo;
        }
        if (externalElement.vatPercentage != null) {
            currentElement.vatPercentage = externalElement.vatPercentage;
        }
        if (externalElement.vatType != null) {
            currentElement.vatType = externalElement.vatType;
        }
        if (externalElement.vatAmount != null) {
            currentElement.vatAmount = externalElement.vatAmount;
        }
        if (externalElement.babyUntilAge != null) {
            currentElement.babyUntilAge = externalElement.babyUntilAge;
        }
        if (externalElement.childUntilAge != null) {
            currentElement.childUntilAge = externalElement.childUntilAge;
        }
        if (externalElement.teenagerUntilAge != null) {
            currentElement.teenagerUntilAge = externalElement.teenagerUntilAge;
        }
        if (externalElement.babyCountInMaxOcc != null && externalElement.babyCountInMaxOcc === 1) {
            currentElement.babyCountsInMaxOcc = true;
        }
        if (externalElement.childCountInMaxOcc != null && externalElement.childCountInMaxOcc === 1) {
            currentElement.childCountsInMaxOcc = true;
        }
        if (externalElement.teenagerCountInMaxOcc != null && externalElement.teenagerCountInMaxOcc === 1) {
            currentElement.teenagerCountsInMaxOcc = true;
        }
        return {
            data: currentElement,
            update: update
        };
    }

    // Get nr of participant types
    getParticipantTypes(itinerary, element, config) {
        const data = {
            nrOfBabies: 0,
            nrOfChildren: 0,
            nrOfAdolescent: 0,
            nrOfAdults: 0,
            participantTypes: {}
        }
        if (itinerary != null && itinerary.data && itinerary.data.participants != null) {
            if (element.olPrices.participants != null) {
                for (let room in itinerary.data.participants) {
                    if (itinerary.data.participants[room] != null && itinerary.data.participants[room].length > 0) {
                        for (let participant of itinerary.data.participants[room]) {
                            if (element.olPrices.participants.hasOwnProperty(String(participant.id)) === true) {
                                if ((participant.birthdate != null && participant.birthdate !== '') || (participant.age_calc_type != null && participant.age_calc_type === 'birthdate')) {
                                    if (this.isValidDate(participant.birthdate) === true) { // Checks if participant birthdate is not invalid
                                        if (typeof participant.birthdate !== 'function') { // Transform birthdate String to birthdate Date
                                            participant.birthdate = new Date(participant.birthdate);
                                        }
                                        const age = this.age(participant.birthdate, new Date(element.date));
                                        const babyAge = element.babyUntilAge != null && element.babyUntilAge > 0 ? element.babyUntilAge : config.upAndUntilBabyAge;
                                        const childAge = element.childUntilAge != null && element.childUntilAge > 0 ? element.childUntilAge : config.upAndUntilChildAge;
                                        const teenagerAge = element.teenagerUntilAge != null && element.teenagerUntilAge > 0 ? element.teenagerUntilAge : config.upAndUntilTeenagerAge;

                                        if (age >= 0 && age <= babyAge) {
                                            data.nrOfBabies++;
                                            data.participantTypes[participant.id] = 'baby';
                                        } else if (age > babyAge && age <= childAge) {
                                            data.nrOfChildren++;
                                            data.participantTypes[participant.id] = 'child';
                                        } else if (age > childAge && age <= teenagerAge) {
                                            data.nrOfAdolescent++;
                                            data.participantTypes[participant.id] = 'teenager';
                                        } else if (age > teenagerAge) {
                                            data.nrOfAdults++;
                                            data.participantTypes[participant.id] = 'adult';
                                        }
                                    } else {
                                        participant.birthdate = null; // Sets participant birthdate is null if invalid
                                        data.nrOfAdults++;
                                        data.participantTypes[participant.id] = 'adult';
                                    }
                                } else if (participant.age_calc_type != null) {
                                    if (participant.age_calc_type === 'baby') {
                                        data.nrOfBabies++;
                                        data.participantTypes[participant.id] = 'baby';
                                    } else if (participant.age_calc_type === 'child') {
                                        data.nrOfChildren++;
                                        data.participantTypes[participant.id] = 'child';
                                    } else if (participant.age_calc_type === 'teenager') {
                                        data.nrOfAdolescent++;
                                        data.participantTypes[participant.id] = 'teenager';
                                    } else if (participant.age_calc_type === 'adult') {
                                        data.nrOfAdults++;
                                        data.participantTypes[participant.id] = 'adult';
                                    } else {
                                        data.nrOfAdults++;
                                        data.participantTypes[participant.id] = 'adult';
                                    }
                                } else { // if no birthdate is set, assume participant to be adult
                                    data.nrOfAdults++;
                                    data.participantTypes[participant.id] = 'adult';
                                }
                            }
                        }
                    }
                }
            }
        }
        return data;
    }

    // https://stackoverflow.com/a/7091965/8979098
    age(birthdate, today) {
        if (birthdate != null) {
            var birthDate = new Date(birthdate);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        } else {
            return null;
        }
    }

    isUsedInInsurance(segmentTypes, segment) {
        if (segmentTypes != null && segmentTypes.items != null && segmentTypes.items[segment.typeId] != null && segmentTypes.items[segment.typeId].used_in_insurance === true) {
            return true;
        }
        return false;
    }

    getIgnoredForInsuranceArray(segmentTypes, segment) {
        if (segmentTypes != null && segmentTypes.items != null && segmentTypes.items[segment.typeId] != null && segmentTypes.items[segment.typeId].ignoreForInsurance != null) {
            return segmentTypes.items[segment.typeId].ignoreForInsurance;
        }
        return [];
    }

    checkInsurance(element, segment, segmentTypes) {
        if (this.isUsedInInsurance(segmentTypes, segment) === true) {
            if (element.roomTypes != null && element.roomTypes.length > 0) {
                for (const roomType of element.roomTypes) {
                    if (roomType.description === element.subTitle) {
                        if (roomType.per === 'inscnc') {
                            return {
                                type: 'inscnc',
                                price: roomType.defaultPrice
                            };
                        } else if (roomType.per === 'insdays') {
                            return {
                                type: 'insdays',
                                price: roomType.defaultPrice
                            };
                        } else {
                            return {
                                type: null,
                                price: null
                            };
                        }
                    }
                }
            }
        }
        return {
            type: null,
            price: null
        };
    }

    calculateInsurance(segments, segmentIndex, price, type, ignoredArray, segmentTypes) {
        let totalElementsSalesPrice = 0;
        if (type === 'inscnc') {
            segments.forEach((segment, index) => {
                if (index !== segmentIndex) {
                    if (ignoredArray.length > 0) {
                        if (
                          segmentTypes.items[segment.typeId] &&
                          segmentTypes.items[segment.typeId].name &&
                          ignoredArray.indexOf(segmentTypes.items[segment.typeId].name.toLowerCase()) < 0) {
                            if (segment.elements != null && segment.elements.length > 0) {
                                for (const element of segment.elements) {
                                    if (element.optional === false && element.olPrices != null && element.olPrices.salesTotal != null) {
                                        totalElementsSalesPrice += Number(element.olPrices.salesTotal);
                                    }
                                }
                            }
                        }
                    } else {
                        if (segment.elements != null && segment.elements.length > 0) {
                            for (const element of segment.elements) {
                                if (element.optional === false && element.olPrices != null && element.olPrices.salesTotal != null) {
                                    totalElementsSalesPrice += Number(element.olPrices.salesTotal);
                                }
                            }
                        }
                    }
                }
            });
        }
        return Number((totalElementsSalesPrice / 100 * Number(price.replace(',', '.'))).toFixed(3));
    }

    calculateParticipantsInsurance(participants, salesTotal) {
        const ids = Object.keys(participants);
        const salesIds = [];

        if (ids != null && ids.length > 0) {
            ids.forEach(id => {
                if (participants[id].salesPrice != null) {
                    salesIds.push(id);
                }
            });
            if (salesIds.length > 0) {
                salesIds.forEach(id => {
                    participants[id].salesPrice = Number(salesTotal / salesIds.length).toFixed(3);
                });
            }
        }
        return participants;
    }

    openPublishedCustomerTemplate(template) {
        if (template.status != null && template.status !== 'pending') {
            if (template.completeUrl != null) {
                if (template.completeUrl.indexOf('http') > -1) {
                    window.open(`${template.url}`);
                } else {
                    window.open(`https://${template.url}`);
                }
            } else {
                if (template.pdfName) {
                    if (template.downloadName != null) {
                        window.open(`https://${template.url}/${template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf`);
                    } else {
                        window.open(`https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`);
                    }
                } else {
                    if (isGlobal === true && template.environment === 'vtbGlobal') {
                        window.open(`${template.url}`);
                    } else {
                        window.open(`https://${template.url}/`);
                    }
                }
            }
        }
    }

    copyUrl(template) {
        const foundHttps = template.completeUrl != null;
        if (foundHttps === true) {
            this.clipboardApi.copyFromContent(`https://${template.url}`);
        }
        if (template.status != null && template.status !== 'pending') {
            if (template.type === 'pdfjson') {
                if (foundHttps === false) {
                    this.clipboardApi.copyFromContent(`https://${template.url}/zra.json`);
                }
            } else if (template.type === 'pdf') {
                if (foundHttps === false) {
                    if (template.downloadName != null) {
                        if (isGlobal === true && template.environment === 'vtbGlobal') {
                            this.clipboardApi.copyFromContent(`${template.url}/`);
                        } else {
                            this.clipboardApi.copyFromContent(`https://${template.url}/${template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf`);
                        }
                    } else {
                        if (isGlobal === true && template.environment === 'vtbGlobal') {
                            this.clipboardApi.copyFromContent(`${template.url}/`);
                        } else {
                            this.clipboardApi.copyFromContent(`https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`);
                        }
                    }
                }
            } else {
                if (foundHttps === false) {
                    if (isGlobal === true && template.environment === 'vtbGlobal') {
                        this.clipboardApi.copyFromContent(`${template.url}/`);
                    } else {
                        this.clipboardApi.copyFromContent(`https://${template.url}/`);
                    }
                }
            }
            const copUrlDialog = this.dialog.open(CopyUrlDialogComponent, {
                height: '120px',
                width: '250px',
                hasBackdrop: false,
                panelClass: 'no-padding-dialog'
            });

            copUrlDialog.afterOpened().subscribe(() => {
                setTimeout(() => {
                    copUrlDialog.close();
                }, 2000);
            });
        }
    }

    cleanUpData(data) {
        if (data != null) {
            if (data.segments == null || data.segments.length === 0) {
                return data;
            } else {
                for (const segment of data.segments) {
                    if (segment.elements == null || segment.elements.length === 0) {
                        break;
                    } else {
                        for (const element of segment.elements) {
                            if (element.TSOrderline.extraFieldValues == null || element.TSOrderline.extraFieldValues.length === 0) {
                                break;
                            } else {
                                for (const extraField of element.TSOrderline.extraFieldValues) {
                                    delete extraField.type_id;
                                    delete extraField.valid_regex;
                                    delete extraField.defaults;
                                    delete extraField.category_id;
                                    delete extraField.sort_order;
                                    delete extraField.show_in_product;
                                    delete extraField.layout_data;
                                    delete extraField.maxlength;
                                    delete extraField.maxlength_enforce;
                                    delete extraField.enable_multilanguage;
                                    delete extraField.field_width;
                                    delete extraField.field_type;
                                    delete extraField.display_at;
                                    delete extraField.display_at_vtb;
                                    delete extraField.display_at_vtb_template;
                                    delete extraField.display_at_travelplan;
                                    delete extraField.display_at_template_travelplan;
                                    delete extraField.field_value_link_id;
                                    delete extraField.link_type;
                                    delete extraField.link_id;
                                    delete extraField.value_lang;
                                }
                            }
                        }
                    }
                }
            }
        }
        return data;
    }
}
