import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class TravelplanTokenGuard implements CanActivate {
  constructor(private jwtHelper: JwtHelperService) { }
  canActivate() {
    const url = new URL(window.location.href);
    const rawToken = url.searchParams.get("token");
    if (!this.jwtHelper.isTokenExpired(rawToken)) {
      return true;
    }
    return false;
  }
}
