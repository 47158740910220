import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const CURRENCIES_UPDATE = 'CURRENCIES_UPDATE';
const logService = new LogService();

export function currenciesReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {
    case CURRENCIES_UPDATE:
      let type = '';
      state = action.payload.currencies.reduce((all, item) => {
        if (item.iso_code != null) {
          all.ids.push(item.id);
          all.codes.push(item.iso_code);
          all.itemsByCode[item.iso_code] = item;
          all.itemsById[item.id] = item;
          type = 'ts';
        } else if (item.isoCode != null) {
          all.ids.push(item.id);
          all.codes.push(item.isoCode);
          all.itemsByCode[item.isoCode] = item;
          all.itemsById[item.id] = item;
          type = 'global';
        }
        return all;
      }, { ids: [], codes: [], itemsByCode: {}, itemsById: {} });
      if (type === 'ts') {
        state.exchangeRates = action.payload.exchangeRates;
        state.orderlinePurchaseExchangeDate = action.payload.orderlinePurchaseExchangeDate;
      } else if (type === 'global') {
        state.exchangeRates = action.payload.exchangeRates.map(exchangeRate => {
          exchangeRate.currency_id_one = exchangeRate.currencyFrom.id;
          exchangeRate.currency_id_two = exchangeRate.currencyTo.id;
          exchangeRate.rate_start_date = '20200101'; // TODO use the actual startdate of the set exchange rate
          exchangeRate.rate_end_date = '21000101'; // TODO use the actual enddate of the set exchange rate

          delete exchangeRate.currencyFrom;
          delete exchangeRate.currencyTo;
          return exchangeRate;
        });
        state.orderlinePurchaseExchangeDate = 'order_startdate';
      }
      logService.consoleLog('CURRENCIES_UPDATE:', state);
      return state;
    default:
      return state;
  }
}
