import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const PARTICIPANTS_UPDATE = 'PARTICIPANTS_UPDATE';
const logService = new LogService();

export function participantsReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {
    case PARTICIPANTS_UPDATE:
      state = action.payload;
      logService.consoleLog('PARTICIPANTS_UPDATE:',state);
      return state;
    default:
      return state;
  }
}
