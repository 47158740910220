import { Routes, RouterModule } from '@angular/router';
import { ListViewComponent } from './pages/list-view/list-view.component';
import { TravelplanTokenGuard } from './guards/travelplan-token.guard';
import { ErrorViewComponent } from './pages/error-view/error-view.component';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: './plain-page.module#PlainPageModule',
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: 'grid-view',
    loadChildren: './grid-page.module#GridPageModule',
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: 'json-view',
    loadChildren: './json-page.module#JsonPageModule',
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: 'participants-view',
    loadChildren: './participants-page.module#ParticipantsPageModule',
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: 'map-view',
    loadChildren: './map-page.module#MapPageModule',
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: 'day-texts-view',
    loadChildren: './day-texts.module#DayTextsModule',
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: 'list-view',
    component: ListViewComponent,
    data: {
      title: 'TourSpirit - List View'
    },
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: 'texts-view',
    loadChildren: './texts-page.module#TextsPageModule',
    canActivate: [TravelplanTokenGuard]
  },
  {
    path: '**',
    component: ErrorViewComponent
  }
];

export const routing = RouterModule.forRoot(appRoutes);
