import { Directive } from '@angular/core';

@Directive({
  selector: '[appItineraryDay]'
})
export class ItineraryDayDirective {

  constructor() { }

}
