import { LogService } from '../services/log.service';
import { ActionWithPayload } from '../models/action-with-payload';

export const MAP_CREATED = 'MAP_CREATED';
export const MAP_UPDATED = 'MAP_UPDATED';
export const MAP_DELETED = 'MAP_DELETED';
let logService = new LogService();

export function mapDrawReducer(state: any = [], action: ActionWithPayload) {
    switch (action.type) {
        case MAP_CREATED:
            state = action.payload;
            logService.consoleLog('MAP_CREATED:', state);
            return state;
        case MAP_UPDATED:
            state = action.payload;
            logService.consoleLog('MAP_UPDATED:', state);
            return state;
        case MAP_DELETED: 
            state = null;
            logService.consoleLog('MAP_DELETED:', state);
            return state;
        default:
            return state;
    }
}
