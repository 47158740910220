import { LogService } from '../services/log.service';
import { ActionWithPayload } from '../models/action-with-payload';

export const PRICE_UPDATED = 'PRICE_UPDATED';

export function priceReducer(state: number = 0, action: ActionWithPayload) {
  switch (action.type) {

    case PRICE_UPDATED:
      state++;
      let logService = new LogService();
      logService.consoleLog('PRICE_UPDATED:',state);
      return state;

    default:
      return state;

  }
}
