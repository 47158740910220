import 'hammerjs'
import * as bowser from 'bowser';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const showErrorMessage = (err) => {
  if(document != null){
    document.getElementById('vtb-hidden-download-message').setAttribute('style', 'display: block;');
    document.getElementById('vtb-spinner').setAttribute('style', 'display: none;');
    document.getElementById('vtb-report-btn').setAttribute('href', `mailto:info@sitespirit.nl?subject=VTB Error log&body=${err}`);
  }
}

const browser:any = bowser.getParser(window.navigator.userAgent);
if(['Firefox','Chrome'].indexOf(browser.getBrowserName()) > -1){
  platformBrowserDynamic().bootstrapModule(AppModule).catch(err => {
    showErrorMessage(JSON.stringify(err));
    return console.log('test',err);
  });
} else {
  showErrorMessage(JSON.stringify(browser.parse().parsedResult));
}
