import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const LIBORDERS_UPDATE = 'LIBORDERS_UPDATE';
const logService = new LogService();

export function libordersReducer(state: any = { templates: [] }, action: ActionWithPayload) {
  switch (action.type) {
    case LIBORDERS_UPDATE:
      state.templates = action.payload.map(x => {
        x.olPrices = {
          participants: {}
        };
        return x;
      });
      logService.consoleLog('LIBORDERS_UPDATE:', state);
      return { ...state };

    default:
      return state;
  }
}
