import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { USER_INTERFACE_UPDATE } from '../../reducers/user-interface';
import { AppState } from '../../models/app-state';

@Component({
    selector: 'app-side-navigation',
    templateUrl: './side-navigation.component.html',
    styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent {
    @Input() userInterface: any;
    flights: string[];
    constructor(private store: Store<AppState>) {
        this.flights = ['AMS-AGP','AMS-SVQ','AMS-GRX'];
    }

    get containerWidthStyle(){
        let style = 300;
        if(this.userInterface.collapseSideNavigation == true){
            style = 64;
        }
        return style+'px';
    }

    toggleCollapse(){
        let payload = this.userInterface;
        payload.collapseSideNavigation = !payload.collapseSideNavigation;
        this.store.dispatch({ type: USER_INTERFACE_UPDATE, payload: payload });
    }

}
