import { Injectable } from '@angular/core';

@Injectable()
export class PnrService {

  today: Date = new Date();
  monthsMap: any = {
    'JAN': '01', 'FEB': '02', 'MAR': '03', 'APR': '04', 'MAY': '05',
    'JUN': '06', 'JUL': '07', 'AUG': '08', 'SEP': '09', 'OCT': '10',
    'NOV': '11', 'DEC': '12'
  };

  pnrMap: any = {
    default: {
      departureTime: {
        start: 28,
        end: 32
      },
      arrivalTime: {
        start: 33,
        end: 38
      },
      departureDate: {
        month: {
          start: 13,
          end: 16
        },
        day: {
          start: 11,
          end: 13
        }
      },
      fallbackDepartureDate: {
        month: {
          start: 11,
          end: 14
        },
        day: {
          start: 9,
          end: 11
        }
      },
      arrivalDate: {
        month: {
          start: 42,
          end: 45
        },
        day: {
          start: 40,
          end: 42
        },
        fallbackMonth: {
          start: 40,
          end: 43
        },
        fallbackDay: {
          start: 38,
          end: 40
        }
      },
      fallbackArrivalDate: {
        month: {
          start: 13,
          end: 16,
        },
        day: {
          start: 11,
          end: 13
        }
      },
      airlineCode: {
        start: 2,
        end: 4
      },
      flightnumber: {
        start: 4,
        end: 8
      },
      fallbackFlightnumber: {
        start: 4,
        end: 7
      },
      class: {
        start: 9,
        end: 10
      },
      departureAirport: {
        start: 17,
        end: 20
      },
      arrivalAirport: {
        start: 20,
        end: 23
      }
    },
    sabre: {
      departureTime: {
        start: 29,
        end: 33
      },
      arrivalTime: {
        start: 34,
        end: 39
      },
      departureDate: {
        month: {
          start: 12,
          end: 15
        },
        day: {
          start: 10,
          end: 12
        }
      },
      fallbackDepartureDate: {
        month: {
          start: 14,
          end: 17
        },
        day: {
          start: 12,
          end: 14
        }
      },
      arrivalDate: {
        month: {
          start: 41,
          end: 44
        },
        day: {
          start: 39,
          end: 41
        }
      },
      fallbackArrivalDate: {
        month: {
          start: 14,
          end: 17,
        },
        day: {
          start: 12,
          end: 14
        },
        fallbackMonth: {
          start: 12,
          end: 15,
        },
        fallbackDay: {
          start: 10,
          end: 12
        }
      },
      airlineCode: {
        start: 2,
        end: 4
      },
      fallbackAirlineCode: {
        start: 4,
        end: 6
      },
      flightnumber: {
        start: 4,
        end: 8
      },
      fallbackFlightnumber: {
        start: 6,
        end: 9
      },
      class: {
        start: 9,
        end: 10
      },
      departureAirport: {
        start: 18,
        end: 21
      },
      arrivalAirport: {
        start: 21,
        end: 24
      }
    },
    galileo: {
      departureTime: {
        start: 28,
        end: 32
      },
      arrivalTime: {
        start: 33,
        end: 38
      },
      departureDate: {
        month: {
          start: 13,
          end: 16
        },
        day: {
          start: 11,
          end: 13
        }
      },
      arrivalDate: {
        month: {
          start: 42,
          end: 45
        },
        day: {
          start: 40,
          end: 42
        }
      },
      fallbackArrivalDate: {
        month: {
          start: 13,
          end: 16,
        },
        day: {
          start: 11,
          end: 13
        }
      },
      airlineCode: {
        start: 2,
        end: 4
      },
      flightnumber: {
        start: 4,
        end: 9
      },
      class: {
        start: 9,
        end: 10
      },
      departureAirport: {
        start: 17,
        end: 20
      },
      arrivalAirport: {
        start: 20,
        end: 23
      }
    },
    tui: {
      departureTime: {
        start: 30,
        end: 34
      },
      arrivalTime: {
        start: 35,
        end: 40
      },
      departureDate: {
        month: {
          start: 13,
          end: 16
        },
        day: {
          start: 11,
          end: 13
        }
      },
      fallbackDepartureDate: {
        month: {
          start: 11,
          end: 14
        },
        day: {
          start: 9,
          end: 11
        }
      },
      arrivalDate: {
        month: {
          start: 42,
          end: 45
        },
        day: {
          start: 40,
          end: 42
        },
        fallbackMonth: {
          start: 40,
          end: 43
        },
        fallbackDay: {
          start: 38,
          end: 40
        }
      },
      fallbackArrivalDate: {
        month: {
          start: 13,
          end: 16,
        },
        day: {
          start: 11,
          end: 13
        }
      },
      airlineCode: {
        start: 2,
        end: 4
      },
      flightnumber: {
        start: 4,
        end: 8
      },
      fallbackFlightnumber: {
        start: 4,
        end: 7
      },
      class: {
        start: 9,
        end: 10
      },
      departureAirport: {
        start: 17,
        end: 20
      },
      arrivalAirport: {
        start: 20,
        end: 23
      }
    }
  }

  constructor() { }

  pnrProcess(pnrString) {
    pnrString = pnrString.trim();
    let pnrArray = pnrString.split('\n');
    pnrArray = pnrArray.filter(value => Object.keys(value).length !== 0); // Removed empty lines of element from array object!
    let passengerArray = [];
    let operatedByArray = [];
    let operatedBy = null;
    let lastIndex = 0;
    pnrArray = pnrArray.reduce((all, row, index) => {
      row = row.trim();
      //if(index > 0){
      if (/^\d+\s+\w+/.exec(row) != null && row.indexOf(' ARNK') === -1 && row.length > 40) { // match all the flight info rows
        lastIndex++;
        all.push(this.parsePNR(row));
      } else if (row.indexOf(' . ') === 1) { // added for galileo
        lastIndex++;
        all.push(this.parsePNR(row));
      } else if (/\(.+?\)/.exec(row) != null && /\w+\s\s\-\s\s\w+/.exec(row) != null && /\d\d\:\d\d\s\-\s\d\d\:\d\d/.exec(row) != null) {
        all.push(this.parseTuiPreBookedFlights(row));
      } else if (row.indexOf('OPERATED BY') > -1) {
        operatedBy = row.replace('OPERATED BY', '').trim();
        operatedByArray.push({ 'indexNo': lastIndex, 'operatedByName': operatedBy });
      } else if (row.indexOf('/') > -1 && row.indexOf('.') > -1) {
        passengerArray.push(row);
      }
      //}
      return all;
    }, []);
    for (let prop of operatedByArray) {
      if (pnrArray[prop.indexNo - 1] != null) {
        pnrArray[prop.indexNo - 1].operatedBy = prop.operatedByName; // Set operatedBy property value!
      }
    }
    return { flightRows: pnrArray, passengerRows: passengerArray };
  };

  parsePNR(row) {
    let offset = 0;
    let extraNumberOffset = 0;
    let galileoDuoNumber = false;
    let extraFlightEnd = 0;

    const regex = /(\d+)(?<spaces>\s\s+)(\d+)/g;
    if (row.match(regex) != null) {
      const index = row.indexOf(row.match(regex)[0]);
      const galileoRegex2 = /([a-zA-Z])(?<spaces>\s\s\s+)(\d)/g;
      const matches2 = row.match(galileoRegex2);
      if (index > 20) {
        if (matches2 != null && matches2.length > 0) {
          if (row.indexOf(matches2[0]) < 10) {
            galileoDuoNumber = true;
          }
        }
        const spaces = row.slice(index).replace(/\s\s+/g, ' ');
        const start = row.slice(0, index).replace(/\s\s+/, ' ');
        row = start + spaces;
      } else {
        row = row.replace(/\s\s+/g, ' ');
      }
    } else {
      row = row.replace(/\s\s+/g, ' ');
    }
    let pnrType = 'default';
    let isNan = false;
    let isNanArrival = false;
    let isNanDepature = false;
    let isfallbackArrival = false;

    const hasTwoDigitNumber = /^(0|[1-9][0-9]*)$/.test(row.substring(0, 2)); // check if the first two digits are number
    if (hasTwoDigitNumber === true) {
      row = row.replace(row.substring(0, 2), '1');
    }

    if (row.substring(31, 32) === ' ') {
      offset = 2;
    } else if (row.indexOf(' . ') === 1) {
      pnrType = 'galileo';
      row = row.replace(' . ', ' ');
    } else if (row.substring(9, 10) === ' ' && row.length < 57) {
      pnrType = 'sabre';
      offset = 0;
    } else if (row.substring(16, 17) === ' ' && (row.substring(18, 19) === ' ' || row.substring(18, 19) === '#' || row.substring(18, 19) === '-' || row.substring(18, 19) === '*')) {
      offset = 2;
    }
    if (row[row.length - 1] === '*') {
      pnrType = 'tui';
    }
    // 1 . KL  809 N  29JUL AMSKUL HS8  2050  #1450  O         E MO/TU
    if (pnrType === 'default' && row.substring(0, 2).indexOf(' ') === -1) {
      extraNumberOffset = 1;
    }

    let extraGalileoOffset = 0;
    if (pnrType === 'galileo') {
      const galileoRegex = /([a-zA-Z])(?<spaces>\s\s+)(\d)/g;
      const matches = row.match(galileoRegex);
      if (matches != null) {
        const galileoRIndex = row.indexOf(matches[0]);

        const spaces = row.slice(galileoRIndex).replace(/\s\s+/g, ' ');
        const start = row.slice(0, galileoRIndex).replace(/\s\s+/, ' ');
        row = start + spaces;
      }

      const lastIndex = row.substring(extraNumberOffset + this.pnrMap[pnrType].flightnumber.start, extraNumberOffset + this.pnrMap[pnrType].flightnumber.end).lastIndexOf(' ');
      if (lastIndex !== 4) {
        extraGalileoOffset++;
      }
      if (galileoDuoNumber === true) {
        extraFlightEnd = -2
        extraGalileoOffset = -1
      }
    }
    let foundSpecialChar = '';
    let departureTime = row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureTime.start, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureTime.end);
    departureTime = departureTime.slice(0, 2) + ":" + departureTime.slice(2);
    let arrivalTime = row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalTime.start, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalTime.end);
    if (arrivalTime.indexOf('#') > -1 || arrivalTime.indexOf('-') > -1 || arrivalTime.indexOf('*') > -1) {
      foundSpecialChar = arrivalTime.slice(0, 1);
      arrivalTime = arrivalTime.replace('#', '');
      arrivalTime = arrivalTime.replace('-', '');
      arrivalTime = arrivalTime.replace('*', '');
    }
    arrivalTime = arrivalTime.slice(0, 2) + ":" + arrivalTime.slice(2);

    if (pnrType === 'tui') {
      if (departureTime[0] === ' ') {
        departureTime = row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureTime.start + 1, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureTime.end + 1);
        departureTime = departureTime.slice(0, 2) + ":" + departureTime.slice(2);
        arrivalTime = row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalTime.start + 1, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalTime.end + 1);
        arrivalTime = arrivalTime.slice(0, 2) + ":" + arrivalTime.slice(2);
      } else if (departureTime[3] === ' ') {
        departureTime = row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureTime.start - 2, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureTime.end - 2);
        departureTime = departureTime.slice(0, 2) + ":" + departureTime.slice(2);
        arrivalTime = row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalTime.start - 2, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalTime.end - 2);
        arrivalTime = arrivalTime.slice(0, 2) + ":" + arrivalTime.slice(2);
      }
    }

    let extraDay = false;
    const splittedTime = arrivalTime.split(':');
    if (splittedTime[1].length > 2) {
      if (Number(splittedTime[1][splittedTime[1].length - 1]) > 0) {
        extraDay = true;
      }
      arrivalTime = arrivalTime.slice(0, -1);
    }

    let today = new Date();
    let arrivalDate = new Date(today.getFullYear(), Number(this.monthsMap[row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.month.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.month.end).trim()]) - 1, Number(row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.day.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.day.end).trim()));
    arrivalDate.setFullYear(today.getFullYear());
    arrivalDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.month.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.month.end).trim()]) - 1);
    arrivalDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.day.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.day.end).trim()));
    let departureDate = new Date();
    departureDate.setFullYear(today.getFullYear());
    departureDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.month.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.month.end).trim()]) - 1);
    departureDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.day.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.day.end).trim()));
    if (isNaN(arrivalDate.getTime())) {
      switch (pnrType) {
        case 'default':
          isNanArrival = true;
          arrivalDate = new Date(today.getFullYear(), Number(this.monthsMap[row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackMonth.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackMonth.end).trim()]) - 1, Number(row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackDay.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackDay.end).trim()));
          arrivalDate.setFullYear(today.getFullYear());
          arrivalDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackMonth.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackMonth.end).trim()]) - 1);
          arrivalDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackDay.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackDay.end).trim()));
          break;
        case 'sabre':
          isfallbackArrival = true;
          arrivalDate = new Date(today.getFullYear(), Number(this.monthsMap[row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackMonth.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackMonth.end).trim()]) - 1, Number(row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackDay.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackDay.end).trim()));
          arrivalDate.setFullYear(today.getFullYear());
          arrivalDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackMonth.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackMonth.end).trim()]) - 1);
          arrivalDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackDay.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackDay.end).trim()));
          break;
      }
    }

    if (isNaN(arrivalDate.getTime())) {
      isNan = true;
      arrivalDate.setFullYear(today.getFullYear());
      arrivalDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.month.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.month.end).trim()]) - 1);
      arrivalDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.day.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.day.end).trim()));
    }

    if ((pnrType == 'sabre' || pnrType == 'default') && isNaN(departureDate.getTime())) {
      isNanDepature = true;
      departureDate.setFullYear(today.getFullYear());
      departureDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.month.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.month.end).trim()]) - 1);
      departureDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.day.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.day.end).trim()));
    }
    if (departureDate.getTime() <= today.getTime()) { // check if the departure date has passed this year, if so use next year
      departureDate.setFullYear(departureDate.getFullYear() + 1);
      arrivalDate.setFullYear(arrivalDate.getFullYear() + 1);
      if (isNanDepature === true) {
        departureDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.month.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.month.end).trim()]) - 1);
        departureDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.day.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackDepartureDate.day.end).trim()));
      } else {
        departureDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.month.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.month.end).trim()]) - 1);
        departureDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.day.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].departureDate.day.end).trim()));
      }

      if (isNan === true) {
        arrivalDate.setMonth(Number(this.monthsMap[row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.month.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.month.end).trim()]) - 1);
        arrivalDate.setDate(Number(row.substring(extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.day.start, extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.day.end).trim()));
      } else {
        if (isNanArrival === true) {
          arrivalDate.setMonth(Number(this.monthsMap[row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackMonth.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackMonth.end).trim()]) - 1);
          arrivalDate.setDate(Number(row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackDay.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.fallbackDay.end).trim()));
        } else {
          if (isfallbackArrival === true) {
            arrivalDate.setMonth(Number(this.monthsMap[row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackMonth.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackMonth.end).trim()]) - 1);
            arrivalDate.setDate(Number(row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackDay.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].fallbackArrivalDate.fallbackDay.end).trim()));

            // REMOVED below to fix an arrivalDate invalid date bug
            // } else {
            //   arrivalDate.setMonth(Number(this.monthsMap[row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.month.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.month.end).trim()]) - 1);
            //   arrivalDate.setDate(Number(row.substring(offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.day.start, offset + extraGalileoOffset + extraNumberOffset + this.pnrMap[pnrType].arrivalDate.day.end).trim()));
            // }
          }
        }
      }
    }

    if (row.indexOf('+1') > -1 || foundSpecialChar === '#' || extraDay === true) {
      arrivalDate.setDate(arrivalDate.getDate() + 1);
    }
    if (foundSpecialChar === '*') {
      arrivalDate.setDate(arrivalDate.getDate() + 2);
    }
    if (foundSpecialChar === '-') {
      arrivalDate.setDate(arrivalDate.getDate() - 1);
    }

    arrivalTime = arrivalTime.trim();

    var airlineCodeVal = row.substring(extraNumberOffset + this.pnrMap[pnrType].airlineCode.start, extraNumberOffset + this.pnrMap[pnrType].airlineCode.end).trim();
    var flightNumberVal = Number(row.substring(extraNumberOffset + this.pnrMap[pnrType].flightnumber.start, extraFlightEnd + extraNumberOffset + this.pnrMap[pnrType].flightnumber.end).trim());
    switch (pnrType) {
      case 'default':
        if (isNaN(flightNumberVal)) flightNumberVal = row.substring(extraNumberOffset + this.pnrMap[pnrType].fallbackFlightnumber.start, extraNumberOffset + this.pnrMap[pnrType].fallbackFlightnumber.end).trim();
        break;
      case 'sabre':
        if (airlineCodeVal.length != 2) airlineCodeVal = row.substring(extraNumberOffset + this.pnrMap[pnrType].fallbackAirlineCode.start, extraNumberOffset + this.pnrMap[pnrType].fallbackAirlineCode.end).trim();
        if (isNaN(flightNumberVal)) flightNumberVal = row.substring(extraNumberOffset + this.pnrMap[pnrType].fallbackFlightnumber.start, extraNumberOffset + this.pnrMap[pnrType].fallbackFlightnumber.end).trim();
        break;
    }

    const obj = {
      'airlineCode': airlineCodeVal,
      'flightnumber': flightNumberVal,
      'class': row.substring(extraNumberOffset + this.pnrMap[pnrType].class.start, extraNumberOffset + this.pnrMap[pnrType].class.end),
      'plane': null,
      'fare_basis': null,
      'ticket_designator': null,
      'stops': null,
      'flighttime': null,
      'departure': {
        'airport': row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureAirport.start, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].departureAirport.end),
        'date': departureDate,
        'time': departureTime
      },
      'arrival': {
        'airport': row.substring(extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalAirport.start, extraGalileoOffset + extraNumberOffset + offset + this.pnrMap[pnrType].arrivalAirport.end),
        'date': arrivalDate,
        'time': arrivalTime
      },
      'operatedBy': null
    };
    return obj;
  }

  parseSegmentDataSABRE($line) {
    // handle general information
    let cmatches = $line.match(/[^\d+]\w+/);
    let carriercode = cmatches[0].trim();
    let specialCase = false;
    let flightnumber = null;
    let flightclass = null;
    let fmatches = null;
    let clmatches = null;
    if (carriercode.length > 2) {
      carriercode = carriercode.substr(0, 2);
      flightnumber = cmatches[0].trim().replace(carriercode, '');
      flightnumber = flightnumber.trim();
      flightclass = cmatches[0].replace(carriercode + flightnumber, '');
      specialCase = true;
    } else {
      fmatches = $line.match(/[^\d+]\d{2,3}/);
      flightnumber = fmatches[0].trim();
      clmatches = $line.match(/[^\d+]\d{2,3}.{1}/);
      flightclass = flightnumber.replace('', clmatches[0]);
    }
    let frmatches = $line.match(/\/.{4}\*\w+/);
    let fare_basis = null;
    let ticketdesignator = null;
    let fareBasisArr = null;
    if (frmatches != null) {
      fareBasisArr = frmatches[0].split('*');
      fare_basis = fareBasisArr[1];
      ticketdesignator = fareBasisArr[0].replace("/", '');
    }
    let tempArr = $line.split(' ');
    let wordsArr = [];
    for (let ta of tempArr) {
      ta = ta.trim();
      if (ta != null && ta != '') {
        wordsArr.push(ta);
      }
    }
    let txt_src_airport = (specialCase == true) ? wordsArr[4] : wordsArr[5];
    let $src_airport = null;
    let dst_airport = null;
    if (txt_src_airport != null) {
      $src_airport = txt_src_airport.substring(0, 3);
      dst_airport = txt_src_airport.replace($src_airport, '');
      dst_airport = dst_airport.substr(0, 3);
    }

    // handle date information
    let date_tmp = (specialCase == true) ? wordsArr[2] : wordsArr[3];
    let day = null;
    let month = null;
    let year = null;
    let src_date = null;
    if (date_tmp != null && date_tmp != '') {
      day = date_tmp.substr(0, 2);
      month = this.monthsMap[date_tmp.substr(2, 3)];
      year = this.today.getFullYear();
      if (new Date(year, month, day).getTime() / 1000 < new Date().getTime()) year++;
      src_date = year + '-' + month + '-' + day;
    }
    let dmatches = $line.match(/[^\d+]\d{2}\w{3}/g);
    date_tmp = dmatches != null && dmatches[0] != null && dmatches[0][1] != null ? dmatches[0][1].trim() : '';
    if (isNaN(date_tmp.substr(0, 2))) {
      date_tmp = null;
    }
    let dst_date = null;
    if (date_tmp != null && date_tmp != '') {
      dst_date = src_date;
    } else {
      day = date_tmp.substr(0, 2);
      month = this.monthsMap[date_tmp.substr(2, 3)];
      year = this.today.getFullYear();
      if (new Date(year, month, day).getTime() / 1000 < new Date().getTime()) year++;
      dst_date = year + '-' + month + '-' + day;
    }

    // handle time information
    let tmatches = $line.match(/[^\s]?\b\d{4}[^\s]?/g);
    let time_tmp = null;
    let src_time = null;
    let hours = null;
    let mins = null;
    if (tmatches != null && tmatches[0] != null && tmatches[0] != '') {
      tmatches[0] = tmatches[0].trim();
      time_tmp = tmatches[0];
      hours = time_tmp.substr(0, 2);
      mins = time_tmp.substr(2, 2);
      if (time_tmp[4] == 'A' && hours == 12) hours = 0;
      if (time_tmp[4] == 'P' && hours != 12) hours += 12;
      src_time = hours + ':' + mins;
    }

    let dst_time = null;
    if (tmatches != null && tmatches[1] != null && tmatches[1] != '') {
      tmatches[1] = tmatches[1].trim();
      time_tmp = tmatches[1];
      hours = time_tmp.substr(0, 2);
      mins = time_tmp.substr(2, 2);
      if (time_tmp[4] == 'A' && hours == 12) hours = 0;
      if (time_tmp[4] == 'P' && hours != 12) hours += 12;
      dst_time = hours + ':' + mins;
    }

    let plane = '';
    let stops = '';
    let flighttime = '';

    return {
      'carriercode': carriercode,
      'flightnumber': flightnumber,
      'class': flightclass,
      'plane': plane,
      'fare_basis': fare_basis,
      'ticket_designator': ticketdesignator,
      'stops': stops,
      'flighttime': flighttime,
      'departure': {
        'airport': $src_airport,
        'date': src_date,
        'time': src_time
      },
      'arrival': {
        'airport': dst_airport,
        'date': dst_date,
        'time': dst_time
      }
    };
  }

  parseSegmentDataTAIR(line) {
    let parts = line.split(' ');
    let carriercode = parts[2].substring(2, 2);
    let flightnumber = parts[2].substring(4);
    let flightclass = parts[3];
    let src_airport = parts[4].substring(0, 3);
    let src_date = null;
    let date_tmp = null;
    let day = null;
    let month = null;
    let year = this.today.getFullYear();
    if (parts[4][4] != '*') {
      date_tmp = parts[4].substring(3, 5);
      day = date_tmp.substring(0, 2);
      month = this.monthsMap[date_tmp.substring(2, 3)];
      if (new Date(year, month, day).getTime() / 1000 < new Date().valueOf()) { year++; }
      src_date = year + '-' + month + '-' + day;
    }
    let timeoffs = (src_date === null) ? 5 : 8;
    let src_time = null;
    let time_tmp = null;
    let hours = null;
    let mins = null;
    if (parts[4][timeoffs] != '*') {
      time_tmp = parts[4].substring(timeoffs);
      hours = time_tmp.substring(0, 2);
      mins = time_tmp.substring(2, 2);
      if (time_tmp[4] == 'A' && hours == 12) hours = 0;
      if (time_tmp[4] == 'P' && hours != 12) hours += 12;
      src_time = hours + ':' + mins;
    }
    let dst_airport = parts[5].substring(0, 3);
    let dst_date = null;
    if (parts[5][4] != '*') {
      date_tmp = parts[5].substring(3, 5);
      day = date_tmp.substring(0, 2);
      month = this.monthsMap[date_tmp.substring(2, 3)];
      if (new Date(year, month, day).getTime() / 1000 < new Date().valueOf()) { year++; }
      dst_date = year + '-' + month + '-' + day;
    }
    timeoffs = (src_date === null) ? 5 : 8;
    let dst_time = null;
    if (parts[5][timeoffs] != '*') {
      time_tmp = parts[5].substring(timeoffs);
      hours = time_tmp.substring(0, 2);
      mins = time_tmp.substring(2, 2);
      if (time_tmp[4] == 'A' && hours == 12) hours = 0;
      if (time_tmp[4] == 'P' && hours != 12) hours += 12;
      dst_time = hours + ':' + mins;
    }
    let plane = parts[10];
    let tmp = parts[11].split('/');
    let fare_basis = tmp[0];
    let ticketdesignator = tmp[1];
    let stops = parts[13];
    let flighttime = null;
    if (parts[14] != null) {
      flighttime = parts[14].replace('.', ':');
    }
    return {
      'carriercode': carriercode,
      'flightnumber': flightnumber,
      'class': flightclass,
      'plane': plane,
      'fare_basis': fare_basis,
      'ticket_designator': ticketdesignator,
      'stops': stops,
      'flighttime': flighttime,
      'departure': {
        'airport': src_airport,
        'date': src_date,
        'time': src_time
      },
      'arrival': {
        'airport': dst_airport,
        'date': dst_date,
        'time': dst_time
      }
    };
  };

  parseTuiPreBookedFlights(row) {
    row = row.replace(/\s\s+/g, ' ');

    // Below regex replace is to replace all ctrl characters to spaces
    row = row.replace(/	/g, ' ');

    const airlineCode = row.slice(row.indexOf('(') + 1, row.indexOf(')'));
    row = row.slice(row.indexOf(')') + 2);
    let operatedBy = null;
    if (row[0] === '(') {
      const operatedByString = row.slice(row.indexOf('(') + 1, row.indexOf(')'));
      if (operatedByString.indexOf('by') > -1) {
        operatedBy = operatedByString.replace('by ', '');
      }
      row = row.slice(row.indexOf(')') + 2);
    }
    const flightNumber = row.slice(0, row.indexOf(' '));
    row = row.slice(row.indexOf(' ') + 1);

    const classVal = row.slice(0, row.indexOf(' '));
    row = row.slice(row.indexOf(' ') + 5);

    const departureAirport = row.slice(0, row.indexOf(' '));
    row = row.slice(row.indexOf(' ') + 3);

    const arrivalAirport = row.slice(0, row.indexOf(' '));
    row = row.slice(row.search(/\d\d\.\d\d/));

    const departureDate = new Date();
    const date = row.slice(0, row.indexOf('.'));
    row = row.slice(row.indexOf('.') + 1);
    departureDate.setMonth(Number(row.slice(0, row.indexOf('.'))) - 1);
    departureDate.setDate(date);
    row = row.slice(row.indexOf(' ') + 1);

    if (departureDate.getTime() <= new Date().getTime()) {
      departureDate.setFullYear(departureDate.getFullYear() + 1);
    }

    const departureTime = row.slice(0, row.indexOf(' '));
    row = row.slice(row.indexOf(' ') + 3);

    let extraDay = false;
    let arrivalTime = row.slice(0, row.indexOf(' '));
    if (arrivalTime.indexOf('+1') > -1) {
      extraDay = true;
      arrivalTime = arrivalTime.replace('+1', '')
    }

    row = row.slice(row.indexOf(' ') + 1);

    const arrivalDate = new Date(departureDate);
    if (extraDay === true) {
      arrivalDate.setDate(arrivalDate.getDate() + 1);
    }

    let flightTime = null;

    if (row.indexOf(':') > -1) {
      flightTime = row.slice(0, row.indexOf(' '));
    }

    const obj = {
      'airlineCode': airlineCode,
      'flightnumber': flightNumber,
      'class': classVal,
      'stops': null,
      'duration': flightTime,
      'departure': {
        'airport': departureAirport,
        'date': departureDate,
        'time': departureTime
      },
      'arrival': {
        'airport': arrivalAirport,
        'date': arrivalDate,
        'time': arrivalTime
      },
      'operatedBy': operatedBy
    };
    return obj;
  }
}
