import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const LIBTEXTS_TYPES_UPDATE = 'LIBTEXTS_TYPES_UPDATE';
export const LIBTEXTS_TYPES_ITEMS_UPDATE = 'LIBTEXTS_TYPES_ITEMS_UPDATE';
const logService = new LogService();

export function libtextsReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {
    case LIBTEXTS_TYPES_UPDATE:
    state = action.payload;
    logService.consoleLog('LIBTEXTS_TYPES_UPDATE:',state);
    return state;

    case LIBTEXTS_TYPES_ITEMS_UPDATE:
    for(let type of state){
      if(type.id === action.payload.typeId){
        type.items = action.payload.items;
      }
    }
    logService.consoleLog('LIBTEXTS_TYPES_ITEMS_UPDATE:',state);
    return [].concat(state);

    default:
    return state;
  }
}
