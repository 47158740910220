import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { PageScrollService } from 'ngx-page-scroll-core';
import { take } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { Store } from '@ngrx/store';
import { Config } from './config.class';
import { PricesParticipantsSalesTotalDialogComponent } from '../components/prices-participants-sales-total-dialog/prices-participants-sales-total-dialog.component';
import { SegmentLinkComponent } from '../components/segment-link/segment-link.component';
import { MarginEditDialogComponent } from '../components/margin-edit-dialog/margin-edit-dialog.component';
import { ExpiredDialogComponent } from '../components/expired-dialog/expired-dialog.component';
import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';
import { AppState } from '../models/app-state';
import { DayCalculationService } from '../services/day-calculation.service';
import { AuthService, isGlobal } from '../services/auth.service';
import { TelespiritService } from '../services/telespirit.service';
import { HelpersService } from '../services/helpers.service';

export class PriceCalculationClass {
    rowData: any[];
    dataObj: any;
    gridApi: any;
    gridColumnApi: any;
    gridOptions: any = {
        suppressScrollOnNewData: true
    };
    columnDefs: any = [];
    itineraryData: any;
    unitsData: any;
    roundingData: any;
    isTokenValid: boolean;
    i: number = 0;
    columnDefsObj: any;
    params: any;
    participants: Array<any> = [];
    bottomRowData: Array<any> = [];
    notShownElements: Array<any> = [];
    counter: any;
    costCurrency = 'EUR';
    salesCurrency = 'EUR';
    rowClassRules: any;
    rowSelection = 'single';
    currenciesValue: any;
    recalc: boolean = false;
    firstTime = true;
    firstTimePricesCheck = true;
    triggerValue = '';

    env: any;
    isGlobalValue = isGlobal;

    components: any = {
        PricesCheckboxComponent: null,
        PricesCurrencySelectComponent: null,
        PricesSupplierSelectComponent: null,
        MarginLockComponent: null,
        GridNumberCellComponent: null,
        InternalInfoIconComponent: null,
        InsuranceLockComponent: null
    }

    totalMargin: any;
    roundId: number = 9999;
    advancedMode: boolean = false;
    showGrid: boolean = true;
    visibleIndex: number;

    nonLockedFound = false;
    showOptionals = true;

    rounded = false;

    constructor(
        public matDialog: MatDialog,
        public auth: AuthService,
        public teleService: TelespiritService,
        public helpersService: HelpersService,
        public notificationsService: NotificationsService,
        public dayCalculationService: DayCalculationService,
        public config: Config,
        public store: Store<AppState>,
        private pageScrollService: PageScrollService,
        private router: Router,
        @Inject(DOCUMENT) private document: any
    ) {
        this.env = this.config;
        this.rowClassRules = {
            'not-calculated': function (params) {
                if (((params.context.isGlobalValue === false || (params.context.isGlobalValue === true && params.context.env.tsToken != null)) || (params.data.element && params.data.element.dataSource && params.data.element.dataSource === 'desty')) && params.data.extra && params.data.extra.background === 'orange' && params.data.Enabled === false) {
                    return true;
                }
                return false;
            },
            'calculated': function (params) {
                if (params.data.extra && params.data.extra.background === 'white' && params.data.Enabled === false) {
                    return true;
                }
                return false;
            },
            'grey-text': function (params) {
                if (params.data.extra && params.data.extra.optional === true) {
                    return true;
                }
                return false;
            },
            'error': function (params) {
                if (((params.context.isGlobalValue === false || (params.context.isGlobalValue === true && params.context.env.tsToken != null)) || (params.data.element && params.data.element.dataSource && params.data.element.dataSource === 'desty')) && (((params.data.extra && params.data.extra.error === true) || (params.data.extra && params.data.extra.errorMessage && params.data.extra.errorMessage.indexOf('amount is 0') > -1)) && params.data.Enabled === false)) {
                    return true;
                }
                return false;
            },
            'rounding': function (params) {
                if (params.data.extra && params.data.extra.unit === 13) {
                    return true;
                }
                return false;
            }
        };
    }

    valueSetter(params) {
        params.data.oldValue = params.oldValue
        const regex = new RegExp('\,');
        if (String(params.newValue).match(regex)) {
            params.newValue = params.newValue.replace(/\,/g, '.');
        }
        if (params.colDef != null && params.colDef.field != null && params.colDef.field.toLowerCase() !== 'cur' && params.colDef.field.toLowerCase() !== 'supplier') {
            if (isNaN(Number(params.newValue))) {
                if (isNaN(Number(params.newValue.toLowerCase().replace('p', '').replace('u', '')))) {
                    if (params.context != null) {
                        params.context.notificationsService.warn('Warning', "Invalid input, reverted to previous value", {
                            timeOut: 5000
                        });
                    }
                    return;
                }
            }
        }
        if (params.colDef.field.indexOf('Cost') > -1 || params.colDef.field.indexOf('Cur') > -1 || params.colDef.field.indexOf('Exch Rate') > -1 || params.colDef.field.indexOf('Supplier') > -1) {
            if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                return false;
            }
        }
        if (params.colDef.field === 'Cost' || params.colDef.field === 'Cost Per Unit' || params.colDef.field === 'Cost EUR' || params.colDef.field === 'Cost EUR Per Unit' || params.colDef.field === 'Sales EUR') {
            if (params.newValue && params.newValue.length) {
                if (params.newValue.toLowerCase().indexOf('u') > 0 && params.newValue.toLowerCase().indexOf('p') > 0) {
                    const price = params.newValue.toLowerCase().replace('p', '').replace('u', '');
                    if (Number(price) > 0 && params.data.element && params.data.element.unitAmount && params.data.element.olPrices && params.data.element.olPrices.participants && Object.keys(params.data.element.olPrices.participants)) {
                        params.data[params.colDef.field] = Number(price) * Number(params.data.element.unitAmount) * Object.keys(params.data.element.olPrices.participants).length;
                        return true;
                    }
                } else if (params.newValue.toLowerCase().indexOf('p') > 0) {
                    const price = params.newValue.toLowerCase().replace('p', '');
                    if (Number(price) > 0 && params.data.element && params.data.element.olPrices && params.data.element.olPrices.participants && Object.keys(params.data.element.olPrices.participants)) {
                        params.data[params.colDef.field] = Number(price) * Object.keys(params.data.element.olPrices.participants).length;
                        return true;
                    }
                } else if (params.newValue.toLowerCase().indexOf('u') > 0) {
                    const price = params.newValue.toLowerCase().replace('u', '');
                    if (Number(price) > 0 && params.data.element && params.data.element.unitAmount) {
                        params.data[params.colDef.field] = Number(price) * Number(params.data.element.unitAmount);
                        return true;
                    }
                }
            }
        }
        if (params.colDef.field === 'Exch Rate') {
            if (params.data['Cur'] === 'EUR') {
                params.data[params.colDef.field] = 1;
                return true;
            }
            if (params.newValue <= 0) {
                params.data[params.colDef.field] = 1;
                return true;
            }
        }
        if (params.colDef.field === 'Margin %') {
            if (params.newValue > 99.99999) {
                params.data[params.colDef.field] = 99.99999;
                return true;
            } else if (!Array.isArray(params.newValue)) { // https://github.com/ReactiveX/rxjs/blob/master/src/internal/util/isNumeric.ts
                params.data[params.colDef.field] = Number(params.newValue).toFixed(5);
                return true;
            }
        }
        if (params.oldValue !== params.newValue) {
            params.data[params.colDef.field] = params.newValue;
            return true;
        }
        return false;
    }

    generateColumnDefsObjAndRowData(PricesCheckboxComponent, PricesCurrencySelectComponent, PricesSupplierSelectComponent, PricesSubtitleComponent, MarginLockComponent, InsuranceLockComponent, GridNumberCellComponent, InternalInfoIconComponent, optionalElements = true) {
        this.rowData = [];
        this.notShownElements = [];
        let index = 0;
        this.showOptionals = optionalElements;
        new Promise<void>(resolve => {
            for (const { segment, segmentIndex } of this.itineraryData.data.segments.map((segment, segmentIndex) => ({ segment, segmentIndex }))) {
                if (segment != null) {
                    for (let element of segment.elements || []) {
                        if (element.optional === true && this.showOptionals === false) {
                            continue;
                        }
                        if (this.unitsData) {
                            if (element != null && element.olPrices) {
                                let productId = null;
                                if (element.TSProduct && element.TSProduct.id) {
                                    productId = element.TSProduct.id;
                                } else {
                                    productId = null;
                                }
                                if (element.unitId > 0 && this.unitsData.items[element.unitId] && this.unitsData.items[element.unitId].id === element.unitId) {
                                    if (this.helpersService.isText(this.unitsData.items[element.unitId]) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal && (element.olPrices.costPrice > 0 || element.olPrices.salesTotal > 0))) {
                                        this.generateRowData(element.vtbObjectId, segmentIndex, element.title, element.subTitle, element.optional, element.olPrices, index, element, this.itineraryData.data.participants, PricesCheckboxComponent, PricesCurrencySelectComponent, PricesSupplierSelectComponent, PricesSubtitleComponent, MarginLockComponent, InsuranceLockComponent, GridNumberCellComponent, productId, InternalInfoIconComponent);
                                        index++;
                                    } else {
                                        element.not_calculated = false;
                                    }
                                } else if (element.unitId > 0) {  // else is needed for if the units are scrambled and not sorted on id.
                                    for (const unit of this.unitsData.items) {
                                        if (unit.id === element.unitId && (this.helpersService.isText(unit) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal && (element.olPrices.costPrice > 0 || element.olPrices.salesTotal > 0)))) {
                                            this.generateRowData(element.vtbObjectId, segmentIndex, element.title, element.subTitle, element.optional, element.olPrices, index, element, this.itineraryData.data.participants, PricesCheckboxComponent, PricesCurrencySelectComponent, PricesSupplierSelectComponent, PricesSubtitleComponent, MarginLockComponent, InsuranceLockComponent, GridNumberCellComponent, productId, InternalInfoIconComponent);
                                            index++;
                                        } else {
                                            element.not_calculated = false;
                                        }
                                    }
                                } else {
                                    this.notShownElements.push(element);
                                }
                            }
                        }
                    }
                }
            }
            resolve();
        }).then(() => {
            this.columnDefsObj = this.columnDefs.reduce((all, item) => {
                all[item.field] = item;
                return all;
            }, {});
            const roundingObj = this.setRounding(this.itineraryData, this['agGrid']);
            if (roundingObj) {
                for (let row of this.rowData) {
                    if (row.extra.id === roundingObj.roundingElement.vtbObjectId) {
                        row['Sales ' + this.salesCurrency] = roundingObj.rounding.discount;
                    }
                }
            }
            this.setTotals(roundingObj);
            if (this.firstTime === true) {
                this.firstTime = false;
                if (this.notShownElements.length > 0) {
                    if (this.notShownElements.length > 1) {
                        this.notificationsService.warn('Warning', this.notShownElements.length + " Elements have no unit and will not be shown", {
                            timeOut: 5000
                        });
                    } else {
                        this.notificationsService.warn('Warning', this.notShownElements.length + " Element has no unit and will not be shown", {
                            timeOut: 5000
                        });
                    }
                }
            }
        });
    }

    onCellValueChanged(params: any) {
        params.saveTrigger = true;
        const matches = params.colDef.field.match(/\(.+?\)/);
        if (matches && matches[0]) {
            const participant = this.getParticipant(matches[0].replace('(', '').replace(')', ''));
            if (this.columnDefsObj[participant.name + ' ' + participant.surname + ' (' + matches[0].replace('(', '').replace(')', '') + ')']) {
                for (const child of this.columnDefsObj[participant.name + ' ' + participant.surname + ' (' + matches[0].replace('(', '').replace(')', '') + ')'].children) {
                    if (child.field === matches.input) {
                        const rowNode = this.gridApi.getRowNode(params.rowIndex);
                        for (const method of child.execute) {
                            method.call(this, params, rowNode);
                        }
                    }
                }
            }
            if (params.colDef.field !== 'Subtitle') {
                this.saveChangesToObject(params);
            }
        } else {
            if (params.colDef && params.colDef.field && this.columnDefsObj && this.columnDefsObj[params.colDef.field] && this.columnDefsObj[params.colDef.field].execute) {
                const rowNode = this.gridApi.getRowNode(params.rowIndex);
                for (const method of this.columnDefsObj[params.colDef.field].execute) {
                    method.call(this, params, rowNode);
                }
            }
            if (params.colDef.field !== 'Subtitle') {
                this.saveChangesToObject(params);
            }
        }
    }

    widthSetter(context, checkParticipants = false) {
        let columnVisibility = this.config != null && this.config.gridViewSettings != null && this.config.gridViewSettings.columnVisibility != null ? this.config.gridViewSettings.columnVisibility : null;
        let participantGroup = false;
        let showParticipantsAlways = false;
        if (columnVisibility != null) {
            if (columnVisibility.hasOwnProperty('Cost Participants') === true) {
                if (columnVisibility['Cost Participants'] === 'always') {
                    showParticipantsAlways = true;
                }
                if (context.field.indexOf('Cost (') > -1) {
                    columnVisibility[context.field] = columnVisibility['Cost Participants'];
                } else if (checkParticipants === true && context.field.search(/\(\d*?\)/) > -1) {
                    participantGroup = true;
                }
            }
            if (columnVisibility.hasOwnProperty('Cost ' + this.salesCurrency + ' Participants') === true) {
                if (columnVisibility['Cost ' + this.salesCurrency + ' Participants'] === 'always') {
                    showParticipantsAlways = true;
                }
                if (context.field.indexOf('Cost ' + this.salesCurrency + ' (') > -1) {
                    columnVisibility[context.field] = columnVisibility['Cost ' + this.salesCurrency + ' Participants'];
                } else if (checkParticipants === true && context.field.search(/\(\d*?\)/) > -1) {
                    participantGroup = true;
                }
            }
            if (columnVisibility.hasOwnProperty('Sales ' + this.salesCurrency + ' Participants') === true) {
                if (columnVisibility['Sales ' + this.salesCurrency + ' Participants'] === 'always') {
                    showParticipantsAlways = true;
                }
                if (context.field.indexOf('Sales ' + this.salesCurrency + ' (') > -1) {
                    columnVisibility[context.field] = columnVisibility['Sales ' + this.salesCurrency + ' Participants'];
                } else if (checkParticipants === true && context.field.search(/\(\d*?\)/) > -1) {
                    participantGroup = true;
                }
            }
            if (columnVisibility.hasOwnProperty('Cost Participants') === false || columnVisibility.hasOwnProperty('Cost ' + this.salesCurrency + ' Participants') === false || columnVisibility.hasOwnProperty('Sales ' + this.salesCurrency + ' Participants') === false) {
                if (checkParticipants === true && context.field.search(/\(\d*?\)/) > -1) {
                    showParticipantsAlways = true
                    participantGroup = true;
                }
            }
        } else {
            if (checkParticipants === true && context.field.search(/\(\d*?\)/) > -1) {
                showParticipantsAlways = true
                participantGroup = true;
            }
        }
        if (this.isGlobalValue === true && this.config.tsToken == null && context.field.indexOf('Supplier') > -1) {
            return 0.00000000000001;
        }
        if (participantGroup && (showParticipantsAlways === true || this.advancedMode === true)) {
            return 20;
        }
        if (columnVisibility === null || columnVisibility.hasOwnProperty(context.field) === false || columnVisibility[context.field] === 'always') {
            return context.vtbBaseWidth;
        } else if (columnVisibility[context.field] === 'advanced' && this.advancedMode === true) {
            return context.vtbBaseWidth;
        } else {
            return 0.00000000000001;
        }
    }

    generateRowData(id, segmentIndex, title, subTitle, optional, prices, index, element, participants, PricesCheckboxComponent = null, PricesCurrencySelectComponent = null, PricesSupplierSelectComponent = null, PricesSubtitleComponent = null, MarginLockComponent = null, InsuranceLockComponent = null, GridNumberCellComponent = null, productId = null, InternalInfoIconComponent = null) {
        const _self = this;
        let cost;
        let exchangeRate = 1;
        let exchangeDate: any = new Date();
        let disable = false;

        if (element.TSProduct && element.TSProduct.disabledForPurchase) {
            disable = element.TSProduct.disabledForPurchase;
        }

        if (prices.salesCurrency != null) {
            this.salesCurrency = String(prices.salesCurrency);
        } else {
            if (this.config != null && this.config.defaultSalesCurrency != null) {
                this.salesCurrency = this.config.defaultSalesCurrency;
            }
        }
        if (prices.purchaseCurrency != null) {
            this.costCurrency = String(prices.purchaseCurrency);
        }
        if (this.salesCurrency === this.costCurrency) {
            if (element.newElement == null || element.newElement === false) {
                if (prices.exchangeRate != null) {
                    exchangeRate = Number(prices.exchangeRate);
                }
            }
        } else {
            if (prices.exchangeRate) {
                exchangeRate = Number(prices.exchangeRate)
            } else {
                if (this.currenciesValue && this.currenciesValue.itemsByCode) {
                    const sourceCurrency = this.currenciesValue.itemsByCode[this.costCurrency];
                    const targetCurrency = this.currenciesValue.itemsByCode[this.salesCurrency];

                    if (this.currenciesValue.orderlinePurchaseExchangeDate === 'order_startdate') {
                        exchangeDate = new Date(this.itineraryData.data.startDate);
                    }
                    exchangeDate = Number(exchangeDate.getFullYear() + this.helpersService.formatTrailingZeros(exchangeDate.getMonth() + 1, 2) + this.helpersService.formatTrailingZeros(exchangeDate.getDate(), 2));
                    const exchangeObj = this.currenciesValue.exchangeRates.find(exchangeRate => sourceCurrency.id === exchangeRate.currency_id_one && targetCurrency.id === exchangeRate.currency_id_two && exchangeDate >= exchangeRate.rate_start_date && exchangeDate <= exchangeRate.rate_end_date);
                    if (exchangeObj != null) {
                        exchangeRate = exchangeObj.rate;
                    }
                } else {
                    exchangeRate = 1;
                }
                // element.newElement = false;
            }
        }
        for (const row of this.columnDefs) {
            row.editable = ((params) => {
                if (params.data.Title === 'Totals:') {
                    return false;
                } else if (params.colDef.field === 'enabled') {
                    return false;
                } else if (params.data[row.field] != null) {
                    return true;
                }
                return false;
            });
        }
        this.i++;
        if (prices.purchaseCurrency === 'EUR') {
            if (prices.costPrice != null) {
                cost = Number(prices.costPrice).toFixed(3)
            } else {
                cost = Number(0).toFixed(3);
            }
        } else {
            if (prices.costPrice == null || prices.exchangeRate == null) {
                if (prices.costPrice != null && exchangeRate != null) {
                    cost = Number(prices.costPrice / exchangeRate).toFixed(3);
                } else {
                    cost = Number(0).toFixed(3);
                }
            } else {
                cost = Number(prices.costPrice / prices.exchangeRate).toFixed(3);
            }
        }

        if (element.marginType != null && element.marginType === 'raise_pp') {
            if (element.olPrices != null && element.olPrices.participants != null) {
                cost = Number(Number(cost) * Object.keys(element.olPrices.participants).length).toFixed(3);
            }
            element.not_calculated = false;
        }

        let extra = {
            'id': id,
            'costPriceCurrency': this.costCurrency,
            'salesPriceCurrency': this.costCurrency,
            'segmentIndex': segmentIndex,
            'optional': optional || false,
            'unit': element.unitId,
            'lastRecalc': null,
            'background': null,
            'error': null,
            'errorParams': null,
            'errorMessage': null,
            'disabledForPurchase': disable,
            'language': this.itineraryData.data.TSOrder.language || null
        };

        this.dataObj = {
            'extra': extra,
            'Product Id': productId,
            'Title': title || '',
            'Subtitle': subTitle || '',
            'Cost': cost,
            'Cur': this.costCurrency,
            'Supplier': disable === true ? 'none' : (element.supplierName === ' [#000000]' ? 'none' : element.supplierName || ''),
            'Suppliers': disable === true ? null : element.arrSuppliers || [],
            'SupplierId': disable === true ? null : element.supplierId || null,
            'Exch Rate': Number(exchangeRate).toFixed(9)
        };

        if (this.config.gridViewSettings != null && this.config.gridViewSettings.extra != null && this.config.gridViewSettings.extra.headerName != null) {
            this.dataObj[this.config.gridViewSettings.extra.headerName] = prices[this.config.gridViewSettings.extra.variableName] || '';
        }

        let found = this.columnDefs.find(x => ['Cost ' + this.salesCurrency, 'Margin %', 'Sales ' + this.salesCurrency].indexOf(x.field) > -1);
        if (!found) {
            this.columnDefs.push(
                {
                    field: 'extra',
                    headerClass: 'no-padding-header',
                    cellClass: 'no-padding-header',
                    pinned: 'left',
                    vtbBaseWidth: 1,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    headerName: '',
                    field: 'InternalInfoIcon',
                    editable: false,
                    pinned: 'left',
                    cellRendererFramework: InternalInfoIconComponent,
                    cellStyle: { 'padding-left': 0, 'padding-right': 0 },
                    vtbBaseWidth: 40,
                    height: 100,
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    headerName: '',
                    field: 'Enabled',
                    pinned: 'left',
                    cellRendererFramework: PricesCheckboxComponent,
                    cellStyle: { 'padding-left': 0, 'padding-right': 0 },
                    vtbBaseWidth: 50,
                    height: 100,
                    execute: [this.setTriggerValue, this.changeEnabled],
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    headerName: '',
                    field: 'Suppliers',
                    pinned: 'left',
                    cellRendererFramework: PricesSupplierSelectComponent,
                    cellStyle: { 'padding-left': 0, 'padding-right': 0 },
                    vtbBaseWidth: 0.00000000000001,
                    execute: [this.setTriggerValue, this.execute],
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    headerName: '',
                    field: 'SupplierId',
                    pinned: 'left',
                    cellRendererFramework: PricesSupplierSelectComponent,
                    cellStyle: { 'padding-left': 0, 'padding-right': 0 },
                    vtbBaseWidth: 0.00000000000001,
                    execute: [this.setTriggerValue, this.execute],
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    headerName: '',
                    field: 'Link',
                    pinned: 'left',
                    cellRendererFramework: SegmentLinkComponent,
                    cellStyle: { 'padding-left': 0, 'padding-right': 0 },
                    vtbBaseWidth: 40,
                    height: 100,
                    execute: [this.scrollToSegment],
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    }
                },
                {
                    field: 'Title',
                    editable: true,
                    pinned: 'left',
                    vtbBaseWidth: 250,
                    execute: [this.setTriggerValue, this.execute],
                    headerTooltip: 'Title',
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Subtitle',
                    editable: false,
                    vtbBaseWidth: 200,
                    cellRendererFramework: PricesSubtitleComponent,
                    execute: [this.setTriggerValue, this.checkOldValue, this.execute, this.saveChangesToObject],
                    context: this,
                    headerTooltip: 'Subtitle',
                    cellStyle: function (params) {
                        if (params.data != null && params.data.element != null && params.data.element.subtitleChanged === true) {
                            return { 'background-color': 'lightblue' };
                        }
                    },
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Cost Per Unit',
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    vtbBaseWidth: 100,
                    execute: [this.setTriggerValue, this.updateCostByUnit, this.recalculateCostCurPrice, this.updateCostPrices, this.updateCostCurPrices, this.recalculateSalesPriceTotal, this.updateSalesPrices, this.saveChangesToObject],
                    cellStyle: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return { 'text-align': 'left', 'color': 'gray', 'background-color': '#dddddd' };
                        } else {
                            return { 'text-align': 'left' };
                        }
                    },
                    tooltipValueGetter: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return 'Purchase is disabled for this product in Back Office, so it is not possible to add a costprice to this element';
                        } else {
                            return null;
                        }
                    },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Cost Per Unit',
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Cost',
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    vtbBaseWidth: 100,
                    execute: [this.setTriggerValue, this.changeCostPrice, this.updateCostPrices, this.updateCostCurPrices, this.recalculateSalesPriceTotal, this.updateSalesPrices, this.saveChangesToObject],
                    cellStyle: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return { 'text-align': 'left', 'color': 'gray', 'background-color': '#dddddd' };
                        } else {
                            return { 'text-align': 'left' };
                        }
                    },
                    tooltipValueGetter: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return 'Purchase is disabled for this product in Back Office, so it is not possible to add a costprice to this element';
                        } else {
                            return null;
                        }
                    },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Cost',
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Cur',
                    editable: false,
                    vtbBaseWidth: 70,
                    execute: [this.setTriggerValue, this.changeExchangeRate, this.changeCostPrice, this.updateCostPrices, this.updateCostCurPrices, this.recalculateSalesPriceTotal, this.updateSalesPrices],
                    cellStyle: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return { 'color': 'gray', 'background-color': '#dddddd' };
                        }
                    },
                    cellRendererFramework: PricesCurrencySelectComponent,
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Cur',
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Supplier',
                    editable: true,
                    vtbBaseWidth: 80,
                    execute: [this.setTriggerValue, this.execute, this.saveChangesToObject],
                    cellStyle: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return { 'color': 'gray', 'background-color': '#dddddd' };
                        }
                    },
                    cellRendererFramework: PricesSupplierSelectComponent,
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Supplier',
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Exch Rate',
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    execute: [this.setTriggerValue, this.changeCostPrice, this.updateCostPrices, this.updateCostCurPrices, this.recalculateSalesPriceTotal, this.updateSalesPrices],
                    cellStyle: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return { 'text-align': 'left', 'color': 'gray', 'background-color': '#dddddd' };
                        } else {
                            return { 'text-align': 'left' };
                        }
                    },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Exch Rate',
                    vtbBaseWidth: 100,
                    tooltipField: 'Exch Rate',
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Cost ' + this.salesCurrency + ' Per Unit',
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    vtbBaseWidth: 130,
                    execute: [this.setTriggerValue, this.changeCostPriceTotalByUnit, this.updateCostCurPrices, this.recalculateCostPrice, this.recalculateSalesPriceTotal, this.updateSalesPrices, this.saveChangesToObject],
                    cellStyle: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return { 'text-align': 'left', 'color': 'gray', 'background-color': '#dddddd' };
                        } else {
                            return { 'text-align': 'left' };
                        }
                    },
                    tooltipValueGetter: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return 'Purchase is disabled for this product in Back Office, so it is not possible to add a costprice to this element';
                        } else {
                            return null;
                        }
                    },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Cost ' + this.salesCurrency + ' Per Unit',
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Cost ' + this.salesCurrency,
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    vtbBaseWidth: 100,
                    execute: [this.setTriggerValue, this.changeCostPriceTotal, this.updateCostCurPrices, this.recalculateCostPrice, this.recalculateSalesPriceTotal, this.updateSalesPrices, this.saveChangesToObject],
                    cellStyle: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return { 'text-align': 'left', 'color': 'gray', 'background-color': '#dddddd' };
                        } else {
                            return { 'text-align': 'left' };
                        }
                    },
                    tooltipValueGetter: function (params) {
                        if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                            return 'Purchase is disabled for this product in Back Office, so it is not possible to add a costprice to this element';
                        } else {
                            return null;
                        }
                    },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Cost ' + this.salesCurrency,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    headerName: '',
                    field: 'MarginEnabled',
                    cellRendererFramework: MarginLockComponent,
                    vtbBaseWidth: 50,
                    height: 100,
                    execute: [this.setTriggerValue, this.changeMarginEnabled],
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Margin %',
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    vtbBaseWidth: 93,
                    execute: [this.setTriggerValue, this.changeMargin, this.updateSalesPrices, this.saveChangesToObject],
                    cellStyle: { 'text-align': 'left' },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Margin %',
                    tooltipValueGetter: () => 'Click the margin total to change multiple margins',
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Sales ' + this.salesCurrency + ' Per Unit',
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    vtbBaseWidth: 130,
                    execute: [this.setTriggerValue, this.changeSalesPriceTotalByUnit, this.updateSalesPrices, this.recalculateMargin, this.saveChangesToObject],
                    cellStyle: { 'text-align': 'left' },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Sales ' + this.salesCurrency + ' Per Unit',
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    headerName: '',
                    field: 'InsuranceEnabled',
                    cellRendererFramework: InsuranceLockComponent,
                    vtbBaseWidth: 50,
                    height: 100,
                    execute: [this.setTriggerValue, this.changeInsuranceEnabled],
                    context: this,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                },
                {
                    field: 'Sales ' + this.salesCurrency,
                    editable: true,
                    cellRendererFramework: GridNumberCellComponent,
                    vtbBaseWidth: 100,
                    execute: [this.setTriggerValue, this.changeSalesPriceTotal, this.updateSalesPrices, this.recalculateMargin, this.saveChangesToObject],
                    cellStyle: { 'text-align': 'left' },
                    valueSetter: this.valueSetter,
                    headerTooltip: 'Sales ' + this.salesCurrency,
                    get width() {
                        return _self.widthSetter(this);
                    },
                    get hide() {
                        return _self.widthSetter(this) > 1 ? false : true;
                    }
                }
            );
            if (this.config.gridViewSettings != null && this.config.gridViewSettings.extra != null && this.config.gridViewSettings.extra.headerName != null) {
                this.columnDefs.push(
                    {
                        field: this.config.gridViewSettings.extra.headerName,
                        editable: true,
                        vtbBaseWidth: 100,
                        execute: [this.saveChangesToObject],
                        cellStyle: { 'text-align': 'left' },
                        headerTooltip: 'test',
                        get width() {
                            return _self.widthSetter(this);
                        }
                    }
                )
            }
        }

        this.dataObj.element = element;
        if (element.lastRecalc) {
            this.dataObj.extra.lastRecalc = element.lastRecalc;
        } else {
            this.dataObj.extra.lastRecalc = null;

        }
        if (element.not_calculated === true) {
            this.dataObj.extra.background = 'orange';
        } else {
            this.dataObj.extra.background = 'white';
        }
        if (element.olPrices.hasError) {
            this.dataObj.extra.error = true;
            this.dataObj.extra.errorMessage = element.olPrices.hasError;
            this.dataObj.extra.errorParams = element.olPrices.usedParams;
        } else {
            this.dataObj.extra.error = false;
            this.dataObj.extra.errorMessage = null;
            this.dataObj.extra.errorParams = null;
        }
        this.dataObj['InternalInfoIcon'] = element;
        if (element.manual_calc) {
            this.dataObj['Enabled'] = element.manual_calc;
        } else {
            this.dataObj['Enabled'] = false;
        }
        if (element.TSOrderline && element.TSOrderline.locked) {
            let locked = false;
            if (element.TSOrderline.locked === 1) {
                locked = true;
            }
            if (locked != element.manual_calc) {
                element.manual_calc = locked;
                this.dataObj['Enabled'] = locked;
            }
        }

        if (element.marginType != null && element.marginType === 'raise_pp') {
            if (prices.exchangeRate) {
                this.dataObj['Cost ' + this.salesCurrency] = Number(Number(cost) * prices.exchangeRate).toFixed(3);
            } else {
                this.dataObj['Cost ' + this.salesCurrency] = Number(0).toFixed(3);
            }
        } else {
            if (prices.costPrice) {
                this.dataObj['Cost ' + this.salesCurrency] = Number(prices.costPrice).toFixed(3);
            } else {
                this.dataObj['Cost ' + this.salesCurrency] = Number(0).toFixed(3);
            }
        }
        if (element.marginLocked != null) {
            this.dataObj['MarginEnabled'] = element.marginLocked;
        } else if (element.marginType === 'margin' || element.marginType === 'percentage') {
            this.dataObj['MarginEnabled'] = true;
        } else {
            this.dataObj['MarginEnabled'] = false;
        }
        if (element.insuranceLocked != null) {
            this.dataObj['InsuranceEnabled'] = element.insuranceLocked;
        } else {
            this.dataObj['InsuranceEnabled'] = false;
        }
        if (prices.salesTotal && this.dataObj['MarginEnabled'] === false) {
            if (Number(prices.salesTotal) === 0) {
                this.dataObj['Sales ' + this.salesCurrency] = Number(0).toFixed(3);
                if (element.olPrices.margin) {
                    this.dataObj['Margin %'] = Number(element.olPrices.margin).toFixed(5);
                } else {
                    this.dataObj['Margin %'] = Number(0).toFixed(5);
                }
            } else {
                this.dataObj['Sales ' + this.salesCurrency] = Number(prices.salesTotal).toFixed(3);
                if (prices.costPrice) {
                    this.dataObj['Margin %'] = ((prices.salesTotal - (prices.costPrice)) / prices.salesTotal * 100).toFixed(5);
                } else {
                    this.dataObj['Margin %'] = Number(0).toFixed(5);
                }
            }
        } else {
            if (element.olPrices.margin) {
                this.dataObj['Margin %'] = Number(element.olPrices.margin).toFixed(5);
                if ((element.olPrices.costPrice || element.olPrices.costPrice > 0) && (element.olPrices.salesTotal == null || element.olPrices.salesTotal <= 0)) {
                    this.dataObj['Sales ' + this.salesCurrency] = Number((Number(element.olPrices.costPrice) * 100) / (100 - Number(element.olPrices.margin))).toFixed(3);
                } else {
                    if (element.olPrices.salesTotal) {
                        this.dataObj['Sales ' + this.salesCurrency] = Number(element.olPrices.salesTotal).toFixed(3);
                    } else {
                        this.dataObj['Sales ' + this.salesCurrency] = Number(0).toFixed(3);
                    }
                }
            } else if (this.env.defaultMargin && element.externalInfo && element.externalInfo.externalType === 'desty') {
                this.dataObj['Margin %'] = Number(this.env.defaultMargin).toFixed(5);
                if ((element.olPrices.costPrice || element.olPrices.costPrice > 0) && (element.olPrices.salesTotal == null || element.olPrices.salesTotal <= 0)) {
                    this.dataObj['Sales ' + this.salesCurrency] = Number((Number(element.olPrices.costPrice) * 100) / (100 - Number(this.env.defaultMargin))).toFixed(3);
                } else {
                    this.dataObj['Sales ' + this.salesCurrency] = Number(element.olPrices.salesTotal).toFixed(3);
                }
            } else {
                if (element.sellingAmount != null && element.sellingMarkupType != null && element.sellingMarkupType === 'margin') {
                    element.olPrices.margin = Number(element.sellingAmount);
                    this.dataObj['Margin %'] = Number(element.sellingAmount).toFixed(5);
                } else {
                    this.dataObj['Margin %'] = Number(0).toFixed(5);
                }
                this.dataObj['Sales ' + this.salesCurrency] = Number(0).toFixed(3);
            }
        }
        if (element.marginType != null && element.marginType === 'raise_pp') {
            this.dataObj['Sales ' + this.salesCurrency] = Number(Number(this.dataObj['Sales ' + this.salesCurrency]) * Object.keys(element.olPrices.participants).length + Number(this.dataObj['Cost ' + this.salesCurrency])).toFixed(3);
            this.dataObj['Margin %'] = Number((Number(this.dataObj['Sales ' + this.salesCurrency]) - this.dataObj['Cost ' + this.salesCurrency]) / Number(this.dataObj['Sales ' + this.salesCurrency]) * 100).toFixed(5);
        }
        if (element.marginType != null && element.marginTypeTotal != null && this.dataObj['MarginEnabled'] === true) {
            if (element.marginType === 'margin') {
                element.olPrices.margin = element.marginTypeTotal;
                this.dataObj['Margin %'] = Number(element.marginTypeTotal).toFixed(5);
                if (Number(element.olPrices.salesTotal) > 0) {
                    this.dataObj['Sales ' + this.salesCurrency] = Number(element.olPrices.salesTotal).toFixed(3);
                    this.dataObj['Cost ' + this.salesCurrency] = Number(Number(element.olPrices.salesTotal) / 100 * (100 - Number(element.marginTypeTotal))).toFixed(5);
                    this.dataObj['Cost'] = Number(this.dataObj['Cost ' + this.salesCurrency] / this.dataObj['Exch Rate']).toFixed(5);
                }
            } else if (element.marginType === 'percentage') {
                if (Number(element.olPrices.salesTotal) > 0) {
                    this.dataObj['Sales ' + this.salesCurrency] = Number(element.olPrices.salesTotal).toFixed(3);
                    this.dataObj['Cost ' + this.salesCurrency] = Number(Number(element.olPrices.salesTotal) / (1 + (Number(element.marginTypeTotal) / 100))).toFixed(5);
                    this.dataObj['Cost'] = Number(this.dataObj['Cost ' + this.salesCurrency] / this.dataObj['Exch Rate']).toFixed(5);
                    this.dataObj['Margin %'] = Number((Number(element.olPrices.salesTotal) - this.dataObj['Cost ' + this.salesCurrency]) / Number(element.olPrices.salesTotal) * 100).toFixed(5);
                } else {
                    this.dataObj['Margin %'] = (0).toFixed(5);
                    this.dataObj['Sales ' + this.salesCurrency] = Number(0).toFixed(3);
                }
            }
        }
        if (element.unitId === this.roundId) {
            this.dataObj['Enabled'] = false;
            this.dataObj['Sales ' + this.salesCurrency] = Number(element.olPrices.salesTotal).toFixed(3);
        }
        this.dataObj['Cost Per Unit'] = Number(this.dataObj['Cost'] / (element.unitAmount || 1)).toFixed(3);
        this.dataObj['Cost ' + this.salesCurrency + ' Per Unit'] = Number(this.dataObj['Cost ' + this.salesCurrency] / (element.unitAmount || 1)).toFixed(3);
        this.dataObj['Sales ' + this.salesCurrency + ' Per Unit'] = Number(this.dataObj['Sales ' + this.salesCurrency] / (element.unitAmount || 1)).toFixed(3);
        this.dataObj.element = element;
        this.dataObj.extra = extra;
        this.rowData.push(this.dataObj);

        let tempArray = [];
        for (const party in participants) {
            for (const participant in participants[party]) {
                if (participants[party] && participant) {
                    if (prices.participants[participants[party][participant]]) {
                        prices.participants[participants[party][participant].id].id = participants[party][participant].id;
                    }
                    if (element.olPrices.participants[String(participants[party][participant].id)] != null) {
                        tempArray.push(participants[party][participant]);
                    }
                    this.updateGrid(participants[party][participant].id, prices, exchangeRate, element, GridNumberCellComponent);
                }
            }
        }
        this.participants[index] = tempArray;
    }

    updateGrid(participantId, prices, exchangeRate, element, GridNumberCellComponent = null) {
        const _self = this;
        for (const column of this.columnDefs) {
            if (column.field.indexOf('(' + participantId + ')') > -1) {
                if (prices.participants[participantId] != null) {
                    if (prices.participants[participantId].costPrice != null) {
                        if (Number(prices.participants[participantId].costPrice) == 0) {
                            if (prices.costPrice != null && Number(prices.costPrice) !== 0) {
                                if (this.compareParticipantPrices('cost', prices) === true) {
                                    this.dataObj['Cost (' + participantId + ')'] = Number(Number(prices.costPrice / (exchangeRate || 1)) / Object.keys(prices.participants).length).toFixed(3) || Number(0).toFixed(3);
                                    this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(prices.costPrice) / Object.keys(prices.participants).length).toFixed(3) || Number(0).toFixed(3);
                                } else {
                                    this.dataObj['Cost (' + participantId + ')'] = null;
                                    this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                                }
                            } else {
                                this.dataObj['Cost (' + participantId + ')'] = null;
                                this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                            }
                        } else {
                            this.dataObj['Cost (' + participantId + ')'] = Number(prices.participants[participantId].costPrice / (exchangeRate || 1)).toFixed(3);
                            this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(prices.participants[participantId].costPrice).toFixed(3);
                        }
                    } else if (Number(prices.costPrice) !== 0) {
                        if (this.compareParticipantPrices('cost', prices) === true) {
                            this.dataObj['Cost (' + participantId + ')'] = Number(Number(prices.costPrice / Number((exchangeRate || 1))) / Object.keys(prices.participants).length).toFixed(3) || Number(0).toFixed(3);
                            this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(prices.costPrice) / Object.keys(prices.participants).length).toFixed(3) || Number(0).toFixed(3);
                        }
                    } else {
                        this.dataObj['Cost (' + participantId + ')'] = null;
                        this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                    }
                } else {
                    this.dataObj['Cost (' + participantId + ')'] = null;
                    this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                }
                if (prices.participants[participantId] != null) {
                    if (prices.participants[participantId].salesPrice != null) {
                        if (Number(prices.participants[participantId].salesPrice) == 0) {
                            if (prices.salesTotal != null && Number(prices.salesTotal) !== 0) {
                                if (this.compareParticipantPrices('sales', prices) === true) {
                                    this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(prices.salesTotal) / Object.keys(prices.participants).length).toFixed(3);
                                } else {
                                    this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                                }
                            } else {
                                this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                                if (this.dataObj.element && this.dataObj.element.externalInfo && this.dataObj.element.externalInfo.externalType && this.dataObj.element.externalInfo.externalType === 'desty') {
                                    this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(this.dataObj['Sales EUR']) / Object.keys(prices.participants).length).toFixed(3);
                                }
                            }
                        } else {
                            if (element.participantSalesUpdate != null && element.participantSalesUpdate === true) {
                                this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(prices.participants[participantId].salesPrice).toFixed(3);
                            } else {
                                if (this.compareParticipantPrices('sales', prices) === true) {
                                    this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(prices.salesTotal) / Object.keys(prices.participants).length).toFixed(3);
                                } else {
                                    this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(prices.participants[participantId].salesPrice).toFixed(3);
                                }
                            }
                        }
                    } else if (Number(prices.salesTotal) !== 0) {
                        if (this.compareParticipantPrices('sales', prices) === true) {
                            this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(prices.salesTotal) / Object.keys(prices.participants).length).toFixed(3);
                        }
                    } else {
                        this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                        if (this.dataObj.element && this.dataObj.element.externalInfo && this.dataObj.element.externalInfo.externalType && this.dataObj.element.externalInfo.externalType === 'desty') {
                            this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(this.dataObj['Sales EUR']) / Object.keys(prices.participants).length).toFixed(3);
                        }
                    }
                } else {
                    this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = null;
                }
                if (element.marginType != null && element.marginType === 'raise_pp') {
                    if (this.dataObj['Cost (' + participantId + ')'] != null) {
                        this.dataObj['Cost (' + participantId + ')'] = Number(Number(this.dataObj['Cost (' + participantId + ')']) * Object.keys(element.olPrices.participants).length).toFixed(3);
                    }
                    if (this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] != null) {
                        this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number((Number(this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')']) + Number(this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'])) * Object.keys(element.olPrices.participants).length).toFixed(3);
                    }
                    if (this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] != null) {
                        this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')']) * Object.keys(element.olPrices.participants).length).toFixed(3);
                    }
                }
                return;
            }
        }
        const participant = this.getParticipant(participantId);
        const checkParticipantName = obj => obj.field === (participant.name + ' ' + participant.surname + ' (' + participantId + ')');
        if (this.columnDefs.some(checkParticipantName) === false) {
            this.columnDefs.push(
                {
                    field: participant.name + ' ' + participant.surname + ' (' + participantId + ')',
                    headerClass: function (params) {
                        return _self.widthSetter(params.colDef, true) > 1 ? 'participantHeader' : 'vtb-hide';
                    },
                    children: [
                        {
                            field: 'Cost ' + '(' + participantId + ')',
                            editable: true,
                            cellRendererFramework: GridNumberCellComponent,
                            vtbBaseWidth: 100 + (String(participantId).length * 5),
                            execute: [this.setTriggerValue, this.recalculateCostPriceTotalParticipant, this.recalculateCostCurPrice, this.recalculateSalesPriceTotal, this.saveChangesToObject],
                            cellStyle: function (params) {
                                if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                                    return { 'text-align': 'left', 'border-left': '3px solid black', 'color': 'gray', 'background-color': '#dddddd' };
                                } else {
                                    return { 'text-align': 'left', 'border-left': '3px solid black' };
                                }
                            },
                            tooltipValueGetter: function (params) {
                                if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                                    return 'Purchase is disabled for this product in Back Office, so it is not possible to add a costprice to this element';
                                } else {
                                    return null;
                                }
                            },
                            valueSetter: this.valueSetter,
                            headerTooltip: 'Cost ' + '(' + participantId + ')',
                            headerClass: function (params) {
                                return _self.widthSetter(params.colDef) > 1 ? 'participantHeader' : 'vtb-hide';
                            },
                            cellClassRules: {
                                'empty': function (params) {
                                    if (params.value != null) {
                                        return false;
                                    }
                                    return true;
                                },
                            },
                            get width() {
                                return _self.widthSetter(this);
                            },
                            get hide() {
                                return _self.widthSetter(this) > 1 ? false : true;
                            }
                        },
                        {
                            field: 'Cost ' + this.salesCurrency + ' (' + participantId + ')',
                            editable: true,
                            cellRendererFramework: GridNumberCellComponent,
                            vtbBaseWidth: 100 + (String(participantId).length * 5),
                            execute: [this.setTriggerValue, this.recalculateCostPriceCurTotalParticipant, this.recalculateCostPrice, this.updateCostPrices, this.recalculateSalesPriceTotal, this.saveChangesToObject],
                            cellStyle: function (params) {
                                if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                                    return { 'text-align': 'left', 'color': 'gray', 'background-color': '#dddddd' };
                                } else {
                                    return { 'text-align': 'left' };
                                }
                            },
                            tooltipValueGetter: function (params) {
                                if (params.data.Title !== 'Totals:' && params.data.extra.disabledForPurchase === true) {
                                    return 'Purchase is disabled for this product in Back Office, so it is not possible to add a costprice to this element';
                                } else {
                                    return null;
                                }
                            },
                            valueSetter: this.valueSetter,
                            headerTooltip: 'Cost ' + this.salesCurrency + ' (' + participantId + ')',
                            headerClass: function (params) {
                                return _self.widthSetter(params.colDef) > 1 ? 'vtb-show' : 'vtb-hide';
                            },
                            cellClassRules: {
                                'empty': function (params) {
                                    if (params.value != null) {
                                        return false;
                                    }
                                    return true;
                                },
                            },
                            get width() {
                                return _self.widthSetter(this);
                            },
                            get hide() {
                                return _self.widthSetter(this) > 1 ? false : true;
                            }
                        },
                        {
                            field: 'Sales ' + this.salesCurrency + ' (' + participantId + ')',
                            editable: true,
                            cellRendererFramework: GridNumberCellComponent,
                            vtbBaseWidth: 100 + (String(participantId).length * 5),
                            execute: [this.setTriggerValue, this.recalculateSalesPriceTotalParticipant, this.recalculateMargin, this.updateCostPrices, this.updateCostCurPrices, this.saveChangesToObject],
                            cellStyle: { 'text-align': 'left' },
                            valueSetter: this.valueSetter,
                            headerTooltip: 'Sales ' + this.salesCurrency + ' (' + participantId + ')',
                            headerClass: function (params) {
                                return _self.widthSetter(params.colDef) > 1 ? 'vtb-show' : 'vtb-hide';
                            },
                            cellClassRules: {
                                'empty': function (params) {
                                    if (params.value != null) {
                                        return false;
                                    }
                                    return true;
                                },
                            },
                            get width() {
                                return _self.widthSetter(this);
                            },
                            get hide() {
                                return _self.widthSetter(this) > 1 ? false : true;
                            }
                        },
                    ]
                }
            );
        }
        if (prices.participants[participantId] != null) {
            if (prices.participants[participantId].costPrice != null && prices.participants[participantId].costPrice !== 0) {
                this.dataObj['Cost (' + participantId + ')'] = Number(prices.participants[participantId].costPrice / exchangeRate).toFixed(3) || Number(0).toFixed(3);
                this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(prices.participants[participantId].costPrice).toFixed(3) || Number(0).toFixed(3);
            } else if (prices.participants[participantId].costPrice != null && prices.participants[participantId].costPrice === 0) {
                if (Number(prices.costPrice) !== 0) {
                    this.dataObj['Cost (' + participantId + ')'] = Number(prices.participants[participantId].costPrice / exchangeRate).toFixed(3) || Number(0).toFixed(3);
                    this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(prices.participants[participantId].costPrice / exchangeRate).toFixed(3) || Number(0).toFixed(3);
                } else {
                    this.dataObj['Cost (' + participantId + ')'] = Number(0).toFixed(3);
                    this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(0).toFixed(3);
                }
            } else {
                this.dataObj['Cost (' + participantId + ')'] = Number(0).toFixed(3);
                this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(0).toFixed(3);
            }
            if (prices.participants[participantId].salesPrice != null && prices.participants[participantId].salesPrice === 0) {
                if (Number(prices.salesTotal) !== 0 && prices.salesTotal != null) {
                    this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(prices.salesTotal) / Object.keys(prices.participants).length).toFixed(3);
                } else {
                    if (this.dataObj.element && this.dataObj.element.dataSource && this.dataObj.element.dataSource === 'desty') {
                        this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(this.dataObj['Sales EUR']) / Object.keys(prices.participants).length).toFixed(3);
                    } else {
                        this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(0).toFixed(3);
                    }
                }
            } else if (prices.participants[participantId].salesPrice != null && prices.participants[participantId].salesPrice !== 0) {
                this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(prices.participants[participantId].salesPrice).toFixed(3);
            } else {
                this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number(0).toFixed(3);
            }
        } else {
            this.dataObj['Cost (' + participantId + ')'] = null;  // These 3 used to be null
            this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = null;
            this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = null;
        }
        if (element.marginType != null && element.marginType === 'raise_pp') {
            if (this.dataObj['Cost (' + participantId + ')'] != null) {
                this.dataObj['Cost (' + participantId + ')'] = Number(Number(this.dataObj['Cost (' + participantId + ')']) * Object.keys(element.olPrices.participants).length).toFixed(3);
            }
            if (this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] != null) {
                this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')'] = Number((Number(this.dataObj['Sales ' + this.salesCurrency + ' (' + participantId + ')']) + Number(this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'])) * Object.keys(element.olPrices.participants).length).toFixed(3);
            }
            if (this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] != null) {
                this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')'] = Number(Number(this.dataObj['Cost ' + this.salesCurrency + ' (' + participantId + ')']) * Object.keys(element.olPrices.participants).length).toFixed(3);
            }
        }
    }

    setTotals(roundingObj = null) {
        let marginTotal = Number((Number(this.getSaleCurrencyTotal()) - Number(this.getCostCurrencyTotal())) / Number(this.getSaleCurrencyTotal()) * 100);
        if (marginTotal > 100 || marginTotal < -1000) {
            marginTotal = 0;
        }
        this.bottomRowData = [{
            'Title': 'Totals:',
            ['Cost ' + this.salesCurrency]: Number(this.getCostCurrencyTotal()).toFixed(3),
            ['Sales ' + this.salesCurrency]: Number(this.getSaleCurrencyTotal()).toFixed(3),
            'Margin %': marginTotal.toFixed(2),
            'CostPriceCurrency': this.costCurrency,
            'SalesPriceCurrency': this.salesCurrency,
        }];
        this.totalMargin = Number((Number(this.getSaleCurrencyTotal()) - Number(this.getCostCurrencyTotal())) / Number(this.getSaleCurrencyTotal()) * 100).toFixed(2);
        if (roundingObj != null) {
            this.bottomRowData[0]['Margin %'] = roundingObj.rounding.roundedMargin.toFixed(5);
            this.totalMargin = roundingObj.rounding.roundedMargin.toFixed(5);
            this.bottomRowData[0]['Sales ' + this.salesCurrency] = (Number(this.bottomRowData[0]['Sales ' + this.salesCurrency]) + Number(roundingObj.rounding.discount)).toFixed(3);
        }
        if (this.itineraryData) {
            for (let segment of this.itineraryData.data.segments || []) {
                if (segment != null) {
                    for (let element of segment.elements || []) {
                        if (element != null && element.olPrices && element.olPrices.participants) {
                            for (let participant in element.olPrices.participants) {
                                for (const column of this.bottomRowData) {
                                    if (column['Cost ' + this.salesCurrency + ' (' + participant + ')'] == null && column['Sales ' + this.salesCurrency + ' (' + participant + ')'] == null) {
                                        // this.bottomRowData[0]['Cost (' + participant + ')'] = Number(this.getParticipantCostTotal('Cost (' + participant + ')') / element.olPrices.exchangeRate).toFixed(3);
                                        this.bottomRowData[0]['Cost ' + this.salesCurrency + ' (' + participant + ')'] = Number(this.getParticipantCostTotal('Cost ' + this.salesCurrency + ' (' + participant + ')')).toFixed(3);
                                        this.bottomRowData[0]['Sales ' + this.salesCurrency + ' (' + participant + ')'] = Number(this.getParticipantSalesTotal('Sales ' + this.salesCurrency + ' (' + participant + ')')).toFixed(3);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    execute(params, rowNode) {
        rowNode.setDataValue(params.colDef.field, params.value);
    }

    checkOldValue(params, rowNode) {
        if (params.value === '') {
            let extra = params.data.extra;
            extra.background = 'orange';
            rowNode.data.element.not_calculated = true;
            rowNode.setDataValue('extra', extra);
        }
    }

    changeCostPrice(params, rowNode) {
        if (params) {
            if (this.rowData[params.rowIndex]['Cost ' + this.salesCurrency] != null && params.colDef.field === 'Cost') {
                rowNode.setDataValue('Cost ' + this.salesCurrency, (Number(params.value) * params.data['Exch Rate']).toFixed(3));
                rowNode.setDataValue('Cost', Number(params.value).toFixed(3))
            } else if (this.rowData[params.rowIndex]['Cost ' + this.salesCurrency] != null && (params.colDef.field === 'Exch Rate' || params.colDef.field === 'Cur')) {
                rowNode.setDataValue('Cost ' + this.salesCurrency, (Number(params.data['Exch Rate']) * params.data['Cost']).toFixed(3));
                rowNode.setDataValue('Exch Rate', Number(params.data['Exch Rate']).toFixed(9))
            }
        }
    }

    updateCostByUnit(params, rowNode) {
        rowNode.setDataValue('Cost', Number(params.value * (params.data.element.unitAmount || 1)))
    }

    updateCostPrices(params, rowNode) {
        let participantsAmount = this.participants[params.rowIndex].length;
        const costPrice = Number(this.getCostPrice(params));
        const rowCostPrice = Number(params.data['Cost']);
        let empty = true;
        if (participantsAmount > 0) {
            for (const participant of this.participants[params.rowIndex]) {
                const participantCostColumn = 'Cost (' + participant.id + ')';
                if (rowNode.data[participantCostColumn] != null) {
                    if (this.recalc === true) {
                        rowNode.setDataValue(participantCostColumn, Number(0).toFixed(3));
                    }
                    if (this.rowData[params.rowIndex][participantCostColumn] != 0) {
                        empty = false;
                        if (costPrice != null && rowCostPrice != null) {
                            let newCostPrice: any = ((rowCostPrice / costPrice) * params.data[participantCostColumn]).toFixed(3);
                            if (!isFinite(newCostPrice)) {
                                newCostPrice = Number(0).toFixed(3);
                            }
                            rowNode.setDataValue(participantCostColumn, newCostPrice);
                        }
                    } else if (this.rowData[params.rowIndex][participantCostColumn] == null) {
                        participantsAmount--;
                    }
                } else {
                    participantsAmount--;
                }
            }
            if (empty === true) {
                for (const participant of this.participants[params.rowIndex]) {
                    const participantCostColumn = 'Cost (' + participant.id + ')';
                    if (rowNode.data[participantCostColumn] != null) {
                        if (params.data[participantCostColumn]) {
                            rowNode.setDataValue(participantCostColumn, (rowCostPrice / participantsAmount).toFixed(3));
                        }
                    }
                }
            }
        }
    }

    updateCostCurPrices(params, rowNode) {
        let participantsAmount = this.participants[params.rowIndex].length;
        const costPrice = Number(this.getCostCurPrice(params));
        const rowCostPrice = Number(params.data['Cost ' + this.salesCurrency]);
        let empty = true;
        if (participantsAmount > 0) {
            for (const participant of this.participants[params.rowIndex]) {
                const participantCostColumn = 'Cost ' + this.salesCurrency + ' (' + participant.id + ')';
                const keys = Object.keys(rowNode.data);
                if (keys.indexOf(participantCostColumn) > -1) {
                    if (this.recalc === true) {
                        rowNode.setDataValue(participantCostColumn, Number(0).toFixed(3));
                    }
                    if (this.rowData[params.rowIndex][participantCostColumn] != 0) {
                        empty = false;
                        if (costPrice != null && rowCostPrice != null) {
                            let newCostPrice: any = ((rowCostPrice / costPrice) * params.data[participantCostColumn]).toFixed(3);
                            if (!isFinite(newCostPrice)) {
                                newCostPrice = Number(0).toFixed(3);
                            }
                            rowNode.setDataValue(participantCostColumn, newCostPrice);
                        }
                    } else if (this.rowData[params.rowIndex][participantCostColumn] == null) {
                        participantsAmount--;
                    }
                } else {
                    participantsAmount--;
                }
            }
            if (empty === true) {
                for (const participant of this.participants[params.rowIndex]) {
                    const participantCostColumn = 'Cost ' + this.salesCurrency + ' (' + participant.id + ')';
                    if (rowNode.data[participantCostColumn] != null) {
                        if (params.data[participantCostColumn]) {
                            rowNode.setDataValue(participantCostColumn, (rowCostPrice / participantsAmount).toFixed(3));
                        }
                    }
                }
            }
        }
    }

    updateSalesPrices(params, rowNode) {
        const salesPrice = this.getSalesPrice(params);
        let empty = true;
        let participantsAmount = this.participants[params.rowIndex].length;
        if (participantsAmount > 0) {
            for (const participant of this.participants[params.rowIndex]) {
                const participantSalesColumn = 'Sales ' + this.salesCurrency + ' (' + participant.id + ')';
                const keys = Object.keys(rowNode.data);
                if (keys.indexOf(participantSalesColumn) > -1) {
                    if (this.recalc === true) {
                        rowNode.setDataValue(participantSalesColumn, Number(0).toFixed(3));
                    }
                    if (this.rowData[params.rowIndex][participantSalesColumn] != 0) {
                        empty = false;
                        if (salesPrice) {
                            let newSalesPrice: any = ((params.data['Sales ' + this.salesCurrency] / Number(salesPrice))
                                * params.data[participantSalesColumn]).toFixed(3);
                            if (!isFinite(newSalesPrice)) {
                                newSalesPrice = Number(0).toFixed(3);
                            }
                            rowNode.setDataValue(participantSalesColumn, newSalesPrice);
                        }
                    } else if (this.rowData[params.rowIndex][participantSalesColumn] == null) {
                        participantsAmount--;
                    }
                } else {
                    participantsAmount--;
                }
            }
            if (empty === true) {
                for (const participant of this.participants[params.rowIndex]) {
                    const participantSalesColumn = 'Sales ' + this.salesCurrency + ' (' + participant.id + ')';
                    if (rowNode.data[participantSalesColumn] != null) {
                        if (params.data[participantSalesColumn]) {
                            rowNode.setDataValue(participantSalesColumn, (params.data['Sales ' + this.salesCurrency] / participantsAmount).toFixed(3));
                        }
                    }
                }
            }
        }
        if (this.triggerValue === 'Cur') {
            this.saveChangesToObject({ saveTrigger: true });
        }
    }

    changeCostPriceTotal(params, rowNode) {
        rowNode.setDataValue('Cost ' + this.salesCurrency, Number(params.value).toFixed(3));
    }

    changeCostPriceTotalByUnit(params, rowNode) {
        rowNode.setDataValue('Cost ' + this.salesCurrency, Number(params.value * (params.data.element.unitAmount || 1)).toFixed(3));
    }

    changeSalesPriceTotal(params, rowNode) {
        if (params.data.MarginEnabled === false || params.data.MarginEnabled == null) {
            rowNode.setDataValue('Sales ' + this.salesCurrency, Number(params.value).toFixed(3));
        } else {
            rowNode.setDataValue('Sales ' + this.salesCurrency, Number(params.data.oldValue).toFixed(3));
        }
    }

    changeSalesPriceTotalByUnit(params, rowNode) {
        if (params.data.MarginEnabled === false || params.data.MarginEnabled == null) {
            rowNode.setDataValue('Sales ' + this.salesCurrency, Number(params.value * (params.data.element.unitAmount || 1)).toFixed(3));
        } else {
            rowNode.setDataValue('Sales ' + this.salesCurrency, Number(params.data.oldValue * (params.data.element.unitAmount || 1)).toFixed(3));
        }
    }

    changeExchangeRate(params, rowNode) {
        const sourceCurrency = this.currenciesValue.itemsByCode[params.value];
        const targetCurrency = this.currenciesValue.itemsByCode[this.salesCurrency];
        let exchangeRate = 1;
        let exchangeDate: any = new Date();

        if (this.currenciesValue.orderlinePurchaseExchangeDate === 'order_startdate') {
            exchangeDate = new Date(this.itineraryData.data.startDate);
        }
        exchangeDate = Number(exchangeDate.getFullYear() + this.helpersService.formatTrailingZeros(exchangeDate.getMonth() + 1, 2) + this.helpersService.formatTrailingZeros(exchangeDate.getDate(), 2));
        const exchangeObj = this.currenciesValue.exchangeRates.find(exchangeRate => sourceCurrency.id === exchangeRate.currency_id_one && targetCurrency.id === exchangeRate.currency_id_two && exchangeDate >= exchangeRate.rate_start_date && exchangeDate <= exchangeRate.rate_end_date);
        if (exchangeObj != null) {
            exchangeRate = exchangeObj.rate;
        }
        rowNode.setDataValue('Exch Rate', Number(exchangeRate).toFixed(9));
    }

    recalculateCostPrice(params, rowNode) {
        if (params.data['Cost ' + this.salesCurrency] == null || params.data['Exch Rate'] == (null || 0)) {
            rowNode.setDataValue('Cost', Number(0).toFixed(3));
        } else {
            rowNode.setDataValue('Cost', Number(params.data['Cost ' + this.salesCurrency] / params.data['Exch Rate']).toFixed(3));
        }
    }

    recalculateCostCurPrice(params, rowNode) {
        if (params.data['Cost'] == null || params.data['Exch Rate'] == (null || 0)) {
            rowNode.setDataValue('Cost ' + this.salesCurrency, Number(0).toFixed(3));
        } else {
            rowNode.setDataValue('Cost ' + this.salesCurrency, Number(params.data['Cost'] * params.data['Exch Rate']).toFixed(3));
        }
    }

    recalculateSalesPriceTotal(params, rowNode) {
        if (params.data['Cost ' + this.salesCurrency]) {
            let margin = 100 - Number(params.data['Margin %']);
            if (margin === 0) {
                margin = 1;
            }
            rowNode.setDataValue('Sales ' + this.salesCurrency, Number((Number(params.data['Cost ' + this.salesCurrency]) * 100) / margin).toFixed(3));
        }
    }

    recalculateCostPriceTotalParticipant(params, rowNode) {
        let costPrice = this.getCostPrice(params);
        const participantId = parseInt(params.colDef.field.match(/\d+/), 10);
        let found = this.participants[params.rowIndex].find(x => Number(x.id) === participantId) || null;
        if (found) {
            rowNode.setDataValue(String(params.colDef.field), Number(params.value).toFixed(3));
            let tempMargin = (100 - Number(params.data['Margin %']));
            let newSalesPrice = params.value;
            if (tempMargin !== 0) {
                newSalesPrice = Number((Number(Number(newSalesPrice * params.data['Exch Rate']) * 100) / tempMargin)).toFixed(3);
            }
            rowNode.setDataValue((params.colDef.field).replace('Cost', 'Cost ' + this.salesCurrency),
                Number(params.value * params.data['Exch Rate']).toFixed(3));
            rowNode.setDataValue(String(params.colDef.field).replace('Cost', 'Sales ' + this.salesCurrency), newSalesPrice);
            rowNode.setDataValue('Cost', Number(costPrice).toFixed(3));
        }
    }

    recalculateCostPriceCurTotalParticipant(params, rowNode) {
        let costPrice = this.getCostCurPrice(params);
        const participantId = parseInt(params.colDef.field.match(/\d+/), 10);
        let found = this.participants[params.rowIndex].find(x => Number(x.id) === participantId) || null;
        if (found) {
            found.costPrice = Number(params.value).toFixed(2);
            rowNode.setDataValue(String(params.colDef.field), Number(params.value).toFixed(3));
            let tempMargin = (100 - Number(params.data['Margin %']));
            let newSalesPrice = params.value;
            if (tempMargin !== 0) {
                newSalesPrice = Number((Number(newSalesPrice * 100) / tempMargin)).toFixed(3);
            }
            rowNode.setDataValue((params.colDef.field).replace('Cost ' + this.salesCurrency, 'Cost'),
                Number(params.value / params.data['Exch Rate']).toFixed(3));
            rowNode.setDataValue(String(params.colDef.field).replace('Cost', 'Sales'), newSalesPrice);
            rowNode.setDataValue('Cost ' + this.salesCurrency, Number(costPrice).toFixed(3));
        }
    }

    recalculateSalesPriceTotalParticipant(params, rowNode) {
        let salesPrice = this.getSalesPrice(params);
        const participantId = String(params.colDef.field).replace('Sales ' + this.salesCurrency, '').replace('(', '').replace(')', '').replace(/\s/g, '');
        let found = this.participants[params.rowIndex].find(x => Number(x.id) === Number(participantId)) || null;
        if (found) {
            if (params.data.MarginEnabled === false || params.data.MarginEnabled == null) {
                found.salesPrice = Number(params.value).toFixed(2);
                rowNode.setDataValue(params.colDef.field, Number(params.value).toFixed(3));
                rowNode.setDataValue((params.colDef.field).replace('Sales', 'Cost'),
                    Number(params.value - ((params.value / 100) * params.data['Margin %'])).toFixed(3));
                rowNode.setDataValue((params.colDef.field).replace('Sales ' + this.salesCurrency, 'Cost'),
                    Number(params.data[(params.colDef.field).replace('Sales', 'Cost')] / params.data['Exch Rate']).toFixed(3));
                rowNode.setDataValue('Sales ' + this.salesCurrency, Number(salesPrice).toFixed(3));
            } else {
                rowNode.setDataValue(params.colDef.field, Number(params.data.oldValue).toFixed(3));
            }
        }
    }

    changeMargin(params, rowNode) {
        let costPrice = this.getCostCurPrice(params);
        if (Number(costPrice) <= 0) {
            rowNode.setDataValue('Sales ' + this.salesCurrency, Number((Number(params.data['Cost ' + this.salesCurrency]) * 100) / (100 - Number(params.value))).toFixed(3));
        } else {
            rowNode.setDataValue('Sales ' + this.salesCurrency, Number((Number(costPrice) * 100) / (100 - Number(params.value))).toFixed(3));
        }
    }

    recalculateMargin(params, rowNode) {
        if (params.data['MarginEnabled'] === false) {
            const salesPrice = this.getSalesPrice(params);
            let newMargin: any = Number((Number(salesPrice) - params.data['Cost ' + this.salesCurrency]) / Number(salesPrice) * 100).toFixed(5);
            if (!isFinite(newMargin)) {
                newMargin = 0;
            }
            rowNode.setDataValue('Margin %', newMargin);
        } else {
            rowNode.setDataValue('Cost ' + this.salesCurrency, Number(params.data['Sales ' + this.salesCurrency] / 100 * (100 - params.data['Margin %'])).toFixed(3));
            rowNode.setDataValue('Cost', Number(params.data['Cost ' + this.salesCurrency] / params.data['Exch Rate']).toFixed(3));
        }
    }

    getCostPrice(params) {
        let costPrice = 0;
        for (const participant of this.participants[params.rowIndex]) {
            const participantCostColumn = 'Cost (' + participant.id + ')';
            if (this.rowData[params.rowIndex][participantCostColumn]) {
                costPrice = costPrice + Number(params.data[participantCostColumn]) || 0;
            }
        }
        if (!isFinite(costPrice)) {
            costPrice = 0;
        }
        return costPrice.toFixed(3);
    }

    getCostCurPrice(params) {
        let costPrice = 0;
        for (const participant of this.participants[params.rowIndex]) {
            const participantCostColumn = 'Cost ' + this.salesCurrency + ' (' + participant.id + ')';
            if (this.rowData[params.rowIndex][participantCostColumn]) {
                costPrice = costPrice + Number(params.data[participantCostColumn]) || 0;
            }
        }
        if (!isFinite(costPrice)) {
            costPrice = 0;
        }
        return costPrice.toFixed(3);
    }

    getSalesPrice(params) {
        let salesPrice = 0;
        for (const participant of this.participants[params.rowIndex]) {
            if (this.rowData[params.rowIndex]['Sales ' + this.salesCurrency + ' (' + participant.id + ')']) {
                salesPrice = salesPrice + Number(params.data['Sales ' + this.salesCurrency + ' (' + participant.id + ')']) || 0;
            }
        }
        if (!isFinite(salesPrice)) {
            salesPrice = 0;
        }
        return salesPrice.toFixed(3);
    }

    getParticipantsName(id) {
        for (const room in this.itineraryData.data.participants) {
            for (const participant of this.itineraryData.data.participants[room]) {
                if (Number(participant.id) === Number(id)) {
                    if (participant.name) {
                        return participant.name;
                    } else if (participant.surname) {
                        return participant.surname;
                    } else {
                        return '';
                    }
                }
            }
        }
        return '';
    }

    getParticipant(id) {
        for (const room in this.itineraryData.data.participants) {
            for (const participant of this.itineraryData.data.participants[room]) {
                if (Number(participant.id) === Number(id)) {
                    return participant;
                }
            }
        }
        return {};
    }

    getParticipantsId(name) {
        for (const room in this.itineraryData.data.participants) {
            for (const participant of this.itineraryData.data.participants[room]) {
                if (participant.name) {
                    if (String(participant.name) === String(name)) {
                        return participant.id;
                    }
                } else if (participant.surname) {
                    if (String(participant.surname) === String(name)) {
                        return participant.id;
                    }
                }
            }
        }
    }

    changeMarginEnabled(params) {
        if (this.gridApi) {
            const rowNode = this.gridApi.getRowNode(params.rowIndex);
            if (params.data['MarginEnabled'] === true) {
                rowNode.setDataValue('MarginEnabled', true);
                this.saveChangesToObject({ saveTrigger: true });
                return;
            }
            rowNode.setDataValue('MarginEnabled', false);
            this.saveChangesToObject({ saveTrigger: true });
        }
    }

    changeInsuranceEnabled(params) {
        if (this.gridApi) {
            const rowNode = this.gridApi.getRowNode(params.rowIndex);
            if (params.data['InsuranceEnabled'] === true) {
                rowNode.setDataValue('InsuranceEnabled', true);
                this.saveChangesToObject({ saveTrigger: true });
                return;
            }
            rowNode.setDataValue('InsuranceEnabled', false);
            this.saveChangesToObject({ saveTrigger: true });
        }
    }

    changeEnabled(params) {
        const rowNode = this.gridApi.getRowNode(params.rowIndex);
        if (!params.data['Enabled'] === true) {
            rowNode.setDataValue('Enabled', false);
            this.saveChangesToObject({ saveTrigger: true });
            return;
        }
        rowNode.setDataValue('Enabled', true);
        this.saveChangesToObject({ saveTrigger: true });
    }

    // Total calculations start
    getCostTotal() {
        let costTotal = 0;
        for (const orderLine of this.rowData) {
            if (!isNaN(Number(orderLine.Cost)) && orderLine.extra.optional === false && orderLine.extra.unit !== this.roundId) {
                costTotal = costTotal + Number(orderLine.Cost);
            }
        }
        return costTotal;
    }

    getCostCurrencyTotal() {
        let costCurrencyTotal = 0;
        for (const orderLine of this.rowData) {
            if (!isNaN(Number(orderLine['Cost ' + this.salesCurrency])) && orderLine.extra.optional === false && orderLine.extra.unit !== this.roundId) {
                costCurrencyTotal = costCurrencyTotal + Number(orderLine['Cost ' + this.salesCurrency]);
            }
        }
        return costCurrencyTotal;
    }

    getSaleCurrencyTotal() {
        let saleCurrencyTotal = 0;
        for (const orderLine of this.rowData) {
            if (!isNaN(Number(orderLine['Sales ' + this.salesCurrency])) && orderLine.extra.optional === false && orderLine.extra.unit !== this.roundId) {
                saleCurrencyTotal = saleCurrencyTotal + Number(orderLine['Sales ' + this.salesCurrency]);
            }
        }
        return saleCurrencyTotal;
    }

    getParticipantCostTotal(participantName) {
        let participantCostTotal = 0;
        for (const orderLine of this.rowData) {
            if (!isNaN(Number(orderLine[participantName])) && orderLine.extra.optional === false && orderLine.extra.unit !== this.roundId) {
                participantCostTotal = participantCostTotal + Number(orderLine[participantName]);
            }
        }
        return participantCostTotal;
    }

    getParticipantSalesTotal(participantName) {
        let participantSalesTotal = 0;
        for (const orderLine of this.rowData) {
            if (!isNaN(Number(orderLine[participantName])) && orderLine.extra.optional === false && orderLine.extra.unit !== this.roundId) {
                participantSalesTotal = participantSalesTotal + Number(orderLine[participantName]);
            }
        }
        return participantSalesTotal;
    }
    // Totals calculation end

    saveChangesToObject(params = null) {
        if (params != null && ((params.saveTrigger != null && params.saveTrigger === true) || (params.colDef != null && params.colDef.field != null && (params.colDef.field === 'Subtitle' || params.colDef.field === 'Supplier')))) {
            this.itineraryData.data.salesPriceBeforeRounding = 0;
            this.itineraryData.data.costPriceBeforeRounding = 0;
            const itinerary = JSON.parse(JSON.stringify(this.itineraryData)); // Needed to fix RC2-719
            for (let orderLine of this.rowData) {
                for (let segment of itinerary.data.segments || []) {
                    if (segment != null) {
                        for (let element of segment.elements || []) {
                            if (element != null) {
                                if (element.participantSalesUpdate != null) {
                                    delete element.participantSalesUpdate;
                                }
                                if (params.participantSalesUpdate != null && params.participantSalesUpdate === true && params.vtbObjectId != null && element.vtbObjectId === params.vtbObjectId) {
                                    element.participantSalesUpdate = true;
                                }
                                let unitBool = false;
                                if (String(element.vtbObjectId) === String(orderLine.extra.id)) {
                                    if (this.unitsData.items[element.unitId] && this.unitsData.items[element.unitId].id === element.unitId) {
                                        if (element.unitId > 0 && this.helpersService.isText(this.unitsData.items[element.unitId]) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal)) {
                                            unitBool = true;
                                        }
                                    } else if (element.unitId > 0) { // else is needed for if the units are scrambled and not sorted on id
                                        for (const unit of this.unitsData.items) {
                                            if (unit.id === element.unitId && (this.helpersService.isText(unit) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal && (element.olPrices.costPrice > 0 || element.olPrices.salesTotal > 0)))) {
                                                unitBool = true;
                                            }
                                        }
                                    } else {
                                        unitBool = true;
                                    }
                                }

                                if (unitBool === true) {
                                    element.lastRecalc = orderLine.extra.lastRecalc;
                                    element.title = orderLine.Title;
                                    element.subTitle = orderLine.Subtitle;
                                    element.supplierName = orderLine.Supplier;
                                    element.supplierId = orderLine.SupplierId;
                                    element.arrSuppliers = orderLine.Suppliers;
                                    if (orderLine.element.subtitleChanged === true) {
                                        if (params.colDef != null && params.colDef.field === 'Subtitle' && element.vtbObjectId === params.data.element.vtbObjectId) {
                                            element.subTitle = params.value;
                                            element.subtitleChanged = false;
                                            element.not_calculated = true;
                                        } else {
                                            element.subTitle = orderLine.element.subTitle;
                                        }
                                    } else if (params.colDef != null && params.colDef.field === 'Subtitle' && element.vtbObjectId === params.data.element.vtbObjectId) {
                                        element.not_calculated = true;
                                    }
                                    element['manual_calc'] = orderLine.Enabled || false;
                                    element.marginLocked = orderLine.MarginEnabled || false;
                                    element.insuranceLocked = orderLine.InsuranceEnabled || false;
                                    if (element.marginType) {
                                        delete element.marginType;
                                    }
                                    if (element.marginTypeTotal) {
                                        delete element.marginTypeTotal;
                                    }
                                    if (element.TSOrderline != null) {
                                        if (orderLine.Enabled === true) {
                                            element.TSOrderline.locked = 1;
                                        } else {
                                            element.TSOrderline.locked = 0;
                                        }
                                    }
                                    element.olPrices.costPrice = orderLine['Cost ' + this.salesCurrency];
                                    element.olPrices.exchangeRate = orderLine['Exch Rate'];
                                    element.olPrices.margin = orderLine['Margin %'];
                                    element.olPrices.salesTotal = orderLine['Sales ' + this.salesCurrency];
                                    if (this.config.gridViewSettings != null && this.config.gridViewSettings.extra != null && this.config.gridViewSettings.extra.headerName != null) {
                                        element.olPrices[this.config.gridViewSettings.extra.variableName] = orderLine[this.config.gridViewSettings.extra.headerName];
                                    }
                                    if (element.optional === false && element.olPrices != null && element.olPrices.salesTotal != null && element.unitId !== this.roundId && (this.roundingData == null || (this.roundingData && this.roundingData.indexOf(segment.typeId) < 0))) {
                                        itinerary.data.salesPriceBeforeRounding += Number(element.olPrices.salesTotal || 0);
                                    }
                                    if (element.optional === false && element.olPrices != null && element.olPrices.costPrice != null && element.unitId !== this.roundId && (this.roundingData == null || (this.roundingData && this.roundingData.indexOf(segment.typeId) < 0))) {
                                        itinerary.data.costPriceBeforeRounding += Number(element.olPrices.costPrice || 0);
                                    }
                                    for (let participantId in element.olPrices.participants) {
                                        if (element.olPrices.participants[participantId] != null) {
                                            if (orderLine['Cost ' + this.salesCurrency + ' (' + participantId + ')']) {
                                                element.olPrices.participants[participantId].costPrice = orderLine['Cost ' + this.salesCurrency + ' (' + participantId + ')'];
                                            } else {
                                                element.olPrices.participants[participantId].costPrice = null;  // This price used to be null
                                            }
                                            if (orderLine['Sales ' + this.salesCurrency + ' (' + participantId + ')']) {
                                                element.olPrices.participants[participantId].salesPrice = orderLine['Sales ' + this.salesCurrency + ' (' + participantId + ')'];
                                            } else {
                                                element.olPrices.participants[participantId].salesPrice = null;  // This price used to be null
                                            }
                                        }
                                    }
                                    element.olPrices['purchaseCurrency'] = orderLine.Cur || this.salesCurrency;
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            if (itinerary.data.segments != null && itinerary.data.segments.length > 0) {
                itinerary.data.segments.forEach((segment, segmentIndex) => {
                    if (segment != null && segment.elements != null && segment.elements.length > 0) {
                        segment.elements.forEach((element) => {
                            if (params.context != null && params.context.segmentTypes != null) {
                                if (element.insuranceData == null) {
                                    element.insuranceData = this.helpersService.checkInsurance(element, segment, params.context.segmentTypes);
                                }
                                if (element.insuranceLocked === false || element.insuranceLocked == null) {
                                    if (element.insuranceData.type != null) {
                                        const ignoredSegmentTypes = this.helpersService.getIgnoredForInsuranceArray(params.context.segmentTypes, segment);
                                        element.olPrices.salesTotal = this.helpersService.calculateInsurance(itinerary.data.segments, segmentIndex, element.insuranceData.price, element.insuranceData.type, ignoredSegmentTypes, params.context.segmentTypes);
                                        element.olPrices.participants = this.helpersService.calculateParticipantsInsurance(element.olPrices.participants, element.olPrices.salesTotal);
                                    }
                                }
                            }
                        });
                    }
                });
            }
            if (itinerary.data.segments != null && itinerary.data.segments.length > 0) {
                itinerary.data.segments.forEach((segment, segmentIndex) => {
                    if (segment != null && segment.elements != null && segment.elements.length > 0) {
                        segment.elements.forEach((element) => {
                            if (params.context != null && params.context.segmentTypes != null) {
                                if (element.insuranceData == null) {
                                    element.insuranceData = this.helpersService.checkInsurance(element, segment, params.context.segmentTypes);
                                }
                                if (element.insuranceLocked === false || element.insuranceLocked == null) {
                                    if (element.insuranceData.type != null) {
                                        const ignoredSegmentTypes = this.helpersService.getIgnoredForInsuranceArray(params.context.segmentTypes, segment);
                                        element.olPrices.salesTotal = this.helpersService.calculateInsurance(itinerary.data.segments, segmentIndex, element.insuranceData.price, element.insuranceData.type, ignoredSegmentTypes, params.context.segmentTypes);
                                        element.olPrices.participants = this.helpersService.calculateParticipantsInsurance(element.olPrices.participants, element.olPrices.salesTotal);
                                        element.insuranceTimeStamp = Date.now();
                                    }
                                }
                            }
                        });
                    }
                });
            }
            if (itinerary.data.resetTable == null) {
                itinerary.data.resetTable = true;
            } else {
                itinerary.data.resetTable = null;
            }
            this.itineraryData = itinerary; // Needed to fix RC2-719
            this.restartTable();
        }
    }

    saveSalesPrice() {
        this.itineraryData.data.salesPriceBeforeRounding = 0;
        for (let orderLine of this.rowData) {
            for (let segment of this.itineraryData.data.segments || []) {
                if (segment != null) {
                    for (let element of segment.elements || []) {
                        if (element != null) {
                            let unitBool = false;
                            if (String(element.vtbObjectId) === String(orderLine.extra.id)) {
                                if (element.unitId > 0 && this.unitsData.items[element.unitId] && this.unitsData.items[element.unitId].id === element.unitId) {
                                    if (this.helpersService.isText(this.unitsData.items[element.unitId]) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal && (element.olPrices.costPrice > 0 || element.olPrices.salesTotal > 0))) {
                                        unitBool = true;
                                    }
                                } else if (element.unitId > 0) { // else is needed for if the units are scrambled and not sorted on id
                                    for (const unit of this.unitsData.items) {
                                        if (unit.id === element.unitId && (this.helpersService.isText(unit) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal && (element.olPrices.costPrice > 0 || element.olPrices.salesTotal > 0)))) {
                                            unitBool = true;
                                        }
                                    }
                                } else {
                                    unitBool = true;
                                }
                            }

                            if (unitBool === true) {
                                if (element.optional === false && element.olPrices != null && element.olPrices.salesTotal != null && element.unitId !== this.roundId && this.roundingData && this.roundingData.indexOf(segment.typeId) < 0) {
                                    this.itineraryData.data.salesPriceBeforeRounding += Number(element.olPrices.salesTotal || 0);
                                }
                                break;
                            }
                        }
                    }
                }
            }
        }
        this.itineraryData.data.salesPriceAfterRounding = Math.round(this.itineraryData.data.salesPriceBeforeRounding);
        if (this.helpersService.isExpired() === false) {
            this.dayCalculationService.calcAndUpdateItinerary(this.itineraryData, 'ITINERARY_UPDATE', true);
        } else {
            this.matDialog.open(ExpiredDialogComponent, {
                width: '600px'
            });
        }
    }

    setRounding(itinerary, agGrid = null) {
        if (itinerary.data.extraFieldValues != null && itinerary.data.extraFieldValues.length > 0) {
            let roundingValue = 0;

            // get the rounding value
            for (let extraFieldGroup of itinerary.data.extraFieldValues) {
                for (let extraField of extraFieldGroup.fields) {
                    if (extraField.discountRounding != null && (extraField.value === true || extraField.value === 1 || extraField.value === '1')) {
                        roundingValue += extraField.discountRounding;
                    }
                }
            }

            // if the rounding value is not 0, find rounding element(s) and reset all of them
            if (roundingValue !== 0 && this.roundingData != null) {
                let roundingIndex = 0;
                let mainRoundingElement = null;
                for (let segment of itinerary.data.segments || []) {
                    for (let element of segment.elements || []) {
                        if (element.unitId === this.roundId && element.olPrices) {
                            element.unitAmount = 1;
                            const currentParticipants = element.olPrices && element.olPrices.participants ? element.olPrices.participants : {};
                            element.olPrices = { participants: currentParticipants }; // reset the element
                            if (mainRoundingElement === null) {
                                mainRoundingElement = element;
                                break;
                            }
                        }
                        roundingIndex++;
                    }
                }

                // if a mainRoundingElement is available, we know we can now find the total price to calculate the rounding
                if (mainRoundingElement != null) {
                    const totalMargin: number = Number(((this.itineraryData.data.salesPriceBeforeRounding - (this.itineraryData.data.costPriceBeforeRounding)) / this.itineraryData.data.salesPriceBeforeRounding * 100).toFixed(5));
                    const roundedMargin = Number((totalMargin + roundingValue).toFixed(5));
                    const salesPriceAfterRounding: any = Number((Number(this.itineraryData.data.costPriceBeforeRounding) * 100) / (100 - roundedMargin)); // (this.itineraryData.data.costPriceBeforeRounding / 100) * (100+roundedMargin);
                    const roundingObj = {
                        salesPriceBeforeRounding: this.itineraryData.data.salesPriceBeforeRounding,
                        salesPriceAfterRounding: salesPriceAfterRounding,
                        costPriceBeforeRounding: this.itineraryData.data.costPriceBeforeRounding,
                        discount: salesPriceAfterRounding - this.itineraryData.data.salesPriceBeforeRounding,
                        roundedMargin: roundedMargin
                    };

                    mainRoundingElement.olPrices.salesTotal = String(roundingObj.discount);
                    const participantsCount = Object.keys(mainRoundingElement.olPrices.participants).length;
                    if (participantsCount > 0) {
                        for (let participantId in mainRoundingElement.olPrices.participants) {
                            mainRoundingElement.olPrices.participants[participantId].salesPrice = (roundingObj.discount / participantsCount).toFixed(3);
                        }
                    }
                    if (agGrid != null) {
                        agGrid.api.forEachNode((rowNode, index) => {
                            if (index === roundingIndex) {
                                this.setRowNodeFieldValue(rowNode, 'Sales ' + this.salesCurrency, roundingObj.discount);
                                agGrid.api.refreshCells({ rowNodes: [rowNode], force: true });
                                return;
                            }
                        });
                    }
                    this.itineraryData.data.salesPriceAfterRounding = salesPriceAfterRounding;
                    return { roundingElement: mainRoundingElement, rounding: roundingObj };
                }
            }
        }
        this.itineraryData.data.salesPriceAfterRounding = this.itineraryData.data.salesPriceBeforeRounding;
        return null;
    }

    restartTable(initial = null) {
        if ((this.itineraryData && this.itineraryData.data && this.itineraryData.data.resetTable === true) || initial === true) {
            this.rowData = [];
            this.notShownElements = [];
            this.triggerValue = '';
            let index = 0;
            new Promise<void>(resolve => {
                if ((this.itineraryData && this.itineraryData.data)) {
                    for (let { segment, segmentIndex } of this.itineraryData.data.segments.map((segment, segmentIndex) => ({ segment, segmentIndex })) || []) {
                        if (segment != null) {
                            for (let element of segment.elements || []) {
                                if (element.optional === true && this.showOptionals === false) {
                                    continue;
                                }
                                if (this.unitsData) {
                                    if (element != null && element.olPrices) {
                                        let productId = null;
                                        if (element.TSProduct && element.TSProduct.id) {
                                            productId = element.TSProduct.id;
                                        } else {
                                            productId = null;
                                        }
                                        if (element.unitId > 0 && this.unitsData.items[element.unitId] && this.unitsData.items[element.unitId].id === element.unitId) {
                                            if (this.helpersService.isText(this.unitsData.items[element.unitId]) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal && (element.olPrices.costPrice > 0 || element.olPrices.salesTotal > 0))) {
                                                this.generateRowData(element.vtbObjectId, segmentIndex, element.title, element.subTitle, element.optional,
                                                    element.olPrices, index, element, this.itineraryData.data.participants, this.components.PricesCheckboxComponent, this.components.PricesCurrencySelectComponent, this.components.PricesSupplierSelectComponent, this.components.PricesSubtitleComponent, this.components.MarginLockComponent, this.components.InsuranceLockComponent, this.components.GridNumberCellComponent, productId, this.components.InternalInfoIconComponent);
                                                index++;
                                            } else {
                                                element.not_calculated = false;
                                            }
                                        } else if (element.unitId > 0) {  // else is needed for if the units are scrambled and not sorted on id.
                                            for (const unit of this.unitsData.items) {
                                                if (unit.id === element.unitId && (this.helpersService.isText(unit) === false || (element.olPrices && element.olPrices.costPrice && element.olPrices.salesTotal && (element.olPrices.costPrice > 0 || element.olPrices.salesTotal > 0)))) {
                                                    this.generateRowData(element.vtbObjectId, segmentIndex, element.title, element.subTitle, element.optional, element.olPrices, index, element, this.itineraryData.data.participants, this.components.PricesCheckboxComponent, this.components.PricesCurrencySelectComponent, this.components.PricesSupplierSelectComponent, this.components.PricesSubtitleComponent, this.components.MarginLockComponent, this.components.InsuranceLockComponent, this.components.GridNumberCellComponent, productId, this.components.InternalInfoIconComponent);
                                                    index++;
                                                } else {
                                                    element.not_calculated = false;
                                                }
                                            }
                                        } else {
                                            this.notShownElements.push(element);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                resolve();
            }).then(() => {
                if ((this.itineraryData && this.itineraryData.data)) {
                    const roundingObj = this.setRounding(this.itineraryData, this['agGrid']);
                    if (roundingObj) {
                        for (let row of this.rowData) {
                            if (row.extra.id === roundingObj.roundingElement.vtbObjectId) {
                                row['Sales ' + this.salesCurrency] = roundingObj.rounding.discount;
                            }
                        }
                    }
                    this.setTotals(roundingObj);
                    if (this.firstTime === true) {
                        this.firstTime = false;
                        if (this.notShownElements.length > 0) {
                            if (this.notShownElements.length > 1) {
                                this.notificationsService.warn('Warning', this.notShownElements.length + " Elements have no unit and will not be shown", {
                                    timeOut: 5000
                                });
                            } else {
                                this.notificationsService.warn('Warning', this.notShownElements.length + " Element has no unit and will not be shown", {
                                    timeOut: 5000
                                });
                            }
                        }
                    }
                    this.saveSalesPrice();
                }
            });
            if (this.gridApi) {
                this.gridApi.setRowData(this.rowData);
            }
        }
    }

    scrollToSelectPriceRow() {
        this.gridApi.forEachNode(function (node) {
            if (node.data && node.data.element && node.data.element.selectedForUpdatePriceRow === true) {
                node.setSelected(true, true);
                node.gridApi.ensureIndexVisible(node.rowIndex, 'middle');
                node.data.element.selectedForUpdatePriceRow = false;
            }
        });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.setPinnedBottomRowData(this.bottomRowData);
        params.api.ensureIndexVisible(this.visibleIndex, 'middle');
        try {
            (params.api as any).context.beanWrappers.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 1000;
        } catch (e) {
            console.error(e);
        }
        this.scrollToSelectPriceRow();
    }

    setRowNodeFieldValue(rowNode, field, value, parent = null) {
        if (parent == null) {
            rowNode.setDataValue(field, value);
            if (this.columnDefsObj != null && this.columnDefsObj[field] != null && this.columnDefsObj[field].execute != null) {
                for (const method of this.columnDefsObj[field].execute) {
                    method.call(this, Object.assign(rowNode, { value: rowNode.data[field], colDef: { field: field } }), rowNode);
                }
            }
        } else {
            if (this.columnDefsObj != null && this.columnDefsObj[parent] != null && this.columnDefsObj[parent] != null) {
                for (const child of this.columnDefsObj[parent].children) {
                    if (child.field === field) {
                        rowNode.setDataValue(field, value);
                        if (child.execute != null) {
                            for (const method of child.execute) {
                                method.call(this, Object.assign(rowNode, { value: rowNode.data[field], colDef: { field: field } }), rowNode);
                            }
                        }
                        break;
                    }
                }
            }
        }
    }

    recalculatePrices(agGrid) {
        return new Promise<void>((resolve, reject) => {
            this.teleService.getLivePrice(this.itineraryData, this.unitsData).then((results: any) => {
                this.setRecalcData(agGrid, results);
                this.saveChangesToObject({ saveTrigger: true });
                resolve();
            }).catch(e => {
                reject(e);
            });
        });
    }

    recalculateAll(agGrid, context) {
        return new Promise<void>((resolve, reject) => {
            let dialog: any = this.matDialog.open(ConfirmDialogComponent);
            dialog.componentInstance = {
                title: 'Recalculate warning',
                message: `You are about to recalculate all rows that aren't locked, are you sure?`
            };
            dialog.afterClosed().pipe(take(1)).subscribe(x => {
                if (x === 'true') {
                    return this.teleService.getLivePrice(this.itineraryData, this.unitsData, true).then((results: any) => {
                        this.setRecalcData(agGrid, results);
                        this.saveChangesToObject({ saveTrigger: true, context });
                        resolve();
                    }).catch(e => {
                        reject(e);
                    });
                } else {
                    resolve();
                }
            });
        });
    }

    setRecalcData(agGrid, results) {
        for (let item of results) {
            this.recalc = true;
            agGrid.api.forEachNode((rowNode) => {
                const tempMargin = rowNode.data['Margin %'];
                if (item.newPrices != null && String(rowNode.data.extra.id) === String(item.orderlineId)) {
                    if (item.type && item.type === 'desty') {
                        this.setRowNodeFieldValue(rowNode, 'Cost', Number(item.newPrices.value || 0).toFixed(3));
                        if (item.newPrices.currency != null) {
                            this.setRowNodeFieldValue(rowNode, 'Cur', item.newPrices.currency);
                            if (item.newPrices.currency === 'EUR') {
                                this.setRowNodeFieldValue(rowNode, 'Exch Rate', Number(1).toFixed(9));
                            }
                        }
                        if (item.newPrices.subtitle != null) {
                            // this.setRowNodeFieldValue(rowNode, 'Subtitle', item.newPrices.subtitle);
                            item.element.subTitle = item.newPrices.subtitle;
                            item.element.subtitleChanged = true;
                        }
                        if (Number(rowNode.data['Sales EUR']) === 0 && Number(rowNode.data['Cost EUR']) === 0) {
                            this.setRowNodeFieldValue(rowNode, 'Margin %', tempMargin);
                        }
                        item.element.not_calculated = false;
                        if (item.errorMsg != null) {
                            item.element.olPrices.hasError = item.errorMsg;
                            this.notificationsService.error(item.element.title, 'No prices found', {
                                timeOut: 8000
                            });
                            console.log('Could not calculate price due to:', item.element.olPrices.hasError);
                        } else {
                            item.element.olPrices.hasError = null;
                            item.element.olPrices.errorType = null;
                        }
                    } else {
                        this.setRowNodeFieldValue(rowNode, `Sales ${this.salesCurrency}`, Number(item.newPrices.salesPriceInMainCurrency).toFixed(2));
                        this.setRowNodeFieldValue(rowNode, 'Cost', Number(item.newPrices.costPriceInOtherCurrency || 0).toFixed(3));
                        if (item.newPrices.otherCurrencyCode != null) {
                            this.setRowNodeFieldValue(rowNode, 'Cur', item.newPrices.otherCurrencyCode);
                        }
                        let exchangeRate = 1;
                        if (item.newPrices.costPriceInOtherCurrency != null) {
                            const costPriceInMainCurrency = Number(item.newPrices.costPriceInMainCurrency);
                            const costPriceInOtherCurrency = Number(item.newPrices.costPriceInOtherCurrency);
                            exchangeRate = costPriceInMainCurrency + costPriceInOtherCurrency > 0 ? (costPriceInMainCurrency / costPriceInOtherCurrency) : 1;
                        }
                        if (item.newPrices.exchangeRate != null) {
                            exchangeRate = Number(item.newPrices.exchangeRate);
                        }
                        this.setRowNodeFieldValue(rowNode, 'Exch Rate', exchangeRate.toFixed(9));
                        if (rowNode.data.MarginEnabled === false) {
                            this.setRowNodeFieldValue(rowNode, `Sales ${this.salesCurrency}`, Number(item.newPrices.salesPriceInMainCurrency).toFixed(2));
                        }

                        if (item.errorMsg == null) {
                            for (const participant of this.participants[rowNode.rowIndex]) {
                                if (item.participantData != null) {
                                    if (item.participantData.participantTypes[participant.id] == 'baby') {
                                        rowNode.setDataValue('Cost (' + participant.id + ')', Number(item.newPrices.babyCostPriceInOtherCurrency) / item.participantData.nrOfBabies);
                                        rowNode.setDataValue('Cost ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.babyCostPriceInMainCurrency) / item.participantData.nrOfBabies);
                                        rowNode.setDataValue('Sales ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.babySalesPriceInMainCurrency) / item.participantData.nrOfBabies);
                                    } else if (item.participantData.participantTypes[participant.id] == 'child') {
                                        rowNode.setDataValue('Cost (' + participant.id + ')', Number(item.newPrices.childCostPriceInOtherCurrency) / item.participantData.nrOfChildren);
                                        rowNode.setDataValue('Cost ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.childCostPriceInMainCurrency) / item.participantData.nrOfChildren);
                                        rowNode.setDataValue('Sales ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.childSalesPriceInMainCurrency) / item.participantData.nrOfChildren);
                                    } else if (item.participantData.participantTypes[participant.id] == 'teenager') {
                                        rowNode.setDataValue('Cost (' + participant.id + ')', Number(item.newPrices.adolescentCostPriceInOtherCurrency) / item.participantData.nrOfAdolescent);
                                        rowNode.setDataValue('Cost ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.adolescentCostPriceInMainCurrency) / item.participantData.nrOfAdolescent);
                                        rowNode.setDataValue('Sales ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.adolescentSalesPriceInMainCurrency) / item.participantData.nrOfAdolescent);
                                    } else if (item.participantData.participantTypes[participant.id] == 'adult') {
                                        rowNode.setDataValue('Cost (' + participant.id + ')', Number(item.newPrices.adultCostPriceInOtherCurrency) / item.participantData.nrOfAdults);
                                        rowNode.setDataValue('Cost ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.adultCostPriceInMainCurrency) / item.participantData.nrOfAdults);
                                        rowNode.setDataValue('Sales ' + this.salesCurrency + ' (' + participant.id + ')', Number(item.newPrices.adultSalesPriceInMainCurrency) / item.participantData.nrOfAdults);
                                    }
                                }
                            }
                        }

                        this.recalc = false;
                        item.element.not_calculated = false;
                        item.element.lastRecalc = new Date();
                        rowNode.data.extra.lastRecalc = new Date();
                        if (item.element.olPrices.hasError != null) {
                            delete item.element.olPrices.hasError;
                        }
                        if (item.element.olPrices.errorType != null) {
                            delete item.element.olPrices.errorType;
                        }
                        if (Number(rowNode.data['Sales EUR']) === 0 && Number(rowNode.data['Cost EUR']) === 0) {
                            this.setRowNodeFieldValue(rowNode, 'Margin %', tempMargin);
                        }

                        if (item.errorMsg != null) {
                            item.element.olPrices.hasError = item.errorMsg;
                            this.notificationsService.error(item.element.title, 'Failed fetching live prices', {
                                timeOut: 8000
                            });
                            console.log('Could not calculate price due to:', item.element.olPrices.hasError);
                        }
                    }
                } else if (item.caughtError != null) {
                    let error;
                    if (item.caughtError.error != null && item.caughtError.error.error != null) {
                        error = item.caughtError.error.error.message;
                    } else {
                        error = item.caughtError.message;
                    }
                    item.element.not_calculated = false;
                    item.element.olPrices.hasError = error;
                    if (error === 'prices not updated because unit is a text unit') {
                        item.element.olPrices.errorType = 'text';
                    }
                    item.element.olPrices.usedParams = item.usedParams;
                    if (item.showError == null) {
                        this.notificationsService.error(item.element.title, 'Failed fetching live prices', {
                            timeOut: 8000
                        });
                    }
                    console.log('Could not calculate price due to:', item.element.olPrices.hasError);
                }
            });
            this.setNotCalculated(item);
        }
    }

    get tokenObj() {
        let obj: any = {};
        if (this.auth.authenticated != null && this.auth.authenticated === true) {
            obj.token = this.auth.token;
        }
        return obj;
    }

    scrollToSegment(params, retry = true) {
        const plainView = document.querySelector('#plain-view-scroll');
        if (plainView != null) {
            this.pageScrollService.scroll({ document: this.document, scrollTarget: `#item_${params.data.extra.segmentIndex}`, scrollViews: [plainView] });
        } else if (retry === true) {
            this.router.navigate(['/'], { queryParams: this.tokenObj }).then(x => {
                setTimeout(() => {
                    this.scrollToSegment(params, false);
                });
            });
        }
    }

    setNotCalculated(item) {
        for (const segment of this.itineraryData.data.segments) {
            for (const element of segment.elements) {
                if (element.vtbObjectId === item.element.vtbObjectId) {
                    if (item.element.subtitleChanged == null) {
                        element.not_calculated = item.element.not_calculated;
                        return;
                    } else {
                        element.not_calculated = false;
                        element.subtitleChanged = item.element.subtitleChanged;
                        this.itineraryData.data.subtitleChangedTrigger = true;
                        return;
                    }
                }
            }
        }
    }

    setTriggerValue(params) {
        if (this.triggerValue === '') {
            this.triggerValue = params.colDef.field;
        }
    }

    checkMarginEnabled(params) {
        if (params.column.getColId().indexOf('Sales ' + this.salesCurrency) > -1) {
            if (params.data['MarginEnabled'] === true) {
                this.notificationsService.warn('Warning', "Margin is locked. Unlock to manually change the salestotal", {
                    timeOut: 5000
                });
            }
        }
    }

    cellClickedHandler(event) {
        if (event.rowPinned != null && event.rowPinned === 'bottom') {
            const that = event.context;
            let salesDialog: any = null;
            let foundRow = null;
            let correctionUnitFound = false;
            if (event.column && event.column.colId !== 'Margin %') {
                if (that.unitsData != null && that.unitsData.items != null) {
                    const units = Object.keys(that.unitsData.items);
                    for (const unit of units) {
                        if (that.unitsData.items[unit].name === 'correction') {
                            correctionUnitFound = true;
                            break;
                        }
                    }
                }
                if (correctionUnitFound === true) {
                    foundRow = that.checkCorrectionElements();
                }
            }
            if (event.column && event.column.colId === 'Margin %') {
                let marginTotal = event.data['Margin %'];
                const oldMarginPrice = Number((Number(event.data['Cost EUR']) / (100 - Number(marginTotal))) * 100) - Number(event.data['Cost EUR']);
                for (const data of this.rowData) {
                    if (that.segmentTypes && that.segmentTypes.items) {
                        for (const key in that.segmentTypes.items) {
                            if (Object.prototype.hasOwnProperty.call(that.segmentTypes.items, key)) {
                                if (that.segmentTypes.items[key].id != null && that.itineraryData.data.segments[data.extra.segmentIndex] && that.itineraryData.data.segments[data.extra.segmentIndex].typeId && that.segmentTypes.items[key].id === that.itineraryData.data.segments[data.extra.segmentIndex].typeId) {
                                    data.backgroundColor = that.segmentTypes.items[key].backgroundColor;
                                    break;
                                }
                            }
                        }
                    }
                }

                let marginDialog: any = that.matDialog.open(MarginEditDialogComponent);
                marginDialog.componentInstance = {
                    title: 'Change the average Margin for all orderlines',
                    inputHeader: 'Margin total',
                    margin: Number(marginTotal),
                    data: this.rowData,
                    advanced: true,
                    lockedMargin: that.checkMarginLocked()
                };
                marginDialog.afterClosed().pipe(take(1)).subscribe(x => {
                    if (x) {
                        if (x.selectedRows && x.selectedRows.length > 0) {
                            if (x.roundPrices != null) {
                                that.rounded = x.roundPrices;
                            }
                            that['agGrid'].api.forEachNode((rowNode) => {
                                for (const row of x.selectedRows) {
                                    if (rowNode.data.extra.id === row.extra.id) {
                                        that.setRowNodeFieldValue(rowNode, 'Margin %', Number(x.margin).toFixed(2));
                                        if (that.rounded === true) {
                                            that.setRowNodeFieldValue(rowNode, 'Sales EUR', Number(rowNode.data['Sales EUR']).toFixed(0));
                                        }
                                    }
                                }
                            });
                            that.saveChangesToObject({ saveTrigger: true });
                        } else if (x.margin) {
                            if (x.roundPrices != null) {
                                that.rounded = x.roundPrices;
                            }
                            let newMarginPrice = 0;
                            if (Number(x.margin) > 100) {
                                newMarginPrice = Number((Number(event.data['Cost EUR'])) * 100) - Number(event.data['Cost EUR']);
                            } else {
                                newMarginPrice = Number((Number(event.data['Cost EUR']) / (100 - x.margin)) * 100) - Number(event.data['Cost EUR']);
                            }
                            that.calculateMargins(newMarginPrice / oldMarginPrice, Number((Number(event.data['Cost EUR']) / (100 - x.margin)) * 100), x.margin);
                        }
                    }
                });
            } else if (event.column && event.column.colId === 'Sales EUR') {
                if (foundRow != null) {
                    if (that.rowData && that.rowData.length > 0) {
                        salesDialog = that.matDialog.open(PricesParticipantsSalesTotalDialogComponent, {
                            width: '300px'
                        });
                        salesDialog.componentInstance = {
                            title: 'Salesprice total',
                            inputHeader: 'Salesprice total',
                            salesTotal: event.value
                        };
                    } else {
                        that.notificationsService.warn('Warning', "Add data to use the grid feature", {
                            timeOut: 5000
                        });
                    }
                }
            } else if (event.column && event.column.colId.indexOf('Sales EUR (') > -1) {
                if (foundRow != null) {
                    if (that.rowData && that.rowData.length > 0) {
                        salesDialog = that.matDialog.open(PricesParticipantsSalesTotalDialogComponent, {
                            width: '300px'
                        });
                        salesDialog.componentInstance = {
                            title: 'Participant salesprice total',
                            inputHeader: 'Participant total',
                            salesTotal: event.value,
                            participantName: event.column.originalParent.colGroupDef.field || '-'
                        };
                    } else {
                        that.notificationsService.warn('Warning', "Add data to use the grid feature", {
                            timeOut: 5000
                        });
                    }
                }
            }
            if (salesDialog != null) {
                salesDialog.afterClosed().pipe(take(1)).subscribe(x => {
                    if (x.salesTotal != null) {
                        let change = 0;
                        that['agGrid'].api.forEachNode((rowNode) => {
                            if (rowNode.data.extra.id === foundRow.extra.id) {
                                if (x.participantName != null) {
                                    change = Number(x.salesTotal) - Number(event.value);
                                    if (rowNode.data[event.column.colId] != null) {
                                        change += Number(rowNode.data[event.column.colId]);
                                    }
                                    that.setRowNodeFieldValue(rowNode, event.column.colId, Number(change), x.participantName);
                                    that.saveChangesToObject({ saveTrigger: true, participantSalesUpdate: true, colId: event.column.colId, vtbObjectId: foundRow.extra.id });
                                    return;
                                } else {
                                    if (x.divideEqually === true) {
                                        let participantsAmount = that.participants[rowNode.rowIndex].length;
                                        let totalChange = 0;
                                        for (const participant of that.participants[rowNode.rowIndex]) {
                                            change = 0;
                                            const participantSalesColumn = 'Sales ' + that.salesCurrency + ' (' + participant.id + ')';
                                            if (rowNode.data[participantSalesColumn] != null && !isNaN(Number(rowNode.data[participantSalesColumn]))) {
                                                change += Number(x.salesTotal / participantsAmount) - (Number(event.data[participantSalesColumn]) - Number(rowNode.data[participantSalesColumn]));
                                            } else {
                                                change += Number(x.salesTotal / participantsAmount) - Number(event.data[participantSalesColumn]);
                                            }
                                            rowNode.setDataValue(participantSalesColumn, Number(change));
                                            totalChange += change;
                                        }
                                        that.setRowNodeFieldValue(rowNode, event.column.colId, Number(totalChange));
                                    } else {
                                        change = x.salesTotal - event.value;
                                        change += Number(rowNode.data[event.column.colId]);

                                        for (const participant of that.participants[rowNode.rowIndex]) {
                                            const participantSalesColumn = 'Sales ' + that.salesCurrency + ' (' + participant.id + ')';
                                            const proChange = Number(change) * (Number(that.bottomRowData[0][participantSalesColumn]) / Number(that.bottomRowData[0][event.column.colId]));
                                            rowNode.setDataValue(participantSalesColumn, proChange);
                                        }

                                        that.setRowNodeFieldValue(rowNode, event.column.colId, Number(change));
                                    }
                                    that.saveChangesToObject({ saveTrigger: true });
                                    return;
                                }
                            }
                        });
                    }
                });
            }
        }
    }

    calculateMargins(change, oldMarginPriceTotal, margin, rows?) {
        let marginPriceTotal = 0;
        let found = false;
        const rounded = this.rounded;
        this['agGrid'].api.forEachNode((rowNode) => {
            if ((Number(rowNode.data['Margin %']) > 0 || Number(rowNode.data['Margin %']) < 0) && (rowNode.data.extra && rowNode.data.extra.optional === false)) {
                let rowMarginPrice = Number((Number(rowNode.data['Cost EUR']) / (100 - Number(rowNode.data['Margin %']))) * 100) - Number(rowNode.data['Cost EUR']);
                marginPriceTotal += Number(Number(rowNode.data['Cost EUR']) + (rowMarginPrice * change));
                let setSalesPrice = Number(Number(rowNode.data['Cost EUR']) + (rowMarginPrice * change)).toFixed(2);
                if (rounded === true) {
                    this.setRowNodeFieldValue(rowNode, 'Sales EUR', Number(setSalesPrice).toFixed(0));
                } else {
                    this.setRowNodeFieldValue(rowNode, 'Sales EUR', Number(setSalesPrice).toFixed(2));
                }
                if (margin === 0 && rowNode.childIndex === this.rowData.length - 1 && Number(oldMarginPriceTotal) !== Number(marginPriceTotal)) {
                    let dif = marginPriceTotal - Number(oldMarginPriceTotal);
                    if (dif < 0) {
                        dif = dif * -1;
                    }
                    if (rounded === true) {
                        this.setRowNodeFieldValue(rowNode, 'Sales EUR', Number(Number(setSalesPrice) + dif).toFixed(0));
                    } else {
                        this.setRowNodeFieldValue(rowNode, 'Sales EUR', Number(Number(setSalesPrice) + dif).toFixed(2));
                    }
                }
                found = true;
            }
        });
        if (found === false) {
            this['agGrid'].api.forEachNode((rowNode) => {
                if (rowNode.data.extra && rowNode.data.extra.optional === false) {
                    if (Number(rowNode.data['Sales EUR']) < 1) {
                        this.setRowNodeFieldValue(rowNode, 'Sales EUR', Number(1).toFixed(2));
                    }
                    this.setRowNodeFieldValue(rowNode, 'Margin %', Number(margin).toFixed(2));
                }
            });
        }
        this.saveChangesToObject({ saveTrigger: true });
    }

    checkMarginLocked() {
        for (const row of this.rowData) {
            if (row.extra.optional === false) {
                if (row.MarginEnabled === true) {
                    return true;
                }
                if (Number(row['Cost EUR']) == null || Number(row['Cost EUR']) < 0.01) {
                    return true;
                }
            }
        }
        return false;
    }

    checkEnabled() {
        let found = true;
        this['agGrid'].api.forEachNode((rowNode) => {
            if (rowNode.data.Enabled === false) {
                found = false;
            }
        });
        return found;
    }

    checkMarginChecked() {
        let found = true;
        this['agGrid'].api.forEachNode((rowNode) => {
            if (rowNode.data.MarginEnabled === false) {
                found = false;
            }
        });
        return found;
    }

    checkParticipantPrices(itinerary, participantErrorShown) {
        if (itinerary != null && participantErrorShown === false) {
            this.itineraryData = itinerary;
            for (const segment of this.itineraryData.data.segments) {
                for (const element of segment.elements) {
                    if (this.unitsData && this.unitsData.items && this.unitsData.items[element.unitId] && this.helpersService.isText(this.unitsData.items[element.unitId]) === false) {
                        let costTotal = 0;
                        let salesTotal = 0;

                        if (element.olPrices && element.olPrices.participants) {
                            for (const id of Object.keys(element.olPrices.participants)) {
                                if (element.olPrices.participants[id].costPrice) {
                                    costTotal += Number(element.olPrices.participants[id].costPrice);
                                }
                                if (element.olPrices.participants[id].salesPrice) {
                                    salesTotal += Number(element.olPrices.participants[id].salesPrice);
                                }
                            }

                            if (element.olPrices.costPrice == null || element.olPrices.costPrice === '' || element.olPrices.costPrice === 'NaN') {
                                element.olPrices.costPrice = 0;
                            }
                            if (element.olPrices.salesTotal == null || element.olPrices.salesTotal === '' || element.olPrices.salesTotal === 'NaN') {
                                element.olPrices.salesTotal = 0;
                            }

                            if (Number(costTotal).toFixed(2) !== Number(element.olPrices.costPrice).toFixed(2) || Number(salesTotal).toFixed(2) !== Number(element.olPrices.salesTotal).toFixed(2)) {
                                const costDifference = costTotal - element.olPrices.costPrice;
                                const salesDifference = salesTotal - element.olPrices.salesTotal;

                                if (costDifference < -0.01) {
                                    alert(`The sum of the participant Cost prices or sales Prices aren't equal for element: ${element.title} with roomtype: ${element.subTitle}. Please update them and inform SiteSpirit`);
                                    return true;
                                } else if (costDifference > 0.01) {
                                    alert(`The sum of the participant Cost prices or sales Prices aren't equal for element: ${element.title} with roomtype: ${element.subTitle}. Please update them and inform SiteSpirit`);
                                    return true;
                                }
                                if (salesDifference < -0.01) {
                                    alert(`The sum of the participant Cost prices or sales Prices aren't equal for element: ${element.title} with roomtype: ${element.subTitle}. Please update them and inform SiteSpirit`);
                                    return true;
                                } else if (salesDifference > 0.01) {
                                    alert(`The sum of the participant Cost prices or sales Prices aren't equal for element: ${element.title} with roomtype: ${element.subTitle}. Please update them and inform SiteSpirit`);
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
            return true;
        } else {
            return participantErrorShown;
        }
    }

    compareParticipantPrices(type, prices) {
        const participants = Object.keys(prices.participants);
        if (type === 'cost') {
            const costTotal = Number(prices.costPrice);
            let particpantsTotal = 0;
            for (const participant of participants) {
                particpantsTotal += Number(prices.participants[participant].costPrice);
            }
            const difference = costTotal - particpantsTotal;
            if (particpantsTotal === 0) {
                return true;
            }
            if (costTotal === particpantsTotal || (difference < 0.1 || difference > -0.1)) {
                return false;
            }
        } else if (type === 'sales') {
            const salesTotal = Number(prices.salesTotal);
            let particpantsTotal = 0;
            for (const participant of participants) {
                particpantsTotal += Number(prices.participants[participant].salesPrice);
            }
            const difference = salesTotal - particpantsTotal;
            if (particpantsTotal === 0) {
                return true;
            }
            if (salesTotal === particpantsTotal || (difference < 0.1 || difference > -0.1)) {
                return false;
            }
        }
        return true;
    }

    checkCorrectionElements() {
        const foundRows = [];
        if (this.unitsData != null && this.unitsData.items != null) {
            for (const row of this.rowData) {
                if (row.element.unitName === 'correction' || this.unitsData.items[row.element.unitId].name === 'correction') {
                    foundRows.push(row);
                }
            }
        }
        if (foundRows.length === 1) {
            return foundRows[0];
        } else if (foundRows.length < 1) {
            this.notificationsService.warn('Warning', "Total price cannot be changed, please add element with unit: 'correction'", {
                timeOut: 5000
            });
            return null;
        } else if (foundRows.length > 1) {
            this.notificationsService.warn('Warning', "Only 1 element with unit' correction' allowed", {
                timeOut: 5000
            });
            return null;
        }
    }
}
