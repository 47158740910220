import { Component, OnInit, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { MatBottomSheetRef } from '@angular/material';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-notification-center-dialog',
  templateUrl: './notification-center-dialog.component.html',
  styleUrls: ['./notification-center-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCenterDialogComponent implements OnInit, OnDestroy {
  unsubscribe = new Subject<void>();

  itinerary$: Observable<AppModel.Itinerary>;
  itinerarySubscription: Subscription;
  itinerary: AppModel.Itinerary;

  constructor(@Inject(DOCUMENT) private document: any, private store: Store<any>, private pageScrollService: PageScrollService, private matBottomSheetRef: MatBottomSheetRef<NotificationCenterDialogComponent>, private authService: AuthService, private router: Router) {
    this.itinerary$ = this.store.select('itinerary');
  }

  ngOnInit() {
    this.itinerarySubscription = this.itinerary$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.itinerary = x;
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get tokenObj() {
    let obj: any = {};
    if (this.authService.authenticated != null && this.authService.authenticated === true) {
      obj.token = this.authService.token;
    }
    return obj;
  }

  scrollToSegment(segmentIndex, retry = true) {
    const plainView = document.querySelector('#plain-view-scroll');
    if (plainView != null) {
      this.pageScrollService.scroll({ document: this.document, scrollTarget: `#item_${segmentIndex}`, scrollViews: [plainView] });
      this.matBottomSheetRef.dismiss();
    } else if (retry === true) {
      this.router.navigate(['/'], { queryParams: this.tokenObj }).then(x => {
        setTimeout(() => {
          this.scrollToSegment(segmentIndex, false);
        });
      });
    }
  }

  scrollToElement(elementId, retry = true) {
    const participantsView = document.querySelector('app-participants-view');
    if (participantsView != null) { // TODO: needs to be fixed when updating the scrollbar on this page too
      this.pageScrollService.scroll({ document: this.document, scrollTarget: `#${elementId}`, scrollViews: [participantsView] });
      this.matBottomSheetRef.dismiss();
    } else if (retry === true) {
      this.router.navigate(['/participants-view'], { queryParams: this.tokenObj }).then(x => {
        setTimeout(() => {
          this.scrollToElement(elementId, false);
        });
      });
    }
  }
}
