import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const CUSTOMER_TEMPLATES_UPDATE = 'CUSTOMER_TEMPLATES_UPDATE';
const logService = new LogService();

export function customerTemplatesReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {
    case CUSTOMER_TEMPLATES_UPDATE:
      for (const template of action.payload) {
        template.environment = action.config.environment;
        state.push(template);
      }
      logService.consoleLog('CUSTOMER_TEMPLATES_UPDATE:',state);
      return state;
    default:
      return state;
  }
}
