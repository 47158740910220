import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { AdvancedMarginEditDialogComponent } from '../advanced-margin-edit-dialog/advanced-margin-edit-dialog.component';

@Component({
  selector: 'app-margin-edit-dialog',
  templateUrl: './margin-edit-dialog.component.html',
  styleUrls: ['./margin-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarginEditDialogComponent implements OnInit {
  margin: number;
  inputHeader: string;
  title: string;
  data: any;
  selectedSegmentsData: any;
  advanced: boolean;
  lockedMargin: boolean;
  roundPrices = false;

  constructor(private matDialog: MatDialog, private matDialogRef: MatDialogRef<MarginEditDialogComponent>) { }

  ngOnInit() {
    if (this.matDialogRef.componentInstance !== null) {
      this.title = this.matDialogRef.componentInstance.title;
      this.inputHeader = this.matDialogRef.componentInstance.inputHeader;
      this.margin = this.matDialogRef.componentInstance.margin;
      if (this.margin > 100) {
        this.margin = 100;
      } else if (this.margin < -1000) {
        this.margin = 0;
      }
      this.data = this.matDialogRef.componentInstance.data;
      if (this.matDialogRef.componentInstance.selectedSegmentsData != null && this.matDialogRef.componentInstance.selectedSegmentsData.length > 0) {
        this.selectedSegmentsData = this.matDialogRef.componentInstance.selectedSegmentsData;
      }
      this.advanced = this.matDialogRef.componentInstance.advanced;
      this.lockedMargin = this.matDialogRef.componentInstance.lockedMargin;
    }
  }
  
  updateRoundPrices() {
    this.roundPrices = !this.roundPrices;
  }

  openAdvanced() {
    let dialog: any = this.matDialog.open(AdvancedMarginEditDialogComponent);
    dialog.componentInstance = {
      title: 'Check the rows you wish to change the margin for',
      inputHeader: 'Margin total',
      margin: this.margin,
      data: this.data,
      selectedSegmentsData: this.selectedSegmentsData
    };
    dialog.afterClosed().pipe(take(1)).subscribe(x => {
      this.matDialogRef.close(x);
    });
  }
}
