import { Component, Input, ViewChild, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { USER_INTERFACE_UPDATE } from '../../reducers/user-interface';
import { AppState } from '../../models/app-state';
import { Config } from '../../classes/config.class';
import { DayCalculationService } from '../../services/day-calculation.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { HelpersService } from 'app/services/helpers.service';
import { ExpiredDialogComponent } from '../../components/expired-dialog/expired-dialog.component';
import { MatDialog } from '@angular/material';
import { SegmentsCopyDialogComponent } from '../../components/segments-copy-dialog/segments-copy-dialog.component';
import { take } from 'rxjs/operators';
import { SegmentsDeleteDialogComponent } from '../../components/segments-delete-dialog/segments-delete-dialog.component';
import { CreateLibraryOrderDialogComponent } from '../create-library-order-dialog/create-library-order-dialog.component';
import { NotificationsService } from 'angular2-notifications';
import { PubnubService } from 'app/services/pubnub.service';
import { TelespiritService } from 'app/services/telespirit.service';
import { isGlobal } from 'app/services/auth.service';
import { VisualtourbuilderService } from 'app/services/visualtourbuilder.service';
import { SegmentsCopyParticipantsDialogComponent } from '../segments-copy-participants-dialog/segments-copy-participants-dialog.component';
import { InternalInfoDialogComponent } from '../internal-info-dialog/internal-info-dialog.component';

@Component({
    selector: 'app-side-itinerary',
    templateUrl: './side-itinerary.component.html',
    styleUrls: ['./side-itinerary.component.scss']
})
export class SideItineraryComponent implements OnInit {
    @ViewChild('scrollBar') scrollBar;
    @ViewChild('bag') bag: any;
    @ViewChild('focussedElement', { read: ElementRef }) focussedElement: ElementRef;
    @Output() startDate = new EventEmitter();
    @Input() userInterface: any;
    @Input() itinerary: AppModel.Itinerary;
    @Input() segmentTypes: any;
    @Input() unitsValue: any;

    nights: number;
    env: any;
    triggerAnimation = false;

    constructor(
        private dialog: MatDialog,
        private store: Store<AppState>,
        private helpersService: HelpersService,
        private dayCalculationService: DayCalculationService,
        private notificationsService: NotificationsService,
        private pubnubService: PubnubService,
        private telespiritService: TelespiritService,
        private visualtourbuilderService: VisualtourbuilderService,
        private config: Config
    ) {
        this.env = this.config;
    }

    ngOnInit() { }

    get containerLeftStyle() {
        let style = 0;
        if (this.userInterface.showSideNavigation == true) {
            style = 300;
            if (this.userInterface.collapseSideNavigation == true) {
                style = 64;
            }
        }
        return style + 'px';
    }

    get containerWidthStyle() {
        let style = 300;
        if (this.userInterface.collapseSideItinerary == true) {
            style = 64;
        }
        return style + 'px';
    }

    get totalNights() {
        if (this.itinerary.data.segments.length > 0) {
            return ((this.itinerary.data.segments[this.itinerary.data.segments.length - 1].day + this.itinerary.data.segments[this.itinerary.data.segments.length - 1].nights));
        } else {
            return 0;
        }
    }

    toggleCollapse() {
        let payload = this.userInterface;
        payload.collapseSideItinerary = !payload.collapseSideItinerary;
        this.store.dispatch({ type: USER_INTERFACE_UPDATE, payload: payload });
    }

    pushItinerarySegment(id) {
        this.itinerary.data.segments.push({
            typeId: id,
            media: [],
            elements: [],
            flightInfo: [],
            inViewport: false,
            collapse: false,
            content: null,
            date: null,
            endDate: null,
            hasElementsOverlap: false,
            itemType: 'segment',
            maps: { latitude: null, longitude: null, zoom: null },
            subTitle: null,
            title: null,
            nights: null,
            day: null,
            segmentIndex: null,
            vtbObjectId: this.helpersService.generateRandomId(),
            vtbSegmentId: this.helpersService.generateRandomId()
        });
        let days = 0;
        for (let segment of this.itinerary.data.segments) {
            days += segment.nights;
        };
        if (this.itinerary.data.totalDays < days) {
            this.itinerary.data.totalDays = days;
        }
        this.storeUpdateItinerary();
        setTimeout(() => { this.scrollBar.directiveRef.scrollToBottom(); });
    }

    deleteSegment(index) {
        this.itinerary.data.segments.splice(index, 1);
        this.itinerary.data.deletedIndex = index;
        this.storeUpdateItinerary();
    }

    addSegment(obj) {
        if (this.helpersService.isExpired() === false) {
            if (obj.position === 'below') {
                obj.segmentIndex++;
            }
            this.itinerary.data.segments.splice(obj.segmentIndex, 0, {
                typeId: obj.segmentTypeId,
                media: [],
                elements: [],
                flightInfo: [],
                inViewport: false,
                collapse: false,
                content: null,
                date: null,
                endDate: null,
                hasElementsOverlap: false,
                itemType: 'segment',
                maps: { latitude: null, longitude: null, zoom: null },
                subTitle: null,
                title: '',
                nights: 0,
                day: null,
                segmentIndex: null,
                vtbObjectId: this.helpersService.generateRandomId(),
                vtbSegmentId: this.helpersService.generateRandomId()
            });
            this.storeUpdateItinerary();
        } else {
            this.dialog.open(ExpiredDialogComponent, {
                width: '600px'
            });
        }
    }

    addSegmentCopy(event) {
        if (localStorage.getItem('segment copy')) {
            const segmentCopy = JSON.parse(localStorage.getItem('segment copy'));
            if (segmentCopy.elements != null && segmentCopy.elements.length > 0) {
                const dialog: any = this.dialog.open(SegmentsCopyParticipantsDialogComponent);
                dialog.componentInstance = {
                    segments: [segmentCopy],
                    participantsList: this.itinerary.data.participants,
                    segmentTypes: this.segmentTypes
                };
                dialog.afterClosed().pipe(take(1)).subscribe(result => {
                    if (result != null && result !== '' && result.length > 0) {
                        const copiedSegment = JSON.parse(JSON.stringify(result[0]));
                        const cleanResult = this.helpersService.cleanSegmentCopy(copiedSegment, this.itinerary, this.unitsValue);
                        if (event.type === 'above') {
                            this.itinerary.data.segments.splice(event.index, 0, cleanResult);
                            localStorage.removeItem('segment copy');
                        } else {
                            this.itinerary.data.segments.splice(event.index + 1, 0, cleanResult);
                            localStorage.removeItem('segment copy');
                        }
                        this.storeUpdateItinerary();
                    }
                });
            } else {
                const copiedSegment = JSON.parse(JSON.stringify(segmentCopy));
                const cleanResult = this.helpersService.cleanSegmentCopy(copiedSegment, this.itinerary, this.unitsValue);
                if (event.type === 'above') {
                    this.itinerary.data.segments.splice(event.index, 0, cleanResult);
                    localStorage.removeItem('segment copy');
                } else {
                    this.itinerary.data.segments.splice(event.index + 1, 0, cleanResult);
                    localStorage.removeItem('segment copy');
                }
                this.storeUpdateItinerary();
            }
        } else if (localStorage.getItem('segments copy')) {
            let found = false;
            const segmentsCopy = JSON.parse(localStorage.getItem('segments copy'));
            for (const segment of segmentsCopy) {
                if (segment.elements != null && segment.elements.length > 0) {
                    found = true;
                    break;
                }
            }
            if (found === true) {
                const dialog: any = this.dialog.open(SegmentsCopyParticipantsDialogComponent);
                dialog.componentInstance = {
                    segments: segmentsCopy,
                    participantsList: this.itinerary.data.participants,
                    segmentTypes: this.segmentTypes
                };
                dialog.afterClosed().pipe(take(1)).subscribe(result => {
                    if (result != null && result !== '' && result.length > 0) {
                        for (let i = 0; i < result.length; i++) {
                            const copiedSegment = JSON.parse(JSON.stringify(result[i]));
                            const cleanResult = this.helpersService.cleanSegmentCopy(copiedSegment, this.itinerary, this.unitsValue);
                            if (event.type === 'above') {
                                this.itinerary.data.segments.splice(event.index + i, 0, cleanResult);
                            } else {
                                this.itinerary.data.segments.splice(event.index + 1 + i, 0, cleanResult);
                            }
                        }
                        localStorage.removeItem('segments copy');
                        this.storeUpdateItinerary();
                    }
                });
            } else {
                for (let i = 0; i < segmentsCopy.length; i++) {
                    const copiedSegment = JSON.parse(JSON.stringify(segmentsCopy[i]));
                    const cleanResult = this.helpersService.cleanSegmentCopy(copiedSegment, this.itinerary, this.unitsValue);
                    if (event.type === 'above') {
                        this.itinerary.data.segments.splice(event.index + i, 0, cleanResult);
                    } else {
                        this.itinerary.data.segments.splice(event.index + 1 + i, 0, cleanResult);
                    }
                }
                localStorage.removeItem('segments copy');
                this.storeUpdateItinerary();
            }
        }
    }

    endDateChanged() {
        if (this.itinerary.data.startDate != null && (this.itinerary.data.startDate instanceof Date || this.itinerary.data.startDate.hasOwnProperty('_isAMomentObject') === true) && this.itinerary.data.endDate != null && (this.itinerary.data.endDate instanceof Date || this.itinerary.data.endDate.hasOwnProperty('_isAMomentObject') === true)) {
            this.itinerary.data.totalDays = this.daysBetween(this.itinerary.data.startDate, this.itinerary.data.endDate);
        }
        this.storeUpdateItinerary();
    }

    startDateChanged() {
        this.storeUpdateItinerary();
    }

    calcEndDate() {
        if (this.itinerary.data.startDate != null) {
            let date = new Date(this.itinerary.data.startDate);
            date.setDate(date.getDate() + (this.itinerary.data.totalDays - 1));
            this.itinerary.data.endDate = date;
        }
    }

    daysBetween(date1, date2) {
        //Get 1 day in milliseconds
        let one_day = 1000 * 60 * 60 * 24;

        // Convert both dates to milliseconds
        let date1_ms = date1.valueOf();
        let date2_ms = date2.valueOf();

        // Calculate the difference in milliseconds
        let difference_ms = date2_ms - date1_ms;

        // Convert back to days and return
        return Math.round(difference_ms / one_day);
    }

    storeUpdateItinerary(config = null) {
        if (this.helpersService.isExpired() === false) {
            if (config != null) {
                if (config.updateOptionalFlex != null && config.updateOptionalFlex === true) {
                    this.dayCalculationService.calcAndUpdateItinerary(this.itinerary, 'ITINERARY_UPDATE', false, true);
                } else {
                    this.dayCalculationService.calcAndUpdateItinerary(this.itinerary, 'ITINERARY_UPDATE');
                }
                this.store.dispatch({ type: 'COUNTER_ADD' });
            } else {
                this.dayCalculationService.calcAndUpdateItinerary(this.itinerary, 'ITINERARY_UPDATE');
                this.store.dispatch({ type: 'COUNTER_ADD' });
            }
        } else {
            this.dialog.open(ExpiredDialogComponent, {
                width: '600px'
            });
        }
    }

    nightsFormatter(value) {
        if (this.itinerary.data.totalDays) {
            return this.itinerary.data.totalDays - 1;
        } else {
            return 0;
        }
    }

    nightsParser(value) {
        this.itinerary.data.totalDays = value + 1;
    }

    elementDropped(indexObj) {
        if (indexObj.keepParticipants === true) {
            this.dayCalculationService.allocatePartiesToElements(this.itinerary, indexObj, indexObj.element.olPrices.participants, false, true, true);
        } else {
            this.dayCalculationService.allocatePartiesToElements(this.itinerary, indexObj, null, false, true);
        }
    }

    flightElementDropped(indexObj) {
        if (this.itinerary.data.segments != null && this.itinerary.data.segments.length > 0) {
            for (const segment of this.itinerary.data.segments) {
                if (segment.flightInfo != null && segment.flightInfo.length > 0) {
                    let found = false;
                    for (let i = 0; i < segment.flightInfo.length; i++) {
                        if (segment.flightInfo[i].vtbFlightId === indexObj.flight.vtbFlightId) {
                            segment.flightInfo.splice(i, 1);
                            found = true;
                            break;
                        }
                    }
                    if (found === true) {
                        this.itinerary.data.segments[indexObj.segmentIndex].flightInfo.push(indexObj.flight);
                        break;
                    }
                }
            }
        }
        this.store.dispatch({ type: 'COUNTER_ADD' });
        this.storeUpdateItinerary();
    }

    setStartDate($event) {
        this.startDate.emit($event);
    }

    drop(event: CdkDragDrop<any[]>) {
        if (this.itinerary.data && this.itinerary.data.segments && this.itinerary.data.segments.length < 1) {
            if (event.previousContainer.data != null && event.previousContainer.data[event.previousIndex] != null && event.previousContainer.data[event.previousIndex].externalInfo != null && event.previousContainer.data[event.previousIndex].externalInfo.destyFromDate != null) {
                this.itinerary.data.startDate = new Date(event.previousContainer.data[event.previousIndex].externalInfo.destyFromDate);
            }
        }
        if (event.previousContainer === event.container && event.previousIndex !== event.currentIndex) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

            if (event.currentIndex > event.previousIndex) {
                for (let i = event.previousIndex; i < event.currentIndex + 1; i++) {
                    const segment: any = event.container.data[i];
                    for (const element of segment.elements) {
                        if (element.relativeOffset === true) {
                            element.flexSettings = {
                                previousSegmentDate: segment.endDate
                            }
                        }
                    }
                }
            } else {
                for (let i = event.previousIndex; i > event.currentIndex - 1; i--) {
                    const segment: any = event.container.data[i];
                    for (const element of segment.elements) {
                        if (element.relativeOffset === true) {
                            element.flexSettings = {
                                previousSegmentDate: segment.endDate
                            }
                        }
                    }
                }
            }
        } else {
            const internalElement = event.previousContainer.data[event.previousIndex];
            const selectedElements = [];
            if (this.env.retainDraggedElement === true && event.previousContainer.id === 'side-elements') {
                for (let i = 0; i < event.previousContainer.data.length; i++) {
                    if (event.previousContainer.data[i].multiSelected === true) {
                        delete event.previousContainer.data[i].multiSelected;
                        if (this.unitsValue && this.unitsValue.items && this.unitsValue.items[event.previousContainer.data[i].unitId] && this.unitsValue.items[event.previousContainer.data[i].unitId]) {
                            event.previousContainer.data[i].optional = this.config.defaultOptionalUnits.indexOf(this.unitsValue.items[event.previousContainer.data[i].unitId].id) > -1 ? true : event.previousContainer.data[i].optional;
                        }
                        selectedElements.push({
                            data: { ...event.previousContainer.data[i] },
                            index: i
                        });
                    }
                }
                if (selectedElements.length > 0) {
                    event.previousContainer.data[selectedElements[0].index].multiSelectedElements = selectedElements;
                    copyArrayItem(event.previousContainer.data, event.container.data, selectedElements[0].index, event.currentIndex);
                } else {
                    copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
                }
            } else {
                for (let i = 0; i < event.previousContainer.data.length; i++) {
                    if (event.previousContainer.data[i].multiSelected === true) {
                        delete event.previousContainer.data[i].multiSelected;
                        if (this.unitsValue && this.unitsValue.items && this.unitsValue.items[event.previousContainer.data[i].unitId] && this.unitsValue.items[event.previousContainer.data[i].unitId]) {
                            event.previousContainer.data[i].optional = this.config.defaultOptionalUnits.indexOf(this.unitsValue.items[event.previousContainer.data[i].unitId].id) > -1 ? true : event.previousContainer.data[i].optional;
                        }
                        selectedElements.push({
                            data: { ...event.previousContainer.data[i] },
                            index: i
                        });
                    }
                }
                if (selectedElements.length > 0) {
                    event.previousContainer.data[selectedElements[0].index].multiSelectedElements = selectedElements;
                    transferArrayItem(event.previousContainer.data, event.container.data, selectedElements[0].index, event.currentIndex);
                } else {
                    transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
                }
            }

            let internalInfo = '';
            if (internalElement.internalInfo && internalElement.internalInfo != null) internalInfo = internalElement.internalInfo;

            if (this.config.internalPopupIcon != '' && internalInfo.indexOf(this.config.internalPopupIcon) > -1) {
                this.helpersService.showInternalInformationText(internalInfo, this.dialog, InternalInfoDialogComponent);
            }
        }
        this.storeUpdateItinerary();
    }

    toggleSideItinerary() {
        this.store.dispatch({ type: 'USER_INTERFACE_TOGGLE_SIDE_ITINERARY' });
    }

    copySegments() {
        let dialogRef: any = this.dialog.open(SegmentsCopyDialogComponent, {
            width: '600px'
        });
        dialogRef.componentInstance = {
            segments: this.itinerary.data.segments,
            segmentTypes: this.segmentTypes
        };
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result && result.segments && result.segments.length > 0) {
                const segmentCopies = [];
                for (const segment of result.segments) {
                    if (segment.segment) {
                        segment.segment.vtbObjectId = this.helpersService.generateRandomId();
                        if (segment.segment.carRentalElements && segment.segment.carRentalElements.length > 0) {
                            for (const carelement of segment.segment.carRentalElements) {
                                if (carelement.TSOrderline && carelement.TSOrderline.extraFieldValues && carelement.TSOrderline.extraFieldValues.length > 0) {
                                    for (const extraField of carelement.TSOrderline.extraFieldValues) {
                                        extraField.vtbObjectId = this.helpersService.generateRandomId();
                                    }
                                }
                                carelement.vtbObjectId = this.helpersService.generateRandomId();
                            }
                        }
                        if (segment.segment.elements && segment.segment.elements.length > 0) {
                            for (const element of segment.segment.elements) {
                                if (element.TSOrderline && element.TSOrderline.extraFieldValues && element.TSOrderline.extraFieldValues.length > 0) {
                                    for (const extraField of element.TSOrderline.extraFieldValues) {
                                        extraField.vtbObjectId = this.helpersService.generateRandomId();
                                    }
                                }
                                element.vtbObjectId = this.helpersService.generateRandomId();
                            }
                        }
                        segmentCopies.push(JSON.parse(JSON.stringify(segment.segment)));
                    }
                }
                if (localStorage.getItem('segment copy')) {
                    localStorage.removeItem('segment copy');
                }
                localStorage.setItem('segments copy', JSON.stringify(segmentCopies));
            }
        });
    }

    deleteSegments() {
        let dialogRef: any = this.dialog.open(SegmentsDeleteDialogComponent, {
            width: '600px'
        });
        dialogRef.componentInstance = {
            segments: this.itinerary.data.segments,
            segmentTypes: this.segmentTypes
        };
        dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
            if (result && result.segments && result.segments.length > 0) {
                for (const segment of result.segments) {
                    this.itinerary.data.segments.splice(segment.index, 1);
                }
                this.storeUpdateItinerary();
            }
        });
    }

    createLibraryTravelplan() {
        let dialogRef: any = this.dialog.open(CreateLibraryOrderDialogComponent, {
            width: '620px'
        });
        dialogRef.componentInstance = {
            hiddenLayout: this.itinerary.data.hiddenLayout,
            segments: this.itinerary.data.segments,
            segmentTypes: this.segmentTypes
        };
        dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
            if (result != null && result !== '') {
                const itineraryCopy = await this.helpersService.preSaveChecks(this.itinerary);

                itineraryCopy.data.TSOrder.createFromSourceFields = {
                    description: result.templateName,
                    booked_by: 1,
                    currency_rate: 1,
                    discount: 0,
                    locked: 0,
                    show_blocks: 1,
                    office_department_id: null,
                    inserted_by: 1,
                    updated_by: 1,
                    sub_contact_id: null,
                    active: 1
                }
                itineraryCopy.data.segments = result.segments;

                if (result.templateName != null && result.templateName !== '') {
                    if (isGlobal === false) {
                        itineraryCopy.data.TSOrder.sourceDescription = result.templateName;
                    } else {
                        itineraryCopy.name = result.templateName;
                        itineraryCopy.isTemplateVtb = true;
                        itineraryCopy.data.templateView = true;
                    }
                }
                if (result.orderExtraFields === false) {
                    if (itineraryCopy.data.extraFieldValues != null && itineraryCopy.data.extraFieldValues.length > 0) {
                        delete itineraryCopy.data.extraFieldValues;
                    }
                }
                if (result.orderTexts === false) {
                    if (itineraryCopy.data.TSOrder.texts != null) {
                        delete itineraryCopy.data.TSOrder.texts;
                    }
                }
                if (isGlobal === false || this.env.tsToken != null) {
                    this.telespiritService.putItinerary(itineraryCopy).then(() => {
                        this.createLibOrder(itineraryCopy, this.pubnubService.pubnubConfig.publishKey).then((x) => {
                            if (x == null) {
                                this.notificationsService.success('Created Travelplan Template', '');
                                this.telespiritService.putItinerary(itineraryCopy);
                            }
                        }).catch(e => {
                            console.log(e);
                            this.telespiritService.putItinerary(itineraryCopy);
                        });
                    });
                } else {
                    this.visualtourbuilderService.updateVtbData(itineraryCopy).then(() => {
                        this.createLibOrder(itineraryCopy, this.pubnubService.pubnubConfig.publishKey).then(async () => {
                            this.notificationsService.success('Created Travelplan Template', '');
                            this.visualtourbuilderService.updateVtbData(itineraryCopy);
                            if (isGlobal === false || this.config.tsToken != null) {
                                this.telespiritService.putItinerary(itineraryCopy);
                            }
                        }).catch(e => {
                            console.log(e);
                            this.visualtourbuilderService.updateVtbData(itineraryCopy);
                            if (isGlobal === false || this.config.tsToken != null) {
                                this.telespiritService.putItinerary(itineraryCopy);
                            }
                        });
                    });
                }
            }
        });
    }

    async createLibOrder(itinerary, publishKey) {
        if (isGlobal === false || this.config.tsToken != null) {
            const itineraryCopy = JSON.parse(JSON.stringify(itinerary));
            itineraryCopy.id = this.config.selectedItinerary || this.itinerary.id;
            const channel = await this.pubnubService.init(itineraryCopy).then(x => x.channel);
            return this.telespiritService.createLibraryOrder(publishKey, channel);
        } else {
            return this.visualtourbuilderService.createVtbTemplate(itinerary.name, itinerary);
        }
    }

    closeSegmentTypes() {
        this.focussedElement.nativeElement.blur();
    }

    checkAnimation() {
        if (this.triggerAnimation === true) {
            this.focussedElement.nativeElement.blur();
            this.triggerAnimation = false;
        } else {
            this.triggerAnimation = true;
        }
    }
}
