import { Injectable, RendererFactory2, Renderer2, Inject } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Config } from 'app/classes/config.class';

@Injectable({
    providedIn: 'root'
})
export class ThemeService {
    private _mainTheme$: BehaviorSubject<string> = new BehaviorSubject('default-theme');
    private _destyTheme$: BehaviorSubject<string> = new BehaviorSubject('desty-theme');
    //   private _darkMode$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    //   darkMode$: Observable<boolean> = this._darkMode$.asObservable();

    private _renderer: Renderer2;
    private head: HTMLElement;
    private themeLinks: HTMLElement[] = [];

    theme$: Observable<[string, string]>;

    currentTheme = "";

    constructor(
        private config: Config,
        private JwtHelper: JwtHelperService,
        rendererFactory: RendererFactory2,
        @Inject(DOCUMENT) document: Document
    ) {
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        if (token != null && !this.JwtHelper.isTokenExpired(token)) {
            const decodedToken = this.JwtHelper.decodeToken(token);
            let theme = '';
            if (decodedToken['https://visualtourbuilder.com/app_metadata'] != null && decodedToken['https://visualtourbuilder.com/app_metadata'].vtbConfig != null) {
                theme = decodedToken['https://visualtourbuilder.com/app_metadata'].vtbConfig.theme || this.config.theme;
            } else {
                theme = decodedToken.vtbConfig.theme || this.config.theme;
            }

            this.head = document.head;
            this._renderer = rendererFactory.createRenderer(null, null);
            this.theme$ = combineLatest(this._mainTheme$, this._destyTheme$);
            this.theme$.subscribe(async () => {
                const cssExt = '.css';
                const cssFilename = theme + '-theme' + cssExt;
                this.currentTheme = theme;
                await this.loadCss(cssFilename);
                if (this.themeLinks.length == 2) {
                    this._renderer.removeChild(this.head, this.themeLinks.shift());
                }
            })
        }
    }

    // setMainTheme(name: string) {
    //     this._mainTheme$.next(name);
    // }

    //   setDarkMode(value: boolean) {
    //     this._darkMode$.next(value);
    //   }

    private async loadCss(filename: string) {
        return new Promise(resolve => {
            const linkEl: HTMLElement = this._renderer.createElement('link');
            this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
            this._renderer.setAttribute(linkEl, 'type', 'text/css');
            this._renderer.setAttribute(linkEl, 'href', filename);
            this._renderer.setProperty(linkEl, 'onload', resolve);
            this._renderer.appendChild(this.head, linkEl);
            this.themeLinks = [...this.themeLinks, linkEl];
        })
    }
}