import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const UNITS_UPDATE = 'UNITS_UPDATE';
const logService = new LogService();

export function unitsReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {

    case UNITS_UPDATE:
      state = action.payload.reduce((all, item) => {
        item.name = item.name.toLowerCase();
        item.id = Number(item.id);
        if (item.managedBy == null || item.managedBy === 'tenant') {
          all.ids.push(item.id);
          all.items[item.id] = item;
          if (item.flexRelatedProduct != null && item.flexRelatedProduct === true) {
            all.flexId[item.id] = item;
          } else if (item.flexRelated != null && item.flexRelated === true) {
            all.flexId[item.id] = item;
          }
          if (item.name === 'round') {
            all.roundId = item.id;
          }
        }
        return all;
      }, { ids: [], items: {}, roundId: 9999, flexId: {} });
      logService.consoleLog('UNITS_UPDATE:', state);
      return state;

    default:
      return state;
  }
}
