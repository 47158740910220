import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Config } from '../../classes/config.class';

@Component({
  selector: 'app-import-airtrade-dialog',
  templateUrl: './import-airtrade-dialog.component.html',
  styleUrls: ['./import-airtrade-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportAirtradeDialogComponent implements OnInit {
  pnrId: string;
  anvrId: string;
  env: any;

  constructor(private matDialogRef: MatDialogRef<ImportAirtradeDialogComponent>, public config: Config) {
    this.env = this.config;
  }

  ngOnInit() { }

  closeDialog(pnrId) {
    let ids = [];

    pnrId = pnrId.replace(/\s/g, '');
    if (pnrId.indexOf(',') > -1) {
      ids = pnrId.split(',');
    } else {
      ids.push(pnrId);
    }

    this.matDialogRef.close({anvrId: this.anvrId, pnrIds: ids})
  }
}
