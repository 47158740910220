
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { TelespiritService } from '../../services/telespirit.service';
import { Subject, Subscription, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Config } from '../../classes/config.class';
import { AuthService, isGlobal } from '../../services/auth.service';
import { VisualtourbuilderService } from 'app/services/visualtourbuilder.service';

@Component({
  selector: 'app-liborders',
  templateUrl: './liborders.component.html',
  styleUrls: ['./liborders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibordersComponent implements OnInit, OnDestroy {
  @ViewChild('searchContainer', { read: ElementRef }) searchContainer: ElementRef;
  @Input() bags: any[] = [];
  unsubscribe = new Subject<void>();
  env: any;
  liborders$: any;
  libordersSubscription: Subscription;
  libordersValue: any;
  searchText: string;
  searchTextChanged: Subject<string> = new Subject<string>();
  searchTextChangedSubscription: Subscription;

  departments$: Observable<any[]>;
  departmentsSubscription: Subscription;
  departmentsValue: any[];
  selectedDepartments: any[];

  condition: string = 'OR';
  busy = false;
  searched = false;

  searchAmount = 0;
  pageNr = 0;

  constructor(
    private auth: AuthService,
    public config: Config,
    private store: Store<any>,
    private telespiritService: TelespiritService,
    private visualtourbuilderService: VisualtourbuilderService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.env = this.config;
    this.liborders$ = this.store.select('liborders');
    this.departments$ = this.store.select('departments');
  }

  ngOnInit() {
    if (this.env.enablePrefillDepartmentSearch === true) {
      this.selectedDepartments = [this.auth.decodedToken[this.auth.tokenId].departmentId];
    }
    this.libordersSubscription = this.liborders$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      if (isGlobal === true && this.config.baseUrl == null) {
        if (this.libordersValue == null || this.libordersValue.length === 0) {
          this.libordersValue = x.templates;
        } else {
          for (const liborder of x.templates) {
            let found = false;
            for (const libordersValue of this.libordersValue) {
              if (libordersValue.liborderId === liborder.liborderId) {
                found = true;
              }
            }
            if (found === false) {
              this.libordersValue.push(liborder);
            }
          }
        }
      } else {
        this.libordersValue = x.templates;
      }
      this.busy = false;
      this.changeDetectorRef.detectChanges();
    });
    this.departmentsSubscription = this.departments$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.departmentsValue = x;
      this.changeDetectorRef.detectChanges();
    });
    this.searchTextChangedSubscription = this.searchTextChanged.pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.unsubscribe)).subscribe(x => {
      if (this.searchText != null && this.searchText.length > 1) {
        this.search(true);
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get offsetHeight() {
    if (this.searchContainer && this.searchContainer.nativeElement) {
      return this.searchContainer.nativeElement.offsetHeight + 48;
    }
    return 0;
  }

  search(reset = null, pageNr = null) {
    if (reset === true) {
      this.libordersValue = [];
      this.pageNr = 1;
    }
    this.changeDetectorRef.detectChanges();

    let filterExists = false;
    let queryParams: any = {
      pageNr
    };

    if (this.condition != null) {
      queryParams.condition = this.condition;
    }
    if (this.selectedDepartments != null && this.selectedDepartments.length > 0) {
      filterExists = true;
      queryParams.departments = this.selectedDepartments;
    }
    if (this.searchText != null) {
      filterExists = true;
      queryParams.text = this.searchText;
    }
    if (filterExists === true) {
      this.busy = true;
      this.searched = true;
      if (isGlobal === true) {
        if (this.config.baseUrl != null) {
          this.telespiritService.searchLiborders(queryParams);
        } else {
          this.visualtourbuilderService.getTemplates(queryParams).then(data => {
            if (data != null) {
              this.searchAmount = data.totalRows;
              if (reset) {
                this.pageNr = 2;
              } else {
                this.pageNr = this.pageNr + 1;
              }
            }
            this.busy = false;
            this.changeDetectorRef.detectChanges();
          });
        }
      } else {
        this.telespiritService.searchLiborders(queryParams);
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  onSearchTextChange(query: string) {
    this.searchTextChanged.next(query);
  }

  getTooltip(liborder) {
    return liborder.name + '\n' + (liborder.subTitle || '');
  }

  checkLoadMore() {
    if (this.libordersValue.length < this.searchAmount) {
      return true;
    }
    return false;
  }
}
