import { LogService } from '../services/log.service';
import { ActionWithPayload } from '../models/action-with-payload';

export const MARKERS_UPDATE = 'MARKERS_UPDATE';
export const MARKERS_DELETED = 'MARKERS_DELETED';
let logService = new LogService();

export function mapMarkersReducer(state: any = [], action: ActionWithPayload) {
    switch (action.type) {
        case MARKERS_UPDATE:
            state = action.payload;
            logService.consoleLog('MARKERS_UPDATE:', state);
            return state;
        case MARKERS_DELETED:
            state = [];
            logService.consoleLog('MARKERS_DELETED:', state);
            return state;
        default:
            return state;
    }
}
