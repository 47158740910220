import { Component, Input, ChangeDetectionStrategy, OnChanges } from '@angular/core';

@Component({
    selector: 'app-side-context',
    templateUrl: './side-context.component.html',
    styleUrls: ['./side-context.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideContextComponent implements OnChanges {
    @Input() sideContext: any;
    @Input() userInterfaceValue: any;
    @Input() mediaspiritSettings: any;
    @Input() bags: any = {};
    @Input() startDateIndex: any;
    @Input() unitsValue: any;
    authenticated = true;

    constructor() { }

    ngOnChanges() {
        if (this.sideContext !== 'mediaspirit') {
            this.authenticated = true;
        }
    }

    loginResponce(event) {
        this.authenticated = event;
    }

    retryLogin() {
        this.authenticated = true;
    }
}
