import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class CommunicationService {

    // Observable string sources
    private componentMethodCallSourceExtrafields = new Subject<any>();
    private componentMethodCallSourceGeneralInfo = new Subject<any>();
    private componentMethodCallSourceDayTexts = new Subject<any>();

    // Observable string streams
    componentMethodCallSourceExtrafields$ = this.componentMethodCallSourceExtrafields.asObservable();
    componentMethodCallSourceGeneralInfo$ = this.componentMethodCallSourceGeneralInfo.asObservable();
    componentMethodCallSourceDayTexts$ = this.componentMethodCallSourceDayTexts.asObservable();

    // Service message commands
    updateItineraryForOrderExtrafields() {
        this.componentMethodCallSourceExtrafields.next();
    }

    updateItineraryForGeneralInfo() {
        this.componentMethodCallSourceGeneralInfo.next();
    }

    updateItineraryForDayTexts() {
        this.componentMethodCallSourceDayTexts.next();
    }
}
