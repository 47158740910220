/* Special reducer used to trigger change detection on demand, as the itinerary reducer doesnt have this capability */

import { ActionWithPayload } from 'app/models/action-with-payload';
import { LogService } from '../services/log.service';

export const COUNTER_ADD = 'COUNTER_ADD';

export function counterReducer(state = { counter: 0 }, action: ActionWithPayload) {

  switch (action.type) {
    case COUNTER_ADD:
      state.counter = Number(state.counter) + 1;
      let logService = new LogService();
      logService.consoleLog('COUNTER_ADD:', state);
      return { ...state };
    default:
      return state;
  }
}
