import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-expire-warning',
  templateUrl: './expire-warning.component.html',
  styleUrls: ['./expire-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpireWarningComponent implements OnInit {
  @Input() expireTimestamp: any;

  timer: any;
  timeLeft: string;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.expireTimestamp != null) {
      this.timer = setInterval(() => {
        let date_future = new Date();
        date_future.setTime(this.expireTimestamp);
        let date_now = new Date();
    
        let seconds = Math.floor((date_future.getTime() - (date_now.getTime()))/1000);
        let minutes = Math.floor(seconds/60);
        let hours = Math.floor(minutes/60);
        let days = Math.floor(hours/24);
        
        hours = hours-(days*24);
        minutes = minutes-(days*24*60)-(hours*60);
        seconds = seconds-(days*24*60*60)-(hours*60*60)-(minutes*60);
        
        let tempMin = "0" + minutes;
        if (tempMin.length > 2) {
          tempMin = tempMin.slice(1);
        }

        let tempSec = "0" + seconds;
        if (tempSec.length > 2) {
          tempSec = tempSec.slice(1);
        }

        this.timeLeft = hours + ':' + tempMin + ':' + tempSec;
        this.cdr.markForCheck();

        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
          clearInterval(this.timer);
        }
      }, 1000);
    }
  }
}
