import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService, isGlobal } from './auth.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { DayCalculationService } from './day-calculation.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { HelpersService } from './helpers.service';
import { take, skipWhile, catchError } from 'rxjs/operators';
import { Config } from '../classes/config.class';
import { NotificationsService } from 'angular2-notifications';
import { ResponseContentType, Http } from '@angular/http';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { ErrorMessageDialogComponent } from 'app/components/error-message-dialog/error-message-dialog.component';
import { ExpiredDialogComponent } from 'app/components/expired-dialog/expired-dialog.component';
import { DestyService } from './desty.service';
import { VisualtourbuilderService } from './visualtourbuilder.service';

@Injectable()
export class TelespiritService {
    units$: Observable<any>;
    extraFields$: Observable<any>;
    units: any;
    itinerary$: Observable<any>;
    itinerary: any = null;
    token: any;
    baseUrl: any;
    linkType: any;
    linkId: any;
    selectedItinerary: any;

    constructor(
        private http: Http,
        private authHttp: HttpClient,
        private destyService: DestyService,
        private store: Store<any>,
        private auth: AuthService,
        private visualtourbuilderService: VisualtourbuilderService,
        private dayCalculationService: DayCalculationService,
        private title: Title,
        private route: ActivatedRoute,
        private router: Router,
        private helpersService: HelpersService,
        private config: Config,
        private notificationsService: NotificationsService,
        private datePipe: DatePipe,
        private matDialog: MatDialog
    ) {
        this.units$ = this.store.select('units');
        this.units$.subscribe((x) => {
            this.units = x;
        });
        this.itinerary$ = this.store.select('itinerary');
        this.itinerary$.subscribe((x) => {
            this.itinerary = x;
        });
        this.extraFields$ = this.store.select('extraFields');
        console.log('ENVIRONMENT:', this.config);
        const url = new URL(window.location.href);
        this.token = url.searchParams.get("token");
    }

    async getItinerary(itineraryId, iataService, vtbGlobalId = null) {
        await this.setGlobalSettings(itineraryId);
        if (this.helpersService.isExpired() === false) {
            return this.authHttp.get(`${this.baseUrl}/itinerary/${itineraryId || Number(this.selectedItinerary)}`, {
                headers: new HttpHeaders({
                    'Authorization': this.token
                })
            }).toPromise().then(x => {
                return new Promise(async (resolve, reject) => {
                    let response: any = x;
                    if (vtbGlobalId != null) {
                        response.vtb_global_id = vtbGlobalId;
                    }
                    if (this.config.niceUrl && this.config.niceUrl !== '') {
                        response.data.niceUrl = this.config.niceUrl;
                    }
                    this.title.setTitle(`VTB - ${response.name} - #${response.link_id}`);
                    response.data.costPriceBeforeRounding = 0;
                    response.data.salesPriceBeforeRounding = 0;
                    if (!response.data.vtbObjectId) {
                        response.data.vtbObjectId = this.helpersService.generateRandomId();
                    }

                    if (!response.data.TSOrder.texts || response.data.TSOrder.texts.constructor === Array) {
                        response.data.TSOrder.texts = {
                            vtbObjectId: 'orderTexts1'
                        };
                    } else {
                        response.data.TSOrder.texts.vtbObjectId = 'orderTexts1';
                    }

                    if (response.data.TSOrder.texts.internal == null) {
                        response.data.TSOrder.texts.internal = '';
                    }
                    if (response.data.TSOrder.texts.general == null) {
                        response.data.TSOrder.texts.general = '';
                    }
                    if (response.data.TSOrder.texts.proposal == null) {
                        response.data.TSOrder.texts.proposal = '';
                    }
                    if (response.data.TSOrder.texts.invoice == null) {
                        response.data.TSOrder.texts.invoice = '';
                    }
                    if (response.data.TSOrder != null && response.data.TSOrder.language != null) {
                        this.config.orderLanguage = response.data.TSOrder.language;
                    }

                    if (response.hasOwnProperty('changeCounters') === false) {
                        response.changeCounters = { extraFields: 0 };
                    }
                    if (response.data.startDate != null) {
                        response.data.startDate = new Date(response.data.startDate);
                    }
                    if (response.data.endDate != null) {
                        response.data.endDate = new Date(response.data.endDate);
                    }
                    if (response.data.hasOwnProperty('cover') === false) {
                        response.data.cover = [];
                    }
                    if (response.data.hasOwnProperty('participants') === false || typeof response.data.participants !== 'object' || Array.isArray(response.data.participants) === true) {
                        response.data.participants = {};
                    }
                    for (let segment of response.data.segments) {
                        segment.inViewport = false;
                        if (segment.liborderId != null) {
                            delete segment.liborderId;
                        }
                        if (segment.hasOwnProperty('maps') === false) {
                            segment.maps = {};
                        }
                        for (let flightInfo of segment.flightInfo || []) {
                            if (flightInfo.hasOwnProperty('arrivalTime') === false) {
                                flightInfo.arrivalTime = null;
                            }
                            if (flightInfo.hasOwnProperty('departureTime') === false) {
                                flightInfo.departureTime = null;
                            }
                            if (flightInfo.arrivalDate != null) {
                                flightInfo.arrivalDate = moment(flightInfo.arrivalDate);
                            }
                            if (flightInfo.departureDate != null) {
                                flightInfo.departureDate = moment(flightInfo.departureDate);
                            }
                            if (flightInfo.hasOwnProperty('operatedBy') === false) {
                                flightInfo.operatedBy = null;
                            }
                            if (flightInfo.departureAirport != null && flightInfo.departureAirport.length > 0) {
                                if (flightInfo.departureAirportObject == null || Array.isArray(flightInfo.departureAirportObject) === true) {
                                    await iataService.searchSingleAirport(flightInfo.departureAirport, this.config, segment.isHarbour).then(async airport => {
                                        if (airport.iata_code != null || airport.code != null) {
                                            flightInfo.departureAirport = airport.iata_code || airport.code;
                                            flightInfo.departureAirportObject = airport;
                                        }
                                    });
                                }
                            }
                            if (flightInfo.arrivalAirport != null && flightInfo.arrivalAirport.length > 0) {
                                if (flightInfo.arrivalAirportObject == null || Array.isArray(flightInfo.arrivalAirportObject) === true) {
                                    await iataService.searchSingleAirport(flightInfo.arrivalAirport, this.config, segment.isHarbour).then(async airport => {
                                        if (airport.iata_code != null || airport.code != null) {
                                            flightInfo.arrivalAirport = airport.iata_code || airport.code;
                                            flightInfo.arrivalAirportObject = airport;
                                        }
                                    });
                                }
                            }
                        }
                        if (segment.hasOwnProperty('vtbObjectId') === false) {
                            segment.vtbObjectId = this.helpersService.generateRandomId();
                        }
                        for (let element of segment.elements || []) {
                            if (element != null) {
                                element.missingBookableDays = [];
                                if (element.hasOwnProperty('optional') === true && element.optional === true) {
                                    element.optional = true;
                                } else {
                                    element.optional = false;
                                }
                                if (element.hasOwnProperty('olPrices') === false || typeof element.olPrices !== 'object') {
                                    element.olPrices = {
                                        participants: {}
                                    };
                                }
                                if (element.hasOwnProperty('nights') === true && element.hasOwnProperty('unitId') === true && this.units.items[element.unitId] && this.units.items[element.unitId].isTimeBased === true) {
                                    if (this.units.items[element.unitId].flexRelatedProduct === false) {
                                        element.nights = Math.max(eval((this.units.items[element.unitId].multiplier || 'X*1').replace(/x/ig, element.unitAmount)), 0);
                                    } else {
                                        element.needsOffsetRecalculation = true;
                                    }
                                } else {
                                    element.nights = 0;
                                    if (element.unitId != null && this.units.items[element.unitId] != null && this.units.items[element.unitId].flexRelatedProduct === true) {
                                        element.needsOffsetRecalculation = true;
                                    }
                                }
                                if (element.media != null && element.media.length > 0) {
                                    element.media = element.media.filter(x => x.mediaspirit_id != null || x.sourceType === 'mediaspirit' || x.sourceType === 'desty');
                                }
                                if (element.hasOwnProperty('vtbObjectId') === false) {
                                    element.vtbObjectId = this.helpersService.generateRandomId();
                                }
                                if (element.hasOwnProperty('vtbElementId') === false) {
                                    element.vtbElementId = this.helpersService.generateRandomId();
                                }

                                if (element.optional === false && element.olPrices != null && element.olPrices.costPrice != null && element.unitId !== this.units.roundId) {
                                    response.data.costPriceBeforeRounding += Number(element.olPrices.costPrice || 0);
                                }
                                if (element.optional === false && element.olPrices != null && element.olPrices.salesTotal != null && element.unitId !== this.units.roundId) {
                                    response.data.salesPriceBeforeRounding += Number(element.olPrices.salesTotal || 0);
                                }
                                if (element.roomTypes != null && element.roomTypes.length > 0) {
                                    element.roomTypes = (this.config.defaultSubtitleSort != null && this.config.defaultSubtitleSort !== '' && this.config.orderLanguage != null) ? this.helpersService.sortSubtitles(element.roomTypes, this.config.defaultSubtitleSort, this.config.orderLanguage, element.dataSource, this.config.enableLanguageSubtitles) : element.roomTypes;
                                }
                            }
                        }
                    }

                    // merge or add extra field info
                    this.extraFields$.pipe(skipWhile(x => x === undefined), take(1)).subscribe((liveExtraFields: any[]) => {
                        if (response.data.extraFieldValues != null && response.data.extraFieldValues.length > 0) {
                            const liveExtraFieldsMap = liveExtraFields.reduce((all, item) => {
                                if (item.fields != null && item.fields.length > 0) {
                                    for (let field of item.fields) {
                                        delete field.value;
                                        all[field.id] = field;
                                        all[field.id].parent_id = item.id;
                                    }
                                }
                                return all;
                            }, {});

                            const extraFieldsCopy = { ...liveExtraFieldsMap };

                            const liveExtraFieldGroupsMap = liveExtraFields.reduce((all, item) => {
                                delete item.fields;
                                all[item.id] = item;
                                return all;
                            }, {});

                            for (let extraFieldGroup of response.data.extraFieldValues) {
                                if (liveExtraFieldGroupsMap[extraFieldGroup.id] != null) {
                                    extraFieldGroup = Object.assign(extraFieldGroup, liveExtraFieldGroupsMap[extraFieldGroup.id]);
                                }
                                for (let extraField of extraFieldGroup.fields) {
                                    if (liveExtraFieldsMap[extraField.id] != null) {
                                        extraField = Object.assign(extraField, liveExtraFieldsMap[extraField.id]);
                                        delete extraFieldsCopy[extraField.id];
                                    }
                                }
                            }

                            if (response.data.extraFieldValues != null && response.data.extraFieldValues.length > 0) {
                                for (let i = response.data.extraFieldValues.length - 1; i >= 0; i--) {
                                    if (response.data.extraFieldValues[i] != null && liveExtraFieldGroupsMap[response.data.extraFieldValues[i].id] == null) {
                                        response.data.extraFieldValues.splice(i, 1);
                                    } else {
                                        if (liveExtraFieldGroupsMap[response.data.extraFieldValues[i].id].displayAtVtb == null) {
                                            delete response.data.extraFieldValues[i].displayAtTemplate;
                                            delete response.data.extraFieldValues[i].displayAtVtb;
                                        } else if (liveExtraFieldGroupsMap[response.data.extraFieldValues[i].id].display_at_vtb == null) {
                                            delete response.data.extraFieldValues[i].display_at_template_travelplan;
                                            delete response.data.extraFieldValues[i].display_at_travelplan;
                                            delete response.data.extraFieldValues[i].display_at_vtb;
                                            delete response.data.extraFieldValues[i].display_at_vtb_template;
                                        }
                                    }
                                    if (response.data.extraFieldValues[i] != null && response.data.extraFieldValues[i].fields != null && response.data.extraFieldValues[i].fields.length > 0) {
                                        for (let j = response.data.extraFieldValues[i].fields.length - 1; j >= 0; j--) {
                                            if (response.data.extraFieldValues[i].fields[j] != null && liveExtraFieldsMap[response.data.extraFieldValues[i].fields[j].id] == null) {
                                                response.data.extraFieldValues[i].fields.splice(j, 1);
                                            } else {
                                                if (liveExtraFieldsMap[response.data.extraFieldValues[i].fields[j].id].displayAtVtb == null) {
                                                    delete response.data.extraFieldValues[i].fields[j].displayAtTemplate;
                                                    delete response.data.extraFieldValues[i].fields[j].displayAtVtb;
                                                } else if (liveExtraFieldsMap[response.data.extraFieldValues[i].fields[j].id].display_at_vtb == null) {
                                                    delete response.data.extraFieldValues[i].fields[j].display_at_template_travelplan;
                                                    delete response.data.extraFieldValues[i].fields[j].display_at_travelplan;
                                                    delete response.data.extraFieldValues[i].fields[j].display_at_vtb;
                                                    delete response.data.extraFieldValues[i].fields[j].display_at_vtb_template;
                                                }
                                            }
                                        }
                                    }
                                }

                                const extraFieldIds = Object.keys(extraFieldsCopy);

                                if (extraFieldIds != null && extraFieldIds.length > 0) {
                                    for (const id of extraFieldIds) {
                                        for (let i = response.data.extraFieldValues.length - 1; i >= 0; i--) {
                                            if (response.data.extraFieldValues[i] != null && response.data.extraFieldValues[i].fields != null) {
                                                if (response.data.extraFieldValues[i].id === extraFieldsCopy[id].parent_id) {
                                                    delete extraFieldsCopy[id].parent_id;
                                                    response.data.extraFieldValues[i].fields.push(extraFieldsCopy[id]);
                                                    break;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        } else {
                            response.data.extraFieldValues = liveExtraFields;
                        }
                        this.helpersService.setExtraFieldVtbObjectIds(response.data.extraFieldValues);
                        this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                        if (response.data.bookableDaysWarning != null && response.data.bookableDaysWarning == true) {
                            this.notificationsService.warn('Warning', "A non-bookable day in it's period!");
                            response.data.bookableDaysWarning = false;
                        }

                        if (this.helpersService.needsFlexCalc(response.data.segments, this.units)) {
                            // console.log('Found incomplete flex elements, making extra calculations...');
                            this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                            this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                        }

                        const queryParams: Params = { token: this.token };
                        let routerParams: any = { queryParams: queryParams, relativeTo: this.route };

                        if (itineraryId !== null) {
                            routerParams.queryParamsHandling = 'merge';
                        }

                        resolve(response);
                    });

                    // Rechecking pending template statussus
                    if (response.data.publishedTemplates != null && response.data.publishedTemplates.length > 0) {
                        for (const template of response.data.publishedTemplates) {
                            if (template.environment == null && template.status === 'pending') {
                                this.recheckTemplateStatus(template);
                            }
                        }
                    }
                });
            }).catch(e => {
                this.reportError(e);
            });
        } else {
            this.matDialog.open(ExpiredDialogComponent, {
                width: '600px'
            });
        }
    }

    async getLibtextTypes() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/libtextgroup`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            if (response.types) {
                response = response.types;
            }
            this.store.dispatch({ type: 'LIBTEXTS_TYPES_UPDATE', payload: response })
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getLibtextItemsByType(typeId) {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/libtext?type_id=${typeId}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            this.store.dispatch({ type: 'LIBTEXTS_TYPES_ITEMS_UPDATE', payload: { typeId: typeId, items: response } })
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getLibtextItem(id) {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/libtext/${id}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    async getUnits() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/elementunit`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            this.store.dispatch({ type: 'UNITS_UPDATE', payload: response });
            return x;
        }).catch(e => {
            this.reportError(e);
            return [];
        });
    }

    async getSegmentTypes() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/segmenttype`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            this.store.dispatch({ type: 'SEGMENT_TYPES_UPDATE', payload: response, config: this.config })
        }).catch(e => {
            this.reportError(e);
        });
    }

    async searchElements(searchText, postObj, pageNo = null) {
        await this.setGlobalSettings();
        let postParams = JSON.parse(postObj);
        postParams.searchedText = searchText;
        let url = '';
        if (pageNo != null) {
            url = `${this.baseUrl}/element?page_no=${pageNo}`;
        } else {
            url = `${this.baseUrl}/element`;
        }
        return this.authHttp.post(url, JSON.stringify(postParams), {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then((res: any) => {
            let elements: any = res;
            let pageNr = 0;
            let amount = 0;
            if (res.data != null) {
                elements = res.data;
                pageNr = res.pageNo;
                amount = res.totalRecords;
            }
            if (elements != null && elements.length > 0) {
                const markers = [];
                for (const element of elements) {
                    if (element.maps != null && element.maps.latitude != null && element.maps.longitude != null) {
                        markers.push({ title: element.title, description: element.description, latitude: element.maps.latitude, longitude: element.maps.longitude });
                    }
                }
                if (markers && markers.length > 0) {
                    this.store.dispatch({ type: 'MARKERS_UPDATE', payload: markers });
                }
            }
            this.store.dispatch({ type: 'ELEMENTS_UPDATE', payload: this.helpersService.formatSearchedElements(elements, this.units, this.config, this.auth, this.baseUrl), config: this.config });
            return { amount: amount, pageNr: pageNr };
        }).catch(e => {
            this.reportError(e);
            return {};
        });
    }

    async searchConsolidateElements(postObj, pageNo = null) {
        await this.setGlobalSettings();
        let url = '';
        if (pageNo != null) {
            url = `${this.baseUrl}/element?page_no=${pageNo}`;
        } else {
            url = `${this.baseUrl}/element`;
        }
        return this.authHttp.post(url, postObj, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then((res: any) => {
            let elements: any = res;
            let pageNr = 0;
            let amount = 0;
            if (res.data != null) {
                elements = res.data;
                pageNr = res.pageNo;
                amount = res.totalRecords;
            }
            return { elements, amount, pageNr };
        }).catch(e => {
            this.reportError(e);
            return {};
        });
    }

    async getElement(id) {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/element/${id}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    async searchLiborders(postObj) {
        await this.setGlobalSettings();
        return this.authHttp.post(`${this.baseUrl}/template`, postObj, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            this.store.dispatch({ type: 'LIBORDERS_UPDATE', payload: response })
        }).catch(e => {
            this.reportError(e);
        });
    }

    async copyItinerary(itinerary, type = null) {
        await this.setGlobalSettings();
        const itineraryCopy = await this.helpersService.preSaveChecks(itinerary);
        delete itineraryCopy.id;
        itineraryCopy.data.publishedTemplates = [];
        if (type === 'global') {
            this.visualtourbuilderService.minimalCopyVtb(itineraryCopy).then(id => {
                itineraryCopy.vtb_global_id = id;
                this.sendCopyData(itinerary, itineraryCopy);
            });
        } else {
            this.sendCopyData(itinerary, itineraryCopy);
        }
    }

    sendCopyData(itinerary, itineraryCopy) {
        return this.authHttp.post(`${this.baseUrl}/itinerary`, itineraryCopy, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(async x => {
            let response: any = x;
            if (response.hasOwnProperty('changeCounters') === false) {
                response.changeCounters = { extraFields: 0 };
            }
            if (response.data.startDate != null) {
                response.data.startDate = new Date(response.data.startDate);
            }
            if (response.data.endDate != null) {
                response.data.endDate = new Date(response.data.endDate);
            }
            if (response.data.hasOwnProperty('participants') === false || typeof response.data.participants !== 'object' || Array.isArray(response.data.participants) === true) {
                response.data.participants = {};
            }

            let previousId = itinerary.id;
            if (String(previousId).length < 6) {
                let zeros = 6 - String(previousId).length;
                for (let i = 0; i < zeros; i++) {
                    previousId = '0' + previousId;
                }
            }
            response.name = response.name.replace(previousId, ('000000' + response.id).slice(-6));

            let queryParams = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));
            queryParams.id = response.id;
            await this.putItinerary(response, response.id);
            this.router.navigate(['/'], { queryParams: queryParams }).then(x => {
                this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                this.title.setTitle(`VTB - ${response.name} - #${response.link_id}`);
                this.getItineraries();
            });
        });
    }

    async putItinerary(itinerary, selectedItinerary = null) {
        await this.setGlobalSettings(selectedItinerary);
        const itineraryCopy = await this.helpersService.preSaveChecks(itinerary);
        itineraryCopy.id = this.selectedItinerary;
        if (this.config.tsToken != null) {
            await this.visualtourbuilderService.updateVtbData(itineraryCopy);
        }
        return this.authHttp.put(`${this.baseUrl}/itinerary/${this.selectedItinerary}`, itineraryCopy, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            if (response.data.startDate != null) {
                response.data.startDate = new Date(response.data.startDate);
            }
            if (response.data.hasOwnProperty('participants') === false || typeof response.data.participants !== 'object' || Array.isArray(response.data.participants) === true) {
                response.data.participants = {};
            }
            this.store.dispatch({ type: 'ITINERARY_SAVE', payload: response })
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getItineraries() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/itinerary?link_type=${this.linkType}&link_id=${this.linkId}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            this.store.dispatch({ type: 'ITINERARIES_UPDATE', payload: response })
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getParticipants() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/passenger`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then((x: any) => {
            this.store.dispatch({ type: 'PARTICIPANTS_UPDATE', payload: x })
        }).catch(e => {
            this.reportError(e);
        });
    }

    age(birthdate, today) { // https://stackoverflow.com/a/7091965/8979098
        if (birthdate != null) {
            var birthDate = new Date(birthdate);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        } else {
            return null;
        }
    }

    async setLiveElementRoomType(itinerary, element) {
        if (element.TSProduct != null && element.TSProduct.id != null) {
            const liveElement: any = await this.getElement(element.TSProduct.id);
            if (liveElement != null && liveElement.roomTypes != null) {
                element.roomTypes = liveElement.roomTypes;
                element.insuranceData = null;
            }
        }
        this.helpersService.setElementRoomType({
            element: element
        }, itinerary, Object.keys(element.olPrices.participants).length);
    }

    async getLivePrice(itinerary = this.itinerary, unitsData = null, ignore = false) {
        let promises = [];
        let errorSend = true;
        const errorsElements = [];

        for (const segment of itinerary.data.segments) {
            for (const element of segment.elements) {
                if ((ignore === true || element.not_calculated === true) && (element.hasOwnProperty('manual_calc') === false || element.manual_calc === false) && ((element.TSProduct != null && element.TSProduct.id != null) || (element.externalInfo != null && element.externalInfo.externalId != null) || (element.dataSource != null && element.dataSource === 'desty')) && element.unitId !== this.units.roundId) {
                    let unitBool = false;
                    if (unitsData != null) {
                        if (element.unitId > 0 && unitsData.items[element.unitId] != null && unitsData.items[element.unitId].id === element.unitId) {
                            if (this.helpersService.isText(unitsData.items[element.unitId]) === false) {
                                unitBool = true;
                            }
                        } else if (element.unitId > 0) { // else is needed for if the units are scrambled and not sorted on id
                            for (const unit of unitsData.items) {
                                if (unit.id === element.unitId && this.helpersService.isText(unit) === false) {
                                    unitBool = true;
                                }
                            }
                        }
                    } else {
                        unitBool = true;
                    }

                    let queryParams: any;

                    if (unitBool === true) {
                        if (element.externalInfo != null && element.externalInfo.externalType == 'desty') {
                            const partyArray = []
                            if (element.olPrices && element.olPrices.participants && Object.keys(element.olPrices.participants).length > 0) {
                                partyArray.push({ adults: Object.keys(element.olPrices.participants).length });
                            }
                            let postObj = {
                                ids: element.externalInfo.externalId,
                                availabilitySearch: {
                                    checkinDate: element.date.getFullYear() + '-' + ((element.date.getMonth() + 1) < 10 ? '0' : '') + Number(element.date.getMonth() + 1) + '-' + (element.date.getDate() < 10 ? '0' : '') + element.date.getDate(),
                                    nights: element.nights,
                                    party: partyArray
                                }
                            }
                            promises.push(this.destyService.checkHotelAvailability(postObj, 'recalc').then((x: any) => {
                                const res: any = x;
                                if (res[0].hotelInfo && res[0].hotelInfo.rooms && res[0].hotelInfo.rooms.length > 0) {
                                    element.roomTypes = [];
                                    element.insuranceData = null;
                                    for (const room of res[0].hotelInfo.rooms) {
                                        element.roomTypes.push({
                                            defaultPrice: room.sellingPriceTotal,
                                            defaultPricePerRoom: room.sellingPricePerRoom,
                                            description: `${room.name}${room.boardDescription != null && room.boardDescription !== '' ? ` (${room.boardDescription})` : ``}`,
                                            internal_info: room.description,
                                            maxPerson: room.adults,
                                            minPerson: 0,
                                            supplierinfo: room.moreInformation
                                        });
                                    }
                                } else {
                                    return { orderlineId: element.vtbObjectId, newPrices: {}, element: element, errorMsg: 'No rooms found for this hotel at this check in date', type: 'desty' }; // requires fallback id for non orderlines
                                }
                                return this.helpersService.findDestyRate(res[0], element).then(newPrice => {
                                    return { orderlineId: element.vtbObjectId, newPrices: newPrice, element: element, errorMsg: null, type: 'desty' }; // requires fallback id for non orderlines
                                }).catch(e => {
                                    return { orderlineId: element.vtbObjectId, newPrices: {}, element: element, errorMsg: 'No rooms found for this hotel at this check in date', type: 'desty' }; // requires fallback id for non orderlines
                                });
                            }).catch(e => {
                                return { orderlineId: element.vtbObjectId, caughtError: e, element: element, usedParams: postObj, type: 'desty' };
                            }));
                        } else {
                            if (isGlobal === false || (isGlobal === true && this.config.tsToken != null)) {
                                const date = new Date(element.date);
                                queryParams = {
                                    productId: element.TSProduct.id,
                                    startDate: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                                    nrOfItems: element.unitAmount || 0,
                                    nrOfAdults: 0,
                                    nrOfChildren: 0,
                                    nrOfBabies: 0,
                                    nrOfAdolescent: 0,
                                    matrixDescription: element.subTitle || null,
                                    segmentType: segment.typeId
                                };

                                if (element.TSOrderline != null && element.TSOrderline.id != null) {
                                    queryParams.orderlineId = element.TSOrderline.id;
                                    if (element.TSOrderline.liborderId != null) {
                                        queryParams.liborderId = element.TSOrderline.liborderId;
                                    }
                                }

                                if (itinerary.data && itinerary.data.startDate) {
                                    queryParams.exchangeRateLookupDate = this.datePipe.transform(new Date(itinerary.data.startDate), 'yyyy-MM-dd');
                                }

                                // Get nr of participant types
                                if (element.olPrices.participants != null) {
                                    for (let room in itinerary.data.participants) {
                                        for (let participant of itinerary.data.participants[room]) {
                                            if (element.olPrices.participants.hasOwnProperty(String(participant.id)) === true) {
                                                if ((participant.birthdate != null && participant.birthdate !== '') || (participant.age_calc_type != null && participant.age_calc_type === 'birthdate')) {
                                                    if (this.helpersService.isValidDate(participant.birthdate) === true) { // Checks if participant birthdate is not invalid
                                                        if (typeof participant.birthdate !== 'function') { // Transform birthdate String to birthdate Date
                                                            participant.birthdate = new Date(participant.birthdate);
                                                        }
                                                        const age = this.age(participant.birthdate, date);
                                                        const babyAge = element.babyUntilAge != null && element.babyUntilAge > 0 ? element.babyUntilAge : this.config.upAndUntilBabyAge;
                                                        const childAge = element.childUntilAge != null && element.childUntilAge > 0 ? element.childUntilAge : this.config.upAndUntilChildAge;
                                                        const teenagerAge = element.teenagerUntilAge != null && element.teenagerUntilAge > 0 ? element.teenagerUntilAge : this.config.upAndUntilTeenagerAge;

                                                        if (age >= 0 && age <= babyAge) {
                                                            queryParams.nrOfBabies++;
                                                        } else if (age > babyAge && age <= childAge) {
                                                            queryParams.nrOfChildren++;
                                                        } else if (age > childAge && age <= teenagerAge) {
                                                            queryParams.nrOfAdolescent++;
                                                        } else if (age > teenagerAge) {
                                                            queryParams.nrOfAdults++;
                                                        }
                                                    } else {
                                                        participant.birthdate = null; // Sets participant birthdate is null if invalid
                                                        queryParams.nrOfAdults++;
                                                    }
                                                } else if (participant.age_calc_type != null) {
                                                    if (participant.age_calc_type === 'baby') {
                                                        queryParams.nrOfBabies++;
                                                    } else if (participant.age_calc_type === 'child') {
                                                        queryParams.nrOfChildren++;
                                                    } else if (participant.age_calc_type === 'teenager') {
                                                        queryParams.nrOfAdolescent++;
                                                    } else if (participant.age_calc_type === 'adult') {
                                                        queryParams.nrOfAdults++;
                                                    } else {
                                                        queryParams.nrOfAdults++;
                                                    }
                                                } else { // if no birthdate is set, assume participant to be adult
                                                    queryParams.nrOfAdults++;
                                                }
                                            }
                                        }
                                    }
                                }
                                promises.push(this.authHttp.get(`${this.baseUrl}/liveprices`, {
                                    headers: new HttpHeaders({
                                        'Authorization': this.token
                                    }),
                                    params: queryParams
                                }).toPromise().then((x: any) => {
                                    element.subAdditionalText = x.matrixNote;
                                    element.pricematrixSubAdditionalText = x.pricematrixSubAdditionalText;
                                    element.supplierInfo = x.supplierInfo;
                                    element.pmPriceSpecialsPurchaseInfo = x.pmPriceSpecialsPurchaseInfo;
                                    if (x.internalText != null) {
                                        element.internalText = x.internalText;
                                    }
                                    element.pricematrixInternalText = x.pricematrixInternalText;
                                    element.pricespecialInternalText = x.pricespecialInternalText;
                                    element.isElementUpdated = true;
                                    element.isOldPricesUsed = false;
                                    element.vatPercentage = x.vatPercentage || null;
                                    element.vatType = x.vatType || null;
                                    element.vatAmount = x.vatAmount || null;
                                    if (x.oldPricesUsed != null && x.surchargeUsed != null) {
                                        element.isOldPricesUsed = true;
                                    }
                                    if (element.unitAmountPriceError === true) {
                                        return { orderlineId: element.vtbObjectId, newPrices: x, element: element, errorMsg: x.errorMsg + ' and unit amount is 0, so price cannot be calculated' }; // requires fallback id for non orderlines
                                    }
                                    return { orderlineId: element.vtbObjectId, newPrices: x, element: element, errorMsg: x.errorMsg }; // requires fallback id for non orderlines
                                }).catch(e => {
                                    return { orderlineId: element.vtbObjectId, caughtError: e, element: element, usedParams: queryParams };
                                }));
                            }
                        }
                    } else {
                        if (isGlobal === false || (isGlobal === true && this.config.tsToken != null)) {
                            errorSend = false;
                            errorsElements.push(element);
                        }
                    }
                }
            }
        }
        if (errorSend === false) {
            this.notificationsService.warn('Element has a text unit', 'Has not been recalculated', {
                timeOut: 12000,
                showProgressBar: true,
                pauseOnHover: true,
            });
            for (const element of errorsElements) {
                promises.push({ orderlineId: element.vtbObjectId, newPrices: null, element: element, caughtError: { message: 'prices not updated because unit is a text unit' }, showError: false }); // requires fallback id for non orderlines
            }
        }
        return Promise.all(promises).then(x => {
            return x;
        }).catch(e => {
            return e;
        });
    }

    getFullLiborder(liborderId) {
        return this.authHttp.get(`${this.baseUrl}/template/${liborderId}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        });
    }

    async getCustomerTemplates() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/customertemplate`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then((x: any) => {
            this.store.dispatch({ type: 'CUSTOMER_TEMPLATES_UPDATE', payload: x, config: { environment: 'backoffice' } })
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getCustomerTemplate(id) {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/customertemplate/${id}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    publishCustomerTemplate(template, templateName, pubnubChannel, type = null) {
        let obj: any = {
            templateId: template.id,
            vtbId: this.selectedItinerary,
            token: this.token,
            channel: pubnubChannel
        }
        const defaultPdfOptions = {
            format: 'A4',
            margin: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0
            },
            printBackground: true
        }
        if (type != null) {
            if (type === 'pdfcreate') {
                obj.storeBackend = false;
                obj.pdfFileName = templateName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '');
                obj.pdfOptions = template.pdfOptions || defaultPdfOptions;
            } else if (type === 'pdfstore') {
                obj.storeBackend = true;
                obj.pdfFileName = templateName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '');
                obj.pdfOptions = template.pdfOptions || defaultPdfOptions;
            }
        }
        return this.authHttp.post(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/vtbbuilder/start`, obj).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    republishCustomerTemplate(template, pubnubChannel) {
        let obj: any = {
            templateId: template.templateId,
            publishedId: template.publishedId,
            vtbId: this.selectedItinerary,
            token: this.token,
            channel: pubnubChannel
        };

        const defaultPdfOptions = {
            format: 'A4',
            margin: {
                top: 0,
                left: 0,
                bottom: 0,
                right: 0
            },
            printBackground: true
        };

        if (template.type === 'pdf' || template.type === 'pdfjson') {
            if (template.downloadName != null) {
                obj.pdfFileName = template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '');
            } else {
                obj.pdfFileName = template.pdfName.replace(/\s/g, '_');
            }
            obj.pdfOptions = template.pdfOptions || defaultPdfOptions;
        }
        if (template.stored != null && template.stored === true) {
            obj.storeBackend = true;
        }
        return this.authHttp.post(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/vtbbuilder/start`, obj).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    async savePdf(folderName, pdfName, completeUrl = null) {
        await this.setGlobalSettings();
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        };
        let url = 'http://';
        if (completeUrl != null) {
            url += folderName;
        } else {
            url = `http://${folderName}/${pdfName}.pdf`;
        }
        return this.authHttp.post(`${this.baseUrl}/output`, {
            url
        }, httpOptions).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    deleteTemplate(template, vtbId, pubnubChannel) {
        let obj: any = {
            templateId: template.templateId,
            publishedId: template.publishedId,
            vtbId: vtbId,
            token: this.token,
            channel: pubnubChannel,
            delete: true
        }
        return this.authHttp.post(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/vtbbuilder/start`, obj).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    getPubnubChannel() {
        return this.authHttp.get(`https://76apmhoesb.execute-api.eu-west-1.amazonaws.com/prod/vtbbuilder/randomchannel`).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    async getNoNameId() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/createnoname`).toPromise();
    }

    getRoundingSettings() {
        return this.authHttp.get(`${this.baseUrl}/settings/?roundingSettings=true`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    async getCurrencies(date = null) {
        await this.setGlobalSettings();
        let url = `${this.baseUrl}/currency`
        if (date != null) {
            const newDate = new Date(date);
            url = `${this.baseUrl}/currency?exchangeRateLookupDate=${this.datePipe.transform(newDate, 'yyyy-MM-dd')}`
        }
        return this.authHttp.get(url, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then((x: any) => {
            this.store.dispatch({ type: 'CURRENCIES_UPDATE', payload: x })
        }).catch(e => {
            this.reportError(e);
        });
    }

    createPdf(html) {
        //because of the 128kb limit on websockets, the html approach is not possible at this time. so now we are passing the url to generate the pdf
        return this.authHttp.post(`https://zdavuf42bj.execute-api.eu-west-1.amazonaws.com/prod/create-pdf`, { url: location.href }).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    getExtraFields(itineraryId = null) {
        return this.authHttp.get(`${this.baseUrl}/orderextrafield/${Number(this.selectedItinerary || itineraryId)}?lang=EN`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            return x;
        }).catch(e => {
            this.reportError(e);
            return [];
        });
    }

    async getExtraFieldsAndRounding(itineraryId = null, global = null) {
        await this.setGlobalSettings();
        let promises = [this.getExtraFields(itineraryId), this.getRoundingSettings()];
        return Promise.all(promises).then(x => {
            const extraFieldCategories: any = x[0];
            const roundingSettings: any = x[1];
            let discountRounding: any;
            if (roundingSettings != null && roundingSettings.tsoDiscountRounding != null) {
                if (roundingSettings.tsoDiscountRounding != '') {
                    discountRounding = JSON.parse(roundingSettings.tsoDiscountRounding);
                }
            }
            for (let extraFieldCategory of extraFieldCategories) {
                if (global != null) {
                    if (extraFieldCategory.display_at_vtb == null) {
                        extraFieldCategory.displayAtVtb = true;
                        extraFieldCategory.displayAtTemplate = true;
                        delete extraFieldCategory.display_at_vtb;
                        delete extraFieldCategory.display_at_vtb_template;
                        delete extraFieldCategory.display_at_travelplan;
                        delete extraFieldCategory.display_at_template_travelplan;
                    } else {
                        extraFieldCategory.displayAtVtb = extraFieldCategory.display_at_vtb > 0 ? true : false;
                        extraFieldCategory.displayAtTemplate = extraFieldCategory.display_at_vtb_template > 0 ? true : false;
                        delete extraFieldCategory.display_at_vtb;
                        delete extraFieldCategory.display_at_vtb_template;
                        delete extraFieldCategory.display_at_travelplan;
                        delete extraFieldCategory.display_at_template_travelplan;
                    }
                }
                for (let extraField of extraFieldCategory.fields) {
                    if (global != null) {
                        if (extraField.display_at_vtb == null) {
                            extraField.displayAtVtb = true;
                            extraField.displayAtTemplate = true;
                            delete extraField.display_at_vtb;
                            delete extraField.display_at_vtb_template;
                            delete extraField.display_at_travelplan;
                            delete extraField.display_at_template_travelplan;
                            if (extraField.field_type != null) {
                                extraField.type = extraField.field_type;
                                delete extraField.field_type;
                            }
                        } else {
                            extraField.displayAtVtb = extraField.display_at_vtb > 0 ? true : false;
                            extraField.displayAtTemplate = extraField.display_at_vtb_template > 0 ? true : false;
                            delete extraField.display_at_vtb;
                            delete extraField.display_at_vtb_template;
                            delete extraField.display_at_travelplan;
                            delete extraField.display_at_template_travelplan;
                            if (extraField.field_type != null) {
                                extraField.type = extraField.field_type;
                                delete extraField.field_type;
                            }
                        }
                    }

                    if (discountRounding != null && discountRounding[extraField.id] != null) {
                        extraField.discountRounding = Number(discountRounding[extraField.id]);
                    }
                }
            }
            this.store.dispatch({ type: 'EXTRA_FIELDS_UPDATE', payload: extraFieldCategories })
            return x;
        }).catch(e => {
            console.log(e);
        });
    }

    async getElementCategories() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/elementcategory`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            const res: any = x;
            let categories = [
                {
                    description: null,
                    id: null,
                    name: "Uncheck all",
                    parent_id: null,
                    type: "category"
                }
            ];
            categories = categories.concat(res);
            this.store.dispatch({ type: 'ELEMENT_CATEGORIES_UPDATE', payload: categories });
            return x;
        }).catch(e => {
            this.reportError(e);
            return [];
        });
    }

    async transferVtb(publishKey, pubnubChannel, type, postObj = null) {
        await this.setGlobalSettings();
        if (type === 'consolidated' && postObj != null) {
            postObj.id = this.selectedItinerary;
            postObj.channel = pubnubChannel;
            postObj.publishKey = publishKey;
            return this.authHttp.post(`${this.baseUrl}/transfer`, postObj, {
                headers: new HttpHeaders({
                    'Authorization': this.token
                })
            }).toPromise().then(x => {
                if (x != null && x !== true) {
                    this.reportTransferVtbError(x, type);
                } else {
                    this.notificationsService.warn('Consolidated transfer is queued, you will be notified when it is finished', this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm'), {
                        timeOut: 10000,
                        showProgressBar: true,
                        pauseOnHover: true,
                        clickToClose: true
                    });
                }
            }).catch(e => {
                console.log('Caught HTTP Error:', e);
                if (e != null && e.message != null) {
                    this.reportTransferVtbError(e.message);
                }
            });
        } else if (type === 'additional') {
            return this.authHttp.get(`${this.baseUrl}/transfer/${this.selectedItinerary}?channel=${pubnubChannel}&publishKey=${publishKey}&lastMinuteTransfer=true`, {
                headers: new HttpHeaders({
                    'Authorization': this.token
                })
            }).toPromise().then(x => {
                const res: any = x;
                if (res != null && res !== true && ((res.warning != null && res.warning.length > 0) || (res.error != null && res.error.length > 0))) {
                    this.reportTransferVtbError(x, type);
                } else {
                    this.notificationsService.warn('Additional transfer is queued, you will be notified when it is finished', this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm'), {
                        timeOut: 10000,
                        showProgressBar: true,
                        pauseOnHover: true,
                        clickToClose: true
                    });
                }
            }).catch(e => {
                console.log('Caught HTTP Error:', e);
                if (e != null && e.message != null) {
                    this.reportTransferVtbError(e.message);
                }
            });
        } else {
            return this.authHttp.get(`${this.baseUrl}/transfer/${this.selectedItinerary}?channel=${pubnubChannel}&publishKey=${publishKey}`, {
                headers: new HttpHeaders({
                    'Authorization': this.token
                })
            }).toPromise().then(x => {
                const res: any = x;
                if (res != null && res !== true && ((res.warning != null && res.warning.length > 0) || (res.error != null && res.error.length > 0))) {
                    this.reportTransferVtbError(x);
                } else {
                    this.notificationsService.warn('Transfer is queued, you will be notified when it is finished', this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm'), {
                        timeOut: 10000,
                        showProgressBar: true,
                        pauseOnHover: true,
                        clickToClose: true
                    });
                }
            }).catch(e => {
                console.log('Caught HTTP Error:', e);
                if (e != null && e.message != null) {
                    this.reportTransferVtbError(e.message);
                }
            });
        }
    }

    async createLibraryOrder(publishKey, pubnubChannel) {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/transfer/${this.selectedItinerary}?channel=${pubnubChannel}&publishKey=${publishKey}&createLibraryTravelplan=true`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            if (x != null && x !== true) {
                console.log(x);
                return this.reportTransferVtbError(x);
            }
        }).catch(e => {
            console.log('Caught HTTP Error:', e);
            if (e != null && e.message != null) {
                return this.reportTransferVtbError(e.message);
            }
        });
    }

    reportTransferVtbError(errors, type = null) {
        const tempErrors = [];
        const tempWarnings = [];
        if ((errors.error != null && errors.error.length > 0) || (errors.warning != null && errors.warning.length > 0)) {
            if (errors.error != null && errors.error.length > 0) {
                for (const error of errors.error) {
                    if (error != null) {
                        if (error === "Sommige orderregels in de Backoffice hebben al een orderregel korting, dit betekent dat de prijzen van de VTB aangepast zullen worden, kijk alle prijzen goed na") {
                            tempErrors.push("Sommige orderregels in de Backoffice hebben al een orderregel korting, kijk alle prijzen goed na");
                        } else if (error === "Sommige orderregels in de Backoffice zullen een orderregel korting/commissie krijgen, dit betekent dat de prijzen van de VTB aangepast zullen worden, kijk alle prijzen goed na") {
                            tempErrors.push("Sommige orderregels in de Backoffice zullen een orderregel korting/commissie krijgen, kijk alle prijzen goed na");
                        } else {
                            tempErrors.push(error);
                        }
                    }
                }
            }
            if (errors.warning != null && errors.warning.length > 0) {
                for (const warning of errors.warning) {
                    if (warning != null) {
                        tempWarnings.push(warning);
                    }
                }
            }
        } else {
            if (errors.length > 0) {
                for (const error of errors) {
                    if (error != null) {
                        if (error === "Sommige orderregels in de Backoffice hebben al een orderregel korting, dit betekent dat de prijzen van de VTB aangepast zullen worden, kijk alle prijzen goed na") {
                            tempErrors.push("Sommige orderregels in de Backoffice hebben al een orderregel korting, kijk alle prijzen goed na");
                        } else if (error === "Sommige orderregels in de Backoffice zullen een orderregel korting/commissie krijgen, dit betekent dat de prijzen van de VTB aangepast zullen worden, kijk alle prijzen goed na") {
                            tempErrors.push("Sommige orderregels in de Backoffice zullen een orderregel korting/commissie krijgen, kijk alle prijzen goed na");
                        } else {
                            tempErrors.push(error);
                        }
                    }
                }
            }
        }
        if (tempErrors.length > 0 || tempWarnings.length > 0) {
            let dialogTitle = "";
            if (tempErrors.length > 0 && tempWarnings.length > 0) {
                dialogTitle = "Transfer VTB: Error(s) and Warning(s) found"
            } else if (tempErrors.length > 0 && tempWarnings.length === 0) {
                dialogTitle = "Transfer VTB: Error(s) found"
            } else if (tempErrors.length === 0 && tempWarnings.length > 0) {
                dialogTitle = "Transfer VTB: Warning(s) found"
            }
            let dialog: any = this.matDialog.open(ErrorMessageDialogComponent, { disableClose: true });
            dialog.componentInstance = {
                title: dialogTitle,
                errors: tempErrors.concat(tempWarnings)
            };
        }
        if (tempErrors.length > 0) {
            return 'error';
        } else if (type != null) {
            if (type === 'consolidated') {
                this.notificationsService.success('Consolidated transfer was successful', this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm'), {
                    timeOut: 60000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });
            } else if (type === 'additional') {
                this.notificationsService.success('Additional transfer was successful', this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm'), {
                    timeOut: 60000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });
            }
            return null;
        } else {
            this.notificationsService.success('VTB was transferred successfully', this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm'), {
                timeOut: 60000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
            });
            return null;
        }
    }

    async uploadTemporaryObject(obj: any) {
        const itinerary = await this.helpersService.preSaveChecks({ data: obj.itinerary });
        const postObj = {
            token: this.token,
            itinerary: this.helpersService.cleanUpData(itinerary.data),
            templateId: obj.templateId,
            customerTemplate: obj.customerTemplate
        };
        return this.authHttp.post(`https://3f9u2w47tj.execute-api.eu-west-1.amazonaws.com/prod/upload-settings`, postObj).toPromise().then((x: any) => {
            return x.fileName;
        });
    }

    // saveElementAsProduct(element) {
    //   const apolloQuery = gql`
    //     mutation {
    //       addProduct(name: "${element.title}"){
    //         id
    //         name
    //       }
    //     }
    //   `;
    //   return this.apollo.mutate({
    //     mutation: apolloQuery,
    //   }).toPromise().then(x => x).catch(e => console.log(e));
    // }

    async getDepartments() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/department`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            this.store.dispatch({ type: 'DEPARTMENTS_UPDATE', payload: x });
            return x;
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getTpData() {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/tpdata/${this.linkId}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            this.config.tpData = x;
            return x;
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getAirtrade(data, startDate, vtbEndDate, lastFlightBlock) {
        await this.setGlobalSettings();
        if (data != null && data.length > 0) {
            const criterias = [];
            let index = 0;
            for (let i = 0; i < data.length; i++) {
                if (data[i].departureAirport != null && data[i].arrivalAirport != null) {
                    let departureDate = null;
                    if (data[i].departureDate != null) {
                        data[i].departureDate = new Date(data[i].departureDate);
                    } else {
                        if (lastFlightBlock === true && data[i + 1] == null && data[i - 1] != null) {
                            data[i].departureDate = new Date(vtbEndDate);
                        } else {
                            data[i].departureDate = new Date(startDate);
                        }
                    }
                    if (typeof data[i].departureDate === 'object') {
                        departureDate = this.datePipe.transform(data[i].departureDate, 'yyyy-MM-dd');
                    } else {
                        departureDate = data[i].departureDate;
                    }
                    const obj = {
                        "LegNumber": index,
                        "Airport": data[i].airlineCode || null,
                        "DepartureCode": data[i].departureAirport,
                        "ArrivalCode": data[i].arrivalAirport,
                        "DepartureDate": departureDate,
                        "UseTimePreference": false
                    }
                    criterias.push(obj);
                    index++;

                    // TODO: uncomment if this works in the API
                    // if (flightInfo.airlineCode != null) {
                    //   airportFilter = true;
                    // }          
                }
            }

            const postObj = {
                "PassengerAndPreferenceCriteria": {
                    "NumberOfAdults": 2,
                    "NumberOfChildren": 0,
                    "NumberOfInfants": 0,
                    "CabinClass": "Economy",
                    "ApplyAirportFilter": false, // airportFilter, TODO: uncomment if this works in the API
                    "LowCostOnly": false,
                    "NonStopOnly": false
                },
                "LegCriterias": []
            }

            postObj.LegCriterias = criterias;

            return this.authHttp.post(`${this.baseUrl}/airtrade`, postObj, {
                headers: new HttpHeaders({
                    'Authorization': this.token
                })
            }).toPromise().then(x => {
                return x;
            }).catch(e => {
                if (e.error && e.error.error && e.error.error.message && e.error.error.message && e.error.error.message.toLowerCase().indexOf('validation') >= 0) {
                    return { errorMessage: 'You are trying to find a flight too many days in advance or there are no flights available on the searched date(s) / Departure Airport / Arrival Airport.' }
                }
                this.reportError(e);
            });
        }
    }

    async getAirtradeById(id, anvrId = null) {
        await this.setGlobalSettings();
        let url;
        if (anvrId != null) {
            url = `${this.baseUrl}/airtrade?pnr=${id}&anvr=${anvrId}`;
        } else {
            url = `${this.baseUrl}/airtrade?pnr=${id}`;
        }
        return this.authHttp.get(url, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            return x;
        }).catch(e => {
            if (e.error && e.error.error && e.error.error.message && (e.error.error.message === "Internal Server Error: Invalid PNR" || e.name === "HttpErrorResponse")) {
                this.notificationsService.error('Error gathering data', 'Please enter a valid PNR');
            } else {
                this.reportError(e);
            }
        });
    }

    downloadFile(url, ignore = false) {
        return this.http.get(url, { responseType: ResponseContentType.Blob }).pipe(catchError(err => this.reportError(err, 'downloadError', ignore)));
    }

    setGlobalSettings(selectedItinerary = null) {
        let changed = false;
        if (this.config.tsToken != null) {
            this.token = this.config.tsToken;
            if (this.baseUrl == null) {
                this.baseUrl = this.config.baseUrl;
                changed = true;
            }
            if (this.linkType == null) {
                this.linkType = this.config.linkType;
                changed = true;
            }
            if (this.linkId == null) {
                this.linkId = this.config.linkId;
                changed = true;
            }
            if (this.selectedItinerary == null || selectedItinerary != null) {
                if (this.selectedItinerary == null && selectedItinerary == null) {
                    this.selectedItinerary = this.config.selectedItinerary;
                    changed = true;
                } else {
                    this.config.selectedItinerary = selectedItinerary;
                    this.selectedItinerary = selectedItinerary;
                    changed = true;
                }
            }
        } else {
            if (this.auth.decodedToken != null && this.auth.decodedToken[this.auth.tokenId] != null) {
                if (this.baseUrl == null) {
                    this.baseUrl = this.auth.decodedToken[this.auth.tokenId].baseUrl;
                    changed = true;
                }
                if (this.linkType == null) {
                    this.linkType = this.auth.decodedToken[this.auth.tokenId].linkType;
                    changed = true;
                }
                if (this.linkId == null) {
                    this.linkId = this.auth.decodedToken[this.auth.tokenId].linkId;
                    changed = true;
                }
                if (this.selectedItinerary == null || selectedItinerary != null) {
                    if (this.selectedItinerary == null && selectedItinerary == null) {
                        this.config.selectedItinerary = this.auth.decodedToken[this.auth.tokenId].selectedItinerary;
                        this.selectedItinerary = this.auth.decodedToken[this.auth.tokenId].selectedItinerary;
                        changed = true;
                    } else {
                        this.config.selectedItinerary = selectedItinerary;
                        this.selectedItinerary = selectedItinerary;
                        changed = true;
                    }
                }
            }
        }
        if (changed === true) {
            this.store.dispatch({ type: 'COUNTER_ADD' });
        }
    }

    reportError(e, type = null, ignore = false) {
        if (e != null && e.statusText != null && (e.statusText === "Unknown Error" || e.statusText === "")) {
            return Promise.resolve(true);
        }
        if (type != null && type === 'downloadError') {
            if (ignore === false) {
                this.notificationsService.warn('Status not ready yet', `Recheck the status in a minute (big VTB's could take longer)`, {
                    timeOut: 5000
                });
            }
            return Promise.resolve(false);
        }
        console.log('Caught HTTP Error:', e);
        this.notificationsService.error('Caught HTTP Error', 'See console for details');
        return e;
    }

    async checkConsolidate(pubnubChannel) {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/consolidatecheck?channel=${pubnubChannel}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            return response;
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getProductSuppliers(productId) {
        await this.setGlobalSettings();
        return this.authHttp.get(`${this.baseUrl}/supplier?id=${productId}`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => {
            let response: any = x;
            return response;
        }).catch(e => {
            this.reportError(e);
        });
    }

    async getCountries() {
        return this.authHttp.get(`${this.baseUrl}/countries?lang=NL`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    async getAirports() {
        return this.authHttp.get(`${this.baseUrl}/airports`, {
            headers: new HttpHeaders({
                'Authorization': this.token
            })
        }).toPromise().then(x => x).catch(e => this.reportError(e));
    }

    recheckTemplateStatus(template) {
        let url = '';
        if (template.completeUrl != null) {
            url = `https://${template.url}`;
        }
        if (template != null && template.downloadName != null && template.pdfName != null) {
            if (url.length === 0) {
                url = template.downloadName != null ? `https://${template.url}/${template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '')}.pdf` : `https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`;
            }
            this.downloadFile(url, true).subscribe(async response => {
                if (response) {
                    template.status = 'success';
                    this.notificationsService.success('PDF status has been refreshed!', '', {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                    this.store.dispatch({ type: 'COUNTER_ADD' });
                    if (isGlobal === false || this.config.tsToken != null) {
                        this.putItinerary(this.itinerary);
                    }
                    if (isGlobal === true) {
                        this.visualtourbuilderService.updateVtbData(this.itinerary);
                    }
                }
            }), error => console.log('Error checking status', error);
        } else if (template != null && template.url != null && template.pdfName != null) {
            if (url.length === 0) {
                url = `https://${template.url}/${template.pdfName.replace(/\s/g, '_')}.pdf`;
            }
            this.downloadFile(url, true).subscribe(async response => {
                if (response) {
                    template.status = 'success';
                    this.notificationsService.success('PDF status has been refreshed!', '', {
                        timeOut: 5000,
                        showProgressBar: true,
                        pauseOnHover: true,
                    });
                    this.store.dispatch({ type: 'COUNTER_ADD' });
                    if (isGlobal === false || this.config.tsToken != null) {
                        this.putItinerary(this.itinerary);
                    }
                    if (isGlobal === true) {
                        this.visualtourbuilderService.updateVtbData(this.itinerary);
                    }
                }
            }), error => console.log('Error checking status', error);
        } else if (template != null && template.url != null) {
            if (url.length === 0) {
                url = `https://${template.url}/index.html`;
            }
            this.downloadFile(url, true).subscribe(async response => {
                if (response) {
                    template.status = 'success';
                    if (template.type && template.type === 'website') {
                        this.notificationsService.success('Website status has been refreshed!', '', {
                            timeOut: 5000,
                            showProgressBar: true,
                            pauseOnHover: true,
                        });
                    } else {
                        this.notificationsService.success('Website/App status has been refreshed!', '', {
                            timeOut: 5000,
                            showProgressBar: true,
                            pauseOnHover: true,
                        });
                    }
                    this.store.dispatch({ type: 'COUNTER_ADD' });
                    if (isGlobal === false || this.config.tsToken != null) {
                        this.putItinerary(this.itinerary);
                    }
                    if (isGlobal === true) {
                        this.visualtourbuilderService.updateVtbData(this.itinerary);
                    }
                }
            }), error => console.log('Error checking status', error);
        }
    }
}
