import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-segment-elements-update-dialog',
  templateUrl: './segment-elements-update-dialog.component.html',
  styleUrls: ['./segment-elements-update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentElementsUpdateDialogComponent implements OnInit {
  productUpdates: any = [];

  constructor(private matDialogRef: MatDialogRef<SegmentElementsUpdateDialogComponent>) { }

  ngOnInit() {
    if (this.matDialogRef.componentInstance !== null) {
      this.productUpdates = this.matDialogRef.componentInstance.productUpdates;
    }
  }

  setChecked(event, index) {
    for (let i = 0; i < this.productUpdates.length; i++) {
      if (index === i) {
        const checked = event.checked;
        for (let element of this.productUpdates[i].elementCheckUncheckAll) {
          if (typeof element.extrafields === 'undefined') {
            this.productUpdates[i].elementUpdates[element] = checked;
          } else {
            for (let extrafield of element.extrafields) {
              this.productUpdates[i].elementUpdates.extrafields[extrafield] = checked;
            }
          }
        }
      }
    }
  }
}