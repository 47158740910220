import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { AppState } from './models/app-state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService, isGlobal } from './services/auth.service';
import { TelespiritService } from './services/telespirit.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Config } from './classes/config.class';
import { HelpersService } from './services/helpers.service';
import { MatDialog } from '@angular/material';
import { take } from 'rxjs/operators';
import { DestyService } from './services/desty.service';
import { ImportAirtradeDialogComponent } from './components/import-airtrade-dialog/import-airtrade-dialog.component';
import { NotificationsService } from 'angular2-notifications';
import { IataService } from './services/iata.service';
import { VisualtourbuilderService } from './services/visualtourbuilder.service';
import FontFaceObserver from 'fontfaceobserver';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  userInterface: Observable<any>;
  userInterfaceValue: any;
  itinerary: Observable<AppModel.Itinerary>;
  itineraryData: any;
  segmentTypes: Observable<any>;
  segmentTypesValue: any;
  units: Observable<any>;
  unitsValue: any;
  isTokenValid: boolean;
  notificationOptions: any;
  mediaspiritSettings: any = {
    showUpload: false,
    showSearch: true,
    showToolbar: false,
    selectLimit: 0,
    showToggle: false,
    excludeIds: {}
  };
  vtbLoaded: boolean = false;
  url: string;
  bags$: Observable<any>;
  bags: any;
  startDateIndex: any;
  id: string;
  vtbId: string;
  expireTimestamp: number;
  defaultFlightId: number;
  env: any;
  isGlobalValue = isGlobal

  @HostListener('keydown.alt.shift')
  onAltShiftDown() {
    this.toggleSideItinerary();
    this.toggleSideContext();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event: any) {
    if (this.itineraryData.saved === false) {
      event.returnValue = 'You have unfinished changes!';
    }
  }

  constructor(
    private renderer: Renderer2,
    private store: Store<AppState>,
    public dialog: MatDialog,
    public auth: AuthService,
    public iataService: IataService,
    private telespiritService: TelespiritService,
    private visualtourbuilderService: VisualtourbuilderService,
    private notificationsService: NotificationsService,
    private destyService: DestyService,
    private helpersService: HelpersService,
    private JwtHelper: JwtHelperService,
    private route: ActivatedRoute,
    private title: Title,
    public config: Config,
    private themeService: ThemeService
  ) {
    console.log(this.themeService.currentTheme)
    this.env = this.config;
    const url = new URL(window.location.href);
    const token = url.searchParams.get("token");
    this.id = url.searchParams.get("id");
    if (token != null && !this.JwtHelper.isTokenExpired(token)) {
      const decodedToken = this.JwtHelper.decodeToken(token);
      if (decodedToken != null) {
        if (decodedToken.exp != null) {
          this.expireTimestamp = decodedToken.exp * 1000;
          this.helpersService.expireTimestamp = this.expireTimestamp;
        }
        if (decodedToken['https://visualtourbuilder.com/app_metadata'] != null) {
          if (decodedToken['https://visualtourbuilder.com/app_metadata'].vtbConfig != null) {
            this.config.setProperties(decodedToken['https://visualtourbuilder.com/app_metadata'].vtbConfig);
          }
          if (decodedToken['https://visualtourbuilder.com/app_metadata'].arrANVR != null && decodedToken['https://visualtourbuilder.com/app_metadata'].arrANVR.length > 0) {
            this.config.arrANVR = decodedToken['https://visualtourbuilder.com/app_metadata'].arrANVR;
          }
          if (decodedToken['https://visualtourbuilder.com/app_metadata'].selectedItinerary != null) {
            this.config.selectedItinerary = decodedToken['https://visualtourbuilder.com/app_metadata'].selectedItinerary;
          }
          this.getTravelSpiritData();
        } else {
          this.config.setProperties(decodedToken.vtbConfig);
          this.vtbId = decodedToken.vtbId;
          if (isGlobal === false) {
            this.getTravelSpiritData();
          } else {
            const id = this.route.snapshot.queryParams.id || this.id;
            this.visualtourbuilderService.getTsToken().then(async tsToken => {
              if (tsToken != null) {
                const decodedTsToken = this.JwtHelper.decodeToken(tsToken);
                this.config.tsToken = tsToken;
                if (decodedTsToken['https://visualtourbuilder.com/app_metadata'] != null) {
                  this.config.baseUrl = decodedTsToken['https://visualtourbuilder.com/app_metadata'].baseUrl;
                  this.config.linkType = decodedTsToken['https://visualtourbuilder.com/app_metadata'].linkType;
                  this.config.linkId = decodedTsToken['https://visualtourbuilder.com/app_metadata'].linkId;
                  this.config.selectedItinerary = id || decodedTsToken['https://visualtourbuilder.com/app_metadata'].selectedItinerary;
                }
                await this.visualtourbuilderService.getDesignTemplates();
                await this.getTravelSpiritData(decodedToken.vtbId);
              } else {
                const promises = [];
                promises.push(this.visualtourbuilderService.getVtbSegmentTypes());
                promises.push(this.visualtourbuilderService.getVtbElementUnits());
                promises.push(this.visualtourbuilderService.getExtraFieldsData());
                promises.push(this.visualtourbuilderService.getCurrencies());
                promises.push(this.visualtourbuilderService.getTexts());
                promises.push(this.visualtourbuilderService.getDesignTemplates());

                Promise.all(promises).then(async () => {
                  await this.visualtourbuilderService.getVtbData(id);
                }).catch(e => {
                  console.log(e);
                });
              }
            });
          }
        }
        if (this.config.notificationOptions != null) {
          this.notificationOptions = this.config.notificationOptions;
        }
      }
      if (this.env != null && this.env.enableDesty === true) {
        this.destyService.getDestyAuthToken();
      }
    }
    localStorage.setItem('backupDelay', this.config.backupDelay);
    this.bags$ = this.store.select('bags');

    const materialIcons = new FontFaceObserver('Material Icons');
    materialIcons.load(null, 10000)
      .then(() => this.renderer.addClass(document.body, `material-icons-loaded`))
      .catch(() => this.renderer.addClass(document.body, `material-icons-error`)); // this line not necessary for simple example
  }

  ngOnInit() {
    console.log('DECODED_JWT:', this.auth.decodedToken);

    this.userInterface = this.store.select('userInterface');
    this.userInterface.subscribe((x) => {
      this.userInterfaceValue = x;
    });

    this.isTokenValid = this.auth.isTokenValid;
    this.url = window.location.href;
    if (this.isTokenValid === true) {
      this.itinerary = this.store.select('itinerary');
      this.itinerary.subscribe(x => {
        this.itineraryData = x;
        if (this.config.tsToken != null) {
          if (this.itineraryData != null && this.itineraryData.data != null && this.itineraryData.data.startDate != null && this.config.currenciesFound === false) {
            this.telespiritService.getCurrencies(this.itineraryData.data.startDate);
            this.config.currenciesFound = true;
          }
        }
        if (x != null) {
          if (this.env.alwaysEnableTemplateView != null && this.env.alwaysEnableTemplateView !== '' && this.env.alwaysEnableTemplateView === true) {
            this.itineraryData.data.templateView = true;
            this.itineraryData.data.alwaysEnableTemplateView = true;
          } else {
            this.itineraryData.data.alwaysEnableTemplateView = false;
            if (this.env.templateView != null && this.env.templateView !== '') {
              if (this.itineraryData.data.templateView == null) {
                this.itineraryData.data.templateView = this.env.templateView;
              }
            }
          }
          if (this.env.manualCalcByDefault === true) {
            this.itineraryData.data.defaultManualCalc = true;
          }
          if (this.userInterfaceValue.sideContext === '') {
            if (this.env.hiddenLayout != null) {
              this.itineraryData.data.hiddenLayout = this.env.hiddenLayout;
              if (this.itineraryData.data.hiddenLayout.contentFeatures != null && this.itineraryData.data.hiddenLayout.contentFeatures.length > 0) {
                if ((this.itineraryData.data.templateView != null && this.itineraryData.data.templateView === true) && this.itineraryData.data.hiddenLayout.contentFeatures.indexOf('elements') > -1) {
                  this.store.dispatch({ type: 'USER_INTERFACE_SHOW_CONTEXT', payload: 'desty' });
                } else {
                  this.store.dispatch({ type: 'USER_INTERFACE_SHOW_CONTEXT', payload: 'elements' });
                }
              } else {
                this.store.dispatch({ type: 'USER_INTERFACE_SHOW_CONTEXT', payload: 'elements' });
              }
            } else {
              this.store.dispatch({ type: 'USER_INTERFACE_SHOW_CONTEXT', payload: 'elements' });
            }
          }
          if (this.itineraryData.data.startDate) {
            this.itineraryData.data.startDate = new Date(this.itineraryData.data.startDate);
            if (!isNaN(this.itineraryData.data.startDate.getTime())) {
              if (this.itineraryData.data.startDate.getTime() > new Date().getTime() && this.itineraryData.data.startDate.getHours() !== 0) {
                this.itineraryData.data.startDate = this.helpersService.getNewDateWithoutTimezone(new Date(this.itineraryData.data.startDate));
              }
            } else {
              this.itineraryData.data.startDate = this.helpersService.getNewDateWithoutTimezone(new Date());
            }
          }
          if (this.vtbLoaded === false) {
            if (isGlobal === false) {
              this.telespiritService.getCurrencies(this.itineraryData.data.startDate);
              this.vtbLoaded = true;
            } else {
              this.vtbLoaded = true;
            }
          }
        }
      })
      this.segmentTypes = this.store.select('segmentTypes');
      this.segmentTypes.subscribe((x) => {
        this.segmentTypesValue = x;
        if (this.defaultFlightId == null) {
          if (this.segmentTypesValue && this.segmentTypesValue.items) {
            for (const item in this.segmentTypesValue.items) {
              if (this.segmentTypesValue.items[item].isFlight === true) {
                this.defaultFlightId = this.segmentTypesValue.items[item].id;
                break;
              }
            }
          }
        }
      });
      this.units = this.store.select('units');
      this.units.subscribe((x) => {
        this.unitsValue = x;
        if (this.unitsValue && this.unitsValue.ids) {
          if (this.unitsValue.ids.length > 0) {
            this.unitsValue.ids = this.sortUnits(this.unitsValue.items);
          }
        }
      });
    } else {
      this.title.setTitle('VTB - Token expired');
    }

    this.bags$.subscribe(x => {
      this.bags = x;
    });
  }

  async getTravelSpiritData(vtbGlobalId = null) {
    await this.telespiritService.getItineraries();
    await this.telespiritService.getTpData();
    await this.telespiritService.getLibtextTypes();
    await this.telespiritService.getUnits().then(async () => {
      const id = this.route.snapshot.queryParams.id || this.id;
      await this.telespiritService.getExtraFieldsAndRounding(id).then(async x => {
        if (x[1] != null) {
          this.store.dispatch({ type: 'ROUNDING_UPDATE', payload: x[1] });
        }
        await this.telespiritService.getItinerary(id, this.iataService, vtbGlobalId);
        await this.telespiritService.getSegmentTypes();
      });
    });
    await this.telespiritService.getParticipants();
    await this.telespiritService.getCustomerTemplates();
    await this.telespiritService.getElementCategories();
    await this.telespiritService.getDepartments();
  }

  setUrl() {
    window.history.pushState("object or string", "VTB - Token expired", this.url);
    return false;
  }

  toggleItinerary() {
    let payload = this.userInterfaceValue;
    payload.showSideItinerary = !payload.showSideItinerary;
    this.store.dispatch({ type: 'USER_INTERFACE_UPDATE', payload: payload });
  }

  toggleSideItinerary() {
    this.store.dispatch({ type: 'USER_INTERFACE_TOGGLE_SIDE_ITINERARY' });
  }

  toggleSideContext() {
    this.store.dispatch({ type: 'USER_INTERFACE_TOGGLE_SIDE_CONTEXT' });
  }

  sortUnits(items) {
    const itemsArray: Array<any> = Object.values(items);

    itemsArray.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
      if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
      return 0;
    })

    const ids = [];

    itemsArray.forEach(element => {
      ids.push(element.id);
    });

    return ids;
  }

  setStartDate($event) {
    if (this.startDateIndex === $event.segmentIndex) {
      this.startDateIndex = -1;
      this.startDateIndex = $event.segmentIndex;
      this.store.dispatch({ type: 'COUNTER_ADD' });
    } else {
      this.startDateIndex = $event.segmentIndex;
    }
  }

  showSideContext(type) {
    if (this.userInterfaceValue.showSideContext === false) {
      this.store.dispatch({ type: 'USER_INTERFACE_TOGGLE_SIDE_CONTEXT' });
    }
    if (this.userInterfaceValue.sideContext !== type) {
      this.store.dispatch({ type: 'USER_INTERFACE_SHOW_CONTEXT', payload: type });
      this.store.dispatch({ type: 'ELEMENTS_UPDATE', payload: [], config: this.env });
      this.store.dispatch({ type: 'MARKERS_DELETED' });
    }
    if (type !== 'desty') {
      this.store.dispatch({ type: 'MAP_DELETED', payload: null });
      this.store.dispatch({ type: 'USER_INTERFACE_SHOW_MAP', payload: false });
      this.store.dispatch({ type: 'COUNTER_ADD' });
    }
  }

  get almostExpired() {
    if (this.expireTimestamp != null) {
      return (this.helpersService.checkExpired(this.expireTimestamp) === true ? true : false);
    } else {
      return false;
    }
  }

  importAirtradePnr() {
    let dialogRef: any = this.dialog.open(ImportAirtradeDialogComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
      for (const id of result.pnrIds) {
        await this.telespiritService.getAirtradeById(id, result.anvrId).then(async x => {
          let data: any = x;
          if (data && data.Flights && data.Flights.length > 0) {
            for (const flight of data.Flights) {
              if (flight.Passengers && flight.Passengers.length > 0) {
                if (this.itineraryData && this.itineraryData.data && this.itineraryData.data.participants) {
                  for (const passenger of flight.Passengers) {
                    let found = false;
                    for (let party in this.itineraryData.data.participants) {
                      if (this.itineraryData.data.participants[party].length > 0) {
                        this.itineraryData.data.participants[party].forEach(participant => {
                          if ((passenger.FirstName.toLowerCase() === participant.passport_firstname.toLowerCase() && passenger.LastName.toLowerCase() === (((participant.surname_prefix != null && participant.surname_prefix !== '') ? participant.surname_prefix.toLowerCase().replace(/\s/g, '') : '') + participant.passport_surname.toLowerCase().replace(/\s/g, '')) || passenger.FirstName.toLowerCase() === participant.name.toLowerCase() && passenger.LastName.toLowerCase() === (((participant.surname_prefix != null && participant.surname_prefix !== '') ? participant.surname_prefix.toLowerCase().replace(/\s/g, '') : '') + participant.surname.toLowerCase().replace(/\s/g, '')))) {
                            found = true;
                          }
                        });
                      }
                    }
                    if (found === false) {
                      this.notificationsService.error('Error finding passengers', 'Passengers are not added to this VTB');
                      return;
                    }
                  }
                }
              } else {
                this.notificationsService.error('Error checking passengers', 'No passengers were found in the PNR');
                return;
              }
              if (flight.Segments && flight.Segments.length > 0) {
                let index = 0;
                for (const segment of flight.Segments) {
                  const arrivalDate = new Date(segment.ArrivalDate);
                  const departureDate = new Date(segment.DepartureDate);
                  await this.helpersService.getAirportObject(segment.ArrivalAirportCode, this.iataService, isGlobal, this.visualtourbuilderService, this.config).then(async arrival => {
                    await this.helpersService.getAirportObject(segment.DepartureAirportCode, this.iataService, isGlobal, this.visualtourbuilderService, this.config).then(async departure => {
                      await this.helpersService.getAirlineObject(segment.AirlineCode, this.iataService, isGlobal, this.visualtourbuilderService, this.config).then(airline => {
                        let tempSegment = {
                          typeId: this.defaultFlightId,
                          media: [],
                          elements: [],
                          flightInfo: [{
                            airlineCode: segment.AirlineCode,
                            airlineObject: airline,
                            arrivalAirport: segment.ArrivalAirportCode,
                            arrivalAirportObject: arrival,
                            arrivalDate: arrivalDate,
                            arrivalTime: (arrivalDate.getHours() < 10 ? '0' + arrivalDate.getHours() : arrivalDate.getHours()) + ':' + (arrivalDate.getMinutes() < 10 ? '0' + arrivalDate.getMinutes() : arrivalDate.getMinutes()),
                            departureAirport: segment.DepartureAirportCode,
                            departureAirportObject: departure,
                            departureDate: departureDate,
                            departureTime: (departureDate.getHours() < 10 ? '0' + departureDate.getHours() : departureDate.getHours()) + ':' + (departureDate.getMinutes() < 10 ? '0' + departureDate.getMinutes() : departureDate.getMinutes()),
                            duration: segment.FlightDuration,
                            flightNumber: segment.FlightNumber,
                            operatedBy: segment.OperatedBy,
                            stopoverAirport: null
                          }],
                          inViewport: false,
                          collapse: false,
                          content: this.setSegmentContent(flight.Passengers, index),
                          date: departureDate,
                          endDate: null,
                          hasElementsOverlap: false,
                          itemType: 'segment',
                          maps: { latitude: null, longitude: null, zoom: null },
                          subTitle: null,
                          title: this.setSegmentTitle(departure, arrival),
                          nights: null,
                          day: null,
                          segmentIndex: null,
                          vtbObjectId: this.helpersService.generateRandomId(),
                          vtbSegmentId: this.helpersService.generateRandomId()
                        };

                        if (segment.StopOver != null && segment.StopOver[0] != null && segment.StopOver[0].StopArrivingAirportCode != null) {
                          tempSegment.flightInfo[0].stopoverAirport = segment.StopOver[0].StopArrivingAirportCode;
                        }

                        const startDate = new Date(this.itineraryData.data.startDate);
                        const endDate = new Date(this.itineraryData.data.endDate);
                        if (departureDate.getTime() < startDate.getTime()) {
                          this.itineraryData.data.segments.splice(0, 0, tempSegment)
                        } else if (departureDate.getTime() > startDate.getTime() && departureDate.getTime() < endDate.getTime()) {
                          let segmentIndex = 0;

                          for (let i = 0; i < this.itineraryData.data.segments.length; i++) {
                            const segmentDate = new Date(this.itineraryData.data.segments[i].date);
                            if (departureDate.getFullYear() > segmentDate.getFullYear() || departureDate.getMonth() > segmentDate.getMonth() || departureDate.getDate() > segmentDate.getDate()) {
                              segmentIndex++;
                            } else {
                              this.itineraryData.data.segments.splice(segmentIndex, 0, tempSegment);
                              break;
                            }
                          }
                        } else if (departureDate.getTime() > endDate.getTime()) {
                          if (departureDate.getFullYear() === endDate.getFullYear() && departureDate.getMonth() === endDate.getMonth() && departureDate.getDate() === endDate.getDate()) {
                            this.itineraryData.data.segments.splice(this.itineraryData.data.segments.length - 1, 0, tempSegment);
                          } else {
                            this.itineraryData.data.segments.push(tempSegment);
                          }
                        }
                        index++;
                        this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: this.itineraryData });
                        this.store.dispatch({ type: 'COUNTER_ADD' });
                      });
                    });
                  });
                }
              } else {
                this.notificationsService.error('Error adding segments', 'No flight data found');
                return;
              }
            }
          }
        });
      }
    });
  }

  setSegmentTitle(departure, arrival) {
    if (departure && departure.description && arrival && arrival.description) {
      return departure.description + ' - ' + arrival.description;
    }
    return '';
  }

  setSegmentContent(participants, index) {
    let content = '';
    if (index === 0) {
      for (const participant of participants) {
        if (content === '') {
          content = `<p>${participant.Title} ${participant.FirstName} ${participant.LastName}  tax: ${participant.Tax}, fare ${participant.Fare}</p>`;
        } else {
          content += `<p>${participant.Title} ${participant.FirstName} ${participant.LastName}  tax: ${participant.Tax}, fare ${participant.Fare}</p>`;
        }
      }
      return content;
    }
    return content;
  }

  checkHiddenLayout(type) {
    if (this.itineraryData != null && this.itineraryData.data != null && this.itineraryData.data.templateView === true && this.itineraryData.data.hiddenLayout != null) {
      if (this.itineraryData.data.hiddenLayout.contentFeatures != null && this.itineraryData.data.hiddenLayout.contentFeatures.length > 0) {
        if (this.itineraryData.data.hiddenLayout.contentFeatures.indexOf(type) > -1) {
          return true;
        }
      }
    }
    return false;
  }
}
