import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-segments-delete-dialog',
  templateUrl: './segments-delete-dialog.component.html',
  styleUrls: ['./segments-delete-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentsDeleteDialogComponent implements OnInit {
  segments: any;
  segmentTypes: any;
  selectedSegments = [];
  displayedColumns = ['title', 'segmentType', 'day', 'actions'];

  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this.segments = this.dialogRef.componentInstance.segments;
    this.segmentTypes = this.dialogRef.componentInstance.segmentTypes;
  }

  selectAll() {
    if (this.isAllSelected() === true) {
      this.selectedSegments = [];
    } else {
      this.selectedSegments = [];
      for (let i = 0; i < this.segments.length; i++) {
        this.selectedSegments.push({ segment: this.segments[i], index: i, checked: true });
      }
    }
  }

  getSelectedOptions() {
    this.dialogRef.close({ segments: this.selectedSegments.sort((a, b) => (a.index > b.index) ? -1 : ((b.index > a.index) ? 1 : 0)) });
  }

  checkSegment(segment) {
    if (this.selectedSegments && this.selectedSegments.length > 0) {
      for (const selectedSegment of this.selectedSegments) {
        if (selectedSegment.segment.vtbObjectId === segment.vtbObjectId) {
          if (selectedSegment.checked === true) {
            return true;
          }
        }
      }
    }
    return false;
  }

  selectSegment(segment, i) {
    if (this.selectedSegments && this.selectedSegments.length > 0) {
      for (let i = 0; i < this.selectedSegments.length; i++) {
        if (this.selectedSegments[i].segment.vtbObjectId === segment.vtbObjectId) {
          this.selectedSegments.splice(i, 1);
          return;
        }
      }
      this.selectedSegments.push({ segment: segment, index: i, checked: true });
    } else {
      this.selectedSegments.push({ segment: segment, index: i, checked: true });
    }
  }

  isAllSelected() {
    return this.selectedSegments.length === this.segments.length;
  }
}
