import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const ITINERARY_BACKUPS_ADD = 'ITINERARY_BACKUPS_ADD';
const logService = new LogService();

export function itineraryBackupsReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {
    case ITINERARY_BACKUPS_ADD:
      const itineraryCopy = { ...JSON.parse(JSON.stringify(action.payload)) };
      itineraryCopy.backupTimeStamp = new Date();
      state.unshift(itineraryCopy);
      state = state.slice(0, 20);
      logService.consoleLog('ITINERARY_BACKUPS_ADD:', state);
      return state;
    default:
      return state;
  }
}
