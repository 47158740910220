// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { DecimalPipe, DatePipe, CurrencyPipe } from '@angular/common';
import { SharedModule } from './shared.module';
import { VtbGraphqlModule } from './graphql.module';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { MediaSpiritModule } from '@sitespirit/mediaspirit-module';
import { AgmCoreModule } from '@agm/core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LightboxModule } from 'ngx-lightbox';
import { registerLocaleData } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import localeNl from '@angular/common/locales/nl';
import { ClipboardModule } from 'ngx-clipboard';
import { QuillModule } from 'ngx-quill';

// Misc
import { routing } from './app.routing';
import { Config } from './classes/config.class';
import { EncodeHttpParamsInterceptor } from './classes/encodeHttpParamsInterceptor.class';
import { environment } from '../environments/environment';

// Reducers
import { userInterfaceReducer } from './reducers/user-interface';
import { itineraryReducer } from './reducers/itinerary';
import { itinerariesReducer } from './reducers/itineraries';
import { segmentTypesReducer } from './reducers/segment-types';
import { AuthReducer } from './reducers/auth';
import { libtextsReducer } from './reducers/libtexts';
import { libordersReducer } from './reducers/liborders';
import { elementsReducer } from './reducers/elements';
import { unitsReducer } from './reducers/units';
import { counterReducer } from './reducers/counter';
import { priceReducer } from './reducers/price';
import { participantsReducer } from './reducers/participants';
import { currenciesReducer } from './reducers/currencies';
import { customerTemplatesReducer } from './reducers/customer-templates';
import { extraFieldsReducer } from './reducers/extra-fields';
import { elementCategoriesReducer } from './reducers/element-categories';
import { bagsReducer } from './reducers/bags';
import { departmentsReducer } from './reducers/departments';
import { itineraryBackupsReducer } from './reducers/itinerary-backups';
import { roundingReducer } from './reducers/rounding';
import { mapMarkersReducer } from './reducers/map-markers';
import { mapDrawReducer } from './reducers/map-draw';
import { dayTextsReducer } from './reducers/day-texts';
import { proposalBuildsReducer } from './reducers/proposal-builds';

// Effects
import { ItineraryEffects } from './effects/itinerary';

// Actions
import { AuthActions } from './actions/auth.actions';

// Directives
import { ItineraryDayDirective } from './directives/itinerary-day.directive'

// Pipes
import { GetSafePipe } from './pipes/get-safe.pipe';

// Guards
import { TravelplanTokenGuard } from './guards/travelplan-token.guard';

// Services
import { TelespiritService } from './services/telespirit.service';
import { VisualtourbuilderService } from './services/visualtourbuilder.service';
import { DestyService } from './services/desty.service';
import { DayCalculationService } from './services/day-calculation.service';
import { IataService } from './services/iata.service';
import { LogService } from './services/log.service';
import { AuthService } from './services/auth.service';
import { GlobalErrorHandler } from './services/error-handler';
import { PnrService } from './services/pnr.service';
import { HelpersService } from './services/helpers.service';
import { ElementsService } from './services/elements.service';
import { PubnubService } from './services/pubnub.service';
import { PriceCalculationService } from './services/price-calculation.service';
import { CommunicationService } from './services/communication.service';
import { MapboxService } from './services/mapbox.service';
import { ThemeService } from './services/theme.service';

// Page components
import { ListViewComponent } from './pages/list-view/list-view.component';
import { ErrorViewComponent } from './pages/error-view/error-view.component';

// Components
import { AppComponent } from './app.component';
import { TopNavigationComponent } from './components/top-navigation/top-navigation.component';
import { SideNavigationComponent } from './components/side-navigation/side-navigation.component';
import { SideItineraryComponent } from './components/side-itinerary/side-itinerary.component';
import { SideItinerarySegmentComponent } from './components/side-itinerary-segment/side-itinerary-segment.component';
import { SideContextComponent } from './components/side-context/side-context.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ErrorMessageDialogComponent } from './components/error-message-dialog/error-message-dialog.component';
import { SegmentsCopyDialogComponent } from './components/segments-copy-dialog/segments-copy-dialog.component';
import { CreateLibraryOrderDialogComponent } from './components/create-library-order-dialog/create-library-order-dialog.component';
import { SegmentsDeleteDialogComponent } from './components/segments-delete-dialog/segments-delete-dialog.component';
import { ExchangeRateDialogComponent } from './components/exchange-rate-dialog/exchange-rate-dialog.component';
import { MarginEditDialogComponent } from './components/margin-edit-dialog/margin-edit-dialog.component';
import { AdvancedMarginEditDialogComponent } from './components/advanced-margin-edit-dialog/advanced-margin-edit-dialog.component';
import { ErrorPricesDialogComponent } from './components/error-prices-dialog/error-prices-dialog.component';
import { CopyDialogComponent } from './components/copy-dialog/copy-dialog.component';
import { ElementInfoDialogComponent } from './components/element-info-dialog/element-info-dialog.component';
import { LibtextsComponent } from './components/libtexts/libtexts.component';
import { ElementsComponent } from './components/elements/elements.component';
import { LibordersComponent } from './components/liborders/liborders.component';
import { NotificationCenterButtonComponent } from './components/notification-center-button/notification-center-button.component';
import { NotificationCenterDialogComponent } from './components/notification-center-dialog/notification-center-dialog.component';
import { ExtraFieldsConfirmComponent } from './components/extra-fields-confirm/extra-fields-confirm.component';
import { ElementUpdateConfirmComponent } from './components/element-update-confirm/element-update-confirm.component';
import { InternalInfoDialogComponent } from './components/internal-info-dialog/internal-info-dialog.component';
import { CarRentalDialogComponent } from './components/car-rental-dialog/car-rental-dialog.component';
import { ExpiredDialogComponent } from './components/expired-dialog/expired-dialog.component';
import { ExpireWarningComponent } from './components/expire-warning/expire-warning.component';
import { ImportAirtradeDialogComponent } from './components/import-airtrade-dialog/import-airtrade-dialog.component';
import { DestyHotelsComponent } from './components/desty-hotels/desty-hotels.component';
import { ExternalHotelBookedDialogComponent } from './components/external-hotel-booked-dialog/external-hotel-booked-dialog.component';
import { ExternalHotelBookingDialogComponent } from './components/external-hotel-booking-dialog/external-hotel-booking-dialog.component';
import { ConfirmLibtextDropComponent } from './components/confirm-libtext-drop/confirm-libtext-drop.component';
import { CreateElementDialogComponent } from './components/create-element-dialog/create-element-dialog.component';
import { DestyPartyDialogComponent } from './components/desty-party-dialog/desty-party-dialog.component';
import { DestyInfoDialogComponent } from './components/desty-info-dialog/desty-info-dialog.component';
import { MagicDialogComponent } from './components/magic-dialog/magic-dialog.component';
import { CopyUrlDialogComponent } from './components/copy-url-dialog/copy-url-dialog.component';
import { PricesParticipantsSalesTotalDialogComponent } from './components/prices-participants-sales-total-dialog/prices-participants-sales-total-dialog.component';
import { SegmentsCopyParticipantsDialogComponent } from './components/segments-copy-participants-dialog/segments-copy-participants-dialog.component';
import { CreateRoomtypesDialogComponent } from './components/create-roomtypes-dialog/create-roomtypes-dialog.component';
import { ConsolidateTransferDialogComponent } from './components/consolidate-transfer-dialog/consolidate-transfer-dialog.component';
import { SegmentElementsUpdateDialogComponent } from './components/segment-elements-update-dialog/segment-elements-update-dialog.component';
import { TextEditorDialogComponent } from './components/text-editor-dialog/text-editor-dialog.component';

export function tokenGetter() {
  return localStorage.getItem('id_token_mediaspirit');
}

registerLocaleData(localeNl, 'nl');

@NgModule({
  declarations: [
    AppComponent,
    TopNavigationComponent,
    SideNavigationComponent,
    SideItineraryComponent,
    ItineraryDayDirective,
    SideItinerarySegmentComponent,
    SideContextComponent,
    ListViewComponent,
    GetSafePipe,
    ConfirmDialogComponent,
    SegmentsCopyDialogComponent,
    CreateLibraryOrderDialogComponent,
    SegmentsDeleteDialogComponent,
    MarginEditDialogComponent,
    AdvancedMarginEditDialogComponent,
    CopyDialogComponent,
    ElementInfoDialogComponent,
    LibtextsComponent,
    ElementsComponent,
    LibordersComponent,
    DestyHotelsComponent,
    NotificationCenterButtonComponent,
    NotificationCenterDialogComponent,
    ExtraFieldsConfirmComponent,
    ElementUpdateConfirmComponent,
    InternalInfoDialogComponent,
    CarRentalDialogComponent,
    ExchangeRateDialogComponent,
    ErrorPricesDialogComponent,
    ErrorViewComponent,
    ErrorMessageDialogComponent,
    ExpireWarningComponent,
    ExpiredDialogComponent,
    ExternalHotelBookingDialogComponent,
    ExternalHotelBookedDialogComponent,
    ImportAirtradeDialogComponent,
    PricesParticipantsSalesTotalDialogComponent,
    ConfirmLibtextDropComponent,
    CreateElementDialogComponent,
    DestyPartyDialogComponent,
    DestyInfoDialogComponent,
    MagicDialogComponent,
    CopyUrlDialogComponent,
    SegmentsCopyParticipantsDialogComponent,
    CreateRoomtypesDialogComponent,
    ConsolidateTransferDialogComponent,
    SegmentElementsUpdateDialogComponent,
    TextEditorDialogComponent
  ],
  imports: [
    BrowserModule,
    SharedModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    HttpClientModule,
    HttpClientJsonpModule,
    routing,
    PerfectScrollbarModule,
    SimpleNotificationsModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['api.eu.apiconnect.ibmcloud.com', 'service.eu-de.apiconnect.ibmcloud.com', 'eddosgymxl.execute-api.eu-central-1.amazonaws.com'],
        authScheme: ''
      }
    }),
    StoreModule.forRoot({
      bags: bagsReducer,
      userInterface: userInterfaceReducer,
      itinerary: itineraryReducer,
      itineraries: itinerariesReducer,
      auth: AuthReducer,
      segmentTypes: segmentTypesReducer,
      libtexts: libtextsReducer,
      elements: elementsReducer,
      liborders: libordersReducer,
      units: unitsReducer,
      counter: counterReducer,
      participants: participantsReducer,
      currencies: currenciesReducer,
      customerTemplates: customerTemplatesReducer,
      price: priceReducer,
      extraFields: extraFieldsReducer,
      elementCategories: elementCategoriesReducer,
      departments: departmentsReducer,
      itineraryBackups: itineraryBackupsReducer,
      rounding: roundingReducer,
      markers: mapMarkersReducer,
      mapDraw: mapDrawReducer,
      dayTexts: dayTextsReducer,
      proposalBuilds: proposalBuildsReducer
    }),
    EffectsModule.forRoot([ItineraryEffects]),
    MediaSpiritModule,
    NgxPageScrollModule,
    NgxPageScrollCoreModule.forRoot({ scrollOffset: 64, duration: 300 }),
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCxKaQRh_zEXLZd23ofhY633WABuYK9M64'
    }),
    VtbGraphqlModule,
    LightboxModule,
    ClipboardModule,
    NgxSliderModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline'],
          ['blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ align: [] }],
          ['link']
        ]
      },
      theme: 'snow'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    },
    HelpersService,
    ElementsService,
    LogService,
    DayCalculationService,
    PriceCalculationService,
    IataService,
    AuthService,
    PnrService,
    PubnubService,
    MapboxService,
    ThemeService,
    GetSafePipe,
    DecimalPipe,
    CurrencyPipe,
    DatePipe,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: Config,
      useValue: Object.assign(new Config(), environment)
    },
    TelespiritService,
    DestyService,
    AuthActions,
    GlobalErrorHandler,
    TravelplanTokenGuard,
    CommunicationService,
    VisualtourbuilderService
  ],
  entryComponents: [
    ConfirmDialogComponent,
    SegmentsCopyDialogComponent,
    SegmentsDeleteDialogComponent,
    MarginEditDialogComponent,
    AdvancedMarginEditDialogComponent,
    CopyDialogComponent,
    ElementInfoDialogComponent,
    NotificationCenterDialogComponent,
    ExtraFieldsConfirmComponent,
    ElementUpdateConfirmComponent,
    InternalInfoDialogComponent,
    CarRentalDialogComponent,
    ExchangeRateDialogComponent,
    ErrorPricesDialogComponent,
    ErrorMessageDialogComponent,
    ExpiredDialogComponent,
    PricesParticipantsSalesTotalDialogComponent,
    ExternalHotelBookingDialogComponent,
    ExternalHotelBookedDialogComponent,
    ImportAirtradeDialogComponent,
    ConfirmLibtextDropComponent,
    CreateLibraryOrderDialogComponent,
    CreateElementDialogComponent,
    DestyPartyDialogComponent,
    DestyInfoDialogComponent,
    MagicDialogComponent,
    CopyUrlDialogComponent,
    SegmentsCopyParticipantsDialogComponent,
    CreateRoomtypesDialogComponent,
    ConsolidateTransferDialogComponent,
    SegmentElementsUpdateDialogComponent,
    TextEditorDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
