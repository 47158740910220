import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output, ViewChild, HostListener, OnDestroy, AfterViewInit, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Config } from '../../classes/config.class';
import { MatDialog, MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { AuthService, isGlobal } from '../../services/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../models/app-state';
import { ElementsService } from '../../services/elements.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { HelpersService } from '../../services/helpers.service';
import { Subject } from 'rxjs-compat/Subject';
import { take, takeUntil } from 'rxjs/operators';
import { ExpiredDialogComponent } from '../../components/expired-dialog/expired-dialog.component';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { NotificationsService } from 'angular2-notifications';
import { TelespiritService } from 'app/services/telespirit.service';
import { SegmentElementsUpdateDialogComponent } from '../segment-elements-update-dialog/segment-elements-update-dialog.component';
import { Observable } from 'rxjs';
import { InternalInfoDialogComponent } from '../internal-info-dialog/internal-info-dialog.component';

@Component({
  selector: 'app-side-itinerary-segment',
  templateUrl: './side-itinerary-segment.component.html',
  styleUrls: ['./side-itinerary-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SideItinerarySegmentComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() updateItinerary = new EventEmitter();
  @Output() deleteSegment = new EventEmitter();
  @Output() addSegmentCopy = new EventEmitter();
  @Output() addSegment = new EventEmitter();
  @Output() elementDropped = new EventEmitter();
  @Output() flightElementDropped = new EventEmitter();
  @Output() startDate = new EventEmitter();
  @Input() segments: any;
  @Input() item: AppModel.Segment;
  @Input() itemDate: any;
  @Input() itemTitle: string;
  @Input() itemNights: number;
  @Input() itemType: number;
  @Input() itemDay: number;
  @Input() segmentTypes: any;
  @Input() index: number;
  @Input() unitsValue: any;
  @Input() templateView: any;
  @ViewChild('bag') bag: any;
  @ViewChild(MatMenuTrigger) contextMenuButton: MatMenuTrigger;
  @HostListener('contextmenu', ['$event']) onContextMenu(e) {
    this.contextMenuButton.openMenu();
    document.getElementsByClassName('cdk-overlay-backdrop')[document.getElementsByClassName('cdk-overlay-backdrop').length - 1].addEventListener('contextmenu', (offEvent: any) => {
      offEvent.preventDefault();
      this.contextMenuButton.closeMenu();
    });
    e.preventDefault();
  }

  bagSubscription: Subscription;
  bags$: Observable<any>;
  addedBag = false;
  unsubscribe = new Subject<void>();
  inputUpdateStrategy: any = { updateOn: 'blur' };
  env: any;
  isGlobalValue = isGlobal;

  constructor(
    private config: Config,
    private store: Store<AppState>,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private matSnackBar: MatSnackBar,
    private helpersService: HelpersService,
    private notificationsService: NotificationsService,
    private pageScrollService: PageScrollService,
    private telespiritService: TelespiritService,
    public elementsService: ElementsService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.env = this.config;
    this.bags$ = this.store.select('bags');
  }

  ngOnInit(): void {
    this.bagSubscription = this.bags$.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      if (this.bag != null && this.addedBag === false) {
        this.store.dispatch({ type: 'BAG_ADD', payload: { type: 'elements', bag: this.bag } });
        this.store.dispatch({ type: 'BAG_ADD', payload: { type: 'sideSegments', bag: this.bag } });
        this.addedBag = true;
      }
    });
  }

  ngAfterViewInit() {
    if (this.contextMenuButton) {
      this.contextMenuButton.menuOpened.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
        document.getElementsByClassName('cdk-overlay-backdrop')[document.getElementsByClassName('cdk-overlay-backdrop').length - 1].addEventListener('contextmenu', (offEvent: any) => {
          offEvent.preventDefault();
          this.contextMenuButton.closeMenu();
        });
      });
    }
    if (this.bag != null) {
      this.store.dispatch({ type: 'BAG_ADD', payload: { type: 'elements', bag: this.bag } });
      this.store.dispatch({ type: 'BAG_ADD', payload: { type: 'sideSegments', bag: this.bag } });
      this.addedBag = true;
    }
  }

  ngOnDestroy() {
    this.store.dispatch({ type: 'BAG_REMOVE', payload: { type: 'elements', bag: this.bag } });
    this.store.dispatch({ type: 'BAG_REMOVE', payload: { type: 'sideSegments', bag: this.bag } });
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get tokenObj() {
    let obj: any = {};
    if (this.authService.authenticated != null && this.authService.authenticated === true) {
      obj.token = this.authService.token;
    }
    return obj;
  }

  get elementsTotalNights() {
    if (this.item.elements != null) {
      return this.item.elements.reduce((all, item) => {
        if (this.unitsValue && this.unitsValue.items && this.unitsValue.items[item.unitId] && this.unitsValue.items[item.unitId].isTimeBased === true && this.unitsValue.items[item.unitId].allowOverlap === false && (this.unitsValue.items[item.unitId].flexRelatedProduct === false || this.unitsValue.items[item.unitId].flexRelated === false)) {
          if ((item.hasOwnProperty('optional') === false || item.optional === false)) {
            if (all < (item.nights + item.offset)) {
              all = (item.nights + item.offset);
            }
          }
        }
        return all;
      }, 0);
    } else {
      return 0;
    }
  }

  get taxiTooltip() {
    if (this.item.carRentalElements != null && this.item.carRentalElements.length > 0) {
      const tempCarElements = this.item.carRentalElements.sort((a, b) => new Date(a.element.date).getTime() - new Date(b.element.date).getTime());
      let messages = [];
      for (const carElement of tempCarElements) {
        if (carElement.element.title != null) {
          let label = `Name: ${carElement.element.title}`;
          if (this.unitsValue.flexId[carElement.element.unitId] && ((this.unitsValue.flexId[carElement.element.unitId].flexRelatedProduct || this.unitsValue.flexId[carElement.element.unitId].flexRelated) || this.unitsValue.flexId[carElement.element.unitId].flexRelated)) {
            label = `Related product: ${carElement.element.title}`;
          }
          messages.push(label);
        }
        if (carElement.element.date != null) {
          const date = new Date(carElement.element.date);
          messages.push(`Start date: ${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`);
        }
        if (carElement.element.endDate != null) {
          const endDate = new Date(carElement.element.endDate);
          messages.push(`End date: ${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`);
        }
      }
      return messages.join('\n');
    } else {
      return null;
    }
  }

  get hasFirstElementTitle() {
    return this.item.elements != null && this.item.elements.length > 0 && this.item.elements[0] != null;
  }

  scrollToSegment(segmentIndex, retry = true) {
    const plainView = document.querySelector('#plain-view-scroll');
    this.startDate.emit({ segmentIndex: segmentIndex });
    if (plainView != null) {
      this.pageScrollService.scroll({ document: this.document, scrollTarget: `#item_${segmentIndex}`, scrollViews: [plainView] });
    } else if (retry === true) {
      this.router.navigate(['/'], { queryParams: this.tokenObj }).then(x => {
        setTimeout(() => {
          this.scrollToSegment(segmentIndex, false);
        });
      });
    }
  }

  updateWithCalc() {
    this.updateItinerary.emit({
      calcAllDayProperties: true,
      updateOptionalFlex: true
    });
  }

  updatedTitle(obj) {
    if (this.segmentTypes.glueId.indexOf(this.item.typeId) > -1 && this.env.setGlueTitle === true) {
      this.item.title = obj.newValue;
    } else if (this.env.setTitle.length > 0 && this.env.setTitle.indexOf(this.item.typeId) > -1) {
      this.item.title = obj.newValue;
    }
    if (this.helpersService.isExpired() === false) {
      this.store.dispatch({ type: 'ITINERARY_TEXT_UPDATE', payload: obj });
    } else {
      this.dialog.open(ExpiredDialogComponent, {
        width: '600px'
      });
    }
  }

  setElementUnitType(index) {
    if (this.item.elements[index].hasOwnProperty('unitId') === false) {
      this.item.elements[index].unitId = 2;
    }
  }

  setElementOffset(index) {
    if (index > 0 && this.item.elements.length > 0 && this.item.elements[index] != null && this.item.elements[index].unitId && this.unitsValue.items[this.item.elements[index].unitId]) {
      if (this.item.elements[index - 1] != null && this.unitsValue.items[this.item.elements[index].unitId].flexRelatedProduct === false) {
        this.item.elements[index] = this.helpersService.setElementOffsetDays(index, this.item, this.unitsValue);
        this.updateWithCalc();
      } else if (this.unitsValue.items[this.item.elements[index].unitId].flexRelatedProduct === true && this.item.elements[index].relativeOffset === true) {
        if (this.item.elements[index].endDate.getTime() !== this.item.endDate.getTime()) {
          if (this.item.elements[index].endDate.getTime() < this.item.endDate.getTime()) {
            const timeDiff = this.item.endDate.getTime() - this.item.elements[index].endDate.getTime();
            const dayDiff = timeDiff / (1000 * 3600 * 24);
            this.item.elements[index].endOffset -= dayDiff;
          } else {
            const timeDiff = this.item.elements[index].endDate.getTime() - this.item.endDate.getTime();
            const dayDiff = timeDiff / (1000 * 3600 * 24);
            this.item.elements[index].endOffset += dayDiff;
          }
        }
        if (this.item.elements[index].manualOffsetCalc != null) {
          delete this.item.elements[index].manualOffsetCalc;
        }
        this.updateWithCalc();
      }
    }
  }

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer.data[event.previousIndex].departureDate != null) {
      if (this.segmentTypes.items[this.item.typeId].isFlight === true) {
        let found = false;
        if (this.item.flightInfo && this.item.flightInfo.length > 0) {
          for (const flight of this.item.flightInfo) {
            if (flight.vtbFlightId === event.previousContainer.data[event.previousIndex].vtbFlightId) {
              found = true;
            }
          }
        }
        if (found === false) {
          this.flightElementDropped.emit({ flight: event.previousContainer.data[event.previousIndex], segmentIndex: this.index });
        }
      }
    } else {
      if (event.container.data.length === event.previousContainer.data.length) {
        let sameData = true;
        for (let i = 0; i < event.container.data.length; i++) {
          if (event.container.data[i].day !== event.previousContainer.data[i].day || event.container.data[i].nights !== event.previousContainer.data[i].nights || event.container.data[i].linkedParty !== event.previousContainer.data[i].linkedParty) {
            sameData = false;
          }
        }
        if (sameData === true) {
          moveItemInArray(event.container.data, event.previousIndex, event.previousIndex);
          this.store.dispatch({ type: 'COUNTER_ADD' });
          this.updateWithCalc();
          return;
        }
      }

      const index = this.item.elements.length;
      const selectedElements = [];
      if (this.env.retainDraggedElement === true && event.previousContainer.id === 'side-elements') {
        for (let i = 0; i < event.previousContainer.data.length; i++) {
          if (event.previousContainer.data[i].multiSelected === true) {
            delete event.previousContainer.data[i].multiSelected;
            selectedElements.push({
              data: { ...event.previousContainer.data[i] },
              index: i
            });
          }
        }
        if (selectedElements.length > 0) {
          for (let x = 0; x < selectedElements.length; x++) {
            copyArrayItem(event.previousContainer.data, event.container.data, selectedElements[x].index, index + x);
          }
          this.afterDrop(index, selectedElements)
        } else {
          copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, index);
          this.afterDrop(index)
        }
        const element = event.container.data[index];

        if (element != null) {
          let internalInfo = '';
          if (element.internalText && element.internalText != null) internalInfo = element.internalText;
          if (element.pricematrixInternalText && element.pricematrixInternalText != null) internalInfo += ' [' + element.pricematrixInternalText + ']';
          if (element.pricespecialInternalText && element.pricespecialInternalText != null) internalInfo += ' {' + element.pricespecialInternalText + '}';

          if (this.config.internalPopupIcon != '' && internalInfo.indexOf(this.config.internalPopupIcon) > -1) {
            this.helpersService.showInternalInformationText(internalInfo, this.dialog, InternalInfoDialogComponent);
          }
        }
      } else {
        for (let i = 0; i < event.previousContainer.data.length; i++) {
          if (event.previousContainer.data[i].multiSelected === true) {
            delete event.previousContainer.data[i].multiSelected;
            selectedElements.push({
              data: { ...event.previousContainer.data[i] },
              index: i
            });
          }
        }
        if (selectedElements.length > 0) {
          for (let x = selectedElements.length - 1; x >= 0; x--) {
            transferArrayItem(event.previousContainer.data, event.container.data, selectedElements[x].index, index + x);
          }
          this.afterDrop(index, selectedElements, true)
        } else {
          transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, index);
          this.afterDrop(index, null, true)
        }

        const element = event.container.data[index];

        if (element != null) {
          let internalInfo = '';
          if (element.internalText && element.internalText != null) internalInfo = element.internalText;
          if (element.pricematrixInternalText && element.pricematrixInternalText != null) internalInfo += ' [' + element.pricematrixInternalText + ']';
          if (element.pricespecialInternalText && element.pricespecialInternalText != null) internalInfo += ' {' + element.pricespecialInternalText + '}';

          if (this.config.internalPopupIcon != '' && internalInfo.indexOf(this.config.internalPopupIcon) > -1) {
            this.helpersService.showInternalInformationText(internalInfo, this.dialog, InternalInfoDialogComponent);
          }
        }
      }
    }
  }

  afterDrop(index, selectedElements = null, keepParticipants = null) {
    if (selectedElements == null) {
      if (this.segmentTypes.glueId.indexOf(this.item.typeId) > -1 && this.env.setGlueTitle === true) {
        this.item.title = this.item.elements[0].title;
      } else if (this.env.setTitle.length > 0 && this.env.setTitle.indexOf(this.item.typeId) > -1) {
        this.item.title = this.item.elements[0].title;
      }
      this.elementDropped.emit({ segmentIndex: this.index, elementIndex: index, element: this.item.elements[index], segment: this.item, keepParticipants });
      this.setElementOffset(index);
      this.setElementUnitType(index);
      this.item.elements[index] = this.helpersService.setElementInteralInfo(this.item.elements[index]);
      this.store.dispatch({ type: 'COUNTER_ADD' });
      this.updateWithCalc();
      this.matSnackBar.open('Successfully added element to segment.', null, { duration: 500 });
    } else {
      if (this.segmentTypes.glueId.indexOf(this.item.typeId) > -1 && this.env.setGlueTitle === true) {
        this.item.title = this.item.elements[0].title;
      } else if (this.env.setTitle.length > 0 && this.env.setTitle.indexOf(this.item.typeId) > -1) {
        this.item.title = this.item.elements[0].title;
      }
      for (let i = 0; i < selectedElements.length; i++) {
        this.elementDropped.emit({ segmentIndex: this.index, elementIndex: index + i, element: selectedElements[i].data, segment: this.item, keepParticipants });
        this.setElementOffset(index + i);
        this.setElementUnitType(index + i);
        this.item.elements[index + i] = this.helpersService.setElementInteralInfo(this.item.elements[index + i]);
        this.store.dispatch({ type: 'COUNTER_ADD' });
        this.updateWithCalc();
      }
      this.matSnackBar.open('Successfully added elements to segment.', null, { duration: 500 });
    }
  }

  hasUnitIcon(element) {
    if (element.unitId && this.unitsValue.items && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId].iconUrl) {
      element.flexIcon = this.unitsValue.items[element.unitId].iconUrl;
      return true;
    }
    return false;
  }

  copySegment(segment) {
    segment.vtbObjectId = this.helpersService.generateRandomId();
    if (segment.carRentalElements && segment.carRentalElements.length > 0) {
      for (const carElement of segment.carRentalElements) {
        if (carElement.element.TSOrderline && carElement.element.TSOrderline.extraFieldValues && carElement.element.TSOrderline.extraFieldValues.length > 0) {
          for (const extraField of carElement.element.TSOrderline.extraFieldValues) {
            extraField.vtbObjectId = this.helpersService.generateRandomId();
          }
        }
        carElement.element.vtbObjectId = this.helpersService.generateRandomId();
      }
    }
    if (segment.elements && segment.elements.length > 0) {
      for (const element of segment.elements) {
        if (element.TSOrderline && element.TSOrderline.extraFieldValues && element.TSOrderline.extraFieldValues.length > 0) {
          for (const extraField of element.TSOrderline.extraFieldValues) {
            extraField.vtbObjectId = this.helpersService.generateRandomId();
          }
        }
        element.vtbObjectId = this.helpersService.generateRandomId();
      }
    }
    if (localStorage.getItem('segments copy')) {
      localStorage.removeItem('segments copy');
    }
    localStorage.setItem('segment copy', JSON.stringify(segment));
  }

  checkSegmentCopy() {
    if (localStorage.getItem('segment copy')) {
      return true;
    }
    return false;
  }

  checkMultipleSegmentsCopy() {
    if (localStorage.getItem('segments copy')) {
      return true;
    }
    return false;
  }

  updateSegmentType(segmentTypeId) {
    if (this.segmentTypes.items) {
      if (this.segmentTypes.items[this.item.typeId].isFlight === true && this.segmentTypes.items[segmentTypeId].isFlight === false) { // TODO: update for harbour too
        this.item.flightInfo = [];
        this.item.hasFlightMismatch = false;
      }
    }
    if (this.segmentTypes.items[segmentTypeId].isFlight === true) {
      this.item.isFlight = true;
    }
    this.itemType = segmentTypeId;
    this.item.typeId = segmentTypeId;
    this.updateItinerary.emit();
  }

  checkSegmentTypeName(name) {
    if (name.toLowerCase().indexOf('harbor') > -1 || name.toLowerCase().indexOf('harbour') > -1 || name.toLowerCase().indexOf('haven') > -1) {
      return true;
    }
    return false;
  }

  deleteSegmentIndex(index) {
    let destyElementFound = false;
    if (this.item && this.item.elements != null && this.item.elements.length > 0) {
      for (const element of this.item.elements) {
        if (element.externalInfo != null && element.externalInfo.externalType != null && element.externalInfo.externalType === 'desty' && element.externalInfo.destyBookingData != null) {
          destyElementFound = true;
          break;
        }
      }
    }

    if (destyElementFound === true) {
      let dialog: any = this.dialog.open(ConfirmDialogComponent, {
        width: '500px'
      });
      dialog.componentInstance = {
        title: 'Booked hotels found in the Segment',
        message: `Deleting this segment will NOT cancel the hotels that are booked at desty, are you sure you want to delete this segment from the VTB?`
      };
      dialog.afterClosed().pipe(take(1)).subscribe(result => {
        if (result === 'true') {
          this.deleteSegment.emit(index)
        }
      });
    } else {
      this.deleteSegment.emit(index)
    }
  }

  async updateElements() {
    const boIds = [];
    const subTitles = [];
    const vtbIds = [];
    if (this.item.elements != null && this.item.elements.length > 0) {
      for (const element of this.item.elements) {
        if (element.TSProduct != null && element.TSProduct.id != null) {
          boIds.push(element.TSProduct.id);
          subTitles.push(element.subTitle);
          vtbIds.push(element.vtbElementId);
        }
      }
    }
    if (boIds.length > 0) {
      const promises = [];
      for (const boId of boIds) {
        promises.push(this.telespiritService.getElement(boId));
      }
      await Promise.all(promises).then(async (products) => {
        const productUpdates = [];
        let i = 0
        for (const product of products) {
          const elementFields = {};
          const efTranslatedName = {};
          const elementExtrafields = {};
          const elementSelectAll: any = [
            'title', 'additionalText', 'subAdditionalText', 'supplierInfo', 'internalText',
            'pricematrixInternalText', 'maps', 'media'
          ];
          const elementEFSelectAll = [];
          elementFields['selectAll'] = true;
          elementFields['title'] = true;
          elementFields['additionalText'] = true;
          elementFields['subAdditionalText'] = true;
          elementFields['supplierInfo'] = true;
          elementFields['internalText'] = true;
          elementFields['pricematrixInternalText'] = true;
          elementFields['maps'] = true;
          elementFields['media'] = true;
          for (let extrafield of product.orderlineExtrafields) {
            if (extrafield.show_in_product == 1) {
              let translatedName = extrafield.name;
              if (extrafield.translated_name) translatedName = extrafield.translated_name;
              efTranslatedName[extrafield.name] = translatedName;
              elementExtrafields[extrafield.name] = true;
              elementEFSelectAll.push(extrafield.name);
            }
          }
          elementFields['extrafields'] = elementExtrafields;
          elementFields['efTranslatedName'] = efTranslatedName;
          elementSelectAll.push({ extrafields: elementEFSelectAll });

          productUpdates.push({
            id: vtbIds[i],
            title: product.title,
            subTitle: subTitles[i],
            elementUpdates: elementFields,
            elementCheckUncheckAll: elementSelectAll,
            foundProduct: product
          });
          i++;
        }

        let newDialog: any = this.dialog.open(SegmentElementsUpdateDialogComponent);
        newDialog.componentInstance = {
          productUpdates
        };
        newDialog.afterClosed().pipe(take(1)).subscribe(productUpdates => {
          if (productUpdates != null && productUpdates.length > 0) {
            for (const product of productUpdates) {
              for (let element of this.item.elements) {
                if (product.id === element.vtbElementId) {
                  const result = this.helpersService.updateExternalProduct(element, product.elementUpdates, product.foundProduct);
                  element = result.data;
                }
              }
            }
            this.updateItinerary.emit();
          }
        });
      });
    } else {
      this.notificationsService.error('No BO products found', 'Add a BO product to the segment to use this functionality');
    }
  }
}
