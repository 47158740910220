import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';

export const PROPOSAL_BUILDS_UPDATE = 'PROPOSAL_BUILDS_UPDATE';
const logService = new LogService();

export function proposalBuildsReducer(state: any = [], action: ActionWithPayload) {
  switch (action.type) {
    case PROPOSAL_BUILDS_UPDATE:
      state = [];
      for (const proposalBuild of action.payload) {
        proposalBuild.channelid = `${action.config.token.env}-${proposalBuild.proposalId}`;
        state.push(proposalBuild);
      }
      logService.consoleLog('PROPOSAL_BUILDS_UPDATE:', state);
      return [...state];
    default:
      return state;
  }
}
