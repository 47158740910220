import { ErrorHandler, Injectable } from '@angular/core';
import { NotificationsService } from 'angular2-notifications';
import { HttpClient, HttpHeaders } from '@angular/common/http';

declare let window: any;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  headers: HttpHeaders;
  reportedErrors: string[] = [];
  constructor(private notificationsService: NotificationsService, private http: HttpClient) {
    this.headers = new HttpHeaders({
      'Authorization': `Basic ${btoa('gattelessunersuractichav:7600fae3c4bae7c66f36428256fffafedbd8bed6')}`
    });
  }

  sendReport(error) {
    const stringifiedError = JSON.stringify(error, Object.getOwnPropertyNames(error));
    if (this.reportedErrors.indexOf(stringifiedError) === -1) {
      this.reportedErrors.push(stringifiedError);
      let errorObject: any = {
        date: new Date(),
        url: window.location.href
      }
      errorObject = Object.assign(errorObject, JSON.parse(stringifiedError));
      return this.http.post('https://70198239-e7fa-404f-b5c0-a1b54897b97d-bluemix.cloudant.com/vtb-reports/_design/search/_search/errors', {
        q: `lineNumber:${errorObject.lineNumber} AND columnNumber:${errorObject.columnNumber} AND message:${JSON.stringify(errorObject.message)} AND fileName:"${errorObject.fileName}"`
      }, {
        headers: this.headers
      }).toPromise().then((result: any) => {
        if (result != null && typeof result === 'object' && result.total_rows === 0) {
          return this.http.post('https://70198239-e7fa-404f-b5c0-a1b54897b97d-bluemix.cloudant.com/vtb-reports', errorObject, {
            headers: this.headers
          }).toPromise().then(x => {
            //console.log('REPORTED_ERROR:',errorObject);
          }).catch(e => {
            console.log(e);
          });
        }
      }).catch(e => {
        console.log(e);
      })
    }
  }

  handleError(error) {
    let whitelist = [
      `ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'mat-menu-item-highlighted: true'. Current value: 'mat-menu-item-highlighted: false'.`,
      `ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'ngIf: false'. Current value: 'ngIf: true'.`,
      'b is null', 'a.filter is undefined',
      'Permission denied to access property "nodeType"',
      'Http failure response for https://api.eu.apiconnect.ibmcloud.com/barrytelespiritnl-mediaspiritv2/sb/manage/db/allcropres: 500 Invalid-JWT-Validate',
      'Http failure response for https://api.eu.apiconnect.ibmcloud.com/barrytelespiritnl-mediaspiritv2/sb/manage/db/imagesearch: 500 Invalid-JWT-Validate',
      'Http failure response for https://eddosgymxl.execute-api.eu-central-1.amazonaws.com/prod/dbproxy/db/allcropres: 403 Forbidden',
      'Http failure response for https://eddosgymxl.execute-api.eu-central-1.amazonaws.com/prod/dbproxy/db/allcropres: 403 OK',
      'Http failure response for https://eddosgymxl.execute-api.eu-central-1.amazonaws.com/prod/dbproxy/db/imagesearch: 403 Forbidden',
      `b is null; can't access its "getRanges" property`,
      `a.filter is undefined; can't access its "allow" property`,
      `InvalidPipeArgument: 'Unable to convert "Invalid Date" into a date' for pipe 'DatePipe'`,
      `ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'aria-expanded: true'. Current value: 'aria-expanded: null'.`,
      `Uncaught (in promise): Error: ViewDestroyedError: Attempt to use a destroyed view: detectChanges`,
      `ViewDestroyedError: Attempt to use a destroyed view: detectChanges`,
      `ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'saved: true'. Current value: 'saved: false'.`,
      `model-position-before-root: You cannot make a position before root.`,
      `ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: 'id: undefined'. Current value: 'id:`
    ];
    if (whitelist.indexOf(error.message) > -1) {
      // console.log('Whitelisted error:',error);
    } else {
      for (const row of whitelist) {
        if (error.message && error.message.indexOf(row) > -1) {
          // console.log('Whitelisted error:',error);
          return;
        }
      }
      console.log(error);
      //this.sendReport(error);
      // this.notificationsService.error('VTB Error', error.message, {
      //   timeOut: 8000
      // });
      this.notificationsService.error('VTB Error', 'open the browser console and email a screenshot of the error to us at info@travelspirit.nl', {
        timeOut: 8000
      });
    }

    // IMPORTANT: Rethrow the error otherwise it gets swallowed
    // WARNING Note David: enabling this causes effects to crash when a error occurs
    //console.log('JS Error:',error);
    //throw error;
  }

}
