import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { Store } from '@ngrx/store';
import { Config } from 'app/classes/config.class';
import { AuthService } from 'app/services/auth.service';
import { DestyService } from 'app/services/desty.service';
import { HelpersService } from 'app/services/helpers.service';
import { MapboxService } from 'app/services/mapbox.service';
import { Observable, Subject } from 'rxjs';
import { Lightbox, LightboxConfig } from 'ngx-lightbox';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatInput } from '@angular/material';
import { take, takeUntil } from 'rxjs/operators';
import { Options } from '@angular-slider/ngx-slider';
import { DestyPartyDialogComponent } from '../desty-party-dialog/desty-party-dialog.component';
import { DestyInfoDialogComponent } from '../desty-info-dialog/desty-info-dialog.component';

@Component({
  selector: 'app-desty-hotels',
  templateUrl: './desty-hotels.component.html',
  styleUrls: ['./desty-hotels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DestyHotelsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('locationInput') locationInput: MatInput;
  @ViewChild('destySearchFields', { read: ElementRef }) searchFields: ElementRef;
  @Input() bags: any[] = [];
  @Input() startDateIndex: any;

  unsubscribe = new Subject<void>();

  setDestination: any;
  destination: string;
  foundDestinations = [];

  setLocation: any;
  location: string;
  foundLocations = [];
  destinationRadius = "50";
  resultsAmount = "50";

  hotelName: string;
  foundHotels = [];

  minStars: number = 0;
  maxStars: number = 6;

  sliderOptions: Options = {
    floor: 0,
    ceil: 6,
    hideLimitLabels: true,
    hidePointerLabels: true
  };

  imageAlbum = [];

  currentDate = new Date();
  hotelsValue: any;
  hotelsValueCopy: any;
  firstTime = true;

  busy = false;
  loadMoreBusy = false;
  availabilityBusy = false;

  fromDate: any;
  toDate: any;
  nights = 1;
  startDate: any;

  adultsAmount = 0;
  childrenAmount = 0;
  childrenAges = [];
  roomType = "DR";
  roomTypeObjDefault = {};
  roomTypeObj = {};
  // mealType = "none";
  filterArray = [];

  parties: any;
  partyLength: any;
  partyArray: any;

  itinerary: Observable<AppModel.Itinerary>;
  itineraryData: any;
  counter: Observable<any>;
  counterData: any;
  mapCoordinates: Observable<any>;
  mapCoordinatesData: any;

  initialSearch = true;
  searchType = 'geo';
  bookmark: any;
  env: any;

  templateViewState: any;

  constructor(private dialog: MatDialog, public store: Store<any>, public cdr: ChangeDetectorRef, private config: Config, private router: Router, private activatedRoute: ActivatedRoute, private lightbox: Lightbox, private lightboxConfig: LightboxConfig, public sanitizer: DomSanitizer, public auth: AuthService, private destyService: DestyService, private helpersService: HelpersService, private mapbox: MapboxService) {
    this.itinerary = this.store.select('itinerary');
    this.counter = store.select('counter');
    this.mapCoordinates = store.select('mapDraw');
    this.env = this.config;
    this.lightboxConfig.centerVertically = true;
    this.lightboxConfig.disableKeyboardNav = true;
  }

  ngOnInit() {
    this.startDate = new Date();
    this.fromDate = new Date();
    this.calculateToDate();

    if (this.env != null && this.env.defaultDestyRadius != null && this.env.defaultDestyRadius !== '') {
      this.destinationRadius = this.env.defaultDestyRadius;
    }

    this.itinerary.pipe(takeUntil(this.unsubscribe)).subscribe(x => {
      this.itineraryData = x;
      if (this.itineraryData) {
        const datesIndex = this.filterArray.indexOf('dates');
        if (this.itineraryData.data.templateView === false) {
          if (datesIndex < 0) {
            this.filterArray.push('dates');
          }
        } else {
          if (this.templateViewState == null) {
            if (datesIndex > -1) {
              this.filterArray.splice(datesIndex, 1);
            }
          }
        }
        if (this.firstTime === true) {
          if (this.startDateIndex && this.itineraryData && this.itineraryData.data && this.itineraryData.data.segments &&
            this.itineraryData.data.segments[this.startDateIndex] && this.itineraryData.data.segments[this.startDateIndex].date) {
            if (this.itineraryData.data.templateView === true) {
              this.startDate = new Date();
              this.fromDate = new Date();
            } else {
              this.startDate = this.itineraryData.data.segments[this.startDateIndex].date;
              this.fromDate = this.itineraryData.data.segments[this.startDateIndex].date;
            }
            this.calculateToDate();
            this.cleanPrices();
          } else if (this.currentDate < this.itineraryData.data.startDate) {
            if (this.itineraryData.data.templateView === true) {
              this.startDate = new Date();
              this.fromDate = new Date();
            } else if (this.itineraryData.data.templateView === false) {
              this.startDate = this.itineraryData.data.startDate;
              this.fromDate = this.startDate;
            }
            this.calculateToDate();
            this.cleanPrices();
          }
        } else if (this.itineraryData.data.startDate > this.startDate) {
          if (this.templateViewState == null) {
            if (this.itineraryData.data.templateView === true) {
              this.startDate = new Date();
              this.fromDate = new Date();
            } else {
              this.fromDate = this.itineraryData.data.startDate;
              this.startDate = this.itineraryData.data.startDate;
            }
          }
          if (this.itineraryData.data.templateView == null || this.itineraryData.data.templateView === false) {
            this.templateViewState = this.itineraryData.data.templateView;
            this.calculateToDate();
            this.cleanPrices();
          }
        }
      }
      this.firstTime = false;

      this.cdr.markForCheck();
    });

    this.counter.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.itineraryData) {
        const datesIndex = this.filterArray.indexOf('dates');
        const clickedDatesIndex = this.filterArray.indexOf('clicked dates');
        if (this.itineraryData.data.templateView === false) {
          if (datesIndex < 0) {
            this.filterArray.push('dates');
          }
        } else {
          if (clickedDatesIndex < 0) {
            if (datesIndex > -1) {
              this.filterArray.splice(datesIndex, 1);
            }
          }
        }

        if (this.itineraryData.data.templateView === true) {
          if (this.templateViewState !== this.itineraryData.data.templateView) {
            this.startDate = new Date();
            this.fromDate = new Date();
            this.calculateToDate();
            this.cleanPrices();
          }
        } else {
          this.startDate = this.itineraryData.data.startDate;
          if (this.itineraryData.data.startDate > this.fromDate) {
            this.fromDate = this.itineraryData.data.startDate;
            this.calculateToDate();
            this.cleanPrices();
          }
        }
        this.templateViewState = this.itineraryData.data.templateView;
        if (this.itineraryData.data && this.itineraryData.data.participants) {
          this.partyArray = Object.keys(this.itineraryData.data.participants);
          this.partyLength = this.participantsLength(this.itineraryData.data.participants);
          this.setPartyRoomTypesDefault();
          this.roomTypeObj = JSON.parse(JSON.stringify(this.roomTypeObjDefault));
          this.cdr.detectChanges();
        }
        this.parties = JSON.parse(JSON.stringify(this.itineraryData.data.participants));
      }
      this.cdr.markForCheck();
    });

    this.mapCoordinates.pipe(takeUntil(this.unsubscribe)).subscribe(y => {
      if (y != null) {
        this.mapCoordinatesData = y;
        this.search();
        this.cdr.markForCheck();
      } else {
        this.cdr.markForCheck();
      }
    });
    this.initialSearch = true;
  }

  ngOnChanges(event) {
    if (event.startDateIndex && this.itineraryData && this.itineraryData.data && this.itineraryData.data.segments &&
      this.itineraryData.data.segments[event.startDateIndex.currentValue] && this.itineraryData.data.segments[event.startDateIndex.currentValue].date) {
      this.startDate = this.itineraryData.data.segments[event.startDateIndex.currentValue].date;
      this.fromDate = this.itineraryData.data.segments[event.startDateIndex.currentValue].date;
      this.calculateToDate();
      this.cleanPrices();
      if (this.hotelsValueCopy != null) {
        this.hotelsValue = JSON.parse(JSON.stringify(this.hotelsValueCopy));
      }
    }
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get offsetHeight() {
    if (this.searchFields && this.searchFields.nativeElement) {
      return this.searchFields.nativeElement.offsetHeight + 40;
    }
    return 0;
  }

  // indexToLetter(index: number) {
  //   return this.steps[index].label;
  // }

  calculateToDate() {
    this.fromDate = new Date(this.fromDate)
    if (this.fromDate) {
      if (this.fromDate.clone) {
        this.toDate = this.fromDate.clone();
        this.toDate.add(this.nights, 'days');
      } else {
        this.toDate = new Date(this.fromDate.getTime());
        if (this.toDate.setDate && this.fromDate.getDate) {
          this.toDate.setDate(this.fromDate.getDate() + this.nights);
        } else if (this.toDate.setDate && this.fromDate.toDate) {
          this.toDate.setDate(this.fromDate.toDate().getDate() + this.nights);
          if (this.toDate.getDate() < this.fromDate.toDate().getDate() + this.nights) {
            if (this.fromDate.month() + 1 < 12) {
              this.toDate.setMonth(this.fromDate.month() + 1);
            } else {
              this.toDate.setMonth(this.fromDate.month() + 1 % 12);
              this.toDate.setYear(this.fromDate.year() + 1)
            }
          }
        }
      }
    }
  }

  get mergedBags() {
    return this.bags.concat(['side-itinerary-segments']);
  }

  get tokenObj() {
    let obj: any = {};
    if (this.auth.authenticated != null && this.auth.authenticated === true) {
      obj.token = this.auth.token;
    }
    return obj;
  }

  autocomplete($event, type) {
    this.setDestination = null;
    this.setLocation = null;
    if (type === 'destinations') {
      if ($event.length > 0) {
        if (this.searchType === "default") {
          if (this.destination != null && this.destination !== '' && this.destination.length > 1 && $event.length === this.destination.length) {
            this.destyService.getDestinations($event.trim()).then(x => {
              let res: any = x;
              this.foundDestinations = res;
              this.hotelsValue = null;
              this.hotelsValueCopy = null;
              this.cdr.markForCheck();
            });
          }
        } else if (this.searchType === 'geo') {
          if (this.location != null && this.location !== '' && this.location.length > 1 && $event.length === this.location.length) {
            this.mapbox.getAutocorrectLocations($event.trim()).then(x => {
              let res: any = x;
              this.foundLocations = res;
              this.hotelsValue = null;
              this.hotelsValueCopy = null;
              this.cdr.markForCheck();
            }).catch(err => {
              console.log(err);
              this.foundLocations = [];
              this.hotelsValue = null;
              this.hotelsValueCopy = null;
              this.cdr.markForCheck();
            });
          }
        }
      } else {
        if (this.searchType === "default") {
          this.setDestination = null;
          this.destination = null;
        } else if (this.searchType === 'geo') {
          this.setLocation = null;
          this.location = null;
        }
        this.foundDestinations = [];
        this.foundLocations = [];
        this.hotelsValue = null;
        this.hotelsValueCopy = null;
        this.cdr.markForCheck();
      }
    }
  }

  updateNights() {
    const a = new Date(this.fromDate);
    const b = new Date(this.toDate);
    this.nights = this.helpersService.dateDiffInDays(a, b);
    this.hotelsValue = null;
    this.hotelsValueCopy = null;
    this.cdr.markForCheck();
  }

  participantsLength(participants) {
    let participantAmount = 0;
    const parties = Object.keys(participants);

    for (const party of parties) {
      if (participants[party] != null) {
        participantAmount += participants[party].length;
      }
    }
    return participantAmount;
  }

  setPartyRoomTypesDefault() {
    this.roomTypeObjDefault = {};
    for (const party of this.partyArray) {
      let length = 0;
      for (const participant of this.itineraryData.data.participants[party]) {
        if (participant.birthdate === '') {
          length++;
        } else {
          if (this.helpersService.calculateAge(participant.birthdate, this.fromDate) > 17) {
            length++;
          }
        }
      }
      if (length > 0) {
        this.roomTypeObjDefault[party] = length;
      }
    }
  }

  getRoomTypeValue(party) {
    if (this.roomTypeObjDefault && this.roomTypeObjDefault[party]) {
      if (this.roomTypeObjDefault[party] < 5) {
        return '' + this.roomTypeObjDefault[party];
      }
      return '4';
    }
    return '';
  }

  setPartyRoomTypeValue(party, value) {
    this.roomTypeObj[party] = value;
  }

  setSelectedDestination(value) {
    if (this.searchType === 'default') {
      this.setDestination = value;
      this.destination = value.name;
      this.search();
    } else if (this.searchType === 'geo') {
      this.setLocation = value;
      this.location = value.place_name;
      this.search();
    }
  }

  checkInputValues() {
    if (this.searchType === 'default') {
      if (this.destination || this.hotelName) {
        return true;
      } else {
        return false;
      }
    } else if (this.searchType === 'geo') {
      if (this.location || this.hotelName) {
        return true;
      } else {
        return false;
      }
    } else if (this.searchType === 'area-search') {
      if (this.mapCoordinatesData != null && this.mapCoordinatesData.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  typeChange(type) {
    this.searchType = type;
    this.store.dispatch({ type: 'MARKERS_DELETED' });
    this.hotelsValue = null;
    if (this.searchType === 'area-search') {
      this.goToMap(null, 'area-search');
    } else {
      this.goToMap(null, 'else');
    }
    this.cdr.detectChanges();
  }

  cleanupHotelName() {
    if (this.hotelName.length < 1) {
      this.hotelName = null;
    }
  }

  checkSearch() {
    if (this.searchType === 'default') {
      if (this.setDestination != null || this.destination != null || this.hotelName != null) {
        this.search();
      }
    } else if (this.searchType === 'geo') {
      if (this.setLocation != null || this.location != null) {
        this.search();
      } else {
        this.locationInput.focus();
        this.cdr.detectChanges();
      }
    }
  }

  search(type = null) {
    this.initialSearch = false;
    if (this.checkFilterArray('availability') === true) {
      this.setFilter('availability');
    }
    if (type !== 'availability') {
      if (this.activatedRoute.snapshot && this.activatedRoute.snapshot.queryParamMap && this.activatedRoute.snapshot.queryParamMap.get('lat') != null) {
        this.router.navigate(['/map-view'], { queryParams: this.tokenObj });
      }
      if (this.searchType === 'default') {
        if (this.setDestination == null) {
          if (this.foundDestinations != null && this.foundDestinations.length > 0) {
            this.setDestination = this.foundDestinations[0];
            this.setSelectedDestination(this.setDestination);
          } else {
            this.store.dispatch({ type: 'ELEMENTS_UPDATE', payload: [], config: this.env });
            this.hotelsValue = [];
            this.hotelsValueCopy = [];
            this.imageAlbum = []
          }
        }
      }
      if (this.searchType === 'geo') {
        if (this.setLocation == null) {
          if (this.foundLocations != null && this.foundLocations.length > 0) {
            this.setLocation = this.foundLocations[0];
            this.setSelectedDestination(this.setLocation);
          } else {
            this.store.dispatch({ type: 'ELEMENTS_UPDATE', payload: [], config: this.env });
            this.hotelsValue = [];
            this.hotelsValueCopy = [];
            this.imageAlbum = []
          }
        }
      }
      if (type == null) {
        this.bookmark = null;
      }
      if (this.setDestination == null && this.setLocation == null) {
        if (this.hotelName == null) {
          if (this.searchType !== 'area-search') {
            return;
          }
        } else {
          this.busy = true;
        }
      } else {
        if (type === 'load-more') {
          this.loadMoreBusy = true;
        } else {
          this.busy = true;
        }
      }

      const postObj = {
        name: this.hotelName,
        destination: null,
        location: null,
        geo: null,
        availabilitySearch: null,
        fromStars: this.minStars,
        untilStars: this.maxStars,
        polygon: [],
        bookmark: null,
        limit: null
      }

      if (type === 'load-more') {
        postObj.bookmark = this.bookmark;
      }
      if (Number(this.resultsAmount) < 50) {
        postObj.limit = Number(this.resultsAmount);
      }

      if (this.searchType === 'default') {
        if (this.setDestination != null && this.setDestination.id != null) {
          postObj.destination = this.setDestination.id;
        }
      } else if (this.searchType === 'geo') {
        if (this.setLocation && this.setLocation.center) {
          postObj.geo = {
            latitude: this.setLocation.center[1],
            longitude: this.setLocation.center[0],
            radius: this.destinationRadius
          }
        } else {
          this.store.dispatch({ type: 'ELEMENTS_UPDATE', payload: [], config: this.env });
          this.hotelsValue = [];
          this.hotelsValueCopy = [];
          this.imageAlbum = []
          return;
        }
      } else if (this.searchType === 'area-search') {
        for (const coordinates of this.mapCoordinatesData) {
          postObj.polygon.push({
            latitude: coordinates[1],
            longitude: coordinates[0]
          });
        }
      }

      const partyArray = []
      for (const party of Object.keys(this.roomTypeObj)) {
        partyArray.push({ adults: this.roomTypeObj[party] })
      }
      if (partyArray.length === 0) {
        partyArray.push({ adults: 2 })
      }

      this.delay(500).then(() => {
        this.destyService.searchHotels(postObj).then(x => {
          const res: any = x;
          this.bookmark = res.bookmark;
          if (type === 'load-more') {
            for (const result of res.results) {
              let found = false;
              if (this.hotelsValue && this.hotelsValue.length > 0) {
                for (const hotel of this.hotelsValue) {
                  if (result.externalInfo.externalId === hotel.externalInfo.externalId) {
                    found = true;
                    break;
                  }
                }
              }
              if (found === false) {
                this.hotelsValue.push(result);
              }
            }
          } else {
            this.hotelsValue = res.results;
            if (type == null) {
              this.hotelsValueCopy = res.results;
            }
          }
          const markers = [];
          for (const hotel of this.hotelsValue) {
            if (hotel.geo && hotel.geo.latitude && hotel.geo.longitude) {
              markers.push({
                image: hotel.thumbUrl,
                latitude: hotel.geo.latitude,
                longitude: hotel.geo.longitude,
                title: hotel.name
              });
            }
          }
          this.store.dispatch({ type: 'MARKERS_UPDATE', payload: markers });
          this.imageAlbum = []
          for (const hotel of this.hotelsValue) {
            hotel.externalInfo.destyFromDate = this.fromDate;
            if (hotel.destination != null) {
              hotel.externalInfo.destyDestination = hotel.destination.name;
              hotel.externalInfo.destyDestinationId = hotel.destination.id;
              delete hotel.destination;
            }
            hotel.externalInfo.availabilityError = null;
            hotel.externalInfo.usedAvailabilitySearch = null;
            hotel.externalInfo.hasAvailabilityInfo = null;
            hotel.nights = this.nights;
            hotel.unitAmount = this.nights;
            if (hotel.externalInfo.extraInfo && hotel.externalInfo.extraInfo.bestPrice != null) {
              hotel.externalInfo.extraInfo.bestPrice = null;
            }
            if (hotel.thumbUrl != null && hotel.thumbUrl !== '') {
              this.imageAlbum.push({
                src: hotel.thumbUrl
              });
            } else {
              this.imageAlbum.push({
                src: 'dit is geen image'
              });
            }
          }
          this.busy = false;
          this.availabilityBusy = false;
          this.loadMoreBusy = false;
          if (this.bookmark != null) {
            if (this.hotelsValue.length < Number(this.resultsAmount)) {
              this.search('load-more');
            }
          }
          this.cdr.markForCheck();
        });
      });
    } else {
      this.availabilityBusy = true;
      for (const hotel of this.hotelsValue) {
        hotel.externalInfo.availabilitySearchClicked = true;
      }
      const partyArray = []
      for (const party of Object.keys(this.roomTypeObj)) {
        partyArray.push({ adults: this.roomTypeObj[party] })
      }
      if (partyArray.length === 0) {
        partyArray.push({ adults: 2 })
      }

      let postObj = {
        ids: this.getHotelIds(this.hotelsValue),
        availabilitySearch: {
          checkinDate: this.fromDate.getFullYear() + '-' + ((this.fromDate.getMonth() + 1) < 10 ? '0' : '') + Number(this.fromDate.getMonth() + 1) + '-' + (this.fromDate.getDate() < 10 ? '0' : '') + this.fromDate.getDate(),
          nights: this.nights,
          party: partyArray
        }
      }
      this.delay(500).then(() => {
        this.destyService.checkHotelAvailability(postObj).then(x => {
          const res: any = x;
          for (const resHotel of res) {
            for (const hotel of this.hotelsValue) {
              hotel.externalInfo.availabilitySearchClicked = false;
              if (hotel.externalInfo.externalId === resHotel.id) {
                hotel.externalInfo.availabilityError = false;
                hotel.externalInfo.usedAvailabilitySearch = true;
                hotel.externalInfo.hasAvailabilityInfo = resHotel.hasAvailabilityInfo;
                hotel.externalInfo.extraInfo = {};
                hotel.externalInfo.extraInfo = resHotel.hotelInfo;
                // hotel.roomTypes = res
              }
            }
          }
          for (const hotel of this.hotelsValue) {
            hotel.externalInfo.destyFromDate = this.fromDate;
            hotel.nights = this.nights;
            hotel.unitAmount = this.nights;
            if (hotel.externalInfo.availabilityError == null) {
              hotel.externalInfo.availabilityError = true;
              hotel.externalInfo.usedAvailabilitySearch = false;
            }
          }
          this.availabilityBusy = false;
          this.loadMoreBusy = false;
          this.cdr.markForCheck();
        });
      });
    }
  }

  getHotelIds(hotelsValue) {
    let ids = '';
    for (const hotel of hotelsValue) {
      if (ids === '') {
        ids = hotel.externalInfo.externalId || 0;
      } else {
        ids += ', ' + hotel.externalInfo.externalId || 0;
      }
    }
    return ids;
  }

  getAvailability() {
    return false;
  }

  resetInputs() {
    this.destination = null;
    this.setDestination = null;
    this.location = null;
    this.setLocation = null;
    this.hotelName = null;
    this.destinationRadius = "5";
    if (this.startDate > new Date()) {
      this.fromDate = this.startDate;
    } else {
      this.fromDate = new Date();
    }
    this.nights = 1;
    this.calculateToDate();
    // this.roomType = "DR";
    // this.roomTypeObj = JSON.parse(JSON.stringify(this.roomTypeObjDefault));
    // this.setDefaultRoomType();
    // this.mealType = "none";
    // this.filterArray = [];
    // this.sortArray = [];
    this.foundDestinations = [];
    this.foundLocations = [];
    this.foundHotels = [];
    this.hotelsValue = null;
    this.hotelsValueCopy = null;
  }

  resetResults() {
    this.hotelsValue = null;
    this.hotelsValueCopy = null;
    this.cdr.markForCheck();
  }

  cleanPrices() {
    if (this.hotelsValue && this.hotelsValue.length > 0) {
      for (const hotel of this.hotelsValue) {
        hotel.externalInfo.availabilityError = null;
        hotel.externalInfo.usedAvailabilitySearch = null;
        hotel.externalInfo.hasAvailabilityInfo = null;
        if (hotel.externalInfo && hotel.externalInfo.extraInfo && hotel.externalInfo.extraInfo.bestPrice) {
          hotel.externalInfo.extraInfo.bestPrice = null;
        }
      }
    }
    this.hotelsValueCopy = this.hotelsValue;
    this.cdr.markForCheck();
  }

  openImage(i) {
    if (this.imageAlbum[i].src !== 'dit is geen image') {
      this.lightbox.open(this.imageAlbum, i);
    }
  }

  goToMap(element, mode = null) {
    const tempObj = this.tokenObj;
    if (element == null && mode == null && this.searchType === 'area-search') {
      this.router.navigate(['/map-view'], { queryParams: tempObj });
      return;
    }
    if (mode != null && mode === 'area-search') {
      this.store.dispatch({ type: 'USER_INTERFACE_SHOW_MAP', payload: true });
      this.store.dispatch({ type: 'COUNTER_ADD' });
      this.router.navigate(['/map-view'], { queryParams: tempObj });
    } else {
      if (this.searchType !== 'area-search') {
        this.store.dispatch({ type: 'MAP_DELETED', payload: null });
        this.store.dispatch({ type: 'USER_INTERFACE_SHOW_MAP', payload: false });
        this.store.dispatch({ type: 'COUNTER_ADD' });
      }
    }
    if (element != null) {
      tempObj.lat = element.geo.latitude;
      tempObj.lng = element.geo.longitude;
      this.router.navigate(['/map-view'], { queryParams: tempObj });
    }
  }

  checkMapData(element) {
    if (element.geo != null) {
      if (element.geo.latitude != null && !isNaN(element.geo.latitude) && element.geo.longitude != null && !isNaN(element.geo.longitude)) {
        return true
      }
    }
    return false;
  }

  checkAvailability(hotel) {
    const partyArray = []
    for (const party of Object.keys(this.roomTypeObj)) {
      partyArray.push({ adults: this.roomTypeObj[party] })
    }
    if (partyArray.length === 0) {
      partyArray.push({ adults: 2 })
    }

    let postObj = {
      ids: hotel.externalInfo.externalId,
      availabilitySearch: {
        checkinDate: this.fromDate.getFullYear() + '-' + ((this.fromDate.getMonth() + 1) < 10 ? '0' : '') + Number(this.fromDate.getMonth() + 1) + '-' + (this.fromDate.getDate() < 10 ? '0' : '') + this.fromDate.getDate(),
        nights: this.nights,
        party: partyArray
      }
    }
    hotel.externalInfo.availabilitySearchClicked = true;
    this.destyService.checkHotelAvailability(postObj).then(x => {
      const res: any = x;
      if (res === 'error found') {
        hotel.externalInfo.availabilitySearchClicked = false;
        hotel.externalInfo.availabilityError = true;
        hotel.externalInfo.usedAvailabilitySearch = false;
      } else {
        hotel.externalInfo.availabilitySearchClicked = false;
        hotel.externalInfo.availabilityError = false;
        hotel.externalInfo.usedAvailabilitySearch = true;
        hotel.externalInfo.hasAvailabilityInfo = res[0].hasAvailabilityInfo;
        hotel.externalInfo.extraInfo = res[0].hotelInfo;
      }
      this.cdr.markForCheck();
    })
  }

  setFilter(filter, event = null) {
    if (filter === 'availability') {
      const filterIndex = this.filterArray.indexOf(filter);
      if (filterIndex < 0) {
        this.filterArray.push(filter);
      } else {
        this.filterArray.splice(filterIndex, 1);
      }
    } else if (filter === 'dates') {
      const filterIndex = this.filterArray.indexOf(filter);
      if (filterIndex < 0) {
        this.filterArray.push(filter);
        this.filterArray.push('clicked dates');
      } else {
        this.filterArray.splice(filterIndex, 2);
      }
    }
    if (this.hotelsValueCopy && this.hotelsValueCopy.length > 0) {
      this.hotelsValue = this.filterData(this.hotelsValueCopy);
    }
    if (event != null) {
      event.stopPropagation();
    }
    this.cdr.markForCheck();
  }

  filterData(data) {
    let filteredArray = [];
    if (this.checkFilterArray('availability') === true) {
      for (const hotel of data) {
        if (hotel.externalInfo && hotel.externalInfo.extraInfo && hotel.externalInfo.extraInfo.bestPrice && hotel.externalInfo.extraInfo.bestPrice.value) {
          filteredArray.push(hotel);
        }
      }
    } else {
      filteredArray = data;
    }
    return filteredArray;
  }

  checkFilterArray(filter) {
    const index = this.filterArray.indexOf(filter)
    if (index > -1) {
      return true;
    }
    return false;
  }

  openPartyDialog() {
    let dialogRef: any = this.dialog.open(DestyPartyDialogComponent, {
      minHeight: '50px',
      width: '250px',
      backdropClass: 'no-backdrop',
      panelClass: 'desty-party-container'
    });
    dialogRef.componentInstance = {
      data: this.roomTypeObj,
      type: this.searchType,
      fromDate: this.fromDate
    };
    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
      this.cleanPrices();
      for (const party of result) {
        this.setPartyRoomTypeValue(party.name, party.adults); // No children are supported yet because we dont search with them yet
      }
    });
  }

  checkAllAvailability() {
    let found = true;
    for (const hotel of this.hotelsValue) {
      if (hotel.externalInfo.availabilityError == null && hotel.externalInfo.extraInfo == null) {
        found = false;
        break;
      }
    }
    return found;
  }

  delay(time) {
    return new Promise((resolve) => {
      setTimeout(resolve.bind(null), time)
    });
  }

  getExtraInfo(hotel) {
    let dialogRef: any = this.dialog.open(DestyInfoDialogComponent, {
      height: '600px',
      width: '800px',
    });
    dialogRef.componentInstance = {
      hotel: hotel,
      participants: this.roomTypeObj[this.partyArray[0]] || null
    }
  }

  getMinDate() {
    if (this.itineraryData.data.templateView === true) {
      return new Date();
    } else {
      return this.startDate;
    }
  }
}
