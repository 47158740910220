import { ActionWithPayload } from '../models/action-with-payload';

export const USER_INTERFACE_UPDATE = 'USER_INTERFACE_UPDATE';
export const USER_INTERFACE_RESET = 'USER_INTERFACE_RESET';
export const USER_INTERFACE_SHOW_CONTEXT = 'USER_INTERFACE_SHOW_CONTEXT';
export const USER_INTERFACE_SHOW_CONTEXT_MEDIASPIRIT = 'USER_INTERFACE_SHOW_CONTEXT_MEDIASPIRIT';
export const USER_INTERFACE_SHOW_CONTEXT_ELEMENTS = 'USER_INTERFACE_SHOW_CONTEXT_ELEMENTS';
export const USER_INTERFACE_SHOW_CONTEXT_LIBTEXT = 'USER_INTERFACE_SHOW_CONTEXT_LIBTEXT';
export const USER_INTERFACE_TOGGLE_SIDE_ITINERARY = 'USER_INTERFACE_TOGGLE_SIDE_ITINERARY';
export const USER_INTERFACE_TOGGLE_SIDE_CONTEXT = 'USER_INTERFACE_TOGGLE_SIDE_CONTEXT';
export const USER_INTERFACE_HIDE_SIDE_CONTEXT = 'USER_INTERFACE_HIDE_SIDE_CONTEXT';
export const USER_INTERFACE_SHOW_SIDE_CONTEXT = 'USER_INTERFACE_SHOW_SIDE_CONTEXT';
export const USER_INTERFACE_SHOW_MAP = 'USER_INTERFACE_SHOW_MAP';
export const USER_INTERFACE_TRIGGER_ADD_SEGMENT_ANIMATION = 'USER_INTERFACE_TRIGGER_ADD_SEGMENT_ANIMATION';

let userInterfaceObj = { showSideItinerary: true, showSideContext: true, sideContext: '', showMapDraw: false, triggerAnimation: false }

export function userInterfaceReducer(state: any = userInterfaceObj, action: ActionWithPayload) {
    switch (action.type) {
        case USER_INTERFACE_UPDATE:
            state = action.payload;
            return state;

        case USER_INTERFACE_RESET:
            return userInterfaceObj;

        case USER_INTERFACE_TOGGLE_SIDE_ITINERARY:
            state.showSideItinerary = !state.showSideItinerary;
            return { ...state };

        case USER_INTERFACE_TOGGLE_SIDE_CONTEXT:
            state.showSideContext = !state.showSideContext;
            return { ...state };

        case USER_INTERFACE_HIDE_SIDE_CONTEXT:
            state.showSideContext = false;
            state.showSideItinerary = false;
            return state;

        case USER_INTERFACE_SHOW_SIDE_CONTEXT:
            state.showSideContext = true;
            state.showSideItinerary = true;
            return state;

        case USER_INTERFACE_SHOW_CONTEXT:
            state.sideContext = action.payload;
            return state;

        case USER_INTERFACE_SHOW_MAP:
            state.showMapDraw = action.payload;
            return state;

        case USER_INTERFACE_TRIGGER_ADD_SEGMENT_ANIMATION:
            if (state.triggerAnimation !== action.payload) {
                state.triggerAnimation = action.payload;
                return { ...state };
            }
            return state;

        default:
            return state;

    }
}
