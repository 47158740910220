import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable()
export class LogService {
    constructor() {}
    consoleLog(value1,value2:any = ''){
      const config = environment;
        if(config.logging === true || window['logging'] === true){
          console.log(value1,value2);
        }
    }
}
