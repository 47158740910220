import { ActionWithPayload } from '../models/action-with-payload';
import { LogService } from '../services/log.service';
import { segmentTypesState } from './segment-types';

export const ELEMENTS_UPDATE = 'ELEMENTS_UPDATE';
const logService = new LogService();

export function elementsReducer(state: AppModel.Element[] = [], action: ActionWithPayload) {
  switch (action.type) {
    case ELEMENTS_UPDATE:
      state = action.payload;
      state = state.map(x => {

        x.vtbElementId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        x.vtbObjectId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

        if (x.hasOwnProperty('description') === true && x.hasOwnProperty('additionalText') === false) {
          x.additionalText = x.description;
          delete x.description;
        }

        if (x.hasOwnProperty('olPrices') === false || typeof x.olPrices !== 'object') {
          x.olPrices = {
            participants: {}
          };
          if (x.hasOwnProperty('buyingCurrencyCode') === true) {
            x.olPrices.purchaseCurrency = x.buyingCurrencyCode;
            delete x.buyingCurrencyCode;
          }
        }

        x.not_calculated = true;

        if (x.hasOwnProperty('unitId') === false) {
          x.unitId = 2;
        }

        if (x.hasOwnProperty('nights') === false) {
          x.nights = 1;
        }
        if (x.hasOwnProperty('unitAmount') === false) {
          x.unitAmount = 1;
        }

        if (x.sellingAmount != null && x.sellingMarkupType != null && x.sellingMarkupType !== 'absolute') {
          let margin = Number(x.sellingAmount);
          if (x.sellingMarkupType === 'percentage') {
            margin = (1 - (100 / (100 + margin))) * 100;
          }
          x.olPrices.margin = margin;
        }

        x.offset = 0;

        if (x.maps != null) {
          x.maps.latitude = Number(x.maps.latitude);
          x.maps.longitude = Number(x.maps.longitude);
          x.maps.zoom = !x.maps.static_image_zoom ? 14 : Number(x.maps.static_image_zoom);
        } else {
          x.maps = { latitude: null, longitude: null, zoom: null };
        }
        x.optional = false;

        // properties added to allow drop in left itinerary
        let defaultSegmentId = 1;
        if (action.config.defaultSegment != null) {
          for (const id of segmentTypesState.ids) {
            if (segmentTypesState.items[id].name.toLowerCase() === action.config.defaultSegment.toLowerCase()) {
              defaultSegmentId = id;
            }
          }
        } else {
          defaultSegmentId = segmentTypesState.glueId[0];
        }
        x.typeId = defaultSegmentId;
        x.elements = [];
        x.flightInfo = [];
        x.dataType = 'elementSegment';
        // end

        return x;
      });
      logService.consoleLog('ELEMENTS_UPDATE:', state);
      return state;

    default:
      return state;
  }
}
