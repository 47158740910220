import { Injectable } from '@angular/core';
//import { environment } from '../../environments/environment';
import { TelespiritService } from './telespirit.service';
import { NotificationsService } from 'angular2-notifications';
import { Store } from '@ngrx/store';
import { Config } from 'app/classes/config.class';
import { VisualtourbuilderService } from 'app/services/visualtourbuilder.service';
import { isGlobal } from '../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';

import PubNub from 'pubnub';

@Injectable()
export class PubnubService {
    pubnub: any;
    apiKey = 'TyMmPw.FvRODQ:NDve34aOuSR1uYPP';
    pubnubConfig: any = {
        subscribeKey: this.apiKey,
        publishKey: this.apiKey,
        origin: 'pubnub.ably.io',
        ssl: true,
        no_wait_for_pending: true
    };
    channel: string;
    occupancy: number = 1;
    initialized: boolean = false;
    lastMessage: any;
    pubnubListener: any;

    customerTemplateId: number;
    customerTemplate: any;

    itinerary: Observable<any>;
    itineraryData: any;
    itinerarySubscription: Subscription;

    proposalBuilds: Observable<any>;
    proposalBuildsSubscription: Subscription;
    standaloneChannels = [];

    constructor(
        public config: Config,
        private store: Store<any>,
        private telespiritService: TelespiritService,
        private notificationsService: NotificationsService,
        private visualtourbuilderService: VisualtourbuilderService,
        private datePipe: DatePipe
    ) {
        this.itinerary = this.store.select('itinerary');
        this.itinerarySubscription = this.itinerary.subscribe(x => {
            this.itineraryData = x;
        });
        this.proposalBuilds = this.store.select('proposalBuilds');
        this.proposalBuildsSubscription = this.proposalBuilds.subscribe(x => {
            if (x != null && x.length > 0) {
                let changed = false
                for (const proposalBuild of x) {
                    if (this.standaloneChannels.indexOf(proposalBuild.channelid) < 0) {
                        this.standaloneChannels.push(proposalBuild.channelid);
                        changed = true;
                    }
                }
                if (changed === true) {
                    const standalonePubnub = new PubNub({
                        subscribeKey: "TyMmPw.y-amDQ:LBFloJuczO9c0-5f",
                        origin: 'pubnub.ably.io',
                        ssl: true,
                        no_wait_for_pending: true
                    });

                    const standaloneListener = {
                        message: (data) => {
                            this.setProposalStatus(data);
                        }
                    };
                    standalonePubnub.addListener(standaloneListener);

                    standalonePubnub.subscribe({
                        channels: [this.standaloneChannels],
                        withPresence: true
                    });
                }
            }
        });
    }

    async init(itinerary, reset = false) {
        if (this.pubnub != null && this.initialized === true && reset === true) {
            this.pubnub.removeListener(this.pubnubListener);
            this.pubnubListener = {
                presence: (presenceEvent) => {
                    this.occupancy = presenceEvent.occupancy;
                    if (this.lastMessage != null) {
                        this.publishMessage(this.lastMessage);
                    }
                },
                message: (data) => {
                    this.processMessage(data, itinerary);
                }
            };

            this.pubnub.addListener(this.pubnubListener);

            this.pubnub.subscribe({
                channels: [this.channel],
                withPresence: true
            });

        } else if (this.initialized === false && reset === false) {
            this.initialized = true;

            this.channel = sessionStorage.getItem(`pubnub-vtb-channel-${itinerary.id}`) || null;
            if (this.channel === null) {
                this.channel = await this.telespiritService.getPubnubChannel().then((x: any) => x.channelId);
                sessionStorage.setItem(`pubnub-vtb-channel-${itinerary.id}`, this.channel);
            }

            this.pubnubConfig.authKey = this.channel;
            this.pubnub = new PubNub(this.pubnubConfig);
            this.pubnubListener = {
                presence: (presenceEvent) => {
                    this.occupancy = presenceEvent.occupancy;
                    if (this.lastMessage != null) {
                        this.publishMessage(this.lastMessage);
                    }
                },
                message: (data) => {
                    this.processMessage(data, itinerary);
                }
            };
            this.pubnub.addListener(this.pubnubListener);

            this.pubnub.subscribe({
                channels: [this.channel],
                withPresence: true
            });

        }
        return { pubnubInstance: this.pubnub, channel: this.channel, occupancy: this.occupancy };
    }

    setExtraFieldVtbObjectIds(extraFields, data) {
        if (extraFields != null && extraFields.length > 0) {
            for (let extraField of extraFields) {
                if (extraField.vtbObjectId === data.message.vtbObjectId) {
                    extraField[data.message.propertyName] = data.message.newValue;
                } else {
                    this.setExtraFieldVtbObjectIds(extraField.fields, data);
                }
            }
        }
    }

    async processMessage(data, itinerary) {
        if (data.message.vtbObjectId != null) {
            let replaceValue = JSON.parse(JSON.stringify(data.message.newValue));

            let plainTextElements = ['title', 'subTitle'];
            if (plainTextElements.indexOf(data.message.propertyName) > -1) {
                // html -> text, use browser to do it
                const temp = document.createElement("div");
                temp.innerHTML = replaceValue;
                replaceValue = temp.textContent || temp.innerText || "";
            }

            // write change
            if (itinerary.data.vtbObjectId === data.message.vtbObjectId) {
                itinerary.data[data.message.propertyName] = replaceValue;
            }
            if (itinerary.data.TSOrder != null && itinerary.data.TSOrder.texts != null && itinerary.data.TSOrder.texts[data.message.propertyName] != null) {
                itinerary.data.TSOrder.texts[data.message.propertyName] = replaceValue;
            }
            if (itinerary.data.extraFieldValues) {
                for (const extraFieldValue of itinerary.data.extraFieldValues) {
                    for (const extraField of extraFieldValue.fields) {
                        if (extraField.vtbObjectId === data.message.vtbObjectId) {
                            extraField.value = replaceValue;
                            break;
                        }
                    }
                }
            }
            if (itinerary.data.dayTexts) {
                for (const day in itinerary.data.dayTexts) {
                    if (itinerary.data.dayTexts[day].vtbObjectId === data.message.vtbObjectId) {
                        itinerary.data.dayTexts[day].value = replaceValue;
                        this.store.dispatch({ type: 'DAYTEXTS_UPDATED', payload: itinerary.data.dayTexts });
                        break;
                    }
                }
            }
            this.setExtraFieldVtbObjectIds(itinerary.data.extraFieldValues, data);
            for (let segment of itinerary.data.segments) {
                if (segment.vtbObjectId === data.message.vtbObjectId) {
                    segment[data.message.propertyName] = replaceValue;
                    break;
                }
                for (let element of segment.elements) {
                    if (element.vtbObjectId === data.message.vtbObjectId) {
                        if (element[data.message.propertyName] != null) {
                            element[data.message.propertyName] = replaceValue;
                        } else {
                            if (element.TSOrderline && element.TSOrderline.extraFieldValues) {
                                for (const extraField of element.TSOrderline.extraFieldValues) {
                                    if (extraField.name === data.message.propertyName) {
                                        extraField.value = replaceValue;
                                        break;
                                    }
                                }
                            }
                        }
                        break;
                    } else {
                        if (element.TSOrderline && element.TSOrderline.extraFieldValues) {
                            for (const extraField of element.TSOrderline.extraFieldValues) {
                                if (extraField.vtbObjectId === data.message.vtbObjectId) {
                                    extraField.value = replaceValue;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            itinerary.pubnubChange = true;
            this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: itinerary });
        } else if (data.message.subject && data.message.subject === 'buildSuccess') {
            let url = 'https://';
            let type = '';
            if (itinerary.data.publishedTemplates && itinerary.data.publishedTemplates.length > 0) {
                for (const template of itinerary.data.publishedTemplates) {
                    if (template.url.replace(/^.*?\//, '').indexOf(data.message.folderName.replace(/^.*?\//, '')) > -1) {
                        template.status = 'success';
                        type = template.type;
                        if (template.completeUrl != null) {
                            url += template.completeUrl;
                        } else if (template.pdfName) {
                            url += template.url + '/' + template.downloadName.replace(/\s/g, '_').replace(/#/g, '').replace(/\:/g, '').replace(/\-/g, '') + '.pdf';
                        } else {
                            url += template.url + '/index.html';
                        }
                        break;
                    }
                }
            }
            if (type === 'pdf') {
                const toast = this.notificationsService.success('PDF was created!', 'Click here to open PDF', {
                    timeOut: 60000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
                toast.click.subscribe(() => {
                    window.open(url, "_blank");
                });
            } else if (type === 'pdfjson') {
                this.notificationsService.success('Agent JSON was created!', '', {
                    timeOut: 60000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
            } else if (type === 'website') {
                const toast = this.notificationsService.success('Website was created!', 'Click here to open', {
                    timeOut: 60000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
                toast.click.subscribe(() => {
                    window.open(url, "_blank");
                });
            } else if (type === 'app') {
                this.notificationsService.success('App was created!', '', {
                    timeOut: 60000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
            }
            if (isGlobal === false || this.config.tsToken != null) {
                this.telespiritService.putItinerary(itinerary);
            }
            if (isGlobal === true) {
                this.visualtourbuilderService.updateVtbData(itinerary);
            }
            this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: itinerary });
            this.store.dispatch({ type: 'COUNTER_ADD' });
        } else if (data.message.livePreviewReady && data.message.livePreviewReady === true) {
            this.telespiritService.uploadTemporaryObject({ itinerary: itinerary.data, templateId: this.customerTemplateId, customerTemplate: this.customerTemplate }).then(x => {
                this.publishMessage({ fileName: x, source: 'vtb' });
            });
        } else if (data.message.subtype === "transferDuplicate") {
            this.notificationsService.error('Another transfer is already in progress', 'Please wait a few minutes to try again', {
                timeOut: 10000,
                showProgressBar: true,
                pauseOnHover: true,
                clickToClose: true
            });
        } else if (data.message.type && data.message.type === 'vtb') {
            if (data.message.data != null && data.message.data.logs != null && (JSON.parse(data.message.data.logs).warning.length > 0 || JSON.parse(data.message.data.logs).error.length > 0)) {
                this.telespiritService.reportTransferVtbError(JSON.parse(data.message.data.logs));
            } else {
                this.notificationsService.success('Transfer was succesfull', this.datePipe.transform(new Date(), 'dd-MM-yyyy HH:mm'), {
                    timeOut: 60000,
                    showProgressBar: true,
                    pauseOnHover: true,
                    clickToClose: true
                });
            }
        } else {
            this.notificationsService.success('Processed', 'Sending data to preview window');
        }
    }

    publishMessage(message) {
        if (this.initialized === true) {
            this.pubnub.publish({
                message: message,
                channel: this.channel
            });
            this.lastMessage = message;
        }
    }

    setProposalStatus(data) {
        if (this.itineraryData != null) {
            if (data.message != null) {
                if (data.message.status === 'done') {
                    if (this.itineraryData.data != null && this.itineraryData.data.publishedTemplates != null && this.itineraryData.data.publishedTemplates.length > 0) {
                        for (const publishedTemplate of this.itineraryData.data.publishedTemplates) {
                            if (publishedTemplate.publishedId === data.message.outputId) {
                                publishedTemplate.status = 'succes';
                                if (data.message.url != null) {
                                    publishedTemplate.url = data.message.url;
                                }
                                const toast = this.notificationsService.success(`${publishedTemplate.type[0].toUpperCase() + publishedTemplate.type.slice(1)} was created succesfully!`, 'Click here to open', {
                                    timeOut: 20000,
                                    showProgressBar: true,
                                    pauseOnHover: true
                                });
                                toast.click.subscribe(() => {
                                    window.open(publishedTemplate.url, "_blank");
                                });
                                break;
                            }
                        }
                        this.store.dispatch({ type: 'ITINERARY_UPDATE', payload: this.itineraryData });
                        this.visualtourbuilderService.updateVtbData(this.itineraryData);
                    }
                }
            }
        }
    }
}
