import { AuthActions } from '../actions/auth.actions';
import { AuthModel } from '../models/auth';
import { ActionWithPayload } from '../models/action-with-payload';
/*export const AuthReducer: ActionReducer<AuthModel> = (state: AuthModel = { authenticated: false }, action: Action) => {
    switch (action.type) {
        case AuthActions.IS_LOGGED_IN:
        case AuthActions.LOGIN_SUCCESS:
        return {
            authenticated: true,
            profile: action.payload
        };
        case AuthActions.LOGIN_FAILED:
        return {
            authenticated: false,
            authenticationError: action.payload
        };
        case AuthActions.IS_LOGGED_OUT:
        case AuthActions.LOGOUT_SUCCESS:
        let loggedOutUser: AuthModel = state;
        loggedOutUser.authenticated = false;
        delete loggedOutUser.profile;
        return loggedOutUser;
        default:
        return state;
    };
};*/

export function AuthReducer(state: AuthModel = { authenticated: false }, action: ActionWithPayload): AuthModel {
    switch (action.type) {
        case AuthActions.IS_LOGGED_IN:
        case AuthActions.LOGIN_SUCCESS:
        return {
            authenticated: true,
            profile: action.payload
        };
        case AuthActions.LOGIN_FAILED:
        return {
            authenticated: false,
            authenticationError: action.payload
        };
        case AuthActions.IS_LOGGED_OUT:
        case AuthActions.LOGOUT_SUCCESS:
        let loggedOutUser: AuthModel = state;
        loggedOutUser.authenticated = false;
        delete loggedOutUser.profile;
        return loggedOutUser;
        default:
        return state;
    };
}
