import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-segments-copy-participants-dialog',
  templateUrl: './segments-copy-participants-dialog.component.html',
  styleUrls: ['./segments-copy-participants-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SegmentsCopyParticipantsDialogComponent implements OnInit {
  segments: any;
  participantsList: any = [];
  participants: any = [];
  segmentTypes: any;

  partyStart = '1';
  checkAll = false;

  constructor(public dialogRef: MatDialogRef<SegmentsCopyParticipantsDialogComponent>) { }

  ngOnInit() {
    this.segments = JSON.parse(JSON.stringify(this.dialogRef.componentInstance.segments));
    this.segmentTypes = this.dialogRef.componentInstance.segmentTypes;
    this.participantsList = this.dialogRef.componentInstance.participantsList;

    const parties = Object.keys(this.participantsList);
    for (const party of parties) {
      for (const participant of this.participantsList[party]) {
        if (participant.partyName.replace('party ', '') !== this.partyStart) {
          participant.partyStart = true;
          this.partyStart = participant.partyName.replace('party ', '');
        }
        this.participants.push(participant);
      }
    }

    this.findUnchecked();
  }

  participantToggled(element, participant) {
    if (element.olPrices != null && element.olPrices.participants != null) {
      if (element.olPrices.participants['' + participant.id] != null) {
        delete element.olPrices.participants['' + participant.id];
      } else {
        if (Array.isArray(element.olPrices.participants) === true) {
          element.olPrices.participants = {};
        }
        if (element.olPrices.participants['' + participant.id] == null) {
          element.olPrices.participants['' + participant.id] = {
            costPrice: "",
            salesPrice: ""
          }
        }
      }
    }
    this.findUnchecked();
  }

  checkAllParticipants() {
    if (this.checkAll === true) {
      if (this.segments != null && this.segments.length > 0) {
        for (const segment of this.segments) {
          if (segment.elements != null && segment.elements.length > 0) {
            for (const element of segment.elements) {
              for (const participant of this.participants) {
                if (Array.isArray(element.olPrices.participants) === true) {
                  element.olPrices.participants = {};
                }
                if (element.olPrices.participants['' + participant.id] == null) {
                  element.olPrices.participants['' + participant.id] = {
                    id: participant.id,
                    costPrice: null,
                    salesPrice: null
                  }
                }
              }
            }
          }
        }
      }
    } else {
      if (this.segments != null && this.segments.length > 0) {
        for (const segment of this.segments) {
          if (segment.elements != null && segment.elements.length > 0) {
            for (const element of segment.elements) {
              if (element.olPrices.participants != null) {
                element.olPrices.participants = {};
              }
            }
          }
        }
      }
    }
    this.findUnchecked();
  }

  findUnchecked() {
    if (this.segments != null && this.segments.length > 0) {
      for (const segment of this.segments) {
        if (segment.elements != null && segment.elements.length > 0) {
          for (const element of segment.elements) {
            for (const participant of this.participants) {
              if (element.olPrices.participants['' + participant.id] == null) {
                this.checkAll = true;
                return;
              }
            }
          }
        }
      }
    }
    this.checkAll = false;
  }

  getHeaderWidth(participant, index) {
    return 8 * (Number(`${participant.partyName.replace('party ', '')}.${index + 1}`) + Number(participant.name.length || participant.surname.length)) + 40;
  }
}
