import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { NotificationCenterDialogComponent } from '../notification-center-dialog/notification-center-dialog.component';

@Component({
  selector: 'app-notification-center-button',
  templateUrl: './notification-center-button.component.html',
  styleUrls: ['./notification-center-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCenterButtonComponent {
  @Input() notificationCount: number;
  constructor(private bottomSheet: MatBottomSheet) {}

  show(){
    this.bottomSheet.open(NotificationCenterDialogComponent);
  }

}
