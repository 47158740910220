/* This custom date pipe was made because the original date pipe causes errors
when the value received by the pipe is undefined or cant be converted to a valid date.
Logic to determine if the date will be valid is still to be implemented. */

import { Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'safeDate'
})
export class SafeDatePipe {
  constructor(private datePipe: DatePipe) {}

  transform(value: any, format: string = 'dd-MM-yyyy'): string {
    if(value != null){ // Logic to determine if the value can be converted to a valid date should still be added
      return this.datePipe.transform(new Date(value), format);
    } else {
      return null;
    }
  }
}
