import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-extra-fields-confirm',
  templateUrl: './extra-fields-confirm.component.html',
  styleUrls: ['./extra-fields-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtraFieldsConfirmComponent implements OnInit {
  changes: any = [];
  type: any;

  constructor(private matDialogRef: MatDialogRef<ExtraFieldsConfirmComponent>) { }

  ngOnInit() {
    this.changes = this.matDialogRef.componentInstance.changes;
    this.type = this.matDialogRef.componentInstance.type;
    if (this.type === 'unchecked') {
      if (this.changes.dayTextsArr.length > 0) {
        for (const dayText of this.changes.dayTextsArr) {
          dayText.allow = false;
        }
      }
      if (this.changes.extrafieldsArr.length > 0) {
        for (const extraField of this.changes.extrafieldsArr) {
          extraField.allow = false;
        }
      }
      if (this.changes.generalInfoArr.length > 0) {
        for (const generalInfo of this.changes.generalInfoArr) {
          generalInfo.allow = false;
        }
      }
      if (this.changes.orderTextsArr.length > 0) {
        for (const orderText of this.changes.orderTextsArr) {
          orderText.allow = false;
        }
      }
    }
  }

  updateDayTexts(fieldName, allow) {
    if (allow === true) {
      if (fieldName === 'addDayTexts') {
        this.changes.dayTextsArr[1].allow = false;
      } else if (fieldName === 'overwriteDayTexts') {
        this.changes.dayTextsArr[0].allow = false;
      }
    }
  }
}
