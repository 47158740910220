import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-copy-dialog',
  templateUrl: './copy-dialog.component.html',
  styleUrls: ['./copy-dialog.component.scss']
})
export class CopyDialogComponent implements OnInit {
  title: String;
  itinerary: AppModel.Itinerary;
  itineraryName: any;

  constructor(public dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
    this.title = this.dialogRef.componentInstance.title;
    this.itinerary = this.dialogRef.componentInstance.data;
    this.itineraryName = JSON.parse(JSON.stringify(this.itinerary.name)) + ' (copy)';
  }

  updateItinerary() {
    const itineraryCopy = JSON.parse(JSON.stringify(this.itinerary));
    itineraryCopy.name = this.itineraryName;
    return itineraryCopy;
  }
}
