import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { NotificationsService } from 'angular2-notifications';
import { Apollo } from 'apollo-angular';
import { Config } from 'app/classes/config.class';
import { AppState } from 'app/models/app-state';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';
import { skipWhile, take } from 'rxjs/operators';
import { DayCalculationService } from './day-calculation.service';
import { HelpersService } from './helpers.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class VisualtourbuilderService {
    units$: Observable<any>;
    extraFields$: Observable<any>;
    units: any;
    itinerary$: Observable<any>;
    itinerary: any = null;
    customerTemplates$: Observable<any>;
    customerTemplates: any = null;
    token: any;

    constructor(
        private store: Store<AppState>,
        private apollo: Apollo,
        private dayCalculationService: DayCalculationService,
        private helpersService: HelpersService,
        private notificationsService: NotificationsService,
        private config: Config,
        private title: Title,
        private router: Router,
        private route: ActivatedRoute,
        private JwtHelper: JwtHelperService
    ) {
        console.log('ENVIRONMENT:', this.config);
        this.units$ = this.store.select('units');
        this.units$.subscribe((x) => {
            this.units = x;
        });
        this.itinerary$ = this.store.select('itinerary');
        this.itinerary$.subscribe((x) => {
            this.itinerary = x;
        });
        this.customerTemplates$ = this.store.select('customerTemplates');
        this.customerTemplates$.subscribe((x) => {
            this.customerTemplates = x;
        });
        this.extraFields$ = this.store.select('extraFields');
        const url = new URL(window.location.href);
        this.token = url.searchParams.get("token");
    }

    getVtbData(id = null) {
        const apolloQuery = gql`
      {
        vtb(id: ${id}) {
          status
          message
          node {
            id
            name
            data
            pax
            updatedAt
          }
        }
      }
    `;
        this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.vtb && x.data.vtb.status === 'SUCCESS' && x.data.vtb.node != null && x.data.vtb.node.data != null) {
                const response = x.data.vtb.node.data;
                response.id = x.data.vtb.node.id;
                this.store.dispatch({ type: 'ITINERARIES_INIT', payload: { data: response, id: x.data.vtb.node.id } });

                if (this.config.niceUrl && this.config.niceUrl !== '') {
                    response.data.niceUrl = this.config.niceUrl;
                }
                this.title.setTitle(`VTB - ${response.name || ''}`);
                response.data.costPriceBeforeRounding = 0;
                response.data.salesPriceBeforeRounding = 0;
                if (!response.data.vtbObjectId) {
                    response.data.vtbObjectId = this.helpersService.generateRandomId();
                }

                if (response.data.TSOrder == null) {
                    response.data.TSOrder = {};
                }

                if (!response.data.TSOrder.texts || response.data.TSOrder.texts.constructor === Array) {
                    response.data.TSOrder.texts = {
                        vtbObjectId: 'orderTexts1'
                    };
                } else {
                    response.data.TSOrder.texts.vtbObjectId = 'orderTexts1';
                }

                if (response.data.TSOrder.texts.internal == null) {
                    response.data.TSOrder.texts.internal = '';
                }
                if (response.data.TSOrder.texts.general == null) {
                    response.data.TSOrder.texts.general = '';
                }
                if (response.data.TSOrder.texts.proposal == null) {
                    response.data.TSOrder.texts.proposal = '';
                }
                if (response.data.TSOrder.texts.invoice == null) {
                    response.data.TSOrder.texts.invoice = '';
                }

                if (response.hasOwnProperty('changeCounters') === false) {
                    response.changeCounters = { extraFields: 0 };
                }
                if (response.data.startDate != null) {
                    response.data.startDate = new Date(response.data.startDate);
                }
                if (response.data.endDate != null) {
                    response.data.endDate = new Date(response.data.endDate);
                }
                if (response.data.hasOwnProperty('cover') === false) {
                    response.data.cover = [];
                }
                if (response.data.hasOwnProperty('participants') === false || typeof response.data.participants !== 'object' || Array.isArray(response.data.participants) === true) {
                    response.data.participants = {};
                }
                for (let segment of response.data.segments) {
                    segment.inViewport = false;
                    if (segment.liborderId != null) {
                        delete segment.liborderId;
                    }
                    if (segment.hasOwnProperty('maps') === false) {
                        segment.maps = {};
                    }
                    for (let flightInfo of segment.flightInfo || []) {
                        if (flightInfo.hasOwnProperty('arrivalTime') === false) {
                            flightInfo.arrivalTime = null;
                        }
                        if (flightInfo.hasOwnProperty('departureTime') === false) {
                            flightInfo.departureTime = null;
                        }
                        if (flightInfo.arrivalDate != null) {
                            flightInfo.arrivalDate = new Date(flightInfo.arrivalDate);
                        }
                        if (flightInfo.departureDate != null) {
                            flightInfo.departureDate = new Date(flightInfo.departureDate);
                        }
                        if (flightInfo.hasOwnProperty('operatedBy') === false) {
                            flightInfo.operatedBy = null;
                        }
                    }
                    if (segment.hasOwnProperty('vtbObjectId') === false) {
                        segment.vtbObjectId = this.helpersService.generateRandomId();
                    }
                    for (let element of segment.elements || []) {
                        if (element != null) {
                            element.missingBookableDays = [];
                            if (element.hasOwnProperty('optional') === true && element.optional === true) {
                                element.optional = true;
                            } else {
                                element.optional = false;
                            }
                            if (element.hasOwnProperty('olPrices') === false || typeof element.olPrices !== 'object') {
                                element.olPrices = {
                                    participants: {}
                                };
                            }
                            if (element.hasOwnProperty('nights') === true && element.hasOwnProperty('unitId') === true && this.units.items[element.unitId] && this.units.items[element.unitId].isTimeBased === true) {
                                element.nights = Math.max(eval((this.units.items[element.unitId].multiplier || 'X*1').replace(/x/ig, element.unitAmount)), 0);
                            } else {
                                element.nights = 0;
                            }
                            if (element.unitId != null && this.units.items[element.unitId] != null && this.units.items[element.unitId].flexRelatedProduct === true) {
                                element.needsOffsetRecalculation = true;
                            }
                            if (element.media != null && element.media.length > 0) {
                                element.media = element.media.filter(x => x.mediaspirit_id != null || x.sourceType === 'mediaspirit' || x.sourceType === 'desty');
                            }
                            if (element.hasOwnProperty('vtbObjectId') === false) {
                                element.vtbObjectId = this.helpersService.generateRandomId();
                            }

                            if (element.optional === false && element.olPrices != null && element.olPrices.costPrice != null && element.unitId !== this.units.roundId) {
                                response.data.costPriceBeforeRounding += Number(element.olPrices.costPrice || 0);
                            }
                            if (element.optional === false && element.olPrices != null && element.olPrices.salesTotal != null && element.unitId !== this.units.roundId) {
                                response.data.salesPriceBeforeRounding += Number(element.olPrices.salesTotal || 0);
                            }
                        }
                    }
                }

                let coverPhoto = null;
                if (response.data && response.data.cover && response.data.cover.length > 0) {
                    coverPhoto = response.data.cover[0];
                }
                if (response.data.publishedTemplates == null) {
                    response.data.publishedTemplates = [];
                    for (const designTemplate of this.customerTemplates) {
                        if (designTemplate.proposals != null && designTemplate.proposals.length > 0) {
                            for (const proposal of designTemplate.proposals) {
                                response.data.publishedTemplates.push(
                                    {
                                        templateId: designTemplate.id,
                                        publishedId: proposal.id,
                                        url: proposal.url,
                                        date: proposal.createdAt,
                                        name: designTemplate.name,
                                        status: 'success',
                                        type: designTemplate.type,
                                        stored: true,
                                        loading: 100,
                                        cover: coverPhoto
                                    }
                                );
                            }
                        }
                    }
                }

                this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');

                const elementExtraFields = [];

                // merge or add extra field info
                this.extraFields$.pipe(skipWhile(x => x === undefined), take(1)).subscribe((liveExtraFields: any[]) => {
                    if (response.data.extraFieldValues != null && response.data.extraFieldValues.length > 0) {
                        const liveExtraFieldsMap = liveExtraFields.reduce((all, item) => {
                            if (item.extrafields != null && item.extrafields.length > 0) {
                                const deleteIds = [];
                                for (let field of item.extrafields) {
                                    if (field.connectionType != null && field.connectionType === 'element') {
                                        deleteIds.push(field.id);
                                        elementExtraFields.push(field);
                                        field.field_type = field.type;
                                        field.translated_name = field.translatedName;
                                    } else {
                                        if (Number(item.id) > 100000000000000000) {
                                            field.parent_id = String(item.id).slice(0, -2);
                                        } else {
                                            field.parent_id = Number(item.id);
                                        }
                                        delete field.value;
                                        all[Number(field.id)] = field;
                                    }
                                }
                                if (deleteIds.length > 0) {
                                    for (let i = item.extrafields.length - 1; i >= 0; i--) {
                                        if (deleteIds.indexOf(item.extrafields[i].id) > -1) {
                                            item.extrafields.splice(i, 1);
                                        }
                                    }
                                }
                            } else if (item.fields != null && item.fields.length > 0) {
                                const deleteIds = [];
                                for (let field of item.fields) {
                                    if (field.connectionType != null && field.connectionType === 'element') {
                                        deleteIds.push(field.id);
                                        elementExtraFields.push(field);
                                        field.field_type = field.type;
                                        field.translated_name = field.translatedName;
                                    } else {
                                        if (Number(item.id) > 100000000000000000) {
                                            field.parent_id = String(item.id).slice(0, -2);
                                        } else {
                                            field.parent_id = Number(item.id);
                                        }
                                        delete field.value;
                                        all[Number(field.id)] = field;
                                    }
                                }
                                if (deleteIds.length > 0) {
                                    for (let i = item.fields.length - 1; i >= 0; i--) {
                                        if (deleteIds.indexOf(item.fields[i].id) > -1) {
                                            item.fields.splice(i, 1);
                                        }
                                    }
                                }
                            }
                            return all;
                        }, {});
                        const liveExtraFieldGroupsMap = liveExtraFields.reduce((all, item) => {
                            delete item.fields;
                            all[item.id] = item;
                            return all;
                        }, {});
                        for (let extraFieldGroup of response.data.extraFieldValues) {
                            if (Number(extraFieldGroup.id) > 100000000000000000) {
                                extraFieldGroup.id = String(extraFieldGroup.id).slice(0, -2);
                            }
                            if (liveExtraFieldGroupsMap[extraFieldGroup.id] != null) {
                                extraFieldGroup = Object.assign(extraFieldGroup, liveExtraFieldGroupsMap[extraFieldGroup.id]);
                                if (extraFieldGroup.extrafields != null) {
                                    delete extraFieldGroup.extrafields;
                                }
                                if (extraFieldGroup.__typename != null) {
                                    delete extraFieldGroup.__typename;
                                }
                            }
                            for (let extraField of extraFieldGroup.fields) {
                                if (Number(extraField.id) > 100000000000000000) {
                                    extraField.id = String(extraField.id).slice(0, -2);
                                }
                                if (liveExtraFieldsMap[extraField.id] != null) {
                                    extraField = Object.assign(extraField, liveExtraFieldsMap[extraField.id]);
                                    if (extraField.displayName != null) {
                                        delete extraField.displayName;
                                    }
                                    if (extraField.__typename != null) {
                                        delete extraField.__typename;
                                    }
                                }
                            }
                        }

                        const requestKeys = Object.keys(liveExtraFieldGroupsMap);
                        const foundKeys = [];

                        for (const key of requestKeys) {
                            for (const extraFieldCategory of response.data.extraFieldValues) {
                                if (Number(extraFieldCategory.id) === Number(key)) {
                                    foundKeys.push(key);

                                    const foundIds = [];
                                    if (liveExtraFieldGroupsMap[key].extrafields != null && liveExtraFieldGroupsMap[key].extrafields.length > 0) {
                                        for (const extrafield of liveExtraFieldGroupsMap[key].extrafields) {
                                            for (const field of extraFieldCategory.fields) {
                                                if (extrafield.id === field.id) {
                                                    foundIds.push(extrafield.id);
                                                    break;
                                                }
                                            }
                                        }

                                        for (const extrafield of liveExtraFieldGroupsMap[key].extrafields) {
                                            if (foundIds.indexOf(extrafield.id) < 0) {
                                                let tempObj = extrafield;

                                                if (tempObj.displayName != null) {
                                                    delete tempObj.displayName;
                                                }
                                                if (tempObj.__typename != null) {
                                                    delete tempObj.__typename;
                                                }
                                                tempObj.value = null;
                                                extraFieldCategory.fields.push(tempObj);
                                            }
                                        }

                                        for (let i = extraFieldCategory.fields.length - 1; i >= 0; i--) {
                                            let found = false;

                                            for (const field of liveExtraFieldGroupsMap[key].extrafields) {
                                                if (Number(extraFieldCategory.fields[i].id) === Number(field.id)) {
                                                    found = true;
                                                    break;
                                                }
                                            }

                                            if (found === false) {
                                                extraFieldCategory.fields.splice(i, 1);
                                            }
                                        }
                                    }

                                    break;
                                }
                            }
                        }

                        for (const key of requestKeys) {
                            if (foundKeys.indexOf(key) < 0) {
                                let tempObj = liveExtraFieldGroupsMap[key];

                                tempObj.fields = [];
                                if (tempObj.extrafields != null) {
                                    for (let extraField of tempObj.extrafields) {
                                        // if (discountRounding[extraField.id] != null) {
                                        //   extraField.discountRounding = Number(discountRounding[extraField.id]);
                                        // }
                                        if (extraField.displayName != null) {
                                            delete tempObj.displayName;
                                        }
                                        if (extraField.__typename != null) {
                                            delete extraField.__typename;
                                        }
                                        tempObj.fields.push(extraField);
                                    }
                                    delete tempObj.extrafields;
                                }

                                if (tempObj.extrafields != null) {
                                    delete tempObj.extrafields;
                                }
                                if (tempObj.__typename != null) {
                                    delete tempObj.__typename;
                                }

                                response.data.extraFieldValues.push(tempObj);
                            }
                        }

                        for (let i = response.data.extraFieldValues.length - 1; i > 0; i--) {
                            for (let j = 0; j < response.data.extraFieldValues.length; j++) {
                                if (Number(response.data.extraFieldValues[i].id) === Number(response.data.extraFieldValues[j].id)) {
                                    if (response.data.extraFieldValues[i].display_at_vtb != null && response.data.extraFieldValues[i].displayAtVtb == null) {
                                        response.data.extraFieldValues[j].id = null;
                                        break;
                                    } else if (response.data.extraFieldValues[j].display_at_vtb != null && response.data.extraFieldValues[j].displayAtVtb == null) {
                                        response.data.extraFieldValues[i].id = null;
                                        break;
                                    }
                                }
                            }
                        }

                        if (liveExtraFields.length > 0 && liveExtraFields[0].display_at_vtb != null && liveExtraFields[0].displayAtVtb == null) {
                            for (const extraFieldCategory of response.data.extraFieldValues) {
                                if (extraFieldCategory.displayAtVtb != null) {
                                    if (extraFieldCategory.displayAtVtb === true && extraFieldCategory.display_at_vtb == null) {
                                        extraFieldCategory.display_at_travelplan = true;
                                        extraFieldCategory.display_at_vtb = true;
                                    }
                                    delete extraFieldCategory.displayAtVtb;
                                }
                                if (extraFieldCategory.displayAtTemplate != null) {
                                    if (extraFieldCategory.displayAtTemplate === true && extraFieldCategory.display_at_vtb_template == null) {
                                        extraFieldCategory.display_at_template_travelplan = true;
                                        extraFieldCategory.display_at_vtb_template = true;
                                    }
                                    delete extraFieldCategory.displayAtTemplate;
                                }
                                for (const extraField of extraFieldCategory.fields) {
                                    if (extraField.displayAtVtb != null) {
                                        if (extraField.displayAtVtb === true && extraField.display_at_vtb == null) {
                                            extraField.display_at_travelplan = true;
                                            extraField.display_at_vtb = true;
                                        }
                                        delete extraField.displayAtVtb;
                                    }
                                    if (extraField.displayAtTemplate != null) {
                                        if (extraField.displayAtTemplate === true && extraField.display_at_vtb_template == null) {
                                            extraField.display_at_template_travelplan = true;
                                            extraField.display_at_vtb_template = true;
                                        }
                                        delete extraField.displayAtTemplate;
                                    }
                                    if (extraField.type != null && extraField.field_type == null) {
                                        extraField.field_type = extraField.type;
                                        delete extraField.type;
                                    }
                                    if (extraField.translatedName != null && extraField.translated_name == null) {
                                        extraField.translated_name = extraField.translatedName;
                                        delete extraField.translatedName;
                                    }
                                }
                            }
                        }

                        for (let i = response.data.extraFieldValues.length - 1; i >= 0; i--) {
                            if (requestKeys.indexOf(response.data.extraFieldValues[i].id + '') < 0 && requestKeys.indexOf(response.data.extraFieldValues[i].id) < 0) {
                                response.data.extraFieldValues.splice(i, 1);
                            } else {
                                if (response.data.extraFieldValues[i].fields != null && response.data.extraFieldValues[i].fields.length > 0) {
                                    for (let j = response.data.extraFieldValues[i].fields.length - 1; j >= 0; j--) {
                                        if (liveExtraFieldsMap[Number(response.data.extraFieldValues[i].fields[j].id)] == null) {
                                            response.data.extraFieldValues[i].fields.splice(j, 1);
                                        } else {
                                            delete liveExtraFieldsMap[Number(response.data.extraFieldValues[i].fields[j].id)];
                                        }
                                    }
                                }
                            }
                        }

                        if (Object.keys(liveExtraFieldsMap).length > 0) {
                            const liveKeys = Object.keys(liveExtraFieldsMap);

                            for (const key of liveKeys) {
                                for (const category of response.data.extraFieldValues) {
                                    if (Number(category.id) === Number(liveExtraFieldsMap[key].parent_id)) {
                                        category.fields.push(liveExtraFieldsMap[key]);
                                        break;
                                    }
                                }
                            }
                        }
                    } else {
                        let extraFieldCategories = liveExtraFields;
                        for (let extraFieldCategory of extraFieldCategories) {
                            extraFieldCategory.fields = [];
                            if (extraFieldCategory.extrafields != null) {
                                for (let extraField of extraFieldCategory.extrafields) {
                                    // if (discountRounding[extraField.id] != null) {
                                    //   extraField.discountRounding = Number(discountRounding[extraField.id]);
                                    // }
                                    if (extraField.__typename != null) {
                                        delete extraField.__typename;
                                    }
                                    if (extraField.connectionType != null && extraField.connectionType === 'element') {
                                        extraField.field_type = extraField.type;
                                        extraField.translated_name = extraField.translatedName;
                                    } else {
                                        extraFieldCategory.fields.push(extraField);
                                    }
                                    if (Number(extraFieldCategory.id) > 100000000000000000) {
                                        extraField.parent_id = String(extraFieldCategory.id).slice(0, -2);
                                    } else {
                                        extraField.parent_id = Number(extraFieldCategory.id);
                                    }
                                }
                                delete extraFieldCategory.extrafields;
                            }
                            if (extraFieldCategory.extrafields != null) {
                                delete extraFieldCategory.extrafields;
                            }
                            if (extraFieldCategory.__typename != null) {
                                delete extraFieldCategory.__typename;
                            }
                        }
                        response.data.extraFieldValues = extraFieldCategories;
                    }
                    response.data.extraFieldValues = this.helpersService.validateExtraFieldParents(response.data.extraFieldValues);
                    this.helpersService.setExtraFieldVtbObjectIds(response.data.extraFieldValues);
                    this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                    if (response.data.bookableDaysWarning != null && response.data.bookableDaysWarning == true) {
                        this.notificationsService.warn('Warning', "A non-bookable day in it's period!");
                        response.data.bookableDaysWarning = false;
                    }

                    if (this.helpersService.needsFlexCalc(response.data.segments, this.units)) {
                        // console.log('Found incomplete flex elements, making extra calculations...');
                        this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                        this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                    }

                    if (this.token != null) {
                        const queryParams: Params = { token: this.token };
                        let routerParams: any = { queryParams: queryParams, relativeTo: this.router };
                        routerParams.queryParamsHandling = 'merge';
                    }

                    return response;
                });

                if (elementExtraFields.length > 0) {
                    const sortedExtraFields = elementExtraFields.sort((a, b) => {
                        if (a.id < b.id) {
                            return -1;
                        } else if (a.id > b.id) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                    for (let segment of response.data.segments) {
                        if (segment.elements != null && segment.elements.length > 0) {
                            for (let element of segment.elements) {
                                if (element.TSOrderline != null) {
                                    if (element.TSOrderline.extraFieldValues != null && element.TSOrderline.extraFieldValues.length > 0) {
                                        const tempExtraFields = [];
                                        for (const sortedExtrafield of sortedExtraFields) {
                                            let found = false;
                                            const tempExtraField = JSON.parse(JSON.stringify(sortedExtrafield));
                                            delete tempExtraField.name;
                                            delete tempExtraField.type;
                                            delete tempExtraField.connectionType;
                                            delete tempExtraField.sortOrder;
                                            delete tempExtraField.layout;
                                            delete tempExtraField.displayAtVtb;
                                            delete tempExtraField.displayAtTemplate;
                                            delete tempExtraField.translatedName;
                                            delete tempExtraField.__typename;
                                            for (const elementExtrafield of element.TSOrderline.extraFieldValues) {
                                                if (Number(elementExtrafield.id) === Number(sortedExtrafield.id) && (elementExtrafield.field_type === sortedExtrafield.field_type)) {
                                                    tempExtraField.value = elementExtrafield.value;
                                                    tempExtraField.vtbElementId = elementExtrafield.vtbElementId;
                                                    tempExtraField.vtbObjectId = elementExtrafield.vtbObjectId;
                                                    tempExtraFields.push(tempExtraField);
                                                    found = true;
                                                    break;
                                                }
                                            }
                                            if (found === false) {
                                                tempExtraField.value = null;
                                                tempExtraField.vtbElementId = this.helpersService.generateRandomId();
                                                tempExtraField.vtbObjectId = this.helpersService.generateRandomId();
                                                tempExtraFields.push(tempExtraField);
                                            }
                                        }
                                        element.TSOrderline.extraFieldValues = tempExtraFields;
                                    }
                                }
                            }
                        }
                    }
                }
            } else {
                this.notificationsService.error('Error gathering vtb data', 'Pls refresh');
                return null;
            }

            // localStorage.setItem('token', x.data.itineraryToken);
        });
    }

    getTsToken() {
        const apolloQuery = gql`
      {
        tsJWT {
          jwt
          message
          status
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.tsJWT && x.data.tsJWT.status === 'SUCCESS' && x.data.tsJWT.jwt != null && x.data.tsJWT.jwt != 'error') {
                return x.data.tsJWT.jwt;
            }
            return null;
        }).catch(e => {
            console.log(e);
            return null;
        });
    }

    createVtbTemplate(name, data) {
        const stringified = JSON.stringify(data).replace(/[\\"]/g, '\\$&').replace(/\'/g, '\u0027').replace(/\u0000/g, '\\0');
        const apolloQuery = gql`
      mutation {
        vtbCreate(name: "${name}", data: "${stringified}") {
          status
          message
          node {
            id
            name
            data
            pax
            updatedAt
          }
        }
      }
    `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.vtbCreate && x.data.vtbCreate.status === 'SUCCESS' && x.data.vtbCreate.node != null && x.data.vtbCreate.node.data != null) {
                this.store.dispatch({ type: 'ITINERARY_SAVE', payload: x.data.vtbCreate.node.data });
                return x.data.vtbCreate.node.data;
            }
        });
    }

    async copyVtb(data) {
        const itineraryCopy = await this.helpersService.preSaveChecks(data);
        delete itineraryCopy.id;
        const stringified = JSON.stringify(itineraryCopy).replace(/[\\"]/g, '\\$&').replace(/\'/g, '\u0027').replace(/\u0000/g, '\\0');
        const apolloQuery = gql`
      mutation {
        vtbCreate(name: "${itineraryCopy.name}", data: "${stringified}") {
          status
          message
          node {
            id
            name
            data
            pax
            updatedAt
          }
        }
      }
    `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        }).toPromise().then((x: any) => {
            const response: any = x.data.vtbCreate.node.data;
            const responseId: any = x.data.vtbCreate.node.id;
            if (response.hasOwnProperty('changeCounters') === false) {
                response.changeCounters = { extraFields: 0 };
            }
            if (response.data.startDate != null) {
                response.data.startDate = new Date(response.data.startDate);
            }
            if (response.data.endDate != null) {
                response.data.endDate = new Date(response.data.endDate);
            }
            if (response.data.hasOwnProperty('participants') === false || typeof response.data.participants !== 'object' || Array.isArray(response.data.participants) === true) {
                response.data.participants = {};
            }

            let previousId = data.id;
            if (String(previousId).length < 6) {
                let zeros = 6 - String(previousId).length;
                for (let i = 0; i < zeros; i++) {
                    previousId = '0' + previousId;
                }
            }
            response.name = response.name.replace(previousId, ('000000' + responseId).slice(-6));

            let queryParams = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));
            queryParams.id = responseId;
            response.id = responseId;
            this.updateVtbData(response);
            this.router.navigate(['/'], { queryParams: queryParams }).then(x => {
                this.dayCalculationService.calcAndUpdateItinerary(response, 'ITINERARY_UPDATE');
                this.title.setTitle(`VTB - ${response.name} - #${response.link_id}`);
            });
        });
    }

    async minimalCopyVtb(data) {
        const itineraryCopy = await this.helpersService.preSaveChecks(data);
        delete itineraryCopy.id;
        const stringified = JSON.stringify(itineraryCopy).replace(/[\\"]/g, '\\$&').replace(/\'/g, '\u0027').replace(/\u0000/g, '\\0');
        const apolloQuery = gql`
      mutation {
        vtbCreate(name: "${itineraryCopy.name}", data: "${stringified}") {
          status
          message
          node {
            id
            name
            data
            pax
            updatedAt
          }
        }
      }
    `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        }).toPromise().then((x: any) => {
            return x.data.vtbCreate.node.id;
        });
    }

    async updateVtbData(data) {
        const itineraryCopy = await this.helpersService.preSaveChecks(data);
        const apolloQuery = gql`
      mutation vtbUpdate ($vtbData: JSONObject) {
        vtbUpdate(data: $vtbData) {
          status
          message
          node {
            id
            name
            data
            pax
            updatedAt
          }
        }
      }
    `;
        return this.apollo.mutate({
            mutation: apolloQuery,
            variables: { vtbData: itineraryCopy }
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.vtbUpdate && x.data.vtbUpdate.status === 'SUCCESS' && x.data.vtbUpdate.node != null && x.data.vtbUpdate.node.data != null) {
                if (x.data.vtbUpdate.node.data.data.startDate != null) {
                    x.data.vtbUpdate.node.data.data.startDate = new Date(x.data.vtbUpdate.node.data.data.startDate);
                }
                if (x.data.vtbUpdate.node.data.data.hasOwnProperty('participants') === false || typeof x.data.vtbUpdate.node.data.data.participants !== 'object' || Array.isArray(x.data.vtbUpdate.node.data.data.participants) === true) {
                    x.data.vtbUpdate.node.data.data.participants = {};
                }
                this.store.dispatch({ type: 'ITINERARY_SAVE', payload: x.data.vtbUpdate.node.data });
                return x.data.vtbUpdate.node.data;
            }
        });
    }

    createVtbElement(data) {
        const stringified = JSON.stringify(data).replace(/[\\"']/g, '\\$&').replace(/\u0000/g, '\\0');
        const apolloQuery = gql`
      mutation {
        elementCreate(name: "${data.title}", data: "${stringified}") {
          status
          message
          node {
            name
            data
          }
        }
      }
    `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.elementCreate && x.data.elementCreate.status === 'SUCCESS' && x.data.elementCreate.node != null && x.data.elementCreate.node.data != null) {
                this.notificationsService.success('VTB element was created', 'Use the element search to find your created element', {
                    timeOut: 5000,
                    showProgressBar: true,
                    pauseOnHover: true,
                });
                return true;
            } else {
                // this.notificationsService.error('Error creating VTB element', 'Pls try again', {
                //   timeOut: 5000,
                //   showProgressBar: true,
                //   pauseOnHover: true,
                // });
                return false;
            }
        });
    }

    getTemplate(id) {
        const apolloQuery = gql`
      {
        template(filter: { id: "${id}" }) {
          status
          message
          node {
            id
            name
            data
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.template && x.data.template.status === 'SUCCESS' && x.data.template.node != null) {
                return x.data.template.node.data.data;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getTemplates(getParams) {
        const apolloQuery = gql`
      {
        templates(filter: {name: "${getParams.text}"}, page: ${getParams.pageNr}, limit: ${getParams.limit || 20}) {
          status
          message
          node {
            id
            name
            data
          }
          totalRows
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.templates && x.data.templates.status === 'SUCCESS' && x.data.templates.node != null) {
                const reducedTemplates = x.data.templates.node.reduce((templates, template) => {
                    const tempTextsObj: any = {};
                    if (template.data != null && template.data.data != null && template.data.data.TSOrder != null && template.data.data.TSOrder.texts != null) {
                        if (template.data.data.TSOrder.texts.general != null && template.data.data.TSOrder.texts.general.trim() !== '') {
                            tempTextsObj.general = template.data.data.TSOrder.texts.general
                        }
                        if (template.data.data.TSOrder.texts.internal != null && template.data.data.TSOrder.texts.internal.trim() !== '') {
                            tempTextsObj.internal = template.data.data.TSOrder.texts.internal
                        }
                        if (template.data.data.TSOrder.texts.invoice != null && template.data.data.TSOrder.texts.invoice.trim() !== '') {
                            tempTextsObj.invoice = template.data.data.TSOrder.texts.invoice
                        }
                        if (template.data.data.TSOrder.texts.proposal != null && template.data.data.TSOrder.texts.proposal.trim() !== '') {
                            tempTextsObj.proposal = template.data.data.TSOrder.texts.proposal
                        }
                    }

                    templates.push(
                        {
                            extraFieldValues: template.data.data.extraFieldValues,
                            liborderId: template.id,
                            name: template.name,
                            segments: template.data.data.segments,
                            texts: tempTextsObj
                        }
                    );
                    return templates;
                }, []);
                this.store.dispatch({ type: 'LIBORDERS_UPDATE', payload: reducedTemplates });
                return x.data.templates;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getDesignTemplates() {
        const apolloQuery = gql`
      {
        designtemplates {
          message
          status
          node {
            id
            name
            type
            previewUrl
            proposals {
              id
              url
              createdAt
            }
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.designtemplates && x.data.designtemplates.status === 'SUCCESS' && x.data.designtemplates.node != null && x.data.designtemplates.node.length > 0) {
                this.store.dispatch({ type: 'CUSTOMER_TEMPLATES_UPDATE', payload: x.data.designtemplates.node, config: { environment: 'vtbGlobal' } });

                const ids = [];
                for (const designTemplate of x.data.designtemplates.node) {
                    if (designTemplate.proposals != null && designTemplate.proposals.length > 0) {
                        designTemplate.proposals.forEach(proposal => {
                            ids.push(proposal.id);
                        });
                    }
                }
                this.getProposalBuilds(ids)

                return x.data.designtemplates;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getProposals(templateId, vtbId, proposalId) {
        const apolloQuery = gql`
      {
        proposals(templateId: "${templateId}", vtbId: "${vtbId}") {
          status
          message
          node {
            id
            url
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.proposals && x.data.proposals.status === 'SUCCESS' && x.data.proposals.node != null && x.data.proposals.node.length > 0) {
                let updatedProposal = null;
                for (const proposal of x.data.proposals.node) {
                    if (proposal.id === proposalId) {
                        updatedProposal = proposal;
                        break;
                    }
                }
                return updatedProposal;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getProposalBuilds(ids) {
        const apolloQuery = gql`
      query Proposalbuilds($ids: [String]){
        proposalbuilds(ids: $ids) {
          message
          status
          node {
            status
            id
            proposalId
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
            variables: { ids }
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.proposalbuilds && x.data.proposalbuilds.status === 'SUCCESS' && x.data.proposalbuilds.node != null && x.data.proposalbuilds.node.length > 0) {
                this.store.dispatch({ type: 'PROPOSAL_BUILDS_UPDATE', payload: x.data.proposalbuilds.node, config: { token: this.JwtHelper.decodeToken(this.token) } });
                return x.data.proposalbuilds.node;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    publishDesignTemplate(templateId, vtbId) {
        const apolloQuery = gql`
      mutation {
        proposalCreate(input: { designtemplateId: "${templateId}", vtbId: "${vtbId}" }) {
          status
          message
          node {
            id
            url
            createdAt
          }
        }
      }
    `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.proposalCreate && x.data.proposalCreate.status === 'SUCCESS' && x.data.proposalCreate.node != null) {
                // this.store.dispatch({ type: 'ITINERARY_SAVE', payload: x.data.proposalCreate.node.data });
                this.getProposalBuilds([x.data.proposalCreate.node.id]);
                return x.data.proposalCreate.node;
            }
        });
    }

    republishDesignTemplate(templateId, vtbId, publishedId) {
        const apolloQuery = gql`
      mutation {
        proposalUpdate(input: { designtemplateId: "${templateId}", vtbId: "${vtbId}", proposalId: "${publishedId}" }) {
          status
          message
          node {
            id
            url
            createdAt
          }
        }
      }
    `;
        return this.apollo.mutate({
            mutation: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.proposalUpdate && x.data.proposalUpdate.status === 'SUCCESS' && x.data.proposalUpdate.node != null) {
                // this.store.dispatch({ type: 'ITINERARY_SAVE', payload: x.data.proposalUpdate.node.data });
                return x.data.proposalUpdate.node;
            }
        });
    }

    getVtbElements(name, unit, auth, page = null, limit = null) {
        let filterText = '';
        if (name != null && unit != null) {
            filterText = `name: "${name}", unitId: ${unit.id}`;
        } else if (name != null && unit == null) {
            filterText = `name: "${name}"`;
        } else if (name == null && unit != null) {
            filterText = `unitId: ${unit.id}`;
        }
        const apolloQuery = gql`
      {
        elements(filter: {${filterText}}, page: ${page}, limit: ${limit}) {
          node {
            name
            data
          }
          message
          status
          totalRows
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.elements && x.data.elements.status === 'SUCCESS' && x.data.elements.node != null && x.data.elements.node.length > 0) {
                if (x.data.elements != null && x.data.elements.node != null && x.data.elements.node.length > 0) {
                    const markers = [];
                    for (const element of x.data.elements.node) {
                        if (element.data != null && element.data.maps != null && element.data.maps.latitude != null && element.data.maps.longitude != null) {
                            markers.push({ title: element.data.title, description: element.data.description, latitude: element.data.maps.latitude, longitude: element.data.maps.longitude });
                        }
                    }
                    if (markers && markers.length > 0) {
                        this.store.dispatch({ type: 'MARKERS_UPDATE', payload: markers });
                    }
                }
                this.store.dispatch({ type: 'ELEMENTS_UPDATE', payload: this.helpersService.formatSearchedElements(x.data.elements.node, this.units, this.config, auth), config: this.config });
                return x.data.elements;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getVtbElement(id) {
        const apolloQuery = gql`
      {
        element(id: ${id}) {
          node {
            name
            data
          }
          message
          status
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.element && x.data.element.status === 'SUCCESS' && x.data.element.node != null && x.data.element.node.data != null) {
                return x.data.element.node.data;
            }
            return null;
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getVtbSegmentTypes() {
        const apolloQuery = gql`
      {
        segmenttypes{
          status
          message
          node {
            id
            name
            backgroundColor
            iconUrl
            isFlight
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.segmenttypes && x.data.segmenttypes.status === 'SUCCESS' && x.data.segmenttypes.node != null && x.data.segmenttypes.node.length > 0) {
                this.store.dispatch({ type: 'SEGMENT_TYPES_UPDATE', payload: x.data.segmenttypes.node, config: this.config });
                return x;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getVtbElementUnits() {
        const apolloQuery = gql`
      {
        elementunits{
          status
          message
          node {
            id
            name
            iconUrl
            multiplier
            reverseMultiplier
            isTimeBased
            allowOverlap
            flexRelated
            noPartyDivision
            managedBy
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.elementunits && x.data.elementunits.status === 'SUCCESS' && x.data.elementunits.node != null && x.data.elementunits.node.length > 0) {
                this.store.dispatch({ type: 'UNITS_UPDATE', payload: x.data.elementunits.node, config: this.config });
                return x;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getExtraFieldsData() {
        let promises = [this.getExtraFieldCategories()];
        return Promise.all(promises).then(x => {
            let extraFieldCategories: any = x[0];

            if (extraFieldCategories != null && extraFieldCategories.length > 0) {
                for (let extraFieldCategory of extraFieldCategories) {
                    if (Number(extraFieldCategory.id) > 100000000000000000) {
                        extraFieldCategory.id = String(extraFieldCategory.id).slice(0, -2);
                    }
                    if (extraFieldCategory.layout != null) {
                        extraFieldCategory.layout = JSON.parse(extraFieldCategory.layout);
                    }
                    for (let extraField of extraFieldCategory.extrafields) {
                        if (Number(extraField.id) > 100000000000000000) {
                            extraField.id = String(extraField.id).slice(0, -2);
                        }
                        if (extraField.layout != null) {
                            extraField.layout = JSON.parse(extraField.layout);
                        }
                        if (extraField.displayName != null) {
                            extraField.translatedName = extraField.displayName;
                        } else {
                            extraField.translatedName = extraField.name;
                        }
                        delete extraField.displayName;
                    }
                }
            }

            this.store.dispatch({ type: 'EXTRA_FIELDS_UPDATE', payload: extraFieldCategories });
            return x;
        }).catch(e => {
            console.log(e);
        });
    }

    getExtraFields() {
        const apolloQuery = gql`
      {
        extrafields{
          status
          message
          node {
            name
            type
            sortOrder
            layout
            displayAtVtb
            displayAtTemplate
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.extrafields && x.data.extrafields.status === 'SUCCESS' && x.data.extrafields.node != null && x.data.extrafields.node.length > 0) {
                return x.data.extrafields.node;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getExtraFieldCategories() {
        const apolloQuery = gql`
      {
        extrafieldcategories(sort: { field: sortOrder, order: asc }) {
          status
          message
          node {
            id
            name
            layout
            displayAtVtb
            displayAtTemplate
            extrafields(sort: { field: sortOrder, order: asc }) {
              id
              name
              type
              connectionType
              sortOrder
              layout
              displayAtVtb
              displayAtTemplate
              displayName
            }
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.extrafieldcategories && x.data.extrafieldcategories.status === 'SUCCESS' && x.data.extrafieldcategories.node != null && x.data.extrafieldcategories.node.length > 0) {
                return x.data.extrafieldcategories.node;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getCurrencies() {
        let currencyObj = {
            currencies: [],
            exchangeRates: []
        };

        const apolloQuery = gql`
      {
        currencies {
          status
          message
          node {
            id
            isoCode
            symbol
          }
        }
        currencyconversions {
          status
          message
          node {
            id
            rate
            currencyTo {
              id
              isoCode
              symbol
            }
            currencyFrom {
              id
              isoCode
              symbol
            }
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.currencies && x.data.currencies.status === 'SUCCESS' && x.data.currencies.node != null && x.data.currencies.node.length > 0) {
                currencyObj.currencies = x.data.currencies.node;
                if (x.data.currencyconversions && x.data.currencyconversions.status === 'SUCCESS' && x.data.currencyconversions.node != null && x.data.currencyconversions.node.length > 0) {
                    currencyObj.exchangeRates = x.data.currencyconversions.node;

                    this.store.dispatch({ type: 'CURRENCIES_UPDATE', payload: currencyObj })
                    return currencyObj;
                }
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    getTexts() {
        const apolloQuery = gql`
      {
        texts {
          status
          message
          node {
            id
            name
            text
            group {
              id
              name
              sortOrder
            }
            type {
              id
              name
              sortOrder
            }
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.texts && x.data.texts.status === 'SUCCESS' && x.data.texts.node != null && x.data.texts.node.length > 0) {
                this.store.dispatch({ type: 'LIBTEXTS_TYPES_UPDATE', payload: x.data.texts.node })
                return x.data.texts.node;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    searchAirports(searchText = '') {
        const apolloQuery = gql`
      {
        airports(filter: {name: "${searchText}"}) {
          status
          message
          node {
            city
            country
            description
            faa
            iata
            iata_code
            icao
            isMajor
            latitude
            longitude
            state
            weather_station
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.airports && x.data.airports.status === 'SUCCESS' && x.data.airports.node != null && x.data.airports.node.length > 0) {
                return x.data.airports.node;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    searchAirlines(searchText) {
        const apolloQuery = gql`
      {
        airlines(filter: {name: "${searchText}"}) {
          status
          message
          node {
            name
            airline_no
            carrier_code
            carrier_name
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.airlines && x.data.airlines.status === 'SUCCESS' && x.data.airlines.node != null && x.data.airlines.node.length > 0) {
                return x.data.airlines.node;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }

    searchCountries(searchText = '') {
        const apolloQuery = gql`
      {
        countries(filter: {name: "${searchText}"}) {
          status
          message
          node {
            id
            iso3
            short_name
            long_name
          }
        }
      }
    `;
        return this.apollo.query({
            query: apolloQuery,
        }).toPromise().then((x: any) => {
            if (x && x.data && x.data.countries && x.data.countries.status === 'SUCCESS' && x.data.countries.node != null && x.data.countries.node.length > 0) {
                return x.data.countries.node;
            }
        }).catch(e => {
            console.log(e);
            return e;
        });
    }
}
