import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-create-roomtypes-dialog',
  templateUrl: './create-roomtypes-dialog.component.html',
  styleUrls: ['./create-roomtypes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateRoomtypesDialogComponent implements OnInit, AfterViewInit {
  viewInitialized = false;
  roomTypes = [
    {
      defaultPrice: null,
      description: '',
      internal_info: null,
      maxPerson: 1,
      maxUnit: null,
      minPerson: 1,
      minUnit: null,
      note: '',
      per: 'unit',
      supplierinfo: null,
      from: moment(),
      to: moment().add(2, 'years')
    }
  ];

  constructor(public dialogRef: MatDialogRef<any>, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.dialogRef.componentInstance.roomTypes != null && this.dialogRef.componentInstance.roomTypes.length > 0) {
      this.roomTypes = this.dialogRef.componentInstance.roomTypes;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.viewInitialized = true;
    }, 500);
  }

  addRoomType() {
    this.roomTypes.push({
      defaultPrice: null,
      description: '',
      internal_info: null,
      maxPerson: 1,
      maxUnit: null,
      minPerson: 1,
      minUnit: null,
      note: '',
      per: 'unit',
      supplierinfo: null,
      from: moment(),
      to: moment().add(2, 'years')
    });
    this.cdr.detectChanges();
  }

  deleteRoomType(index) {
    this.roomTypes.splice(index, 1);
    this.cdr.detectChanges();
  }

  checkRoomTypes() {
    for (const roomType of this.roomTypes) {
      if (roomType.description === "") {
        return false;
      }
    }
    return true;
  }

  checkMaxParticipants(roomType) {
    if (roomType.maxPerson < roomType.minPerson) {
      roomType.maxPerson = roomType.minPerson;
    }
    this.cdr.detectChanges();
  }
}
