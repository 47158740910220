import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store, State } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HelpersService } from '../services/helpers.service';
import { Config } from '../classes/config.class';
import { ExtraFieldsConfirmComponent } from '../components/extra-fields-confirm/extra-fields-confirm.component';
import { MatDialog } from '@angular/material';
import { take, finalize } from 'rxjs/operators';
import { NotificationsService } from 'angular2-notifications';
import { CommunicationService } from '../services/communication.service';
import { IataService } from './iata.service';

@Injectable()
export class DayCalculationService {
    env: any;
    segmentTypes: Observable<any>;
    segmentTypesData: any;
    rounding: Observable<any>;
    roundingData: any;
    units: Observable<any>;
    unitsValue: any;
    dialogRef: any;
    previousElementStates: any = {};
    previousSegmentStates: any = {};
    previousSegmentNightsAmount: number;
    changedNightsIndex: number;
    foundVtbObjectIds: Array<string> = [];

    constructor(
        private helpersService: HelpersService,
        private notificationsService: NotificationsService,
        private communicationService: CommunicationService,
        private iataService: IataService,
        private store: Store<any>,
        private state: State<any>,
        private config: Config,
        private matDialog: MatDialog,
        private datepipe: DatePipe
    ) {
        this.env = this.config;
        this.segmentTypes = store.select('segmentTypes');
        this.rounding = store.select('rounding');
        this.units = this.store.select('units');
        this.units.subscribe(x => {
            this.unitsValue = x;
        });
        this.segmentTypes.subscribe(x => {
            this.rounding.subscribe(y => {
                const roundingArray = [];
                if (y != null && y.blockDispTypeForRounding) {
                    const roundingTypes = y.blockDispTypeForRounding.split(',');
                    for (const key in x.items) {
                        if (x.items.hasOwnProperty(key)) {
                            if (roundingTypes.indexOf(x.items[key].name) > -1) {
                                roundingArray.push(x.items[key].id);
                            }
                        }
                    }
                }
                this.roundingData = roundingArray;
            });
            this.segmentTypesData = x;
        });
    }

    daysMap: any = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        0: "Sunday"
    }

    checkPartyMatch(element, partiesObj) {
        if (element.olPrices != null && element.olPrices.participants != null && partiesObj != null) {
            if (element.initialParticipantCheck == null || element.initialParticipantCheck === false) {
                for (const participantId in element.olPrices.participants) {
                    let found = false;
                    for (let party in partiesObj) {
                        if (found === true) {
                            break;
                        }
                        for (const participant of partiesObj[party]) {
                            if (String(participant.id) === String(participantId)) {
                                found = true;
                                break;
                            }
                        }
                    }
                    if (found === false) {
                        delete element.olPrices.participants[participantId];
                    }
                }
                element.initialParticipantCheck = true;
            }

            const selectedParticipants = Object.keys(element.olPrices.participants).join('|');
            for (let party in partiesObj) {
                const partyParticipants = partiesObj[party].map(x => x.id).sort().join('|');
                if (selectedParticipants === partyParticipants) {
                    element.linkedParty = party;
                    return;
                }
            }
        }
        delete element.linkedParty; // only triggered when no linkedParty found
    }

    identifySegmentRecalc(segment, index) {
        const previousSegmentState = this.previousSegmentStates[segment.vtbObjectId];
        if (previousSegmentState != null) {
            if ((previousSegmentState.typeId !== segment.typeId && segment.elements && segment.elements.length > 0)) {
                if (this.roundingData != null && this.roundingData.length > 0 && ((this.roundingData.indexOf(previousSegmentState.typeId) === -1 && this.roundingData.indexOf(segment.typeId) > -1) || (this.roundingData.indexOf(previousSegmentState.typeId) > -1 && this.roundingData.indexOf(segment.typeId) === -1))) {
                    for (const element of segment.elements) {
                        element.not_calculated = true;
                    }
                } else if (this.roundingData != null && this.roundingData.length === 0) {
                    for (const element of segment.elements) {
                        element.not_calculated = true;
                    }
                }
                this.store.dispatch({ type: 'COUNTER_ADD' });
            }
            if (previousSegmentState.nights !== segment.nights) {
                this.changedNightsIndex = index;
            }
        } else {
            this.changedNightsIndex = index;
        }

        this.previousSegmentStates[segment.vtbObjectId] = JSON.parse(JSON.stringify(segment));
    }

    identifyElementRecalc(element: AppModel.Element) {
        const previousElementState: AppModel.Element = this.previousElementStates[element.vtbObjectId];

        if (previousElementState != null) {
            if (element.day !== previousElementState.day) {
                // console.log('day changed:',element);
                if (element.lastRecalc) {
                    delete element.lastRecalc
                }
                element.not_calculated = true;
            }
            if (element.unitAmount !== previousElementState.unitAmount) {
                // console.log('nights changed:',element);
                if (element.lastRecalc) {
                    delete element.lastRecalc
                }
                element.not_calculated = true;
            }
            let currentDate = new Date(element.date);
            let previousDate = new Date(previousElementState.date);
            if (currentDate.getFullYear() !== previousDate.getFullYear() || currentDate.getMonth() !== previousDate.getMonth() || currentDate.getDate() !== previousDate.getDate()) {
                // console.log('date changed:',element);
                if (element.lastRecalc) {
                    delete element.lastRecalc
                }
                element.not_calculated = true;
            }
            if (element.subTitle !== previousElementState.subTitle) {
                // console.log('subTitle changed:',element);
                if (element.lastRecalc) {
                    delete element.lastRecalc
                }
                if (element.dataSource === 'desty' && element.subtitleChanged === true) {
                    element.not_calculated = false;
                } else {
                    element.not_calculated = true;
                }
            }

            if (previousElementState != null && previousElementState.olPrices != null && previousElementState.olPrices.participants != null) {
                const currentParticipants = Object.keys(element.olPrices.participants || {}).join(',');
                const previousParticipants = Object.keys(previousElementState.olPrices.participants || {}).join(',');
                if (currentParticipants !== previousParticipants) {
                    // console.log('participants changed:',element);
                    if (element.lastRecalc) {
                        delete element.lastRecalc
                    }
                    element.not_calculated = true;
                }
            }

            if (element.not_calculated === true) {
                // console.log('was already not_calculated:',element);
                if (element.lastRecalc) {
                    delete element.lastRecalc
                }
            }
        } else {
            // console.log('in else:', element.not_calculated);
            if (element.lastRecalc == null && (element.not_calculated == null || element.not_calculated !== false)) {
                element.not_calculated = true;
            } else {
                element.not_calculated = false;
            }
        }
        this.previousElementStates[element.vtbObjectId] = JSON.parse(JSON.stringify(element));
    }

    roomTypeMatchesParticipants(itinerary, element) {
        if (element.subTitle != null && element.roomTypes != null && element.olPrices != null && element.olPrices.participants != null) {
            if (element.roomTypes.length > 0) {
                const maxCapacity = element.roomTypes.some(roomType => roomType.description === element.subTitle && roomType.maxCapacity != null);
                if (maxCapacity === true) {
                    const data = this.helpersService.getParticipantTypes(itinerary, element, this.config);
                    let maxOccupancy = data.nrOfAdults + data.nrOfChildren + data.nrOfAdolescent + data.nrOfBabies;
                    const occupancyMatch = element.roomTypes.some(roomType => roomType.description === element.subTitle && maxOccupancy <= (roomType.maxCapacity || 999));
                    if (occupancyMatch === false) {
                        return occupancyMatch;
                    }
                    let occupancy = data.nrOfAdults;
                    if (element.babyCountsInMaxOcc != null && element.babyCountsInMaxOcc === true) {
                        occupancy += data.nrOfBabies;
                    }
                    if (element.childCountsInMaxOcc != null && element.childCountsInMaxOcc === true) {
                        occupancy += data.nrOfChildren;
                    }
                    if (element.teenagerCountsInMaxOcc != null && element.teenagerCountsInMaxOcc === true) {
                        occupancy += data.nrOfAdolescent;
                    }
                    return element.roomTypes.some(roomType => roomType.description === element.subTitle && occupancy <= (roomType.maxPerson || 999));
                } else {
                    let elementParticipantsCount = Object.keys(element.olPrices.participants).length;
                    return element.roomTypes.some(roomType => roomType.description === element.subTitle && elementParticipantsCount <= (roomType.maxPerson || 999) && elementParticipantsCount >= (roomType.minPerson || 0));
                }
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    confirmExtraFieldsChange(changeQueue, state) {
        if (this.dialogRef || this.env.enableOverwriteGeneralInfoTemplate === false) {
            return;
        } else {
            if (this.env.overwriteGeneralInfoTemplate === true) {
                for (const change of changeQueue.generalInfoArr) {
                    if (change.allow === true) {
                        switch (change.fieldName) {
                            case 'title':
                                state.data.title = change.value;
                                break;
                            case 'subTitle':
                                state.data.subTitle = change.value;
                                break;
                            case 'cover':
                                state.data.cover = change.value;
                                break;
                        }
                    }
                }
                for (const change of changeQueue.orderTextsArr) {
                    if (change.allow === true) {
                        switch (change.fieldName) {
                            case 'internal':
                                state.data.TSOrder.texts.internal = change.value;
                                break;
                            case 'general':
                                state.data.TSOrder.texts.general = change.value;
                                break;
                            case 'proposal':
                                state.data.TSOrder.texts.proposal = change.value;
                                break;
                            case 'invoice':
                                state.data.TSOrder.texts.invoice = change.value;
                                break;
                        }
                    }
                }
                for (const change of changeQueue.extrafieldsArr) {
                    if (change.allow === true) {
                        const field_type = ['CheckBox', 'Date', 'FilteringSelect']; // skip to show history button for these field types
                        let currentDateTime = this.datepipe.transform(new Date(), 'dd-MM-yy hh:mm');
                        if (!change.extraField.oldValues) {
                            if (change.extraField && change.extraField.value && !field_type.includes(change.extraField.field_type)) {
                                change.extraField.oldValues = [currentDateTime + ' ' + change.extraField.value];
                            }
                        } else {
                            let valExists = false;
                            for (let oldValue of change.extraField.oldValues) {
                                if (oldValue && change.extraField && change.extraField.value && !field_type.includes(change.extraField.field_type) && oldValue.substring(14).trim() == change.extraField.value.trim()) {
                                    valExists = true;
                                    break;
                                }
                            }
                            if (valExists === false) {
                                change.extraField.oldValues.unshift(currentDateTime + ' ' + change.extraField.value);
                                if (change.extraField.oldValues.length > 5) {
                                    change.extraField.oldValues.length = 5;
                                }
                            }
                        }
                        change.extraField.value = change.liborderField.value;
                        for (let extraFieldGroup of state.data.extraFieldValues) {
                            for (let extraField of extraFieldGroup.fields) {
                                if (change.extraField.id == extraField.id) {
                                    extraField.value = change.liborderField.value;
                                }
                            }
                        }
                    }
                }
                for (const change of changeQueue.dayTextsArr) {
                    if (change.allow === true) {
                        if (change.fieldName === 'addDayTexts') {
                            let index = 0;
                            let amount = 0;
                            for (const segment of state.data.segments) {
                                if (segment.droppedIndex === true) {
                                    index = segment.day;
                                    amount = segment.droppedNights;
                                    delete segment.droppedIndex;
                                    delete segment.droppedNights;
                                    break;
                                }
                            }

                            const newTempArray = [];
                            const dayTextArr = Object.entries(state.data.dayTexts);

                            for (let i = 0; i < dayTextArr.length; i++) {
                                if (i == index - 1) {
                                    for (const dayText of Object.entries(change.value)) {
                                        if (amount > 0) {
                                            const dayValue: any = dayText[1];
                                            newTempArray.push({
                                                value: dayValue.value,
                                                vtbObjectId: this.helpersService.generateRandomId()
                                            });
                                            amount--;
                                        }
                                    }
                                }
                                if (newTempArray.length <= state.data.totalDays) {
                                    const dayValue: any = dayTextArr[i][1];
                                    newTempArray.push({
                                        value: dayValue.value,
                                        vtbObjectId: this.helpersService.generateRandomId()
                                    });
                                }
                            }

                            for (let i = 0; i < newTempArray.length; i++) {
                                state.data.dayTexts[i + 1] = newTempArray[i];
                            }
                        } else if (change.fieldName === 'overwriteDayTexts') {
                            for (const key in change.value) {
                                if (key <= state.data.totalDays) {
                                    state.data.dayTexts[key] = change.value[key];
                                }
                            }
                        }
                    }
                }
                state.changeCounters.extraFields++;
                this.communicationService.updateItineraryForOrderExtrafields();
                this.communicationService.updateItineraryForGeneralInfo();
                this.communicationService.updateItineraryForDayTexts();
            } else {
                this.dialogRef = this.matDialog.open(ExtraFieldsConfirmComponent);
                this.dialogRef.componentInstance = {
                    changes: changeQueue,
                    type: this.env.overwriteGeneralInfoTemplate
                }
                this.dialogRef.afterClosed().pipe(take(1), finalize(() => this.dialogRef = undefined)).subscribe(changes => {
                    if (changes != null) {
                        for (const change of changes.generalInfoArr) {
                            if (change.allow === true) {
                                switch (change.fieldName) {
                                    case 'title':
                                        state.data.title = change.value;
                                        break;
                                    case 'subTitle':
                                        state.data.subTitle = change.value;
                                        break;
                                    case 'cover':
                                        state.data.cover = change.value;
                                        break;
                                }
                            }
                        }
                        for (const change of changes.orderTextsArr) {
                            if (change.allow === true) {
                                switch (change.fieldName) {
                                    case 'internal':
                                        state.data.TSOrder.texts.internal = change.value;
                                        break;
                                    case 'general':
                                        state.data.TSOrder.texts.general = change.value;
                                        break;
                                    case 'proposal':
                                        state.data.TSOrder.texts.proposal = change.value;
                                        break;
                                    case 'invoice':
                                        state.data.TSOrder.texts.invoice = change.value;
                                        break;
                                }
                            }
                        }
                        for (const change of changes.extrafieldsArr) {
                            if (change.allow === true) {
                                const field_type = ['CheckBox', 'Date', 'FilteringSelect']; // skip to show history button for these field types
                                let currentDateTime = this.datepipe.transform(new Date(), 'dd-MM-yy hh:mm');
                                if (!change.extraField.oldValues) {
                                    if (change.extraField && change.extraField.value && !field_type.includes(change.extraField.field_type)) {
                                        change.extraField.oldValues = [currentDateTime + ' ' + change.extraField.value];
                                    }
                                } else {
                                    let valExists = false;
                                    for (let oldValue of change.extraField.oldValues) {
                                        if (oldValue && change.extraField && change.extraField.value && !field_type.includes(change.extraField.field_type) && oldValue.substring(14).trim() == change.extraField.value.trim()) {
                                            valExists = true;
                                            break;
                                        }
                                    }
                                    if (valExists === false) {
                                        change.extraField.oldValues.unshift(currentDateTime + ' ' + change.extraField.value);
                                        if (change.extraField.oldValues.length > 5) {
                                            change.extraField.oldValues.length = 5;
                                        }
                                    }
                                }
                                change.extraField.value = change.liborderField.value;
                                for (let extraFieldGroup of state.data.extraFieldValues) {
                                    for (let extraField of extraFieldGroup.fields) {
                                        if (change.extraField.id == extraField.id) {
                                            extraField.value = change.liborderField.value;
                                        }
                                    }
                                }
                            }
                        }
                        for (const change of changes.dayTextsArr) {
                            if (change.allow === true) {
                                if (change.fieldName === 'addDayTexts') {
                                    let index = 0;
                                    let amount = 0;
                                    for (const segment of state.data.segments) {
                                        if (segment.droppedIndex === true) {
                                            index = segment.day;
                                            amount = segment.droppedNights;
                                            delete segment.droppedIndex;
                                            delete segment.droppedNights;
                                            break;
                                        }
                                    }

                                    const newTempArray = [];
                                    const dayTextArr = Object.entries(state.data.dayTexts);

                                    for (let i = 0; i < dayTextArr.length; i++) {
                                        if (i == index - 1) {
                                            for (const dayText of Object.entries(change.value)) {
                                                if (amount > 0) {
                                                    newTempArray.push(dayText[1]);
                                                    amount--;
                                                }
                                            }
                                        }
                                        if (newTempArray.length <= state.data.totalDays) {
                                            newTempArray.push(dayTextArr[i][1]);
                                        }
                                    }

                                    for (let i = 0; i < newTempArray.length; i++) {
                                        state.data.dayTexts[i + 1] = newTempArray[i];
                                    }
                                } else if (change.fieldName === 'overwriteDayTexts') {
                                    for (const key in change.value) {
                                        if (key <= state.data.totalDays) {
                                            state.data.dayTexts[key] = change.value[key];
                                        }
                                    }
                                }
                            }
                        }
                        state.changeCounters.extraFields++;
                        this.communicationService.updateItineraryForOrderExtrafields();
                        this.communicationService.updateItineraryForGeneralInfo();
                        this.communicationService.updateItineraryForDayTexts();
                    }
                });
            }
        }
    }

    async calcAndUpdateItinerary(payload, type, counterAdd = false, updateFlex = false) {
        let state = this.state.getValue().itinerary;
        if (type === 'ITINERARY_UPDATE') {
            for (let i = 0; i < payload.data.segments.length || 0; i++) {
                const segment = payload.data.segments[i];
                // transform elementSegment to glue segment
                if (segment != null && segment.dataType != null && (segment.dataType === 'elementSegment' || segment.dataType === 'hotel' || segment.dataType === 'desty')) {
                    let tempSegment = { ...segment };
                    tempSegment.elements = [Object.assign({}, tempSegment)];
                    if (tempSegment.multiSelectedElements != null && tempSegment.multiSelectedElements.length > 1) {
                        let i = 0;
                        for (const multiElement of tempSegment.multiSelectedElements) {
                            if (i != 0) {
                                tempSegment.elements.push(multiElement.data);
                            }
                            i++;
                        }
                    }
                    if (tempSegment.dataType === 'desty') {
                        tempSegment = this.helpersService.formatDestySegment(tempSegment);
                    }
                    if (tempSegment.multiSelectedElements != null && tempSegment.multiSelectedElements.length > 1) {
                        for (let i = 0; i < tempSegment.multiSelectedElements.length; i++) {
                            tempSegment.elements[i].newElement = true;
                            this.allocatePartiesToElements(state, { element: tempSegment.elements[i], segment: tempSegment });
                            tempSegment.elements[i] = this.helpersService.setElementInteralInfo(tempSegment.elements[i]);
                        }
                        delete tempSegment.multiSelectedElements;
                    } else {
                        tempSegment.elements[0].newElement = true;
                        this.allocatePartiesToElements(state, { element: tempSegment.elements[0], segment: tempSegment });
                        tempSegment.elements[0] = this.helpersService.setElementInteralInfo(tempSegment.elements[0]);
                    }
                    tempSegment.vtbObjectId = this.helpersService.generateRandomId();
                    if (segment.dataType !== 'desty') {
                        delete tempSegment.title;
                    }
                    delete tempSegment.description;
                    delete tempSegment.dataType;
                    payload.data.segments[i] = tempSegment;
                }
            }
            if (updateFlex === true) {
                payload = this.checkFlexElements(payload);
            }
            payload = this.calc(payload);
            this.store.dispatch({ type: type, payload: payload });
            if (counterAdd === true) {
                this.store.dispatch({ type: 'COUNTER_ADD' });
            }
        } else if (type === 'ITINERARY_LIBORDER_UPDATE') {
            if (payload != null) {
                let liborderData = payload;
                const liborderId = liborderData.liborderId || null;
                let participants = [];
                let participantLength = 0;
                const participantsCopy = JSON.parse(JSON.stringify(state.data.participants));
                for (let party in participantsCopy) {
                    participants.push(participantsCopy[party]);
                    participantLength += participantsCopy[party].length;
                }
                /* to encounter offset bug when dropping lib order, the offset in the liborder elements is relative to the travelplan but should be relative to the segment */
                let currentSegmentNight = 0;
                for (let segment of liborderData.segments) {
                    let segmentNights = 0;
                    segment.elements = segment.elements.sort((a, b) => a.offset - b.offset);
                    for (let element of segment.elements) {
                        element.offset = Math.max(0, element.offset - currentSegmentNight);
                        if (element.maps) {
                            element.maps.zoom = 16;
                        }
                        if (element.externalInfo != null && element.externalInfo.externalType === 'desty') {
                            element.not_calculated = true;
                        } else if (element.olPrices != null && Number(element.olPrices.costPrice) == 0 && Number(element.olPrices.salesTotal) == 0) {
                            element.not_calculated = true;
                        }
                        if (element.templateCalcMethod) {
                            element.marginType = element.templateCalcMethod;
                        }
                        if (element.olTotal) {
                            element.marginTypeTotal = element.olTotal;
                        }
                        if (element.unitId != null && this.unitsValue.items[element.unitId] != null && (this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false)) {
                            if (element.unitAmount === 0) {
                                element.unitAmount = 1;
                            }
                            element.nights = Math.max(eval((this.unitsValue.items[element.unitId].multiplier || 'X*1').replace(/x/ig, element.unitAmount)), 0);
                            segmentNights = Math.max(segmentNights, element.offset + element.nights);
                        } else {
                            element.nights = 0;
                        }
                        if (this.unitsValue && this.unitsValue.items && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId]) {
                            element.optional = this.config.defaultOptionalUnits.indexOf(this.unitsValue.items[element.unitId].id) > -1 ? true : element.optional;
                        }
                        if (element.olPrices) {
                            let tempObj = {};
                            for (let party in participantsCopy) {
                                if (participantsCopy[party].length > 0) {
                                    participantsCopy[party].forEach((parts) => {
                                        tempObj[parts.id] = {
                                            id: parts.id
                                        };
                                        if (element.olPrices.costPrice != null) {
                                            if (Number(element.olPrices.costPrice) !== 0) {
                                                tempObj[parts.id].costPrice = Number(element.olPrices.costPrice) / participantLength;
                                            } else {
                                                tempObj[parts.id].costPrice = 0;
                                            }
                                        } else {
                                            tempObj[parts.id].costPrice = null;
                                        }

                                        if (element.olPrices.salesTotal != null) {
                                            if (Number(element.olPrices.salesTotal) !== 0) {
                                                tempObj[parts.id].salesPrice = Number(element.olPrices.salesTotal) / participantLength;
                                            } else {
                                                tempObj[parts.id].salesPrice = 0;
                                            }
                                        } else {
                                            tempObj[parts.id].salesPrice = null;
                                        }
                                    });
                                }
                            }
                            element.olPrices.participants = tempObj;
                        }
                    }
                    currentSegmentNight += segmentNights;
                    if (segment.flightInfo != null && segment.flightInfo.length > 0) {

                        // Transform flightInfo array from template travelplan that is saved in the database to what the tranfer expects
                        if (this.segmentTypesData.items[segment.typeId].name.toLowerCase().indexOf('harbor') > -1 || this.segmentTypesData.items[segment.typeId].name.toLowerCase().indexOf('harbour') > -1 || this.segmentTypesData.items[segment.typeId].name.toLowerCase().indexOf('haven') > -1) {
                            const harbours = [];
                            for (const flight of segment.flightInfo) {
                                const tempHarbour = {
                                    arrivalAirport: flight.arrivalAirport,
                                    arrivalAirportObject: flight.arrivalAirportObject,
                                    arrivalDate: flight.arrivalDate,
                                    arrivalTime: flight.arrivalTime,
                                    departureDate: flight.arrivalDate,
                                    departureTime: flight.departureTime,
                                    recheckArrivalAirport: false
                                }
                                if (Number(tempHarbour.arrivalTime.replace(':', '')) < Number(tempHarbour.departureTime.replace(':', ''))) {
                                    tempHarbour.departureDate = new Date(tempHarbour.departureDate);
                                    tempHarbour.departureDate = new Date(tempHarbour.departureDate.setDate(tempHarbour.departureDate.getDate() - 1));
                                }

                                if (tempHarbour.arrivalAirportObject == null || Array.isArray(tempHarbour.arrivalAirportObject) === true) {
                                    tempHarbour.recheckArrivalAirport = true;
                                }
                                harbours.push(tempHarbour);
                            }

                            for (const harbour of harbours) {
                                if (harbour.recheckArrivalAirport === true) {
                                    this.iataService.searchAirports(harbour.arrivalAirport, this.config, true).subscribe(data => {
                                        harbour.arrivalAirportObject = data[0];
                                    });
                                }
                                delete harbour.recheckArrivalAirport;
                            }

                            segment.flightInfo = harbours;
                        }
                    }
                }
                /* end */

                // Below code for general information update
                const changeGeneralInfo = [];
                if (!state.data.title && liborderData.title) {
                    state.data.title = liborderData.title;
                } else if (state.data.title && liborderData.title && state.data.title != liborderData.title) {
                    changeGeneralInfo.push({ name: 'Cover Title', fieldName: 'title', allow: true, value: liborderData.title });
                }
                if (!state.data.subTitle && liborderData.subTitle) {
                    state.data.subTitle = liborderData.subTitle;
                } else if (state.data.subTitle && liborderData.subTitle && state.data.subTitle != liborderData.subTitle) {
                    changeGeneralInfo.push({ name: 'Cover Subtitle', fieldName: 'subTitle', allow: true, value: liborderData.subTitle });
                }
                if (state.data.cover.length == 0 && liborderData.cover.length > 0) {
                    state.data.cover = liborderData.cover;
                } else if (state.data.cover.length > 0 && liborderData.cover.length > 0) {
                    let coverExists = true;
                    const objSourceIDs = Object.keys(state.data.cover).map(indexNo => state.data.cover[indexNo].sourceId);
                    for (let index = 0; index < liborderData.cover.length; index++) {
                        if (objSourceIDs.indexOf(liborderData.cover[index].sourceId) === -1) {
                            coverExists = false;
                            break;
                        }
                    }
                    if (coverExists === false) {
                        changeGeneralInfo.push({ name: 'Cover Image(s)', fieldName: 'cover', allow: true, value: liborderData.cover });
                    }
                }
                // Above code for general information update

                // Below code for order texts update
                const changeOrderTexts = [];
                if (liborderData.TSOrder != null && liborderData.TSOrder.texts != null) {
                    if (!state.data.TSOrder.texts.internal && liborderData.TSOrder.texts.internal) {
                        state.data.TSOrder.texts.internal = liborderData.TSOrder.texts.internal;
                    } else if (state.data.TSOrder.texts.internal && liborderData.TSOrder.texts.internal && state.data.TSOrder.texts.internal != liborderData.TSOrder.texts.internal) {
                        changeOrderTexts.push({ name: 'Internal', fieldName: 'internal', allow: true, value: liborderData.TSOrder.texts.internal });
                    }

                    if (!state.data.TSOrder.texts.general && liborderData.TSOrder.texts.general) {
                        state.data.TSOrder.texts.general = liborderData.TSOrder.texts.general;
                    } else if (state.data.TSOrder.texts.general && liborderData.TSOrder.texts.general && state.data.TSOrder.texts.general != liborderData.TSOrder.texts.general) {
                        changeOrderTexts.push({ name: 'General', fieldName: 'general', allow: true, value: liborderData.TSOrder.texts.general });
                    }

                    if (!state.data.TSOrder.texts.proposal && liborderData.TSOrder.texts.proposal) {
                        state.data.TSOrder.texts.proposal = liborderData.TSOrder.texts.proposal;
                    } else if (state.data.TSOrder.texts.proposal && liborderData.TSOrder.texts.proposal && state.data.TSOrder.texts.proposal != liborderData.TSOrder.texts.proposal) {
                        changeOrderTexts.push({ name: 'Proposal', fieldName: 'proposal', allow: true, value: liborderData.TSOrder.texts.proposal });
                    }

                    if (!state.data.TSOrder.texts.invoice && liborderData.TSOrder.texts.invoice) {
                        state.data.TSOrder.texts.invoice = liborderData.TSOrder.texts.invoice;
                    } else if (state.data.TSOrder.texts.invoice && liborderData.TSOrder.texts.invoice && state.data.TSOrder.texts.invoice != liborderData.TSOrder.texts.invoice) {
                        changeOrderTexts.push({ name: 'Invoice', fieldName: 'invoice', allow: true, value: liborderData.TSOrder.texts.invoice });
                    }
                }
                // Above code for order texts update

                const changeQueue = [];
                let directChange = false;
                if (this.config.disableExtraFieldsOverride === false && state.data != null && state.data.extraFieldValues != null && liborderData.extraFieldValues != null) {
                    const liborderFieldMap = liborderData.extraFieldValues.reduce((all, item) => {
                        for (let field of item.fields) {
                            all[field.id] = field;
                        }
                        return all;
                    }, {});

                    for (let extraFieldGroup of state.data.extraFieldValues) {
                        for (let extraField of extraFieldGroup.fields) {
                            if (liborderFieldMap[extraField.id] != null) {
                                if (extraField.value != null && liborderFieldMap[extraField.id].value != null && liborderFieldMap[extraField.id].value !== '' && extraField.value !== liborderFieldMap[extraField.id].value) {
                                    changeQueue.push({ extraField: extraField, liborderField: liborderFieldMap[extraField.id], allow: true });
                                } else {
                                    const field_type = ['CheckBox', 'Date', 'FilteringSelect', 'CountrySelect', 'AirportSelect']; // skip to show history button for these field types
                                    let currentDateTime = this.datepipe.transform(new Date(), 'dd-MM-yy hh:mm');
                                    if (!extraField.oldValues) {
                                        if (!field_type.includes(extraField.field_type) && !field_type.includes(extraField.type)) {
                                            if (extraField && extraField.value && extraField.value.trim()) {
                                                extraField.oldValues = [currentDateTime + ' ' + extraField.value];
                                            }
                                        }
                                    } else {
                                        let valExists = false;
                                        for (let oldValue of extraField.oldValues) {
                                            if (oldValue && extraField && extraField.value && !field_type.includes(extraField.field_type) && oldValue.substring(14).trim() == extraField.value.trim()) {
                                                valExists = true;
                                                break;
                                            }
                                        }
                                        if (valExists === false) {
                                            extraField.oldValues.unshift(currentDateTime + ' ' + extraField.value);
                                            if (extraField.oldValues.length > 5) {
                                                extraField.oldValues.length = 5;
                                            }
                                        }
                                    }
                                    if (extraField.value == null && liborderFieldMap[extraField.id].value != null && liborderFieldMap[extraField.id].value !== '') {
                                        extraField.value = liborderFieldMap[extraField.id].value;
                                        directChange = true;
                                    }
                                }
                            }
                        }
                    }
                }
                const changeDayTexts = [];
                if (payload.dayTexts != null && payload.dayTexts[1].value != null && payload.dayTexts[1].value.length > 0) {
                    changeDayTexts.push({ name: 'Add day texts where you dragged', fieldName: 'addDayTexts', allow: true, value: payload.dayTexts });
                    changeDayTexts.push({ name: 'Overwrite day texts', fieldName: 'overwriteDayTexts', allow: false, value: payload.dayTexts });
                }
                if (changeQueue.length > 0 || changeGeneralInfo.length > 0 || changeOrderTexts.length > 0 || changeDayTexts.length > 0) {
                    this.confirmExtraFieldsChange({ extrafieldsArr: changeQueue, generalInfoArr: changeGeneralInfo, orderTextsArr: changeOrderTexts, dayTextsArr: changeDayTexts }, state);
                } else if (directChange === true) {
                    state.changeCounters.extraFields++;
                    this.communicationService.updateItineraryForOrderExtrafields();
                    this.communicationService.updateItineraryForGeneralInfo();
                    this.communicationService.updateItineraryForDayTexts();
                }

                state.data.segments = state.data.segments.reduce((all, item) => {
                    if (item.hasOwnProperty('liborderId') === true) {
                        for (let liborderSegment of liborderData.segments) {
                            if (liborderSegment.liborderId == item.liborderId) {
                                delete liborderSegment.liborderId;
                                if (liborderSegment.hasOwnProperty('vtbObjectId') === false) {
                                    liborderSegment.vtbObjectId = this.helpersService.generateRandomId();
                                }

                                let newElementArr = [];
                                liborderSegment.elements.reduce((allSegmentElements, element) => {
                                    newElementArr = this.getPreparedNewLibOrderElementArr(element, participants, newElementArr, liborderId);
                                    return allSegmentElements;
                                }, []);
                                liborderSegment.elements = newElementArr;

                                all.push(liborderSegment);
                            }
                        }
                    } else {
                        all.push(item);
                    }
                    if (item.libOrderId != null) {
                        delete item.libOrderId;
                    }
                    return all;
                }, []);
            }
            payload = this.calc(state);
            this.store.dispatch({ type: type, payload: payload });
            this.store.dispatch({ type: 'COUNTER_ADD' });
        }
    }

    getPreparedNewLibOrderElementArr(oldElement, participants, newElementArr, liborderId) {
        const element = this.preparedDefaultElement(oldElement);
        if (element.hasOwnProperty('olPrices')) {
            let singleOrderline = true;
            let participantLength = 0;
            let elemArr = {};
            for (let key in participants) {
                participantLength += participants[key].length;
                if (participants[key].length > 0) {
                    let pmDescription = null;
                    if (element.olPrices.title != null && element.olPrices.title.length > 0) {
                        pmDescription = element.olPrices.title.match(/\[(.+?)\]/);
                    }
                    if (pmDescription != null && (element && element.unitId && (this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false) && this.unitsValue.items[element.unitId].noPartyDivision === false)) {
                        let cntRoomTypes = pmDescription[1].split('|');
                        if (cntRoomTypes.length > 1) {
                            singleOrderline = false;
                            let arrSplitParticipants = this.helpersService.chunk(participants[key], cntRoomTypes.length);
                            arrSplitParticipants.forEach((participant) => {
                                let newPartArr = {};
                                for (let part of participant) {
                                    part = String(part.id);
                                    if (newPartArr.hasOwnProperty(part) === false) {
                                        if (element && element.olPrices && element.olPrices.participants && Object.keys(element.olPrices.participants).length > 0) {
                                            for (const id of Object.keys(element.olPrices.participants)) {
                                                if (id === part) {
                                                    newPartArr[part] = {
                                                        id: part,
                                                        costPrice: 0,
                                                        salesPrice: 0
                                                    };
                                                    if (element.olPrices.participants[id]) {
                                                        if (element.olPrices.participants[id].costPrice && Number(element.olPrices.participants[id].costPrice) !== 0) {
                                                            if (element.olPrices.costPrice && Number(element.olPrices.costPrice) > 0) {
                                                                newPartArr[part].costPrice = Number(element.olPrices.costPrice) / participant.length;
                                                            }
                                                        }
                                                        if (element.olPrices.participants[id].salesPrice && Number(element.olPrices.participants[id].salesPrice) !== 0) {
                                                            if (element.olPrices.salesTotal && Number(element.olPrices.salesTotal) > 0) {
                                                                newPartArr[part].salesPrice = Number(element.olPrices.salesTotal) / participant.length;
                                                                if (newPartArr[part].costPrice === 0 && element.marginTypeTotal && element.marginTypeTotal > 0) {
                                                                    if (element.marginType === 'percentage') {
                                                                        element.olPrices.costPrice = Number(Number(element.olPrices.salesTotal) / (1 + (Number(element.marginTypeTotal) / 100)))
                                                                        newPartArr[part].costPrice = element.olPrices.costPrice / participant.length;
                                                                    } else if (element.marginType === 'margin') {
                                                                        element.olPrices.costPrice = Number(Number(element.olPrices.salesTotal) / 100 * (100 - Number(element.marginTypeTotal)));
                                                                        newPartArr[part].costPrice = element.olPrices.costPrice / participant.length;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            newPartArr[part] = {
                                                id: part,
                                                costPrice: 0,
                                                salesPrice: 0
                                            };
                                        }
                                    }
                                }

                                elemArr = this.createElemArr(elemArr, element, liborderId, newPartArr, cntRoomTypes[participant.length - 1]);
                                if (elemArr['roomTypes'].length > 0) {
                                    let showWarningMisMatchRoomTypeMatchesPart = false;
                                    for (let roomType of elemArr['roomTypes']) {
                                        if (cntRoomTypes[participant.length - 1] === roomType.description) {
                                            showWarningMisMatchRoomTypeMatchesPart = true;
                                        }
                                    }
                                    let itinerary = this.state.getValue().itinerary;
                                    if (showWarningMisMatchRoomTypeMatchesPart == true && this.roomTypeMatchesParticipants(itinerary, elemArr) == false) {
                                        this.notificationsService.warn('Warning', "Element has pax/room type mismatch");
                                    }
                                }
                                newElementArr.push(elemArr);
                            });
                        } else {
                            singleOrderline = false;
                            let participantsObj = {}
                            for (const participant of participants[key]) {
                                if (element && element.olPrices && element.olPrices.participants && Object.keys(element.olPrices.participants).length > 0) {
                                    for (const id of Object.keys(element.olPrices.participants)) {
                                        if (id === participant.id) {
                                            let tempObj = {
                                                id: participant.id,
                                                costPrice: 0,
                                                salesPrice: 0
                                            };
                                            if (element.olPrices.participants[id]) {
                                                if (element.olPrices.participants[id].costPrice && Number(element.olPrices.participants[id].costPrice) !== 0) {
                                                    if (element.olPrices.costPrice && Number(element.olPrices.costPrice) > 0) {
                                                        tempObj.costPrice = Number(element.olPrices.costPrice) / participants[key].length;
                                                    }
                                                }
                                                if (element.olPrices.participants[id].salesPrice && Number(element.olPrices.participants[id].salesPrice) !== 0) {
                                                    if (element.olPrices.salesTotal && Number(element.olPrices.salesTotal) > 0) {
                                                        tempObj.salesPrice = Number(element.olPrices.salesTotal) / participants[key].length;
                                                        if (tempObj.costPrice === 0 && element.marginTypeTotal && element.marginTypeTotal > 0) {
                                                            if (element.marginType === 'percentage') {
                                                                element.olPrices.costPrice = Number(Number(element.olPrices.salesTotal) / (1 + (Number(element.marginTypeTotal) / 100)))
                                                                tempObj.costPrice = element.olPrices.costPrice / participants[key].length;
                                                            } else if (element.marginType === 'margin') {
                                                                element.olPrices.costPrice = Number(Number(element.olPrices.salesTotal) / 100 * (100 - Number(element.marginTypeTotal)));
                                                                tempObj.costPrice = element.olPrices.costPrice / participants[key].length
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            participantsObj[tempObj.id] = tempObj;
                                        }
                                    }
                                } else {
                                    participantsObj[participant.id] = {
                                        id: participant.id,
                                        costPrice: 0,
                                        salesPrice: 0
                                    };
                                }
                            }

                            elemArr = this.createElemArr(elemArr, element, liborderId, participantsObj);
                            this.resetElementSubtitleAndShowPaxMismatchWarning(elemArr, participants[key].length);
                            newElementArr.push(elemArr);
                        }
                    } else {
                        if (this.unitsValue && this.unitsValue.items && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId].noPartyDivision === false) {
                            singleOrderline = false;
                            let participantsObj = {}
                            for (const participant of participants[key]) {
                                if (element && element.olPrices && element.olPrices.participants && Object.keys(element.olPrices.participants).length > 0) {
                                    for (const id of Object.keys(element.olPrices.participants)) {
                                        if (id === participant.id) {
                                            let tempObj = {
                                                id: participant.id,
                                                costPrice: 0,
                                                salesPrice: 0
                                            };
                                            if (element.olPrices.participants[id]) {
                                                if (element.olPrices.participants[id].costPrice && Number(element.olPrices.participants[id].costPrice) !== 0) {
                                                    if (element.olPrices.costPrice && Number(element.olPrices.costPrice) > 0) {
                                                        tempObj.costPrice = Number(element.olPrices.costPrice) / participants[key].length;
                                                    }
                                                }
                                                if (element.olPrices.participants[id].salesPrice && Number(element.olPrices.participants[id].salesPrice) !== 0) {
                                                    if (element.olPrices.salesTotal && Number(element.olPrices.salesTotal) > 0) {
                                                        tempObj.salesPrice = Number(element.olPrices.salesTotal) / participants[key].length;
                                                        if (tempObj.costPrice === 0 && element.marginTypeTotal && element.marginTypeTotal > 0) {
                                                            if (element.marginType === 'percentage') {
                                                                element.olPrices.costPrice = Number(Number(element.olPrices.salesTotal) / (1 + (Number(element.marginTypeTotal) / 100)))
                                                                tempObj.costPrice = element.olPrices.costPrice / participants[key].length;
                                                            } else if (element.marginType === 'margin') {
                                                                element.olPrices.costPrice = Number(Number(element.olPrices.salesTotal) / 100 * (100 - Number(element.marginTypeTotal)));
                                                                tempObj.costPrice = element.olPrices.costPrice / participants[key].length
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            participantsObj[tempObj.id] = tempObj;
                                        }
                                    }
                                } else {
                                    participantsObj[participant.id] = {
                                        id: participant.id,
                                        costPrice: 0,
                                        salesPrice: 0
                                    };
                                }
                            }

                            elemArr = this.createElemArr(elemArr, element, liborderId, participantsObj);
                            this.resetElementSubtitleAndShowPaxMismatchWarning(elemArr, participants[key].length);
                            newElementArr.push(elemArr);
                        }
                    }
                }
            }
            if (singleOrderline === true) {
                elemArr = this.createElemArr(elemArr, element, liborderId);
                this.resetElementSubtitleAndShowPaxMismatchWarning(elemArr, participantLength);
                newElementArr.push(elemArr);
            }
        }
        return newElementArr;
    }

    createElemArr(elemArr, element, liborderId, participants = null, elemSubTitle = null) {
        let subAdditionalText = '';
        let supplierInfo = '';
        let internalText = '';

        if (elemSubTitle != null) {
            if (element.roomTypes && element.roomTypes.length > 0) {
                for (const roomType of element.roomTypes) {
                    if (roomType.description === elemSubTitle) {
                        if (roomType.note != null) {
                            subAdditionalText = roomType.note;
                        }
                        if (roomType.supplierinfo != null) {
                            supplierInfo = roomType.supplierinfo;
                        }
                        if (roomType.internal_info != null) {
                            internalText = roomType.internal_info;
                        }
                        break;
                    }
                }
            }
        } else {
            if (element.roomTypes && element.roomTypes.length > 0) {
                for (const roomType of element.roomTypes) {
                    if (roomType.description === element.subTitle) {
                        if (roomType.note != null) {
                            subAdditionalText = roomType.note;
                        }
                        if (roomType.supplierinfo != null) {
                            supplierInfo = roomType.supplierinfo;
                        }
                        if (roomType.internal_info != null) {
                            internalText = roomType.internal_info;
                        }
                        break;
                    }
                }
            }
        }

        let elementParticipants = null;
        if (participants != null) {
            if (Object.keys(participants).length > 0) {
                elementParticipants = participants;
            } else {
                elementParticipants = element.olPrices.participants;
            }
        } else {
            elementParticipants = element.olPrices.participants;
        }

        elemArr = {
            TSOrderline: {
                id: (element && element.TSOrderline && element.TSOrderline.id) ? element.TSOrderline.id : null,
                liborderId: liborderId,
                locked: (element && element.TSOrderline && element.TSOrderline.locked) ? 1 : 0,
                extraFieldValues: (element && element.TSOrderline && element.TSOrderline.extraFieldValues) ? element.TSOrderline.extraFieldValues : []
            },
            TSProduct: element.TSProduct,
            emptyOlStartDT: element.emptyOlStartDT,
            flexNights: element.flexNights,
            linkedParty: element.linkedParty,
            maps: element.maps || {},
            media: element.media,
            manual_calc: element.manual_calc || false,
            nights: element.nights,
            offset: element.offset,
            olPrices: {
                participants: elementParticipants,
                margin: element.olPrices.margin,
                costPrice: element.olPrices.costPrice,
                discount: element.olPrices.discount,
                discountMethod: element.olPrices.discountMethod,
                exchangeRate: element.olPrices.exchangeRate,
                purchaseCurrency: element.olPrices.purchaseCurrency,
                salesCurrency: element.olPrices.salesCurrency,
                salesTotal: element.olPrices.salesTotal,
                title: element.olPrices.title
            },
            additionalText: element.additionalText,
            optional: element.optional,
            not_calculated: true,
            marginType: element.templateCalcMethod || null,
            marginTypeTotal: element.olTotal || null,
            roomTypes: (this.env.defaultSubtitleSort != null && this.env.defaultSubtitleSort !== '' && this.env.orderLanguage != null) ? this.helpersService.sortSubtitles(element.roomTypes, this.env.defaultSubtitleSort, this.env.orderLanguage, element.dataSource, this.env.enableLanguageSubtitles) : element.roomTypes,
            sellingAmount: element.sellingAmount,
            sellingMarkupType: element.sellingMarkupType,
            subAdditionalText: element.subAdditionalText || subAdditionalText,
            pricematrixSubAdditionalText: element.pricematrixSubAdditionalText,
            supplierInfo: element.supplierInfo || supplierInfo,
            pmPriceSpecialsPurchaseInfo: element.pmPriceSpecialsPurchaseInfo,
            internalText: element.internalText || internalText,
            pricematrixInternalText: element.pricematrixInternalText,
            pricespecialInternalText: element.pricespecialInternalText,
            subTitle: (elemSubTitle) ? elemSubTitle : element.subTitle,
            supplierId: element.supplierId,
            supplierName: element.supplierName,
            supplierComment: element.supplierComment,
            title: element.title,
            unitAmount: element.unitAmount,
            unitId: element.unitId,
            vatAmount: element.vatAmount,
            vatPercentage: element.vatPercentage,
            vatType: element.vatType,
            vtbElementId: this.helpersService.generateRandomId(),
            vtbObjectId: this.helpersService.generateRandomId()
        };

        if (this.unitsValue.items != null && this.unitsValue.items[element.unitId] != null && this.unitsValue.items[element.unitId].flexRelatedProduct === true) {
            elemArr.manualFlex = element.manualFlex || false;
            if (element.relativeOffset === true) {
                elemArr.relativeOffset = true;
                elemArr.recalculateEndOffset = true;
            } else {
                if (element.endOffset != null && element.endOffset > 0) {
                    elemArr.endOffset = element.endOffset;
                }
            }
        }

        return elemArr;
    }

    preparedDefaultElement(element) {
        if (element.hasOwnProperty('olPrices') === false) {
            element.olPrices = {
                participants: {}
            }
        } else if (element.olPrices.hasOwnProperty('participants') === false) {
            element.olPrices.participants = {};
        }

        if (element.unitId != null && this.unitsValue.items[element.unitId] != null && (this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false)) {
            if (element.unitAmount === 0) {
                element.unitAmount = 1;
            }
            element.nights = Math.max(eval((this.unitsValue.items[element.unitId].multiplier || 'X*1').replace(/x/ig, element.unitAmount)), 0);
        } else {
            element.nights = 0;
            if (element.unitId != null && this.unitsValue.items[element.unitId] != null && this.unitsValue.items[element.unitId].flexRelatedProduct === true) {
                element.needsOffsetRecalculation = true;
            }
        }

        if (this.config.manualCalcByDefault === true) {
            element.manual_calc = true;
        }

        if (element.olPrices.hasOwnProperty('participants') === false || typeof element.olPrices.participants !== 'object' || element.olPrices.participants.length > 0) {
            element.olPrices.participants = {};
        }

        if (element.sellingAmount != null && element.sellingMarkupType != null && element.sellingMarkupType !== 'absolute') {
            let margin = Number(element.sellingAmount);
            if (element.sellingMarkupType === 'percentage') {
                margin = (1 - (100 / (100 + margin))) * 100;
            }
            element.olPrices.margin = margin;
        }

        element.not_calculated = true;

        if (element.media != null && element.media.length > 0) {
            element.media = element.media.filter(x => {
                return x.mediaspirit_id != null || x.sourceId != null
            });
        }

        // delete element.TSOrderline;
        element.vtbElementId = this.helpersService.generateRandomId();
        element.vtbObjectId = this.helpersService.generateRandomId();
        return element;
    }

    resetElementSubtitleAndShowPaxMismatchWarning(element, elementParticipantsCount = null) {
        // Tp. templates -> when dropping in VTB, subtitle should be the text from Template which is between[], 
        // And the subtitle for VTB = between [] in the backoffice
        if (element.hasOwnProperty('olPrices') !== false && (element.subTitle == null || element.subTitle === '')) {
            let showWarningMisMatchRoomTypeMatchesPart = false;
            let pmDescription;
            if (element.olPrices.title != null) {
                pmDescription = element.olPrices.title.match(/\[([^)]+)\]/);
            }
            if (pmDescription != null && element.roomTypes && element.roomTypes.length > 0) {
                for (let roomType of element.roomTypes) {
                    if (pmDescription[1] === roomType.description) {
                        element.subTitle = roomType.description;
                        if (roomType.note != null) {
                            element.subAdditionalText = roomType.note;
                        }
                        if (roomType.supplierinfo != null) {
                            element.supplierInfo = roomType.supplierinfo;
                        }
                        if (roomType.internal_info != null) {
                            element.internalText = roomType.internal_info;
                        }
                        showWarningMisMatchRoomTypeMatchesPart = true;
                    }
                }
                let itinerary = this.state.getValue().itinerary;
                if (showWarningMisMatchRoomTypeMatchesPart == true && this.roomTypeMatchesParticipants(itinerary, element) == false) {
                    this.notificationsService.warn('Warning', "Element has pax/room type mismatch");
                }
            } else {
                if (elementParticipantsCount != null && element.roomTypes && element.roomTypes.length > 0) {
                    let matchedRoomTypes = element.roomTypes.filter(roomType => {
                        return elementParticipantsCount <= (roomType.maxPerson || 999) && elementParticipantsCount >= (roomType.minPerson || 0);
                    }).sort((a, b) => {
                        return Number(a.defaultPrice) - Number(b.defaultPrice);
                    });
                    if (matchedRoomTypes.length > 0) {
                        element.subTitle = matchedRoomTypes[0].description;
                        if (matchedRoomTypes[0].note != null) {
                            element.subAdditionalText = matchedRoomTypes[0].note;
                        }
                        if (matchedRoomTypes[0].supplierinfo != null) {
                            element.supplierInfo = matchedRoomTypes[0].supplierinfo;
                        }
                        if (matchedRoomTypes[0].internal_info != null) {
                            element.internalText = matchedRoomTypes[0].internal_info;
                        }
                    }
                }
            }
        }
    }

    resetElementPriceInfo(element) {
        element.subTitle = null;
        element.olPrices.participants = {};
        element.olPrices.costPrice = null;
        element.olPrices.salesTotal = null;
        if (element.hasOwnProperty('caughtError') === true) {
            delete element.caughtError;
        }
        if (element.olPrices.hasError != null) {
            delete element.olPrices.hasError;
            delete element.olPrices.errorType;
        }
    }

    allocatePartiesToElements(itinerary, indexObj, includedParticipants = null, deleteEmpty = false, checkMissingBookableDays = false, elementMove = false) {
        if (itinerary != null && itinerary.data != null && itinerary.data.participants != null) {
            if (checkMissingBookableDays == true) {
                let notifications = [];
                this.checkMissingBookableDays(itinerary, indexObj.segment, indexObj.segmentIndex, indexObj.element, indexObj.elementIndex, notifications, true);
            }
            const parties = Object.keys(itinerary.data.participants);
            const participantsCopy = JSON.parse(JSON.stringify(itinerary.data.participants));
            let initialElementCopy = JSON.parse(JSON.stringify(indexObj.element));
            if (includedParticipants != null) {
                // reset current participants
                this.resetElementPriceInfo(indexObj.element);
                this.resetElementPriceInfo(initialElementCopy);

                const participantIds = Object.keys(includedParticipants);
                for (let party in participantsCopy) {
                    participantsCopy[party] = participantsCopy[party].filter(participant => participantIds.indexOf(String(participant.id)) > -1);
                }
            }
            if (initialElementCopy.unitId != null && initialElementCopy.unitId !== 0 && this.unitsValue.items[initialElementCopy.unitId].noPartyDivision === false && elementMove === false) {
                parties.forEach((party, partyIndex) => {
                    let elementCopy = JSON.parse(JSON.stringify(initialElementCopy));
                    if (partyIndex !== 0) {
                        elementCopy.vtbElementId = this.helpersService.generateRandomId();
                        elementCopy.vtbObjectId = this.helpersService.generateRandomId();
                        // delete elementCopy.TSOrderline;
                        indexObj.elementIndex++;
                        indexObj.element = elementCopy;
                        indexObj.element.date = new Date(indexObj.element.date);
                        indexObj.element.endDate = new Date(indexObj.element.endDate);
                        if (initialElementCopy.externalInfo != null && initialElementCopy.externalInfo.externalType === 'desty') {
                            if (indexObj.segment && indexObj.segment.elements && indexObj.segment.elements.length > 1) {
                                if (indexObj.elementIndex === 0) {
                                    if (this.helpersService.dateDiffInDays(new Date(initialElementCopy.date), new Date(indexObj.segment.date)) === 0) {
                                        indexObj.element.not_calculated = false;
                                    } else {
                                        indexObj.element.not_calculated = true;
                                    }
                                } else {
                                    if (indexObj.segment.elements[indexObj.elementIndex] && indexObj.segment.elements[indexObj.elementIndex].date) {
                                        if (this.helpersService.dateDiffInDays(new Date(initialElementCopy.date), new Date(indexObj.segment.elements[indexObj.elementIndex].date)) === 0) {
                                            indexObj.element.not_calculated = false;
                                        } else {
                                            indexObj.element.not_calculated = true;
                                        }
                                    } else {
                                        if (indexObj.elementIndex) {
                                            if (this.helpersService.dateDiffInDays(new Date(initialElementCopy.date), new Date(indexObj.segment.elements[indexObj.elementIndex - 2].endDate)) === 0) {
                                                indexObj.element.not_calculated = false;
                                            } else {
                                                indexObj.element.not_calculated = true;
                                            }
                                        } else {
                                            indexObj.element.not_calculated = true;
                                        }
                                    }
                                }
                            }
                        }
                        indexObj.segment.elements.splice(indexObj.elementIndex, 0, elementCopy);
                        indexObj.element.linkedParty = party;
                        this.helpersService.setElementParticipants(indexObj, itinerary, participantsCopy[party]);
                    } else if (partyIndex === 0) {
                        indexObj.element.linkedParty = party;
                        this.helpersService.setElementParticipants(indexObj, itinerary, participantsCopy[party]);
                    }

                    // remove empty elements
                    if (deleteEmpty === true && indexObj.element.olPrices.participants != null && Object.keys(indexObj.element.olPrices.participants).length === 0) {
                        indexObj.segment.elements.splice(indexObj.elementIndex, 1);
                        indexObj.elementIndex--;
                    } else {
                        indexObj.element.roomTypeMatchesParticipants = this.roomTypeMatchesParticipants(itinerary, indexObj.element);
                    }
                });
            } else {
                if (includedParticipants != null && elementMove === true) {
                    for (const key in participantsCopy) {
                        if (Object.prototype.hasOwnProperty.call(participantsCopy, key)) {
                            if (participantsCopy[key].length > 0) {
                                this.helpersService.setElementParticipants(indexObj, itinerary, participantsCopy[key]);
                            }
                        }
                    }
                } else {
                    this.helpersService.setElementParticipants(indexObj, itinerary);
                }
                indexObj.element.roomTypeMatchesParticipants = this.roomTypeMatchesParticipants(itinerary, indexObj.element);
            }
        }
    }

    calc(itinerary) {
        const flexElements = [];
        const reservedDays = [];
        const reservedDaysPerParticipant = {};
        const notifications = [];
        this.foundVtbObjectIds = [];

        if (itinerary.data.participants != null && typeof itinerary.data.participants === 'object') {
            for (let party in itinerary.data.participants) {
                for (let participant of itinerary.data.participants[party]) {
                    reservedDaysPerParticipant[String(participant.id)] = [];
                }
            }
        }
        if (itinerary.data.segments.length > 0) {
            let segmentDay = 1;
            let segmentNightsTotal = 0;
            itinerary.data.segments.forEach((segment, segmentIndex) => {
                if (segment != null) {
                    segment.carRentalElements = [];
                    if (segment.elements && segment.elements.length > 0) {
                        if (segment.elements[0].vtbObjectId === segment.vtbObjectId) {
                            segment.vtbObjectId = this.helpersService.generateRandomId();
                        }
                    }
                    if (segment.vtbObjectId == null || this.foundVtbObjectIds.indexOf(segment.vtbObjectId) > -1) {
                        segment.vtbObjectId = this.helpersService.generateRandomId();
                    } else {
                        this.foundVtbObjectIds.push(segment.vtbObjectId);
                    }
                    // set segment day related properties
                    segment.day = segmentDay;
                    if (itinerary.data.startDate != null) {
                        let date = new Date(itinerary.data.startDate);
                        date.setDate(date.getDate() + (segmentDay - 1));
                        segment.date = date;
                    }

                    if (segment.vtbSegmentId == null) {
                        segment.vtbSegmentId = this.helpersService.generateRandomId();
                    }

                    if (segment.hasOwnProperty('nights') === false) {
                        segment.nights = 0;
                    }

                    let foundElementsOverlap: Boolean = false;

                    // set element related properties
                    segment.missingBookableDays = false;
                    let segmentNights = 0;
                    if (segment.elements != null && segment.elements.length > 0) {
                        let totalSegmentNights = this.getSegmentNights(segment);
                        let previousElement;
                        let temporaryParticipants = [];
                        let elementsOrder = [];
                        let elementsMapActive = [];

                        segment.elements.forEach((element, elementIndex) => {
                            if (element != null) {
                                if (element.vtbObjectId == null || this.foundVtbObjectIds.indexOf(element.vtbObjectId) > -1) {
                                    element.vtbObjectId = this.helpersService.generateRandomId();
                                } else {
                                    this.foundVtbObjectIds.push(element.vtbObjectId);
                                }
                                if ((element.internalText != null && element.internalText.toLowerCase().indexOf('past matrixline is used') > -1) || (element.pricematrixInternalText != null && element.pricematrixInternalText.toLowerCase().indexOf('past matrixline is used') > -1) || (element.pricespecialInternalText != null && element.pricespecialInternalText.toLowerCase().indexOf('past matrixline is used') > -1)) {
                                    element.pastMatrixLineIsUsed = true;
                                } else {
                                    element.pastMatrixLineIsUsed = false;
                                }
                                this.checkPartyMatch(element, itinerary.data.participants);
                                elementsOrder.push(element.vtbObjectId);

                                if (element.maps != null && element.maps.enabled != null && element.maps.enabled === true) {
                                    elementsMapActive.push(element.vtbObjectId);
                                }

                                if (this.env.smartMode === true) {
                                    if (previousElement && element && element.unitId && element.olPrices && element.optional === false && this.unitsValue && this.unitsValue.items[element.unitId] && (this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false)) {
                                        element.hasPreviousElementParticipantOverlap = false;
                                        const participantIds = Object.keys(element.olPrices.participants || {});
                                        if (participantIds.length === 0) {
                                            element.hasPreviousElementParticipantOverlap = true;
                                        }
                                        const previousParticipantIds = previousElement && temporaryParticipants.length > 0 ? temporaryParticipants : null;
                                        if (previousParticipantIds != null) {
                                            for (const participantId of participantIds) {
                                                for (const tempId of temporaryParticipants) {
                                                    if (tempId === participantId) {
                                                        element.hasPreviousElementParticipantOverlap = true;
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    } else if (element && element.unitId && element.optional === false && (this.unitsValue.items && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false)) {
                                        element.hasPreviousElementParticipantOverlap = true;
                                    }

                                    let setAsPreviousElement = true;
                                    if (previousElement != null && element != null && element.unitId != null && this.unitsValue != null && this.unitsValue.items[element.unitId] != null && (this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false)) {
                                        if (this.unitsValue.flexId[element.unitId] != null || element.optional === true || (element.hasPreviousElementParticipantOverlap != null && element.hasPreviousElementParticipantOverlap === false && previousElement != null)) {
                                            if (this.unitsValue.items[previousElement.unitId].multiplier === this.unitsValue.items[element.unitId].multiplier && this.unitsValue.items[previousElement.unitId].reverseMultiplier === this.unitsValue.items[element.unitId].reverseMultiplier) {
                                                element.offset = previousElement != null ? previousElement.offset : 0;
                                                element.nights = previousElement != null ? previousElement.nights : element.nights || 0;
                                                element.unitAmount = previousElement != null ? previousElement.unitAmount : element.unitAmount || 0;
                                                setAsPreviousElement = false;
                                            }
                                        } else {
                                            element.offset = previousElement != null ? previousElement.nights + previousElement.offset : 0;
                                        }
                                    } else if (previousElement && itinerary.data.savedDate == null && element.needsOffsetRecalculation != null && element.needsOffsetRecalculation === true) {
                                        if ((element.offset < previousElement.offset) || (element.offset > segmentNights)) {
                                            if (this.unitsValue != null && this.unitsValue.items[element.unitId] != null && this.unitsValue.items[element.unitId].allowOverlap === true) {
                                                element.offset = element.offset - segmentNights;
                                            } else {
                                                element.offset = previousElement != null ? segmentNights : 0;
                                            }
                                        }
                                        if (element.offset == null) {
                                            element.offset = 0; // Resolved: orderline has no startdate -> NaN on create VTB
                                        }
                                        element.offset = Math.min(element.offset, totalSegmentNights); // force non time based unit to have segmentNights as max value offset
                                        delete element.needsOffsetRecalculation;
                                    } else {
                                        if (element.offset == null) {
                                            element.offset = 0; // Resolved: orderline has no startdate -> NaN on create VTB
                                        }
                                        if (element && element.unitId && this.unitsValue && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId].flexRelatedProduct === false) {
                                            if (this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false) {
                                                element.offset = 0;
                                            } else {
                                                element.offset = Math.min(element.offset, totalSegmentNights); // force non time based unit to have segmentNights as max value offset
                                            }
                                        } else {
                                            if (itinerary.data.savedDate == null && element.needsOffsetRecalculation != null && element.needsOffsetRecalculation === true && this.unitsValue != null && this.unitsValue.items[element.unitId] != null && this.unitsValue.items[element.unitId].allowOverlap === true) {
                                                element.offset = segmentNights - element.offset;
                                                delete element.needsOffsetRecalculation;
                                            }
                                        }
                                    }
                                    if (setAsPreviousElement === false && element.optional === false) {
                                        temporaryParticipants = temporaryParticipants.concat(Object.keys(element.olPrices.participants || {}));
                                    }
                                    if (element && element.unitId && this.unitsValue && this.unitsValue.items[element.unitId] && (this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false) && element.optional === false && setAsPreviousElement === true) {
                                        previousElement = element;
                                        temporaryParticipants = Object.keys(element.olPrices.participants || {});
                                    }
                                } else {
                                    // detect overlap logic
                                    let foundElementOverlap = false;
                                    if (element.optional === false && element.unitId === 2 && element.nights > 0) { // only elements with night unitType and with nights > 0 should be counted
                                        for (let x = 0; x < element.nights; x++) {
                                            let val = element.day + x;

                                            if (Object.keys(element.olPrices.participants || {}).length > 0) {
                                                for (let participant in element.olPrices.participants) {
                                                    participant = String(participant);
                                                    if (reservedDaysPerParticipant[participant].indexOf(val) === -1) {
                                                        reservedDaysPerParticipant[participant].push(val);
                                                    } else {
                                                        foundElementOverlap = true;
                                                    }
                                                }
                                            } else {
                                                if (reservedDays.indexOf(val) === -1) {
                                                    reservedDays.push(val);
                                                } else {
                                                    foundElementOverlap = true;
                                                }
                                            }
                                        }
                                    }
                                    if (foundElementOverlap === true) {
                                        element.hasOverlap = true;
                                        foundElementsOverlap = true;
                                        notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'overlap' });
                                    } else {
                                        element.hasOverlap = false;
                                    }
                                    // end of detect overlap logic
                                }

                                element.day = segmentDay + (element.offset || 0);

                                if (itinerary.data.startDate != null) {
                                    let date = new Date(itinerary.data.startDate);
                                    date.setDate(date.getDate() + (element.day - 1));
                                    element.date = date;

                                    /* might get overriden in later stage @ flex? */
                                    let endDate = new Date(itinerary.data.startDate);
                                    let endDateOffset = 0;
                                    if (element.day != null && element.nights != null) {
                                        endDateOffset = element.day + element.nights - 1;
                                    }
                                    let replaceAmount = 0;

                                    if (this.unitsValue != null && this.unitsValue.items != null && this.unitsValue.items[element.unitId]) {
                                        replaceAmount = Math.max(eval((this.unitsValue.items[element.unitId].multiplier || 'X*1').replace(/x/ig, element.unitAmount)), 0);
                                    }

                                    if (this.unitsValue.items[element.unitId] != null && this.unitsValue.items[element.unitId].allowOverlap === true && this.unitsValue.items[element.unitId].isTimeBased === true) {
                                        endDateOffset = element.day - 1 + replaceAmount;
                                    }

                                    endDate.setDate(endDate.getDate() + endDateOffset);
                                    element.endDate = endDate;
                                    /* end of possible dup */

                                    // if element has unitId set to flex....
                                    if (this.unitsValue.flexId[element.unitId] != null) {
                                        let originalSegmentIndex = 0;
                                        if (element.day <= segment.day + segment.nights) {
                                            element.startSegmentIndex = segmentIndex;
                                            if (segment.carRentalElements == null) {
                                                segment.carRentalElements = [];
                                            }
                                            segment.carRentalElements.push({ segmentIndex, element });
                                            originalSegmentIndex = segmentIndex;
                                        } else {
                                            for (let i = segmentIndex; i < itinerary.data.segments.length; i++) {
                                                if (element.day <= itinerary.data.segments[i].day + itinerary.data.segments[i].nights) {
                                                    element.startSegmentIndex = i;
                                                    if (itinerary.data.segments[i].carRentalElements == null) {
                                                        itinerary.data.segments[i].carRentalElements = [];
                                                    }
                                                    itinerary.data.segments[i].carRentalElements.push({ segmentIndex, element });
                                                    originalSegmentIndex = segmentIndex;
                                                    break;
                                                }
                                            }
                                        }
                                        flexElements.push({ element, segment, segmentIndex: originalSegmentIndex, segments: itinerary.data.segments });
                                    } else {
                                        delete element.startSegmentIndex;
                                        delete element.flexNights;
                                    }
                                }

                                if (this.unitsValue && this.unitsValue.items && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false && (this.unitsValue.items[element.unitId].flexRelatedProduct === false || this.unitsValue.items[element.unitId].flexRelated === false)) {
                                    if (element.optional === false && element.hasOwnProperty('flexNights') === false) {
                                        if (segmentNights < (element.nights + element.offset)) {
                                            segmentNights = (element.nights + element.offset);
                                        }
                                    }
                                }

                                // check if bookableDays array exists and has at least one bookable day(no bookable day set === all days are bookable)
                                this.checkMissingBookableDays(itinerary, segment, segmentIndex, element, elementIndex, notifications, true);
                                element.roomTypeMatchesParticipants = this.roomTypeMatchesParticipants(itinerary, element);
                                if (element.roomTypeMatchesParticipants === false && element.subTitleLock != true) {
                                    notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'room-type-mismatch', elementId: element.vtbObjectId });
                                }
                                if (element.unitId !== this.unitsValue.roundId) {
                                    this.identifyElementRecalc(element);
                                }
                                if (element.olPrices && element.olPrices.participants) {
                                    element.olPrices.participants = this.helpersService.removeEmptyParticipants(element.olPrices.participants);
                                }
                                if (element.media && element.media.length > 0) {
                                    for (const media of element.media) {
                                        if (media.mediaspirit_id != null) {
                                            media.sourceId = media.mediaspirit_id;
                                            delete media.mediaspirit_id;
                                            if (media.sourceType == null) {
                                                media.sourceType = 'mediaspirit';
                                            }
                                        }
                                    }
                                }
                                if (element.TSOrderline && element.TSOrderline.extraFieldValues && element.TSOrderline.extraFieldValues.length > 0) {
                                    if (element.TSOrderline.oldExtraFieldLength == null || element.TSOrderline.oldExtraFieldLength != element.TSOrderline.extraFieldValues.length) {
                                        element.TSOrderline.extraFieldValues = this.helpersService.removeDuplicateExtraFields(element.TSOrderline.extraFieldValues);
                                        element.TSOrderline.oldExtraFieldLength = element.TSOrderline.extraFieldValues.length;
                                    }
                                    for (const extraField of element.TSOrderline.extraFieldValues) {
                                        if (extraField.vtbElementId == null) {
                                            extraField.vtbElementId = this.helpersService.generateRandomId();
                                        }
                                        if (extraField.vtbObjectId == null) {
                                            extraField.vtbObjectId = this.helpersService.generateRandomId();
                                        }
                                        if (extraField.value == null) {
                                            extraField.value = null;
                                        }
                                    }
                                }
                                if (element.ProductTravelInfo != null && element.ProductTravelInfo.startdate_default != null) {
                                    element.emptyOlStartDT = element.ProductTravelInfo.startdate_default === "empty" ? true : false;
                                }

                                if (this.env.quickExtraFieldEdit != null) {
                                    const quickExtraFieldData = this.checkQuickExtraField(segment.typeId, element.unitId);
                                    if (quickExtraFieldData != null) {
                                        element.quickExtraFieldEdit = quickExtraFieldData;
                                    } else {
                                        delete element.quickExtraFieldEdit;
                                    }
                                } else if (element.quickExtraFieldEdit != null) {
                                    delete element.quickExtraFieldEdit;
                                }

                                // Check if nights should be set on the element based on the unit
                                if (this.unitsValue.flexId[element.unitId] != null) {
                                    if (this.unitsValue.flexId[element.unitId].isTimeBased === false) {
                                        element.nights = 0;
                                    }
                                }

                                this.checkDestyBookedDates(element, elementIndex, segmentIndex, notifications);
                            }
                        });

                        segment.elementsOrder = elementsOrder.join('|');
                        segment.elementsMapActive = elementsMapActive.join('|');

                        if (foundElementsOverlap === true) {
                            segment.hasElementsOverlap = true;
                        } else {
                            segment.hasElementsOverlap = false;
                        }

                        // set total days if smaller than segmentNights
                        if (segment.nights < segmentNights || this.config.disableSegmentNightsEdit === true) {
                            segment.nights = segmentNights;
                        }
                    }

                    if (itinerary.data.startDate != null) {
                        let endDate = new Date(itinerary.data.startDate);
                        let endDateOffset = segmentDay + segment.nights - 1;
                        endDate.setDate(endDate.getDate() + endDateOffset);
                        segment.endDate = endDate;
                    }

                    if (segment.media && segment.media.length > 0) {
                        for (const media of segment.media) {
                            if (media.mediaspirit_id != null) {
                                media.sourceId = media.mediaspirit_id;
                                delete media.mediaspirit_id;
                                if (media.sourceType == null) {
                                    media.sourceType = 'mediaspirit';
                                }
                            }
                        }
                    }

                    // prepare segmentDay for next segment
                    segmentDay += segment.nights;
                    segmentNightsTotal += segment.nights;
                    this.identifySegmentRecalc(segment, segmentIndex);
                }
                this.previousSegmentStates[segment.vtbObjectId] = JSON.parse(JSON.stringify(segment));
            });

            if (this.segmentTypesData != null && Object.keys(this.segmentTypesData).length > 0) {
                if (itinerary.data.segments != null && itinerary.data.segments.length > 0) {
                    itinerary.data.segments.forEach((segment, segmentIndex) => {
                        if (segment != null && segment.elements != null && segment.elements.length > 0) {
                            segment.elements.forEach((element) => {
                                if (element.insuranceData == null) {
                                    element.insuranceData = this.helpersService.checkInsurance(element, segment, this.segmentTypesData);
                                }
                                if (element.insuranceLocked === false || element.insuranceLocked == null) {
                                    if (element.insuranceData.type != null) {
                                        const ignoredSegmentTypes = this.helpersService.getIgnoredForInsuranceArray(this.segmentTypesData, segment);
                                        if (element.insuranceTimeStamp == null || (Date.now() - element.insuranceTimeStamp) > 1000) {
                                            element.olPrices.salesTotal = this.helpersService.calculateInsurance(itinerary.data.segments, segmentIndex, element.insuranceData.price, element.insuranceData.type, ignoredSegmentTypes, this.segmentTypesData);
                                            element.olPrices.participants = this.helpersService.calculateParticipantsInsurance(element.olPrices.participants, element.olPrices.salesTotal);
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
            }
            if (this.segmentTypesData != null && Object.keys(this.segmentTypesData).length > 0) {
                if (itinerary.data.segments != null && itinerary.data.segments.length > 0) {
                    itinerary.data.segments.forEach((segment, segmentIndex) => {
                        if (segment != null && segment.elements != null && segment.elements.length > 0) {
                            segment.elements.forEach((element) => {
                                if (element.insuranceData == null) {
                                    element.insuranceData = this.helpersService.checkInsurance(element, segment, this.segmentTypesData);
                                }
                                if (element.insuranceLocked === false || element.insuranceLocked == null) {
                                    if (element.insuranceData.type != null) {
                                        const ignoredSegmentTypes = this.helpersService.getIgnoredForInsuranceArray(this.segmentTypesData, segment);
                                        if (element.insuranceTimeStamp == null || (Date.now() - element.insuranceTimeStamp) > 1000) {
                                            element.olPrices.salesTotal = this.helpersService.calculateInsurance(itinerary.data.segments, segmentIndex, element.insuranceData.price, element.insuranceData.type, ignoredSegmentTypes, this.segmentTypesData);
                                            element.olPrices.participants = this.helpersService.calculateParticipantsInsurance(element.olPrices.participants, element.olPrices.salesTotal);
                                            element.insuranceTimeStamp = Date.now();
                                        }
                                    }
                                }
                            });
                        }
                    });
                }
            }
            if (itinerary.data.resetTable == null) {
                itinerary.data.resetTable = true;
            } else {
                itinerary.data.resetTable = null;
            }

            if (itinerary.data.deletedIndex != null) {
                this.changedNightsIndex = itinerary.data.deletedIndex;
            }
            if (this.previousSegmentNightsAmount == null) {
                this.previousSegmentNightsAmount = segmentNightsTotal;
            } else if (this.previousSegmentNightsAmount !== segmentNightsTotal) {
                const nightDifference = this.previousSegmentNightsAmount > segmentNightsTotal ? this.previousSegmentNightsAmount - segmentNightsTotal : segmentNightsTotal - this.previousSegmentNightsAmount;
                for (let flexItem of flexElements) {
                    if (flexItem.element.relativeOffset === true) {
                        if (this.changedNightsIndex != null && this.changedNightsIndex > flexItem.segmentIndex) {
                            if (itinerary.data.deletedIndex == null) {
                                if (this.previousSegmentNightsAmount > segmentNightsTotal) {
                                    flexItem.element.endOffset = flexItem.element.endOffset - nightDifference;
                                } else {
                                    if (flexItem.element.endOffset == null) {
                                        let calculatedEndOffset = nightDifference;
                                        for (const element of flexItem.segment.elements) {
                                            if (element.vtbElementId === flexItem.element.vtbElementId) {
                                                break;
                                            }
                                            calculatedEndOffset -= element.nights;
                                        }
                                        flexItem.element.endOffset = calculatedEndOffset;
                                    } else {
                                        flexItem.element.endOffset = flexItem.element.endOffset + nightDifference;
                                    }
                                }
                            } else {
                                flexItem.element.endOffset = flexItem.element.endOffset - nightDifference;
                            }
                        }
                    }
                }
                this.previousSegmentNightsAmount = segmentNightsTotal;
                this.changedNightsIndex = null;
            }
            if (itinerary.data.deletedIndex != null) {
                delete itinerary.data.deletedIndex;
            }

            // set total days if smaller than total segmentDay
            if (itinerary.data.hasOwnProperty('totalDays') === false || itinerary.data.totalDays < (segmentDay) || this.config.disableItineraryNightsEdit === true) {
                itinerary.data.totalDays = segmentDay;
            }
            itinerary.data.totalDaysAllocated = segmentDay;

            for (let flexItem of flexElements) {
                if (flexItem.element.flexSettings != null) {
                    if (this.unitsValue.items[flexItem.element.unitId].flexRelatedProduct === true) {
                        let extraMinOffset = 0;
                        if (flexItem.element.manualOffsetCalc != null) {
                            extraMinOffset = Number(flexItem.element.manualOffsetCalc) * 86400000 * 2;
                        }
                        if ((flexItem.element.endDate.getTime() - (flexItem.element.endOffset * 86400000) + extraMinOffset) !== flexItem.element.flexSettings.previousSegmentDate.getTime()) {
                            if ((flexItem.element.endDate.getTime() - (flexItem.element.endOffset * 86400000) + extraMinOffset) < flexItem.element.flexSettings.previousSegmentDate.getTime()) {
                                const timeDiff = flexItem.element.flexSettings.previousSegmentDate.getTime() - (flexItem.element.endDate.getTime() - (flexItem.element.endOffset * 86400000) + extraMinOffset);
                                const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));
                                flexItem.element.endOffset = dayDiff;
                            } else {
                                const timeDiff = (flexItem.element.endDate.getTime() - (flexItem.element.endOffset * 86400000) + extraMinOffset) - flexItem.element.flexSettings.previousSegmentDate.getTime();
                                const dayDiff = Math.round(timeDiff / (1000 * 3600 * 24));
                                flexItem.element.endOffset = dayDiff;
                            }
                        } else {
                            flexItem.element.endOffset = 0;
                        }
                    }
                    delete flexItem.element.flexSettings;
                    delete flexItem.element.manualOffsetCalc;
                } else if (flexItem.element.recalculateEndOffset === true) {
                    let totalNights = 0;
                    for (let i = 0; i < flexItem.segments.length; i++) {
                        totalNights += flexItem.segments[i].nights;
                        if (flexItem.segmentIndex === i) {
                            break;
                        }
                    }
                    flexItem.element.endOffset = segmentNightsTotal - totalNights;
                    delete flexItem.element.recalculateEndOffset;
                }
                let val = Math.max(eval((this.unitsValue.items[flexItem.element.unitId].reverseMultiplier || 'X*1').replace(/x/ig, (itinerary.data.totalDays - (flexItem.element.endOffset || 0)) - flexItem.element.day)), 0);
                if (flexItem.element.manualFlex) {
                    val = flexItem.element.unitAmount;
                } else {
                    flexItem.element.unitAmount = val;
                }
                if (val !== flexItem.element.flexNights) {
                    if (flexItem.segment.hasOwnProperty('flexChangeCounter') === false) {
                        flexItem.segment.flexChangeCounter = 0;
                    }
                    flexItem.segment.flexChangeCounter++;
                }
                flexItem.element.flexNights = val; // using nights will result in weird smartMode behaviour, so we need to use flexNights

                let endDate = new Date(itinerary.data.startDate);
                let endDateOffset = flexItem.element.day + flexItem.element.flexNights - 1;
                let replaceAmount = 0;

                if (this.unitsValue != null && this.unitsValue.items != null && this.unitsValue.items[flexItem.element.unitId]) {
                    replaceAmount = Math.max(eval((this.unitsValue.items[flexItem.element.unitId].multiplier || 'X*1').replace(/x/ig, flexItem.element.unitAmount)), 0);
                }

                if (this.unitsValue.items[flexItem.element.unitId] != null && this.unitsValue.items[flexItem.element.unitId].allowOverlap === true && this.unitsValue.items[flexItem.element.unitId].isTimeBased === true) {
                    endDateOffset = flexItem.element.day - 1 + replaceAmount;
                }
                endDate.setDate(endDate.getDate() + endDateOffset);
                flexItem.element.endDate = endDate;
            }

            // Check flight departure date with segment start date
            itinerary.data.segments.forEach((segment, segmentIndex) => {
                for (let flexElement of flexElements) {
                    const flexEnd = (flexElement.element.day - 1) + Math.max(eval((this.unitsValue.items[flexElement.element.unitId].multiplier || 'X*1').replace(/x/ig, flexElement.element.flexNights + (flexElement.element.manualOffsetCalc || 0))), 0);
                    if (flexEnd >= segment.day && (flexEnd < segment.day + segment.nights) && this.checkCarRentalElements(segment.carRentalElements, flexElement.element) === false && !flexElement.pushed) {
                        const offsetSegmentIndex = this.getEndSegmentIndex(itinerary.data.segments, segmentIndex, flexElement.element.flexNights);
                        flexElement.element.endSegmentIndex = offsetSegmentIndex;
                        flexElement.pushed = true;
                        if (itinerary.data.segments[offsetSegmentIndex].carRentalElements == null) {
                            itinerary.data.segments[offsetSegmentIndex].carRentalElements = [];
                        }
                        itinerary.data.segments[offsetSegmentIndex].carRentalElements.push({ segmentIndex: flexElement.segmentIndex, element: flexElement.element });
                    }
                }
                if (itinerary.data.templateView == null || itinerary.data.templateView === false) {
                    // Check flight departure date with segment start date
                    if (this.segmentTypesData != null && this.segmentTypesData.items != null && this.segmentTypesData.items[segment.typeId] != null) {
                        if (this.segmentTypesData.items[segment.typeId].name.toLowerCase().indexOf('harbor') < 0 && this.segmentTypesData.items[segment.typeId].name.toLowerCase().indexOf('harbour') < 0 && this.segmentTypesData.items[segment.typeId].name.toLowerCase().indexOf('haven') < 0) {
                            if (segment.flightInfo != null && segment.flightInfo.length > 0) {
                                if (segment.flightInfo[0].departureDate) {
                                    if (this.helpersService.checkDates(new Date(segment.date), new Date(segment.flightInfo[0].departureDate)) === false) {
                                        segment.hasFlightMismatch = true;
                                        notifications.push({ segmentIndex: segmentIndex, type: 'flight-departure-date-mismatch' });
                                    } else {
                                        segment.hasFlightMismatch = false;
                                    }
                                }
                                for (const flight of segment.flightInfo) {
                                    if (flight.vtbFlightId == null) {
                                        flight.vtbFlightId = this.helpersService.generateRandomId();
                                    }
                                }
                            } else if (segment.hasFlightMismatch === true) {
                                segment.hasFlightMismatch = false;
                            }
                        } else {
                            if (segment.flightInfo && segment.flightInfo.length > 0) {
                                if (segment.flightInfo[0].arrivalDate) {
                                    if (this.helpersService.checkDates(new Date(segment.date), new Date(segment.flightInfo[0].arrivalDate)) === false) {
                                        segment.hasFlightMismatch = true;
                                        notifications.push({ segmentIndex: segmentIndex, type: 'flight-departure-date-mismatch' });
                                    } else {
                                        segment.hasFlightMismatch = false;
                                    }
                                }
                            } else {
                                segment.hasFlightMismatch = false;
                            }
                        }
                    } else if (segment.hasFlightMismatch === true) {
                        segment.hasFlightMismatch = false;
                    }
                } else if (segment.hasFlightMismatch === true) {
                    segment.hasFlightMismatch = false;
                }

                if (segment.elements && segment.elements.length > 0) {
                    segment.elements.forEach((element, elementIndex) => {
                        // Check unit amount and price
                        if ((element.unitAmount === 0 && this.unitsValue.items && this.unitsValue.items[element.unitId] && this.helpersService.isText(this.unitsValue.items[element.unitId]) === false) || (element.unitAmount === 0 && this.unitsValue.items && this.unitsValue.items[element.unitId] && this.helpersService.isText(this.unitsValue.items[element.unitId]) === true && element.olPrices && ((element.olPrices.costPrice && element.olPrices.costPrice > 0) || (element.olPrices.salesTotal && element.olPrices.salesTotal > 0)))) {
                            if (element.hasError != null && element.hasError.indexOf("failed fetching live prices") > -1) {
                                notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'failed-live-prices-unit-amount' });
                                element.unitAmountPriceError = true;
                                element.olPrices.hasError += ' and unit amount is 0, so price cannot be calculated';
                            } else {
                                if (this.unitsValue.items && this.unitsValue.items[element.unitId] && this.helpersService.isText(this.unitsValue.items[element.unitId]) === true) {
                                    notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'unit-name-text' });
                                    element.unitAmountPriceError = true;
                                    element.olPrices.hasError = 'Selected unit is a text unit and will not be recalculated';
                                } else {
                                    notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'unit-amount-price-error' });
                                    element.unitAmountPriceError = true;
                                    element.olPrices.hasError = 'Unit amount is 0, so price cannot be calculated';
                                }
                            }
                        } else {
                            if (element.hasError != null && element.hasError.indexOf("failed fetching live prices") > -1) {
                                notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'failed-live-prices' });
                            } else {
                                element.unitAmountPriceError = false;
                                if (element.olPrices && element.olPrices.hasError && element.olPrices.hasError === 'Unit amount is 0, so price cannot be calculated') {
                                    element.olPrices.hasError = '';
                                }
                            }
                        }

                        if (element.olPrices && element.olPrices.hasError && element.manual_calc !== true) {
                            // Check unit is a text unit
                            if (element.olPrices.errorType === 'text') {
                                notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'unit-name-text' });
                            }
                        }

                        // Check for participant link errors
                        if (element.olPrices != null && element.olPrices.participants != null && Object.keys(element.olPrices.participants).length === 0) {
                            notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'linked-participants-error' });
                            element.olPrices.hasError = 'There are no linked participants, please check';
                        } else if (element.olPrices.hasError === 'There are no linked participants, please check') {
                            element.olPrices.hasError = '';
                        }
                    });
                }
            });

            // set endDate
            if (itinerary.data.startDate != null) {
                let date = new Date(itinerary.data.startDate);
                date.setDate(date.getDate() + (itinerary.data.totalDays - 1));
                if (this.env.smartMode === true || (itinerary.data.hasOwnProperty('endDate') === false || (itinerary.data.endDate != null && date.valueOf() > itinerary.data.endDate.valueOf()))) {
                    itinerary.data.endDate = date;
                }
            }
        } else {
            itinerary.data.totalDays = 0;
        }
        if (itinerary.data.extraFieldValues != null && itinerary.data.extraFieldValues.length > 0) {
            for (const extraField of itinerary.data.extraFieldValues) {
                if (extraField.fields != null && extraField.fields.length > 0) {
                    for (const field of extraField.fields) {
                        if (field.value == null) {
                            field.value = null;
                        }
                    }
                }
            }
        }
        if (notifications != null && notifications.length > 0) {
            itinerary.data.notifications = notifications.sort((a, b) => a.segmentIndex === b.segmentIndex ? a.elementIndex - b.elementIndex : a.segmentIndex - b.segmentIndex);
        } else {
            itinerary.data.notifications = [];
        }

        // Day texts are initially set/created when amount of nights updates
        if (itinerary.data.dayTexts == null || Object.keys(itinerary.data.dayTexts).length < itinerary.data.totalDays) {
            if (itinerary.data.dayTexts == null) {
                itinerary.data.dayTexts = {};
            }
            for (let i = itinerary.data.totalDays; i > 0; i--) {
                if (itinerary.data.dayTexts[i] == null) {
                    itinerary.data.dayTexts[i] = {
                        value: '',
                        vtbObjectId: this.helpersService.generateRandomId()
                    };
                }
            }
        } else if (Object.keys(itinerary.data.dayTexts).length === itinerary.data.totalDays) {
            for (let i = itinerary.data.totalDays; i > 0; i--) {
                if (itinerary.data.dayTexts[i] != null && itinerary.data.dayTexts[i].value == null) {
                    itinerary.data.dayTexts[i] = {
                        value: itinerary.data.dayTexts[i],
                        vtbObjectId: this.helpersService.generateRandomId()
                    };
                }
            }
        } else {
            const dayTexts = {};
            for (let i = itinerary.data.totalDays; i > 0; i--) {
                const dayValue = itinerary.data.dayTexts[i].value != null ? itinerary.data.dayTexts[i].value : (itinerary.data.dayTexts[i].length > 0 ? itinerary.data.dayTexts[i] : '');
                dayTexts[i] = {
                    value: dayValue,
                    vtbObjectId: itinerary.data.dayTexts[i] && itinerary.data.dayTexts[i].vtbObjectId != null ? itinerary.data.dayTexts[i].vtbObjectId : this.helpersService.generateRandomId()
                };
            }
            itinerary.data.dayTexts = dayTexts;
        }

        return itinerary;
    }

    checkCarRentalElements(elements, element) {
        if (elements != null && elements.length > 0) {
            for (const carElement of elements) {
                if (carElement.element.vtbElementId === element.vtbElementId) {
                    return true;
                }
            }
        }
        return false;
    }

    checkMissingBookableDays(itinerary, segment, segmentIndex, element, elementIndex, notifications, displayNotification = false) {
        if (element.TSProduct != null && element.TSProduct.bookableDays != null && element.TSProduct.bookableDays.length > 0) {
            let missingBookableDays = [];
            let tempDate = new Date(element.date);
            if (element.TSProduct.bookableDays.indexOf(this.daysMap[tempDate.getDay()]) === -1) {
                missingBookableDays.push(this.daysMap[tempDate.getDay()]);
                notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'missing-bookable-days' });
            }
            if (this.unitsValue.items[element.unitId] != null && this.unitsValue.items[element.unitId].name.includes('flights-') === false && this.unitsValue.items[element.unitId].name.includes('excursion-') === false) {
                if (element.nights > 1) {
                    for (let x = 0; x < element.nights; x++) {
                        tempDate.setDate(tempDate.getDate() + 1);
                        if (element.TSProduct.bookableDays.indexOf(this.daysMap[tempDate.getDay()]) === -1) {
                            missingBookableDays.push(this.daysMap[tempDate.getDay()]);
                            notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'missing-bookable-days' });
                        }
                    }
                }
            }
            element.missingBookableDays = missingBookableDays;
            if (missingBookableDays.length > 0) {
                segment.missingBookableDays = true;
                if (displayNotification == true) {
                    this.notificationsService.warn('Warning', "A non-bookable day in it's period!");
                } else {
                    itinerary.data.bookableDaysWarning = true;
                }
            }
        }
    }

    checkDestyBookedDates(element, elementIndex, segmentIndex, notifications) {
        if (element.dataSource && element.dataSource === 'desty') {
            if (element.externalInfo != null && element.externalInfo.destyBookingData != null && element.externalInfo.destyBookingData.reservationDate != null) {
                let destyCheckInDate = new Date(element.externalInfo.destyBookingData.checkInDate);
                let elementStartDate = new Date(element.date);

                if (destyCheckInDate.getHours() > 0) {
                    if (destyCheckInDate.getTimezoneOffset() < 0) {
                        const offset = destyCheckInDate.getTimezoneOffset() / 60 * -1;
                        if (destyCheckInDate.getHours() === offset) {
                            destyCheckInDate = this.helpersService.getNewDateWithoutTimezone(destyCheckInDate);
                        }
                    } else {
                        const offset = destyCheckInDate.getTimezoneOffset() / 60;
                        if (destyCheckInDate.getHours() === 24 - offset) {
                            destyCheckInDate = this.helpersService.getNewDateWithoutTimezone(destyCheckInDate);
                        }
                    }
                }
                if (elementStartDate.getHours() > 0) {
                    if (elementStartDate.getTimezoneOffset() < 0) {
                        const offset = elementStartDate.getTimezoneOffset() / 60 * -1;
                        if (elementStartDate.getHours() === offset) {
                            elementStartDate = this.helpersService.getNewDateWithoutTimezone(elementStartDate);
                        }
                    } else {
                        const offset = elementStartDate.getTimezoneOffset() / 60;
                        if (elementStartDate.getHours() === 24 - offset) {
                            elementStartDate = this.helpersService.getNewDateWithoutTimezone(elementStartDate);
                        }
                    }
                }

                if (destyCheckInDate.getTime() !== elementStartDate.getTime()) {
                    element.destyBookingMismatch = true;
                    notifications.push({ elementIndex: elementIndex, segmentIndex: segmentIndex, type: 'desty-booked-date-mismatch' });
                } else {
                    if (element.destyBookingMismatch === true) {
                        delete element.destyBookingMismatch;
                    }
                }
            }
        }
    }

    checkQuickExtraField(segmentId, unitId) {
        const foundFields = [];
        if (this.env.quickExtraFieldEdit[segmentId] != null) {
            if (this.env.quickExtraFieldEdit[segmentId][unitId] != null) {
                foundFields.push(this.env.quickExtraFieldEdit[segmentId][unitId]);
            }
            if (this.env.quickExtraFieldEdit[segmentId]['all'] != null) {
                foundFields.push(this.env.quickExtraFieldEdit[segmentId]['all']);
            }
        } else if (this.env.quickExtraFieldEdit['all'] != null) {
            if (this.env.quickExtraFieldEdit['all'][unitId] != null) {
                foundFields.push(this.env.quickExtraFieldEdit['all'][unitId]);
            }
            if (this.env.quickExtraFieldEdit['all']['all'] != null) {
                foundFields.push(this.env.quickExtraFieldEdit['all']['all']);
            }
        }
        if (foundFields.length > 0) {
            const tempObj = {};
            for (const field of foundFields) {
                if (Object.keys(field).length > 0) {
                    for (const key of Object.keys(field)) {
                        tempObj[key] = field[key];
                    }
                } else {
                    tempObj[Object.keys(field)[0]] = field;
                }
            }
            return tempObj;
        }
        return null;
    }

    getSegmentNights(segment) {
        let segmentAmount = 0;

        segment.elements.forEach(element => {
            if (this.unitsValue && this.unitsValue.items && this.unitsValue.items[element.unitId] && this.unitsValue.items[element.unitId].isTimeBased === true && this.unitsValue.items[element.unitId].allowOverlap === false && (this.unitsValue.items[element.unitId].flexRelatedProduct === false || this.unitsValue.items[element.unitId].flexRelated === false)) {
                if (segmentAmount < (element.nights + element.offset)) {
                    segmentAmount = (element.nights + element.offset);
                }
            }
        });

        return segmentAmount;
    }

    getElementIndex(segment, elementObj) {
        let elementIndex = 0;
        for (const element of segment.elements) {
            if (element.vtbElementId === elementObj.vtbElementId) {
                return elementIndex
            } else {
                elementIndex++;
            }
        }
        return 0;
    }

    getEndSegmentIndex(segments, segmentIndex, endDay) {
        let endIndex = segmentIndex;
        for (let i = segmentIndex; i < segments.length; i++) {
            if (endDay >= segments[i].day && endDay <= segments[i].day + (segments[i].nights || 0)) {
                endIndex = i;
                if (segments[i + 1] == null || (segments[i + 1].day + (segments[i + 1].nights || 0) > endDay)) {
                    break;
                }
            }
        }
        return endIndex;
    }

    checkFlexElements(payload) {
        if (payload != null && payload.data != null) {
            if (payload.data.segments != null && payload.data.segments.length > 0) {
                for (const segment of payload.data.segments) {
                    if (segment.elements != null && segment.elements.length > 0) {
                        for (let i = 0; i < segment.elements.length; i++) {
                            if (this.unitsValue != null && this.unitsValue.items != null && segment.elements[i].unitId != null && this.unitsValue.items[segment.elements[i].unitId] != null && this.unitsValue.items[segment.elements[i].unitId].flexRelatedProduct === true) {
                                if (segment.elements[i].flexUpdated === true) {
                                    const tempManFlex = segment.elements[i].manualFlex || false;
                                    const tempRelOffset = segment.elements[i].relativeOffset || false;
                                    const tempOffset = segment.elements[i].offset || 0;
                                    const tempEndOffset = segment.elements[i].endOffset || 0;

                                    if (segment.elements[i].optional === false) {
                                        for (let j = i; j < segment.elements.length; j++) {
                                            if (this.unitsValue.items[segment.elements[j].unitId].flexRelatedProduct === true) {
                                                segment.elements[j].manualFlex = tempManFlex;
                                                segment.elements[j].relativeOffset = tempRelOffset;
                                                segment.elements[j].offset = tempOffset;
                                                segment.elements[j].endOffset = tempEndOffset;
                                            } else {
                                                break;
                                            }
                                        }
                                        delete segment.elements[i].flexUpdated;
                                    } else if (segment.elements[i].optional === true) {
                                        for (let j = i; j < segment.elements.length; j++) {
                                            if (this.unitsValue.items[segment.elements[j].unitId].flexRelatedProduct === true) {
                                                segment.elements[j].manualFlex = tempManFlex;
                                                segment.elements[j].relativeOffset = tempRelOffset;
                                                segment.elements[j].offset = tempOffset;
                                                segment.elements[j].endOffset = tempEndOffset;
                                            } else {
                                                break;
                                            }
                                        }
                                        for (let x = i; x >= 0; x--) {
                                            if (this.unitsValue.items[segment.elements[x].unitId].flexRelatedProduct === true) {
                                                segment.elements[x].manualFlex = tempManFlex;
                                                segment.elements[x].relativeOffset = tempRelOffset;
                                                segment.elements[x].offset = tempOffset;
                                                segment.elements[x].endOffset = tempEndOffset;
                                            } else {
                                                break;
                                            }
                                        }
                                        delete segment.elements[i].flexUpdated;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return payload;
    }
}
